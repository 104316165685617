import { Routes } from "@angular/router";
import { CompanyBenchmarkComponent } from "../components/new/company/company-benchmark/company-benchmark.component";
import { CompanyEnergyComponent } from "../components/new/company/company-energy/company-energy.component";
import { CompanyMeasureComponent } from "../components/new/company/company-measure/company-measure.component";
import { CompanyOverviewComponent } from "../components/new/company/company-overview/company-overview.component";

export const routes: Routes = [
    {
        path: '',
        component: CompanyOverviewComponent,
    },
    {
        path: 'energy-follow-up',
        children: [
            {
                path: '',
                redirectTo: 'supplied',
                pathMatch: 'full',
            },
            {
                path: 'supplied',
                component: CompanyEnergyComponent,
                data: { type: "supplied" }
            },
            {
                path: 'used',
                component: CompanyEnergyComponent,
                data: { type: "used" }
            }
        ]
    },
    {
        path: 'benchmark',
        component: CompanyBenchmarkComponent,
    },
    {
        path: 'measures',
        component: CompanyMeasureComponent,
    }
]