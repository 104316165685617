<div ngbDropdown position="bottom-right" display="dynamic" class="d-inline-block">
  <fa-icon id="user-menu-toggle" ngbDropdownToggle [icon]="icon" size="2x"></fa-icon>

  <div ngbDropdownMenu id="user-menu" class="p-3" aria-labelledby="user-menu-toggle">
    <h6>{{ userInfo?.first_name }} {{ userInfo?.last_name }}</h6>

    <div *ngIf="userInfo.is_coordinator">
      <div class="dropdown-divider"></div>

      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="customSwitches" [(ngModel)]="coordinatorMode" (change)="coordinatorChange()">
        <label class="custom-control-label" for="customSwitches">{{ 'app.coordinator-mode' | translate }}</label>
      </div>
    </div>

    <div class="dropdown-divider"></div>

    <ng-select [items]="supportedLanguages" (change)="switchLanguage($event)" [clearable]="false"
      placeholder="{{ 'app.select-language' | translate }}" [(ngModel)]="selectedLanguage"></ng-select>

    <div class="dropdown-divider"></div>

    <button class="btn btn-block btn-primary" (click)="logout()">{{ 'view.login.logout' | translate }}</button>
  </div>
</div>