<div class="container">
  <div class="row flex-column">
    <h5>{{ 'formula.heading' | translate }}</h5>
    <div class="mb-3">
      <ng-katex [equation]="formula.toString()"></ng-katex>
    </div>
  </div>

  <div class="row">
    <button class="btn btn-outline-primary btn-sm mb-3 mr-1" (click)="formula.addLink()" *ngIf="allowLinks">
      {{ 'formula.new_equation' | translate }}
    </button>
    <button class="btn btn-outline-primary btn-sm mb-3 mr-1" (click)="formula.addConstant()">
      {{ 'formula.new_constant' | translate }}
    </button>
    <button class="btn btn-outline-primary btn-sm mb-3 mr-1" (click)="formula.addSensor()" *ngIf="allowSensors">
      {{ 'formula.new_sensor' | translate }}
    </button>
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary btn-sm mb-3 mr-1" id="operatorDropdown" ngbDropdownToggle>
        {{ 'formula.new_operator' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="operatorDropdown">
        <form class="px-2 py-2">
          <button class="btn btn-primary mr-1 mb-1 box-button" *ngFor="let o of operators"
            (click)=formula.addOperator(o.data)>{{ o.show }}</button>
        </form>
      </div>
    </div>
    <button class="btn btn-outline-primary btn-sm mb-3 mr-1" (click)="formula.clear()">
      {{ 'formula.clear' | translate }}
    </button>
    <button class="btn btn-outline-primary btn-sm mb-3 mr-1" (click)="formula.removeLast()">
      <fa-icon [icon]="faBackspace"></fa-icon>
    </button>
  </div>

  <div class="row mb-3" *ngFor="let e of formula.formula; let i=index;">

    <div *ngIf="e.isOperator">
      <div>{{e.operator}}</div>
    </div>

    <div *ngIf="e.sensor" class="keyvalue-link">
      <ng-select [items]="sensorList" bindValue="id" bindLabel="id" [(ngModel)]="e.sensorID">
        <ng-template ng-option-tmp let-item="item">
          <div><span>{{item.description}}</span></div>
          <small><b>Id:</b> {{item.id}}</small>
        </ng-template>
      </ng-select>
    </div>

    <div *ngIf="e.equation" class="keyvalue-link">
      <ng-select [(ngModel)]="e.linkID" placeholder="{{ 'formula.link' | translate }}" [items]="linkList" [clearable]="false" bindLabel="show"
        groupBy="process" bindValue="uuid">
      </ng-select>
    </div>

    <div *ngIf="e.equation" class="keyvalue-link-value">
      <ng-select [(ngModel)]="e.equationName" placeholder="{{ 'formula.equation' | translate }}" [items]="equationNames" [clearable]="false"
        bindLabel="show" bindValue="data">
      </ng-select>
    </div>

    <div *ngIf="e.constant" class="keyvalue-constant-value">
      <input type="number" [(ngModel)]="e.constantValue">
    </div>

  </div>
</div>
