import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APINetworkLicense, LicenseService } from '../../../../../shared/services/license.service';
import { APINetwork, NetworkForm, NetworkService, PublicURL } from '../../../../../shared/services/network.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { APIUser, UserService } from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-networks-form',
  templateUrl: './networks-form.component.html',
  styleUrls: ['./networks-form.component.scss']
})
export class NetworksFormComponent implements OnInit {

  mode: string
  network: APINetwork = new APINetwork

  readonly: boolean = true

  users$: Observable<APIUser[]>

  licenses$: Observable<APINetworkLicense[]>

  specificYears: boolean
  years: number[] = []
  selectedYears: number[] = []


  locationVisuals = [
    {value: "country", display: "view.admin.networks.visualisations.country", active: false},
    {value: "region", display: "view.admin.networks.visualisations.region", active: false},
    {value: "municipality", display: "view.admin.networks.visualisations.municipality", active: false},
  ]

  sniVisuals = [
    {value: "section", display: "view.admin.networks.visualisations.section", active: false},
    {value: "division", display: "view.admin.networks.visualisations.division", active: false},
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private _networkService: NetworkService,
    private _userService: UserService,
    private _licenseService: LicenseService,
    private _router: Router,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.years = this.getYears(1970)
    this.users$ = this._userService.adminList()
    this.licenses$ = this._licenseService.networkList()
    this.activatedRoute.params.subscribe(
      data => {
        if (data["id"] == undefined) {
          this.network = new APINetwork
          this.network.owner = this._userService.getUserInfo().id
          return
        }
        this._networkService.getNetwork(data["id"]).subscribe(
          (networkInfo: APINetwork) => {
            this.network = networkInfo
            this.specificYears = networkInfo.config.time_years.length > 0
            this.selectedYears = [...this.network.config.time_years].map(e => Number(e))
            this.selectLocation(this.network.config.location_level)
            this.selectSNI(this.network.config.sni_level)
          }
        )
      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        console.log(this.mode)
        if (this.mode != "view") {
          this.readonly = false
        }
      }
    )

  }

  getYears(startYear: number): number[] {
    let currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

  save() {
    let form: NetworkForm = new NetworkForm
    form.name = this.network.name
    form.description = this.network.description
    form.owner = this.network.owner
    if (this.mode == "new") {
      this.create(form)
    } else if (this.mode == "edit") {
      this.update(this.network.id, form)
    }
  }

  updateLicense() {
    this._networkService.setLicense(this.network.id, this.network.config.license).subscribe(
      data => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

  selectLocation(v: string) {
    this.locationVisuals.forEach(e => {
      e.active = v == e.value
    })
    this.network.config.location_level = v
  }

  selectSNI(v: string) {
    this.sniVisuals.forEach(e => {
      e.active = v == e.value
    })
    this.network.config.sni_level = v
  }

  updateConfig() {
    if(this.specificYears) {
      this.network.config.time_years = [...this.selectedYears].map(e => String(e))
    } else {
      this.network.config.time_years = []
    }
    this._networkService.updateConfig(this.network.id, this.network.config).subscribe(
      data => {
        this._toastService.saved()
        this.selectedYears = [...data.time_years].map(e => Number(e))
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

  create(form: NetworkForm) {
    this._networkService.create(form).subscribe(
      data => {
        this._router.navigateByUrl(`/admin/networks/${data.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

  update(id: number, form: NetworkForm) {
    this._networkService.update(id, form).subscribe(
      data => {
        console.log(data)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

  addURL() {
    if (this.network.public_urls.length > 0 && !this.network.public_urls[0].key) {
      return
    }
    let url = new PublicURL
    url.network = this.network.id
    this.network.public_urls = [url, ...this.network.public_urls]
  }

  removeURL(idx: number) {
    this.network.public_urls.splice(idx, 1)
  }

}
