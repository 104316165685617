import { Component, OnInit } from '@angular/core';
import { faCheck, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit {

  faCheck = faCheck;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  hidden: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.navigateByUrl('/investment');
    this.hidden = false;
  }

  select(method: number) {
    if (method == 1) {
      this.router.navigateByUrl('/investment/payback');
      this.hidden = true
    } else if (method == 2) {
      this.router.navigateByUrl('/investment/present-value');
      this.hidden = true
    } else if (method == 3) {
      this.router.navigateByUrl('/investment/lcc');
      this.hidden = true
    } else {
      this.router.navigateByUrl('/investment');
      this.hidden = false;
    }
  }
}
