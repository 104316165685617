import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, CurrencyService } from '../../../services/currency.service';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit {

  @Input('value')
  value: number;

  @Output('valueChange')
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input('disabled')
  disabled: boolean;

  @Input('currency')
  currency: Currency

  constructor(
    private _currencyService: CurrencyService,
  ) { }

  ngOnInit(): void {}

  onValueChange() {
    this.valueChange.emit(this.value)
  }

}
