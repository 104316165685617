import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APIObjectDetailed, CompanyObjectService } from '../../../../../shared/services/company-object.service';
import * as _ from 'lodash';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
  selector: 'app-demo-form',
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss']
})
export class DemoFormComponent implements OnInit {
  @Input("edit") edit: boolean = true;
  @Input("templates") templates: APIObjectDetailed[] = [];
  @Input("company") companyRef: APIObjectDetailed;
  company: APIObjectDetailed;
  template: APIObjectDetailed;

  constructor(
    public activeModal: NgbActiveModal,
    private _objectService: CompanyObjectService,
    private _toastService: ToastService,
  ) { }

  ngOnInit() {
    this.company = !this.companyRef ? new APIObjectDetailed() : new APIObjectDetailed(this.companyRef)
  }

  save() {
    this.create(this.company, this.template);
  }

  create(c: APIObjectDetailed, t: APIObjectDetailed) {
    this._objectService.v2_createDemoCompany(t.id, c.name).subscribe((company: APIObjectDetailed) => {
      this.company = company;
      _.forOwn(this.companyRef, (_, key) => { this.companyRef[key] = this.company[key] });
      this.activeModal.close(this.company);
      this._toastService.saved()
    }, (_: any) => {
      this._toastService.server()
    });
  }

}
