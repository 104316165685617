import { Component, OnInit } from '@angular/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import * as Chart from 'chart.js'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'app';

  faSignOutAlt = faSignOutAlt;
  supportedLanguages = ['en', 'sv', 'ru'];

  constructor(
    private _authService: AuthService,
    private translate: TranslateService
  ) {
    let lang = sessionStorage.getItem("lang");
    if (!lang) {
      lang = navigator.language.split('-')[0] in this.supportedLanguages ? navigator.language.split('-')[0] : 'en';
      sessionStorage.setItem("lang", lang);
    }
    translate.setDefaultLang('en');
    translate.use(lang);
  }

  ngOnInit() {
    Chart.defaults.global.defaultFontSize = 14
  }

}
