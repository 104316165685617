import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    private http: HttpClient,
  ) { }

  objectList(): Observable<APIObjectLicense[]> {
    return this.http.get("/api/v2/admin/licenses/object").pipe(
      map((res: APIObjectLicense[]) => { return res })
    )
  }

  objectDetails(id: number): Observable<APIObjectLicense> {
    return this.http.get(`/api/v2/admin/licenses/object/${id}`).pipe(
      map((res: APIObjectLicense) => { return res })
    )
  }

  objectCreate(license: APIObjectLicense): Observable<APIObjectLicense> {
    return this.http.post("/api/v2/admin/licenses/object", license).pipe(
      map((res: APIObjectLicense) => { return res; })
    )
  }

  objectUpdate(id: number, license: APIObjectLicense): Observable<APIObjectLicense> {
    return this.http.put(`/api/v2/admin/licenses/object/${id}`, license).pipe(
      map((res: APIObjectLicense) => { return res; })
    )
  }

  objectDelete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/admin/licenses/object/${id}`)
  }

  networkList(): Observable<APINetworkLicense[]> {
    return this.http.get("/api/v2/admin/licenses/network").pipe(
      map((res: APINetworkLicense[]) => { return res })
    )
  }

  networkDetails(id: number): Observable<APINetworkLicense> {
    return this.http.get(`/api/v2/admin/licenses/network/${id}`).pipe(
      map((res: APINetworkLicense) => { return res })
    )
  }

  networkCreate(license: APINetworkLicense): Observable<APINetworkLicense> {
    return this.http.post("/api/v2/admin/licenses/network", license).pipe(
      map((res: APINetworkLicense) => { return res; })
    )
  }

  networkUpdate(id: number, license: APINetworkLicense): Observable<APINetworkLicense> {
    return this.http.put(`/api/v2/admin/licenses/network/${id}`, license).pipe(
      map((res: APINetworkLicense) => { return res; })
    )
  }

  networkDelete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/admin/licenses/network/${id}`)
  }

}

export class APIObjectLicense {
  id: number
  name: string
  price: number
  nr_users: number
  views: { [view_key: string]: boolean } = {}
}

export class APINetworkLicense {
  id: number
  name: string
  price: number
  nr_users: number
  nr_sites: number
  views: { [view_key: string]: boolean } = {}
}