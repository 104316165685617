<div class="container-fluid">
  <div class="d-flex justify-content-center mb-3">
    <app-date-span class="" (change)="yearChange($event)" onlyYear="true"></app-date-span>
  </div>

  <div class="row">
    <div class="col-md-6">
      <h5>{{ 'view.sites.supplied.total' | translate }}</h5>
      <hr>
      <div class="row row-cols-sm-2 row-cols-md-2 row-cols-xl-2">
        <div class="col mb-3" *ngFor="let g of energyForm.general | slice:0:2">
          <app-carrier skipTooltip="true" inputDisable="true" [data]="g"></app-carrier>
        </div>
      </div>

      <h5>{{ 'view.sites.supplied.supply' | translate }}</h5>
      <hr>
      <div class="row row-cols-sm-2 row-cols-md-2 row-cols-xl-2">
        <div class="col mb-3">
          <app-carrier [(form)]="energyForm" [data]="energyForm.general[2]"></app-carrier>
        </div>
        <div class="col mb-3">
          <app-carrier skipTooltip="true" [(form)]="energyForm" [data]="energyForm.general[3]"></app-carrier>
        </div>
        <div class="col mb-3" *ngFor="let f of energyForm.fuels">
          <app-carrier (saved)="updateView()" [(form)]="energyForm" [data]="f"></app-carrier>
        </div>
      </div>
    </div>

    <!-- GRAPH COLUMN -->
    <div class="col-md-6">
      <div class="card card-block" style="margin-bottom: 8px">
        <p style="text-align: center">{{ 'view.sites.supplied.carrier-chart' | translate }} [MWh]</p>
        <canvas #energyCarrierChart id="energyCarrierChart"></canvas>
      </div>
      <div class="card card-block">
        <p style="text-align: center"> {{ 'view.sites.supplied.carbon-chart' | translate }} [kg]</p>
        <canvas #energyCarbonChart id="energyCarbonChart"></canvas>
      </div>
    </div>
  </div>
</div>