<div class="container-fluid">
  <div class="video-wrapper">
    <div class="video-box card">
      <div class="card-body">
        <h6>Användarguide</h6>
        <iframe src="https://drive.google.com/file/d/14uBWaiKu26N_BL7JYHNZ5k2WeRY7OSG3/preview" width="640" height="360"></iframe>
      </div>
    </div>
    <div class="video-box card">
      <div class="card-body">
        <h6>Energieffektivisering Hexatronic</h6>
        <span class="author">Örjan Bäcklund, Hexatronic</span>
        <iframe src="https://drive.google.com/file/d/14ksgoK-wv1ttvxkE9gH71kr_IsBad6sF/preview" width="640" height="360"></iframe>
      </div>
    </div>
    <div class="video-box card">
      <div class="card-body">
        <h6>LEAN, energi, ledarskap och värdeflöde</h6>
        <span class="author">Per-Erik Johansson, Dynamate</span>
        <iframe src="https://drive.google.com/file/d/14nADjma8BOJEQGZ0xv9gBcBDLyzLK_fW/preview" width="640" height="360"></iframe>
      </div>
    </div>
    <div class="video-box card">
      <div class="card-body">
        <h6>Volvo CEs energieffektiviseringsarbete</h6>
        <span class="author">Johan Wollin, Volvo CE</span>
        <iframe src="https://drive.google.com/file/d/14ijlNJQT2Pi595u2s36QN0UVlxkC_MYj/preview" width="640" height="360"></iframe>
      </div>
    </div>
    <div class="video-box card">
      <div class="card-body">
        <h6>Energieffektivisering Bumax</h6>
        <span class="author">Carina Tronelius, Bumax</span>
        <iframe src="https://drive.google.com/file/d/103mC3NhVVP0Ki9_Tdbmmsq10z9RhJx5j/preview" width="640" height="360"></iframe>
      </div>
    </div>
  </div>
</div>