import { Component, OnInit } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-lcc',
  templateUrl: './lcc.component.html',
  styleUrls: ['./lcc.component.scss']
})
export class LccComponent implements OnInit {

  lcc: LCCVars = new LCCVars;
  faTooltip = faQuestionCircle;

  constructor() { }

  ngOnInit() {
  }

  calcLCC() {
    let factor: number;
    if (this.lcc.interest == 0) {
      factor = this.lcc.years;
    } else {
      factor = (1 - Math.pow((1 + (0.01 * this.lcc.interest)), -this.lcc.years)) / (0.01 * this.lcc.interest);
    }
    this.lcc.ans = this.lcc.investment + (factor * (this.lcc.yearlyCost + this.lcc.yearlyMaintenance + this.lcc.otherCosts - this.lcc.restValue));
  }

}

export class LCCVars {
  investment: number;
  yearlyCost: number;
  yearlyMaintenance: number;
  otherCosts: number;
  restValue: number;
  interest: number;
  years: number;
  ans: number;
}