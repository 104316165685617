import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getCountries(): Observable<Country[]> {
    return this.http.get("/api/v2/locations").pipe(
      map((countries: Country[]) => { return countries })
    )
  }

  getStates(countryID: number): Observable<State[]> {
    return this.http.get(`/api/v2/locations/${countryID}`).pipe(
      map((states: State[]) => { return states })
    )
  }

  getMunicipalities(countryID: number, stateID: number): Observable<Municipality[]> {
    return this.http.get(`/api/v2/locations/${countryID}/${stateID}`).pipe(
      map((municipalities: Municipality[]) => { return municipalities })
    )
  }
}

export class Location {
  id: number;
  name: string;
}

export class Country extends Location {
  code: string;
}

export class State extends Location {
  country: number;
}

export class Municipality extends Location {
  state: number;
}