<div class="container-fluid">
    <app-table [columns]="columns" [data]="companyObjects" [settings]="settings" [loading]="loadingObjects">
        <ng-template #field let-item="item">
            <div *ngIf="item.mapped" class="d-flex flex-column align-items-center">
                <span>
                    {{ item.latest_used }}
                </span>
                <div class="d-flex">
                    <fa-icon [icon]="faCircle" class="mr-2" size="xs"
                        [ngClass]="{'good': item.mapped > 0.95, 'ok': item.mapped >= 0.9 && item.mapped <= 0.95, 'bad': item.mapped < 0.9}">
                    </fa-icon>
                    <span class="font-italic font-weight-light">{{ item.mapped*100 | number : '1.0-0' }} %</span>
                </div>
            </div>
            <div *ngIf="!item.mapped" class="d-flex flex-column align-items-center">-</div>
        </ng-template>
    </app-table>
</div>