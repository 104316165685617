import { Component, OnInit, Input } from '@angular/core';
import { SniService, SNIBase, SNI } from '../../../../shared/services/sni.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash'
import { CategoryService, APICategory, APISubcategory } from '../../../../shared/services/category.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SavingsService, SearchForm, SearchResult } from '../../../../shared/services/savings.service';
import { SelectorEvent, TableColumn, TableSelector, TableSettings } from '../../../../shared/components/table/table.component';
import { Currency, CurrencyService } from '../../../../shared/services/currency.service';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  @Input('publicData')
  publicData: boolean;

  @Input('publicDataKey')
  publicDataKey: string;

  searchForm: SearchForm = new SearchForm

  searchResult: SearchResult = new SearchResult;

  //V2 impl
  v2_categories: APICategory[] = [];
  v2_subcategories: APISubcategory[] = [];
  v2_sniSection: SNIBase[] = [];
  v2_sniDivision: SNI = new SNI;

  //Icons
  faQuestionCircle = faQuestionCircle;
  faList = faList;

  // BAT table
  batColumns: TableColumn[] = [
    { name: "", key: "name" },
    { name: "", key: "", template: true }
  ]
  batTableSettings: TableSettings = new TableSettings(
    "view.database.bat-measures", false, false, false, false, false, true, "", "view.database.tooltips.bat-body"
  )
  batNoData: string = "view.database.no-bat"

  //Savings table
  resultColumns: TableColumn[] = []
  resultTableSettings: TableSettings = new TableSettings(
    "view.database.measures", false, false, true, false, true, false,
    "", "")
  resultTableselectors: TableSelector[] = []
  resultNoData: string = "view.database.no-match"

  tablesLoading: boolean = false;

  currency: Currency;

  constructor(
    private categoryService: CategoryService,
    private sniService: SniService,
    private _savingsService: SavingsService,
    private _currencyService: CurrencyService,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        //Update categories
        this.v2_getCategories(e.lang)
        if (this.searchForm.category) {
          this.v2_getSubCategories(this.searchForm.category, e.lang)
        }
        //Update sni
        this.v2_getBaseSNI(e.lang)
        if (this.searchForm.sni) {
          this.v2_getSNIDivision(this.searchForm.sni, e.lang)
        }
      })
  }

  ngOnInit() {
    this.setTableSelectors()
    this.setResultTableColumns()
    this.v2_getCategories(sessionStorage.getItem("lang"))
    this.v2_getBaseSNI(sessionStorage.getItem("lang"))
  }

  setTableSelectors() {
    let currencySelector = new TableSelector("currency", this._currencyService.supported.map(c => c.code))
    this.resultTableselectors = [currencySelector]
    this.currency = this._currencyService.getCurrency(currencySelector.current)
  }

  setResultTableColumns() {
    this.resultColumns = [
      { name: "view.database.measure", key: "name" },
      { name: "view.database.saving", key: "carriers.total", unit: "units.energy-year" },
      { name: "view.database.investment", key: "cost", unit: this.currency.code },
      { name: "view.database.year", key: "date_implemented", tooltip: "view.database.tooltips.year-implemented" },
    ]
  }

  categoryChange(category: APICategory) {
    this.searchForm.subcategory = null;
    if (!category) return
    this.v2_getSubCategories(category.id, sessionStorage.getItem("lang"))
  }

  clearCategory() {
    this.searchForm.subcategory = null;
  }

  subcategoryDesc() {
    let s = _.find(this.v2_subcategories, ['id', this.searchForm.subcategory]);
    return s.desc
  }

  v2_getSubCategories(categoryID: number, lang?: string) {
    this.categoryService.v2_getCategoryDetails(categoryID, lang).subscribe(
      category => {
        this.v2_subcategories = category.subcategories;
      }
    )
  }

  v2_getCategories(lang?: string) {
    this.categoryService.v2_getCategories(lang).subscribe(
      categories => {
        this.v2_categories = categories
      }
    );
  }

  sniChange(sni: SNIBase) {
    this.searchForm.subsni = null
    if (!sni) return
    this.v2_getSNIDivision(sni.code, sessionStorage.getItem("lang"))
  }

  clearSNI() {
    this.searchForm.subsni = null;
  }

  v2_getBaseSNI(lang?: string) {
    this.sniService.v2_listSNI(lang).subscribe(
      (snis: SNIBase[]) => { this.v2_sniSection = snis })
  }

  v2_getSNIDivision(parent: string, lang?: string) {
    this.sniService.v2_getSNI(parent, lang).subscribe(
      (sni: SNI) => { this.v2_sniDivision = sni })
  }

  search(form: SearchForm, currency?: string) {
    this.tablesLoading = true
    this._savingsService.search(form, currency).subscribe(
      (res: SearchResult) => {
        if (!form.subcategory) {
          let bats = []
          this.v2_subcategories.forEach(s => { bats.push(...s.bats) });
          res.bats = _.uniqBy(bats, 'id')
        } else {
          let s = _.find(this.v2_subcategories, ['id', form.subcategory])
          res.bats = s.bats
        }
        this.searchResult = res
        this.tablesLoading = false
      }
    )
  }

  onSearch() {
    this.search(this.searchForm, this.currency.code)
  }

  onSelectorChange(event: SelectorEvent) {
    console.log(event)
    if (event.selectorID == "currency") {
      this.currency = this._currencyService.getCurrency(event.new)
      this.setResultTableColumns()
      //Check if there is active search parameters
      if (this.searchForm.category || this.searchForm.sni) {
        this.search(this.searchForm, this.currency.code)
      }
    }
  }

}

