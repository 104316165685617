<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ (add ? 'view.sites.savings.add.add-title' : 'view.sites.savings.add.edit-title') | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <i>{{ 'view.sites.savings.add.info1' | translate }} <i class='req-star'>*</i> {{ 'view.sites.savings.add.info2' | translate}}</i>
    <div class="form-group">
      <label for="category">
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.category' | translate }}
      </label>
      <ng-select id="category" [items]="categories" [(ngModel)]="saving.categoryModel"
        placeholder="{{ 'view.sites.savings.add.category' | translate }}" (change)="categoryChange($event)"
        [clearable]="false">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div>{{ item.name }}</div>
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group">
      <label for="subcategory">
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.subcategory' | translate }}
      </label>
      <ng-select id="subcategory" [disabled]="!saving.categoryModel" [items]="subcategories"
        [(ngModel)]="saving.subcategoryModel" placeholder="{{ 'view.sites.savings.add.subcategory' | translate }}"
        [clearable]="false">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div>{{ item.name }}</div>
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group">
      <label for="saving-name">
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.measure' | translate }}
        <app-tooltip text="{{ 'view.sites.savings.add.tooltips.measure' | translate }}"></app-tooltip>
      </label>
      <input type="text" class="form-control" name="saving-name" id="saving-name" [(ngModel)]="saving.name">
    </div>
    <div class="form-group">
      <label for="comment-existing">
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.comment_existing_system' | translate }}
        <app-tooltip text="{{ 'view.sites.savings.add.tooltips.existing' | translate }}"></app-tooltip>
      </label>
      <textarea rows="4" class="form-control" name="comment-existing"
        [(ngModel)]="saving.comment_existing_system">{{ saving.comment_existing_system }}</textarea>
    </div>
    <div class="form-group">
      <label>
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.comment_new_system' | translate }}
        <app-tooltip text="{{ 'view.sites.savings.add.tooltips.new' | translate }}"></app-tooltip>
      </label>
      <textarea rows="4" class="form-control" name="saving-new"
        [(ngModel)]="saving.comment_new_system">{{ saving.comment_new_system }}</textarea>
    </div>
    <div class="form-group">
      <label>
        <span class="req-star">*</span>
        {{ 'view.sites.savings.add.comment_extent' | translate }}
        <app-tooltip text="{{ 'view.sites.savings.add.tooltips.extent' | translate }}"></app-tooltip>
      </label>
      <textarea rows="4" class="form-control" name="saving-extent"
        [(ngModel)]="saving.comment_extent">{{ saving.comment_extent }}</textarea>
    </div>
    <div class="form-row">
      <div class="col-md-4">
        {{ 'view.sites.savings.add.climate-adapted' | translate }}
        <app-tooltip text="{{ 'view.sites.savings.add.tooltips.climate-adapted' | translate }}"></app-tooltip>
      </div>
      <div class="col-md-8">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="climate-option-true" [value]="true"
            [(ngModel)]="saving.climate_adapted">
          <label class="form-check-label">{{ 'view.sites.savings.add.climate-true' | translate }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="climate-option-false" [value]="false"
            [(ngModel)]="saving.climate_adapted">
          <label class="form-check-label">{{ 'view.sites.savings.add.climate-false' | translate }}</label>
        </div>
      </div>
    </div>
    <hr>
    <h6>{{ 'view.sites.savings.add.yearly-saving' | translate }}</h6>
    <div class="form-row pl-3 pr-3">
      <div *ngFor="let c of carriers; let i = index" class="col-md-4">
        <div class="form-group">
          <label for="saving-{{c.model}}">{{ 'energycarriers.'+c.model | translate }}</label>
          <app-energy-input [(value)]="saving.carriers[c.model]"></app-energy-input>
        </div>
      </div>
    </div>
    <hr>
    <h6>{{ 'view.sites.savings.add.investment-title' | translate }}</h6>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="saving-cost">
            <span class="req-star">*</span>
            {{ 'view.sites.savings.add.investment' | translate }}
          </label>
          <app-currency-input [currency]="currency" [(value)]="saving.cost"></app-currency-input>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="saving-payoff">
            <span class="req-star">*</span>
            {{ 'view.sites.savings.add.payoff' | translate }}
            <app-tooltip type="info" text="{{ 'view.sites.savings.add.tooltips.payoff' | translate }}"></app-tooltip>
          </label>
          <app-year-input [(value)]="saving.payoff"></app-year-input>
          <!-- <input type="number" step="0.001" class="form-control" name="saving-payoff" id="saving-payoff"
            [(ngModel)]="saving.payoff" pattern="/[0-9]+[.,\,]*[0-9]{0,2}/"> -->
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="saving-net-present">
            {{ 'view.sites.savings.add.present-value' | translate }}
            <app-tooltip type="info" text="{{ 'view.sites.savings.add.tooltips.present-value' | translate }}"></app-tooltip>
          </label>
          <app-currency-input [currency]="currency" [(value)]="saving.net_present"></app-currency-input>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="saving-lcc">
            {{ 'view.sites.savings.add.lcc' | translate }}
            <app-tooltip type="info" text="{{ 'view.sites.savings.add.tooltips.lcc' | translate }}"></app-tooltip>
          </label>
          <app-currency-input [currency]="currency" [(value)]="saving.lcc"></app-currency-input>
        </div>
      </div>
    </div>
    <hr>
    <h6><span class="req-star">*</span> {{ 'view.sites.savings.add.dates-info' | translate }}</h6>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label>
          {{ 'view.sites.savings.add.date_proposed' | translate }}
          <app-tooltip text="{{ 'view.sites.savings.add.tooltips.date-proposed' | translate }}"></app-tooltip>
        </label>
        <app-date-input [default]="dateProposed" (change)="dateChange($event, 'proposed')"
          (clear)="dateClear('proposed')"></app-date-input>
      </div>
      <div class="form-group col-md-4">
        <label>
          {{ 'view.sites.savings.add.date_planned' | translate }}
          <app-tooltip text="{{ 'view.sites.savings.add.tooltips.date-planned' | translate }}"></app-tooltip>
        </label>
        <app-date-input [default]="datePlanned" (change)="dateChange($event, 'planned')" (clear)="dateClear('planned')">
        </app-date-input>
      </div>
      <div class="form-group col-md-4">
        <label>
          {{ 'view.sites.savings.add.date_impl' | translate }}
          <app-tooltip text="{{ 'view.sites.savings.add.tooltips.date-impl' | translate }}"></app-tooltip>
        </label>
        <app-date-input [default]="dateImpl" (change)="dateChange($event, 'impl')" (clear)="dateClear('impl')">
        </app-date-input>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss();">
    {{ 'buttons.back' | translate }}
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="save();">
    {{ 'buttons.save' | translate }}
  </button>
</div>