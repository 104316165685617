<!-- TEMPLATE FOR A DATE IN THE CALENDAR VIEW -->
<ng-template #t let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>

<div class="calendar-wrapper" [class.allow-span]="allowRange">
    <div ngbDropdown #drop="ngbDropdown" [autoClose]="false">
        <!-- Dropdown containg the date picker -->
        <div ngbDropdownMenu class="p-0 border-0">
            <ngb-datepicker (select)="onDateSelection($event, drop)" [displayMonths]="2" [dayTemplate]="t"
                outsideDays="hidden">
            </ngb-datepicker>
        </div>
        <!-- Input if not only year -->
        <div class="input-group" *ngIf="!onlyYear">
            <div class="input-group-prepend">
                <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                    <fa-icon [icon]="faCalendarAlt"></fa-icon>
                </button>
            </div>
            <input type="text" class="form-control" *ngIf="allowRange"
                value="{{ formatDate(fromDate) }} - {{ formatDate(toDate) }}" [disabled]="true">
            <input type="text" class="form-control" *ngIf="!allowRange" value="{{ formatDate(fromDate) }}"
                [disabled]="true">
            <fa-icon class="ml-2 align-self-center clear-icon" [icon]="faTimes" *ngIf="fromDate" (click)="clearDate()">
            </fa-icon>
        </div>
    </div>

    <!-- Input used for only year option -->
    <ng-select *ngIf="onlyYear" [items]="years" placeholder="{{ 'year-input.select' | translate }}"
        (change)="onYearChange($event)" [(ngModel)]="selectedYears" [multiple]="allowRange"
        [closeOnSelect]="!allowRange">
    </ng-select>
</div>