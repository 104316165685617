import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energydata',
  templateUrl: './energydata.component.html',
  styleUrls: ['./energydata.component.css']
})
export class EnergydataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
