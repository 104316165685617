import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  passwordForm: PasswordForm = new PasswordForm;

  faLock = faLock;
  faUnlock = faUnlock;

  constructor(
    private http: HttpClient,
    private _toastService: ToastService) { }

  ngOnInit() { }

  changePassword() {
    if (this.passwordForm.password != this.passwordForm.confirm_password) {
      this._toastService.noMatchPassword()
      return
    }
    this.http.post("/api/v2/password", this.passwordForm).subscribe(
      (res: any) => {
        this._toastService.savedPassword()
      },
      (error: any) => {
        if (error.status == 401) {
          this._toastService.invalidPassword()
          return
        }
        this._toastService.server()
      });
  }

}

export class PasswordForm {
  current_password: string;
  password: string;
  confirm_password: string;
}