import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { APIObjectDetailed } from '../../../../shared/services/company-object.service';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {

  faCircle = faCircle;

  companyObjects: APIObjectDetailed[] = []
  loadingObjects: boolean = true

  columns: TableColumn[] = [
    { name: "view.companies.overview.site", key: "name", sort: true, },
    { name: "view.companies.overview.nace", key: "sni_data.code", sort: true },
    { name: "view.companies.overview.country", key: "geo.country.name", sort: true },
    { name: "view.companies.overview.region", key: "geo.state.name", sort: true },
    { name: "view.companies.overview.municipality", key: "geo.municipality.name", sort: true },
    { name: "view.companies.overview.supplied", key: "latest_supplied", sort: true },
    { name: "view.companies.overview.used", key: "latest_used", sort: true },
    { name: "view.companies.overview.mapped", key: "mapped", template: true },
  ]
  settings: TableSettings = new TableSettings("view.companies.overview.title", false, true, true, false, false, false);


  constructor(
    private _companyService: CompanyService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this._companyService.selectCompany(params["id"]).subscribe(
        (data: APIObjectDetailed[]) => {
          this.companyObjects = data
          this.loadingObjects = false
        }
      )
    });
  }

}
