<tr>
  <ng-container *ngIf="template">
    <td class="toggle-cell" (click)="expanded = !expanded;">
      <fa-icon *ngIf="expanded" [icon]="faAngleUp"></fa-icon>
      <fa-icon *ngIf="!expanded" [icon]="faAngleDown"></fa-icon>
    </td>
  </ng-container>
  <td *ngFor="let value of values; let i = index;">
    <ng-container *ngIf="columns[i]?.template">
      <ng-template #custom [ngTemplateOutlet]="fieldTemplate" [ngTemplateOutletContext]="{ value: value, item: item }">
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!columns[i]?.template">
      <ng-template #default [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ value: value, item: item }"></ng-template>
    </ng-container>
  </td>
  <td *ngIf="actions && actions.length">
    <div ngbDropdown container="body">
      <fa-icon ngbDropdownToggle id="options-toggle" [icon]="optionsIcon"></fa-icon>
      <div ngbDropdownMenu id="options-menu" aria-labelledby="options-toggle">
        <div ngbDropdownItem id="options-item" *ngFor="let action of actions" (click)="handleAction(action)">
          {{ action | translate }}
        </div>
      </div>
    </div>
  </td>
</tr>
<tr *ngIf="expanded">
  <!-- Number of columns + expand icon and + actions icon -->
  <td class="px-3" [attr.colspan]="columns.length + (actions && actions.length ? 1 : 0) + 1">
    <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item: item }"></ng-template>
  </td>
</tr>

<ng-template #field let-v="value">
  <fa-icon *ngIf="v === true" style="color: green;" [icon]="boolIconTrue"></fa-icon>
  <fa-icon *ngIf="v === false" style="color: red;" [icon]="boolIconFalse"></fa-icon>
  <div *ngIf="v !== true && v !== false">
    {{ !v && v != 0 ? '-' : v }}
  </div>
</ng-template>