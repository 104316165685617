import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faIndustry, faGlobeEurope, faSitemap, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { FilterChangeEvent } from '../../../../shared/components/company-filter/company-filter.component';
import { NetworkConfig, NetworkService } from '../../../../shared/services/network.service';

@Component({
  selector: 'app-network-energy',
  templateUrl: './network-energy.component.html',
  styleUrls: ['./network-energy.component.scss']
})
export class NetworkEnergyComponent implements OnInit {

  options: GraphOption[] = []
  filterData: FilterChangeEvent = new FilterChangeEvent
  selectMode: boolean = true

  //Variables for graph component
  graphGroupBy: string

  type: string = "supplied"

  config: NetworkConfig

  constructor(
    public activatedRoute: ActivatedRoute,
    private _networkService: NetworkService,
  ) { }

  networkID$ = this.activatedRoute.params.pipe(
    map(data => { return data["id"]} )
  )

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      data => {
        this.type = data.type
      }
    )

    this.config = this._networkService.currentConfig()
    this.setOptions()
  }

  optionLevel(v: string): number {
    switch (v) {
      case "country":
        return 1
      case "region":
        return 2
      case "municipality":
        return 3
      case "section":
        return 1
      case "division":
        return 2
    }
  }

  setOptions() {
    if(this.config.object_recognition) {
      this.options.push(new GraphOption("site", "name", faIndustry))
    }

    let locationLevel = this.optionLevel(this.config.location_level)
    if(locationLevel >= 1) {
      this.options.push(new GraphOption("country", "geo.country.name", faGlobeEurope))
    }
    if(locationLevel >= 2) {
      this.options.push(new GraphOption("region", "geo.state.name", faGlobeEurope))
    }
    if(locationLevel >= 3) {
      this.options.push(new GraphOption("municipality", "geo.municipality.name", faGlobeEurope))
    }

    let sniLevel = this.optionLevel(this.config.sni_level)
    if(sniLevel >= 1) {
      this.options.push(new GraphOption("section", "sni_data.section", faSitemap))
    }
    if(sniLevel >= 2) {
      this.options.push(new GraphOption("division", "sni_data.division", faSitemap))
    }
  }

  bundle(filter: string) {
    this.graphGroupBy = filter
    this.selectMode = false
  }

  filterChange(e: FilterChangeEvent) { this.filterData = e }

  back() { this.selectMode = true }

}

class GraphOption {
  name: string
  filterString: string
  icon: IconDefinition

  constructor(name: string, filter: string, icon: IconDefinition) {
    this.name = name
    this.filterString = filter
    this.icon = icon
  }
}