import { Component, OnInit } from '@angular/core';
import { EnergyService, SuppliedEnergyForm } from '../../../../shared/services/energy.service';
import { faLongArrowAltRight, faEquals, faCircle } from '@fortawesome/free-solid-svg-icons';
import { SavingsService, Saving } from '../../../../shared/services/savings.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { CompanyObjectService, APIObjectDetailed } from '../../../../shared/services/company-object.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class CompanyObjectOverviewComponent implements OnInit {

  //Icons
  faCircle = faCircle;

  selectedCompany: APIObjectDetailed;

  faArrow = faLongArrowAltRight;
  faEquals = faEquals;

  supplied: SuppliedEnergyForm[] = []
  suppliedData: SuppliedEntry[] = []
  suppliedYears: number[] = []
  balanceData: number[] = []
  savings: Saving[] = []

  measureTableSettings: TableSettings = new TableSettings(
    "view.sites.overview.savings.title", false, true, true, false, true, false,
    "measures", "view.sites.overview.savings.tooltip")

  measureColumns: TableColumn[] = [
    { name: "view.sites.overview.savings.category", key: "category_name", sort: true },
    { name: "view.sites.overview.savings.name", key: "name", sort: true },
    { name: "view.sites.overview.savings.savings", key: "carriers.total", sort: true, unit: "units.energy-year" },
    { name: "view.sites.overview.savings.proposed", key: "date_proposed", sort: true },
    { name: "view.sites.overview.savings.planned", key: "date_planned", sort: true },
    { name: "view.sites.overview.savings.implemented", key: "date_implemented", sort: true }
  ]

  measureLoading = true

  constructor(
    private _energyService: EnergyService,
    private _savingService: SavingsService,
    private _objectService: CompanyObjectService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        if (this.selectedCompany == undefined) return
        this.getSavings(this.selectedCompany.id, e.lang)
      });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this._objectService.selectObject(params["id"]).subscribe(
        (data: APIObjectDetailed) => {
          this.selectedCompany = data
          this.initCompany()
        }
      )
    });
  }

  initLists() {
    this.supplied = []
    this.suppliedData = []
    this.suppliedYears = []
    this.balanceData = []
    this.savings = []
  }

  initCompany() {
    this.initLists()
    this.getSavings(this.selectedCompany.id, sessionStorage.getItem("lang"))
    this.getLatestSupplied(this.selectedCompany.id)
  }

  calculateBalance(supplied, enduse): number {
    return (supplied > 0 && enduse > 0) ? 100 * (enduse / supplied) : 0
  }

  getLatestSupplied(companyID: number) {
    this._objectService.suppliedLatest(companyID, 2).subscribe(
      res => {
        this.supplied = res

        // No data reported yet
        if (res.length == 0) return

        res[0].fuels.forEach((f, i) => {
          let entry: SuppliedEntry = new SuppliedEntry

          // Check if fuel is reported. Skip if not.
          // Only reported fuels will be shown in view.
          if (f.amount == 0) {
            if (res.length == 1 || res[1].fuels[i].amount == 0) {
              return
            }
          }

          entry.model = f.model
          entry.data.push(f.amount)
          if (res.length != 1) {
            entry.data.push(res[1].fuels[i].amount)
          }
          entry.setChange()
          this.suppliedData.push(entry)
        });
        res.forEach(s => { this.suppliedYears.push(s.year) })
        this._energyService.v2_getMultipleUsed(companyID, this.suppliedYears).subscribe(
          enduses => {
            enduses.forEach((e, i) => {
              this.balanceData.push(
                this.calculateBalance(this.supplied[i].getTotal(), e.getTotal()))
            });
          }
        )
      });
  }

  getSavings(companyID: number, lang?: string) {
    this._savingService.v2_getSavings(companyID, lang).subscribe(
      (res: Observable<Saving[]>) => {
        res.subscribe(
          (res: Saving[]) => {
            this.savings = res
            this.measureLoading = false
          })
      })
  }
}

class SuppliedEntry {
  model: string
  data: number[] = []
  change: number

  setChange() {
    if (this.data.length != 2) {
      this.change = 0
    } else {
      this.change = ((this.data[1] - this.data[0]) / this.data[0]) * 100
    }
    if (isNaN(this.change)) {
      this.change = 0;
    }
  }

}
