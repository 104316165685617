import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPencilAlt, faPlug, faLeaf, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuppliedEnergyForm, EnergyService, GeneralEntry, FormCarrier } from '../../../../shared/services/energy.service';
import * as _ from 'lodash';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss']
})
export class CarrierComponent implements OnInit {

  @Input() form: SuppliedEnergyForm;
  @Input() data: FormCarrier | GeneralEntry;
  @Input() inputDisable: boolean = false;
  @Input() skipTooltip: boolean = false;
  @Output() saved = new EventEmitter();

  faPencilAlt = faPencilAlt;
  faPlug = faPlug;
  faLeaf = faLeaf;
  closeResult: string;

  //Properties used by the template
  //Defaults to the values used to display energy carriers
  hasCo2: boolean = true;
  modelString: string = "energycarriers.";
  unit: string = "MWh"
  icon: IconDefinition = this.faPlug

  //A copy of data that the modal operates on. Don't want changes to show outside of modal until save.
  modalData: FormCarrier | GeneralEntry;

  constructor(
    private modalService: NgbModal,
    private _toastService: ToastService,
    private _energyService: EnergyService) { }

  ngOnInit() {
    if (this.data instanceof GeneralEntry) {
      this.hasCo2 = false
      this.modelString = "view.sites.supplied.general."
      this.unit = this.data.unit
    }
  }

  save(modal) {
    let modalForm = _.cloneDeep(this.form)
    if (this.data instanceof FormCarrier) {
      let fuel = _.find(modalForm.fuels, ['model', this.data.model])
      fuel.amount = this.modalData.amount
      if (this.hasCo2) {
        fuel.co2 = (this.modalData as FormCarrier).co2
      }
    } else {
      _.find(modalForm.general, ['model', this.data.model]).amount = this.modalData.amount
    }

    this._energyService.v2_postSupplied(modalForm).subscribe(
      (data) => {
        this._toastService.saved()
        if (this.data instanceof FormCarrier) {
          let fuel = _.find(this.form.fuels, ['model', this.data.model])
          fuel.amount = this.modalData.amount
          if (this.hasCo2) {
            fuel.co2 = (this.modalData as FormCarrier).co2
          }
        } else {
          _.find(this.form.general, ['model', this.data.model]).amount = this.modalData.amount
        }
        this.saved.emit("saved")
        modal.close()
      }, (error) => {
        this._toastService.server()
      });
  }

  open(modal) {
    this.modalData = { ...this.data }
    this.modalService.open(modal, { centered: true }).result.then((result) => { }, (reason) => { });
  }

}