import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { APIObjectDetailed, CompanyObjectService } from '../../services/company-object.service';
import { GraphData } from '../energy-graph/energy-graph.component';
import { NewEUForm } from '../../services/energy.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash'
import { NetworkService } from '../../services/network.service';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-used-energy-graph',
  templateUrl: './used-energy-graph.component.html',
  styleUrls: ['./used-energy-graph.component.scss']
})
export class UsedEnergyGraphComponent implements OnInit {

  supportedContext: string[] = ["network", "company", "object"]

  @Input() companies: APIObjectDetailed[] = []
  @Input() years: number[] = []
  @Input() type: string = "vertical"
  @Input() group: string = "carrier"
  @Input() groupBy: string
  @Input() co2: boolean = false

  @Input() context: string
  @Input() resourceID: number

  graphData: GraphData = new GraphData

  ngOnChanges(changes: SimpleChanges) {
    this.getUsed()
  }

  constructor(
    private _networkService: NetworkService,
    private _companyService: CompanyService,
    private _objectService: CompanyObjectService
  ) { }

  ngOnInit() {
    if (!this.supportedContext.includes(this.context)) {
      throw Error(`Given context not supported. Allowed contexts: ${this.supportedContext}, got: ${this.context}`)
    }

    if (!this.resourceID) {
      throw Error(`Resource ID missing. Component require id for the resource to get data from`)
    }
  }

  getUsed() {
    if (this.context == "network") {
      this.getNetworkUsed()
    } else if (this.context == "company") {
      this.getCompanyUsed()
    } else if (this.context == "object") {
      throw Error("Fetching used energy for objects not implemented!")
    }
  }

  getObjectUsed() {
    throw new Error('Method not implemented.');
  }

  getCompanyUsed() {
    let request: Observable<NewEUForm[][]>
    if (this.years.length == 0) {
      request = this._companyService.usedLatest(this.resourceID, this.companies.map(e => e.id))
    } else {
      request = this._companyService.used(this.resourceID, this.companies.map(e => e.id), this.years)
    }
    request.subscribe(
      data => this.parseData(data)
    )
  }

  getNetworkUsed() {
    let request: Observable<NewEUForm[][]>
    if (this.years.length == 0) {
      request = this._networkService.usedLatest(this.resourceID, this.companies.map(e => e.id))
    } else {
      request = this._networkService.used(this.resourceID, this.companies.map(e => e.id), this.years)
    }
    request.subscribe(
      data => this.parseData(data)
    )
  }

  private parseData(forms: NewEUForm[][]) {
    let compData: CompanyData[] = []
    forms.forEach((e, i) => {
      let c = new CompanyData
      c.company = this.companies[i]
      c.used = e
      compData.push(c)
    });
    let bundled = _.groupBy(compData, 'company.' + this.groupBy)
    if (this.groupBy.includes("sni_data")) {
      bundled = this.changeSNILabels(bundled, this.groupBy.split('.').slice(-1)[0])
    }
    let graph: GraphData = new GraphData
    graph.type = this.type
    graph.group = this.group
    for (const [label, compData] of Object.entries(bundled)) {
      if (this.years.length == 0) {
        let nodeLabel: string = label
        let usedData = []
        compData.forEach(c => {
          usedData = usedData.concat(c.used)
        });
        graph.data[nodeLabel] = usedData
      } else {
        this.years.forEach(y => {
          let nodeLabel: string = label + " - " + y
          let usedData = []
          compData.forEach(c => {
            usedData.push(_.find(c.used, ['year', y]))
          })
          graph.data[nodeLabel] = usedData
        })
      }
    }
    this.graphData = graph
  }

  changeSNILabels(bundle: _.Dictionary<CompanyData[]>, part: string): _.Dictionary<CompanyData[]> {
    let stop: number = (part == 'section' ? 2 : 3)
    return _.keyBy(bundle, o => {
      return o[0].company.sni_data.code.slice(0, stop) + new Array(5 - stop).fill("0").join("")
    })
  }

}

class CompanyData {
  company: APIObjectDetailed
  used: NewEUForm[] = []
}
