import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private _titleSource = new BehaviorSubject<ToolbarTitle>(new ToolbarTitle)

  constructor() { }

  setTitle(t: ToolbarTitle) {
    this._titleSource.next(t)
  }

  getTitle(): Observable<ToolbarTitle> {
    return this._titleSource.asObservable()
  }

}

export class ToolbarTitle {
  title: string = ""
  icon: IconDefinition
}