import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn, TableSettings, AddEvent } from '../../../../shared/components/table/table.component';
import { RowEvent } from '../../../../shared/components/table/table-row/table-row.component';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { ToastService } from '../../../../shared/services/toast.service';
import { APICompany, CompanyService } from '../../../../shared/services/company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  companies: APICompany[] = [];

  columns: TableColumn[] = [
    new TableColumn("view.admin.companies.name", "name"),
    new TableColumn("view.admin.companies.org", "org_nr"),
    new TableColumn("view.admin.companies.sites", "nr_objects", false, false),
  ];

  settings: TableSettings = new TableSettings("view.admin.companies.title");
  actions: string[];
  actionMap: { [id: string]: (c: APICompany) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }
  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _companyService: CompanyService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.actions = Object.keys(this.actionMap)
    this._companyService.adminList().subscribe(
      (companies: APICompany[]) => {
        this.companies = companies
        this.loading = false;
      }
    )
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onAdd(_: AddEvent) {
    this._router.navigateByUrl('/admin/companies/new')
  }
  view(c: APICompany) {
    this._router.navigateByUrl(`/admin/companies/${c.id}`)
  }
  edit(c: APICompany) {
    this._router.navigateByUrl(`/admin/companies/${c.id}/edit`)
  }
  delete(c: APICompany) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) return
      this._companyService.delete(c.id).subscribe(
        () => {
          this.companies = this.companies.filter((e: APICompany) => { return e.id !== c.id; })
          this._toastService.saved()
        }, (error: any) => {
          this._toastService.server()
        });
    });
  }

}
