export function resourceIDFromURL(context: string): number | string {
    let urlParts = window.location.pathname.split("/")

    for (var i = 0; i < urlParts.length; i++) {
      if (urlParts[i] === context && i+1 < urlParts.length) {
        if (urlParts[i+1] != "") {
          return urlParts[i+1]
        }
      }
    }
}