import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { NgbDate, NgbCalendar, NgbDropdown, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-span',
  templateUrl: './date-span.component.html',
  styleUrls: ['./date-span.component.scss']
})
export class DateSpanComponent implements OnInit {
  faCalendarAlt = faCalendarAlt
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  @Output('change')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Input('allowRange')
  allowRange: boolean = false;

  @Input('onlyYear')
  onlyYear: boolean = false;

  years: number[];
  selectedYears: number | number[]; //Is either a []number or number depending on allowRange

  constructor(
    public calendar: NgbCalendar,
    private ngbDateFormatter: NgbDateParserFormatter) { }

  ngOnInit() {
    //If a datespan exists for the session, use it as init value.
    if(!this.onlyYear) {
      if(sessionStorage.getItem('selectedDateSpan')) {
        let storedObj = JSON.parse(sessionStorage.getItem('selectedDateSpan'))
        let stored : DateSpan = new DateSpan(storedObj)
        this.fromDate = new NgbDate(stored.from.getFullYear(), stored.from.getMonth()+1, stored.from.getDate())
        if(this.allowRange) {
          this.toDate = new NgbDate(stored.to.getFullYear(), stored.to.getMonth()+1, stored.to.getDate())
        } else {
          this.toDate = null
        }
      } else {
        this.toDate = this.calendar.getToday()
        this.fromDate = this.calendar.getToday()
        if (this.allowRange) {
          this.toDate = this.calendar.getNext(this.toDate)
        } else {
          this.toDate = null
        }
      }
      this.emitChange()
    } else {
      this.years = this.getYears(1970)
      if(sessionStorage.getItem('selectedDateSpan')) {
        let storedObj = JSON.parse(sessionStorage.getItem('selectedDateSpan'))
        let stored : DateSpan = new DateSpan(storedObj)
        this.selectedYears = stored.from.getFullYear()
      } else {
        this.selectedYears = this.calendar.getToday().year
      }
      this.onYearChange()
    }
  }

  onDateSelection(date: NgbDate, rangeDropDown: NgbDropdown) {
    if (this.allowRange) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
        this.toDate = date;
        this.emitChange()
        rangeDropDown.close()
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    }
    else {
      this.fromDate = date;
      this.toDate = null;
      this.emitChange()
      rangeDropDown.close()
    }
  }

  formatDate(d) {
    return this.ngbDateFormatter.format(d)
  }

  isHovered(date: NgbDate) {
    if (this.allowRange) {
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }
    return false;
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  getYears(startYear: number): number[] {
    let currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

  onYearChange() {
    if(this.allowRange) {
      this.change.emit( (this.selectedYears as Number[]).sort() )
    } else {
      this.change.emit(this.selectedYears)
    }
  }

  emitChange() {
    let span = new DateSpan
    if (this.fromDate != null) {
      span.from = new Date(this.ngbDateFormatter.format(this.fromDate))
    }
    if (this.toDate != null) {
      span.to = new Date(this.ngbDateFormatter.format(this.toDate))
    }
    this.saveToSession(span)
    this.change.emit(span)
  }

  private saveToSession(span : DateSpan) {
    sessionStorage.setItem('selectedDateSpan', JSON.stringify(span))
  }

}

export class DateSpan {
  from: Date
  to: Date
  constructor(obj? : any) {
    if (obj) {
      this.from = new Date(obj.from)
      this.to = new Date(obj.to)
    } else {
      this.from = null
      this.to = null
    }
  }

  nrOfDays() : number {
    let fromDate = new Date(this.from)
    let nrOfDays = 0
    while (fromDate <= this.to) {
      nrOfDays++
      fromDate.setDate(fromDate.getDate() + 1)
    }
    return nrOfDays
  }

}
