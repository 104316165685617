import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SniService {

  constructor(private http: HttpClient) {}

  v2_listSNI(lang?: string): Observable<SNIBase[]> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get("/api/v2/sni", { params: params }).pipe(
      map((res: SNIBase[]) => { return res }));
  }

  v2_getSNI(parent: string, lang?: string): Observable<SNI> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get(`/api/v2/sni/${parent}`, { params: params }).pipe(
      map((res: SNI) => { return res }));
  }

}

export class SNIBase {
  id: number
  desc: string
  code: string
}

export class SNI extends SNIBase {
  parent: string
  childs: SNIBase[] = []
}