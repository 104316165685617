<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <div class="card panel-login">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <form id="login-form" role="form">
                                <div class="form-group in-panel-body">
                                    <div class="input-group">
                                        <span class="input-group-addon" id="password-addon"><i class="fa fa-lock fa-fw"></i></span>
                                        <input type="password" name="password" id="currentPassword" tabindex="2" class="form-control"
                                            placeholder="{{ 'account.password.current' | translate }}" aria-describedby="password-addon"
                                            [(ngModel)]="passwordForm.current_password">
                                    </div>
                                </div>
                                <div class="form-group in-panel-body">
                                    <div class="input-group">
                                        <span class="input-group-addon" id="password-addon"><i class="fa fa-lock fa-fw"></i></span>
                                        <input type="password" name="password" id="firstPassword" tabindex="2" class="form-control"
                                            placeholder="{{ 'account.password.new' | translate }}" aria-describedby="password-addon"
                                            [(ngModel)]="passwordForm.password">
                                    </div>
                                </div>
                                <div class="form-group in-panel-body">
                                    <div class="input-group">
                                        <span class="input-group-addon" id="password-addon"><i class="fa fa-lock fa-fw"></i></span>
                                        <input type="password" name="password" id="secondPassword" tabindex="2" class="form-control"
                                            placeholder="{{ 'account.password.repeat' | translate }}" aria-describedby="password-addon"
                                            [(ngModel)]="passwordForm.confirm_password">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-sm-6 offset-sm-3">
                                            <input type="submit" name="login-submit" id="login-submit" tabindex="4"
                                                class="form-control btn btn-primary btn-login" value="{{ 'account.password.change' | translate }}"
                                                (click)="changePassword();"
                                                [disabled]="!passwordForm.current_password || !passwordForm.password || !passwordForm.confirm_password">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>