<button type="button" class="{{buttonType}}" (click)="open()" [disabled]="disabled">
  <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
  {{ name }}
</button>

<ng-template #modal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <table class="table table-hover table-striped table-bordered table-sm" style="overflow-y:scroll;">
          <thead>
              <tr>
                  <th>{{ 'view.database.measure' | translate }}</th>
                  <th>{{ 'view.database.saving' | translate }}</th>
                  <th>{{ 'view.database.investment' | translate }}</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of items">
                  <td>{{ item.name }}</td>
                  <td>{{ item.carriers.total }}</td>
                  <td>{{ item.cost }}</td>
              </tr>
          </tbody>
      </table>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close();">
      {{ 'view.database.selected-modal.back' | translate }}
    </button>
    <button ngbAutofocus type="button" class="btn btn-primary" (click)="downloadPdf()">
      {{ 'view.database.selected-modal.download' | translate }}
    </button>
  </div>

</ng-template>