import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { faCopy, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { PublicURL } from '../../../../../../shared/services/network.service';

@Component({
  selector: 'app-public-url',
  templateUrl: './public-url.component.html',
  styleUrls: ['./public-url.component.scss']
})
export class PublicUrlComponent implements OnInit {

  @Input('url') publicURL: PublicURL = new PublicURL
  @Input('readonly') readonly: boolean = true
  @Input('index') index: number = 0
  @Output('deleted') deleted: EventEmitter<any> = new EventEmitter()
  @ViewChild("confirm") modal: ElementRef;

  copyIcon: IconDefinition = faCopy
  new: boolean = true
  url: string = ""

  urlForm: PublicURLForm

  availableViews = [
    {key: "overview"},
    {
      key: "follow_up",
      children: [
        {key: "follow_up_supplied"},
        {key: "follow_up_used"},
      ]
    },
    {key: "benchmark"},
    {key: "measure"},
  ]

  specificYears: boolean
  years: number[] = []
  selectedYears: number[] = []

  locationVisuals = [
    {value: "country", display: "view.admin.networks.visualisations.country", active: false},
    {value: "region", display: "view.admin.networks.visualisations.region", active: false},
    {value: "municipality", display: "view.admin.networks.visualisations.municipality", active: false},
  ]

  sniVisuals = [
    {value: "section", display: "view.admin.networks.visualisations.section", active: false},
    {value: "division", display: "view.admin.networks.visualisations.division", active: false},
  ]

  constructor(
    private http: HttpClient,
    private _modalService: NgbModal,
    private _toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.years = this.getYears(1970)
    this.urlForm = new PublicURLForm(this.publicURL)

    this.specificYears = this.urlForm.config.time_years.length > 0
    this.selectedYears = [...this.urlForm.config.time_years].map(e => Number(e))

    this.selectLocation(this.urlForm.config.location_level)
    this.selectSNI(this.urlForm.config.sni_level)

    this.new = this.urlForm.key ? false : true
  }

  save() {
    if(this.specificYears) {
      this.urlForm.config.time_years = [...this.selectedYears].map(e => String(e))
    } else {
      this.urlForm.config.time_years = []
    }
    this.urlForm.sanitize()
    if (this.new) {
      this.create(this.urlForm).subscribe((data: PublicURL) => {
        this.publicURL = data
        this.urlForm = new PublicURLForm(this.publicURL)
        this.new = false
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
    } else {
      this.update(this.urlForm).subscribe((data: PublicURL) => {
        this.publicURL = data
        this.urlForm = new PublicURLForm(this.publicURL)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
    }
  }

  remove() {
    if (!this.publicURL.key) {
      this.deleted.emit()
    } else {
      this._modalService.open(this.modal, {
        centered: true,
        backdrop: "static",
      }).result.then((ok: boolean) => {
        if (ok) {
          this.delete(this.urlForm).subscribe(data => {
            this.deleted.emit()
            this._toastService.saved()
          })
        }
      }, (_: any) => {
        this._toastService.server()
      });
    }
  }

  delete(publicURL: PublicURL): Observable<any> {
    return this.http.delete(`/api/v2/networks/${publicURL.network}/urls/${publicURL.key}`).pipe(
      map(res => { return res; })
    )
  }

  create(publicURL: PublicURL): Observable<PublicURL> {
    return this.http.post(`/api/v2/networks/${publicURL.network}/urls`, publicURL).pipe(
      map((res: PublicURL) => { return res })
    )
  }

  update(publicURL: PublicURL): Observable<PublicURL> {
    return this.http.put(`/api/v2/networks/${publicURL.network}/urls/${publicURL.key}`, publicURL).pipe(
      map((res: PublicURL) => { return res })
    )
  }

  selectLocation(v: string) {
    if (!v) {
      v = "municipality"
    }
    this.locationVisuals.forEach(e => {
      e.active = v == e.value
    })
    this.urlForm.config.location_level = v
  }

  selectSNI(v: string) {
    if (!v) {
      v = "division"
    }
    this.sniVisuals.forEach(e => {
      e.active = v == e.value
    })
    this.urlForm.config.sni_level = v
  }

  getYears(startYear: number): number[] {
    let currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

}

class PublicURLForm extends PublicURL {
  expire_enabled: boolean = false
  password: string = ""
  url: string = ""

  constructor(obj?: any) {
    super(obj)
    this.expire_enabled = this.expire_date ? true : false
    this.url = window.location.origin + "/public/" + this.key
  }

  sanitize() {
    if (this.password_enabled && !this.password) {
      delete this.password_enabled
    }

    if (this.expire_enabled) {
      if (!this.expire_date) {
        delete this.expire_date
      }
    } else {
      this.expire_date = null
    }
  }

}
