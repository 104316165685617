import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { resourceIDFromURL } from './common/resourceIDFromURL';
import { NetworkConfig } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class PublicUrlService {

  private urlSettings: UrlSettings

  constructor(
    private http: HttpClient,
  ) { }

  // Runs when the page is refreshed to preload current key settings
  preloadUrlSettings(): Observable<UrlSettings> {
    let urlParts = window.location.pathname.split("/")
    let key = resourceIDFromURL("public")
    if (key && urlParts.length != 3) {
      return this.getKeySettings(String(key)).pipe(
        map(data => {
          this.urlSettings = data
          return data
        })
      )
    }
    return of(new UrlSettings)
  }

  setUrlSettings(settings: UrlSettings) {
    this.urlSettings = settings
  }

  currentSettings(): UrlSettings {
    return this.urlSettings
  }

  getKeySettings(key: string): Observable<UrlSettings> {
    return this.http.get(`/api/v2/public/${key}`).pipe(
      map((data: UrlSettings) => { return data })
    )
  }

}

export class UrlSettings {
  active: boolean
  email: string
  expire_date: string
  key: string
  name: string
  network: number
  password_enabled: boolean
  user: number
  username: string
  config: NetworkConfig
  views: { [view_key: string]: boolean }
}

