<div class="container-fluid">
    <app-company-filter class="mb-2" [enablePeriod]="true" (onChange)="filterChange($event)" [hidden]="!selectMode" context="company"></app-company-filter>
    <div *ngIf="selectMode">
        <h6 class="overview-header mb-3 mt-5">
            {{ 'view.companies.energy.title' | translate}}
        </h6>
        <div class="row row-cols-4 justify-content-center">
            <div *ngFor="let o of options" class="card m-2 col">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <h6 class="card-title mb-0">
                        {{ 'view.companies.energy.group.' + o.name | translate }}
                    </h6>
                    <button class="btn btn-primary" (click)="bundle(o.filterString)">
                        <fa-icon [icon]="o.icon"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!selectMode">
        <button class="btn btn-primary mb-3" (click)="back()">{{ 'buttons.back' | translate }}</button>
        <app-supplied-energy-graph *ngIf="type == 'supplied'" context="company" [resourceID]="companyID$ | async" [companies]="filterData.objects" [years]="filterData.period"
            [groupBy]="graphGroupBy" type="horizontal">
        </app-supplied-energy-graph>
        <app-used-energy-graph *ngIf="type == 'used'"
            [companies]="filterData.objects"
            context="company"
            [resourceID]="companyID$ | async"
            [years]="filterData.period"
            group="process"
            [groupBy]="graphGroupBy"
            type="horizontal">
        </app-used-energy-graph>
    </div>
</div>