import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { concat, observable, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { APIObjectDetailed, CompanyObjectService } from '../../services/company-object.service';
import { APICompany, CompanyService } from '../../services/company.service';
import { APINetwork, NetworkService } from '../../services/network.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-membership-manager',
  templateUrl: './membership-manager.component.html',
  styleUrls: ['./membership-manager.component.scss']
})
export class MembershipManagerComponent implements OnInit {

  faTrash = faTrashAlt
  warning = faExclamationTriangle;

  title: string
  @Input() readonly: boolean = false
  currentMembers = []
  loading: boolean = true

  //Resource variables
  private _resource: APINetwork | APICompany | APIObjectDetailed;
  private _resourceService: NetworkService | CompanyService | CompanyObjectService
  private _getMembers: getMembers
  private _addMembers: addMembers
  private _deleteMembers: deleteMembers
  @Input()
  set resource(value: APINetwork | APICompany | APIObjectDetailed) {
    this._resource = value;
    if (this._resource.id) {
      this.init()
      this.getMembers();
    }
  }
  get resource(): APINetwork | APICompany | APIObjectDetailed {
    return this._resource;
  }

  //MemberType variables
  private _memberTypeService: UserService | CompanyObjectService
  private _memberTypeFilter
  memberTypeBind: string
  @Input() memberType: string

  @ViewChild("confirm") modal: ElementRef;

  //MemberType selector variables
  loadingMemberTypes: boolean = false
  members$: Observable<any>;
  memberInput$ = new Subject<string>();
  selectedMembers: any[] = []
  private cachedMemberTypes = []

  constructor(
    private _networkService: NetworkService,
    private _companyService: CompanyService,
    private _userService: UserService,
    private _objectService: CompanyObjectService,
    private _modalService: NgbModal,
  ) { }


  ngOnInit(): void {
    this.loadMemberTypes();
  }

  init() {
    if (this._resource instanceof APINetwork) {
      this._resourceService = this._networkService
    } else if (this._resource instanceof APICompany) {
      this._resourceService = this._companyService
    } else if (this._resource instanceof APIObjectDetailed) {
        this._resourceService = this._objectService
    } else {
      throw new Error(`Error evaluating resource. ${APINetwork.name} or ${APICompany.name} expected. Got ${this._resource}`);
    }

    if (this.memberType == "user") {
      this._memberTypeService = this._userService
      this._memberTypeFilter = this.filterUser
      this.memberTypeBind = "last_name"
      this._getMembers = this._resourceService.getMembers.bind(this._resourceService)
      this._addMembers = this._resourceService.addMembers.bind(this._resourceService)
      this._deleteMembers = this._resourceService.removeMembers.bind(this._resourceService)
    } else if (this.memberType == "object") {
      this._memberTypeService = this._objectService
      this._memberTypeFilter = this.filterObject
      this.memberTypeBind = "name"
      this._getMembers = this._resourceService.getObjects.bind(this._resourceService)
      this._addMembers = this._resourceService.addObjects.bind(this._resourceService)
      this._deleteMembers = this._resourceService.removeObjects.bind(this._resourceService)
    } else {
      throw new Error(`Error evaluating member type. 'user' or 'object' expected. Got ${this.memberType}`);
    }
  }

  getMembers() {
    this._getMembers(this._resource.id).subscribe(
      data => {
        this.currentMembers = data
        this.loading = false
      }
    )
  }

  delete(i) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (ok) {
        this._deleteMembers(this._resource.id, [i.id]).subscribe(
          data => { this.currentMembers = data }
        )
      }
    }, (errors: any) => { });
  };

  add() {
    this._addMembers(this._resource.id, this.selectedMembers.map(e => e.id)).subscribe(
      (data) => {
        this.currentMembers = data
        this.selectedMembers = []
      }
    )
  }

  loadMemberTypes() {
    this.members$ = concat(
      of([]), // default items
      this.memberInput$.pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => this.loadingMemberTypes = true),
        switchMap(term => this.cache(this._memberTypeService.adminList()).pipe(
          map(data => {
            return _.filter(data, (e) => this._memberTypeFilter(e, term))
          }),
          map(data => {
            let selectedIDs = this.currentMembers.map(e => e.id)
            return _.filter(data, (e)=> { return !selectedIDs.includes(e.id) });
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.loadingMemberTypes = false)
        ))
      )
    );
  }

  cache(observable: Observable<any>): Observable<any> {
    if (this.cachedMemberTypes.length > 0) {
      return of(this.cachedMemberTypes)
    }
    return observable.pipe(
      map(data => {
        this.cachedMemberTypes = data
        return data
      })
    )
  }

  filterUser(e, term) {
    if (term == "") return true
    return e.last_name.toLowerCase().startsWith(term.toLowerCase())
  }

  filterObject(e, term) {
    if (term == "") return true
    return e.name.toLowerCase().startsWith(term.toLowerCase())
  }

}

interface getMembers {
  (resourceID: number): Observable<any>
}

interface addMembers {
  (resourceID: number, data: number[]): Observable<any>
}

interface deleteMembers {
  (resourceID: number, data: number[]): Observable<any>
}

