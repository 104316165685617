import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class SensorService {

  private db: SensorDB

  constructor() {
    this.reloadFromStorage()
  }

  availableSensors() : any {
    let sensors = []
    Object.keys(this.db.data).forEach(k => {
      sensors.push({"id": k, "description": this.db.data[k].desc})
    });
    return sensors
  }

  addEntry(e: any) {
    if (this.db.data[e.id]) {
      this.db.data[e.id].data[e.time] = e.value
    } else {
      let timeData = {}
      timeData[e.time] = e.value
      this.db.data[e.id] = { desc: e.description, data: timeData }
    }
    this.writeToStorage()
  }

  getSensorValue(id: string, date: Date): number {
    let sensor = this.db.data[id]
    let value = 0
    if (sensor) {
      let nextEpoch = Math.round(date.getTime() / 1000)
      if (sensor.data[nextEpoch]) {
        //Get defined value field from each time entry
        value = sensor.data[nextEpoch]
      }
    }
    return value
  }

  setSensorDescription(id: string, desc: string) {
    this.db.data[id].desc = desc
    this.writeToStorage()
  }

  private writeToStorage() {
    localStorage.setItem('sensorDB', JSON.stringify(this.db))
  }

  reloadFromStorage() {
    let localdb = JSON.parse(localStorage.getItem('sensorDB'))
    if (!localdb) { //Create an empty if not existing
      localdb = new SensorDB()
    }
    this.db = localdb
  }

}

export class SensorDB {
  data: {
    [id: string]: {
      desc: string
      data: {
        [time: string]: number
      }
    }
  }
  constructor() {
    this.data = {}
  }
}

export class Sensor {
  id: string
  name: string
  description: string
  values: { [time: number]: number }

  constructor(obj) {
    this.id = uuid()
    this.name = obj.id
    this.description = obj.description
    this.values = {}
    this.values[obj.time] = obj.value
  }
}
