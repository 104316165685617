import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as Chart from 'chart.js';
import { ChartOptions } from 'chart.js';
import * as _ from 'lodash';
import { FilterChangeEvent } from '../../../../shared/components/benchmark-filter/benchmark-filter.component';
import { CompanyService } from '../../../../shared/services/company.service';
import { SniService, SNI } from '../../../../shared/services/sni.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-company-benchmark',
  templateUrl: './company-benchmark.component.html',
  styleUrls: ['./company-benchmark.component.scss']
})
export class CompanyBenchmarkComponent implements OnInit {
  //Icons
  faQuestionCircle = faQuestionCircle;
  warningIcon = faExclamationTriangle;

  sniInfoHeading: string = ""

  filterSelection: FilterChangeEvent = new FilterChangeEvent

  currentSNI1: string;
  currentSNI2: string;

  failedCompanies: string[] = []

  companyID: number

  constructor(
    private translate: TranslateService,
    private _sniService: SniService,
    private _toastService: ToastService,
    private _companyService: CompanyService,
    private activatedRoute: ActivatedRoute
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.setSniText(e.lang)
      })
  }

  chart: any;

  resultReady: boolean;
  loadingGraph: boolean;

  private resultText: any = {}

  chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    hover: { mode: null },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          let value = (Math.round(Number(tooltipItem.yLabel) * 100) / 100)
          return `${value}`
        },
        title: function (item, data) {
          let company = data.datasets[item[0].datasetIndex].data[item[0].index]["companyName"]
          return company
        }
      }
    },

    scales: {
      xAxes: [
        {
          offset: true,
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [{
        offset: true,
        scaleLabel: {
          display: true,
        }
      }]
    }
  }

  bubbleData = [];
  bubbleColors = []
  defaultBubbleColor = '#66B2FF'
  badBubbleColor = '#FF6f69'
  okBubbleColor = '#FFFF84'
  goodBubbleColor = '#7CEB98'

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      data => { this.companyID = data["id"]}
    )
    this.resultReady = false;
    this.loadingGraph = true
  }

  resetChartData() {
    this.bubbleData = []
    this.bubbleColors = []
    this.resultText = {}
    this.resultReady = false
    this.loadingGraph = true
  }

  getBubbleColor(percent) {
    if (percent <= 1.10) {
      return this.goodBubbleColor;
    } else if (1.10 < percent && percent <= 1.20) {
      return this.okBubbleColor;
    } else {
      return this.badBubbleColor;
    }
  }

  generateChart(data, colors, label) {
    if (this.chart) this.chart.destroy()

    this.chartOptions.scales.yAxes[0].scaleLabel.labelString = label

    this.chart = new Chart('benchmark-chart', {
      type: 'bubble',
      data: {
        datasets: [
          {
            label: 'Benchmark',
            data: data,
            backgroundColor: colors
          },
        ]
      },
      options: this.chartOptions
    });
    this.loadingGraph = false
  }

  benchmark(event: FilterChangeEvent) {
    this.failedCompanies = []
    this.filterSelection = event
    this.resetChartData()
    this._companyService.benchmark(this.companyID, event.objects.map(o => o.id), event.ratio.id).subscribe(
      data => {
        let formatedData = []
        data.objects.forEach(e => {
          formatedData.push(
            {
              value: e.value,
              color: this.getBubbleColor(e.percentage),
              size: 10,
              companyName: _.find(this.filterSelection.objects, ['id', e.objectID]).name
            }
          )
        });

        data.others.forEach(e => {
          formatedData.push({ value: e, color: this.defaultBubbleColor, size: 5 })
        });

        formatedData = _.orderBy(formatedData, ['value'], ['asc'])
        formatedData.forEach((item, index) => {
          this.bubbleData.push({
            x: index + 1,
            y: item.value,
            r: item.size,
            companyName: item.companyName ? item.companyName : ""
          })
          this.bubbleColors.push(item.color)
        });

        this.failedCompanies = data.missing.map(e => _.find(this.filterSelection.objects, ['id', e]).name)

        this.generateChart(this.bubbleData, this.bubbleColors, this.filterSelection.ratio.name)
        this.setResultText(data.others.length)
        this.resultReady = true
      },
      ((error: HttpErrorResponse) => {
        if(error.status == 400) {
          this._toastService.missingBenchmarks()
        }
      })
    )
  }

  setResultText(nr: number) {
    this.resultText.nr = nr
    this.setSniText(sessionStorage.getItem("lang"))
  }

  setSniText(lang?: any) {
    if (this.filterSelection.objects.length == 0) return
    let sniDivision: string = this.filterSelection.objects[0].sni_data.code.slice(0, 2)
    this._sniService.v2_getSNI(sniDivision, lang).subscribe(
      (division: SNI) => {
        this.currentSNI2 = division.desc
        this._sniService.v2_getSNI(division.parent, lang).subscribe(
          (section: SNI) => { this.currentSNI1 = section.desc }
        )
      }
    )
  }

}