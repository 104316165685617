import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementService, APIAgreementModel } from '../../../shared/services/agreement.service';
@Component({
    selector: 'app-agreement-modal',
    templateUrl: './agreement-modal.component.html',
    styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent implements OnInit {

    @Input() data: APIAgreementModel;
    @Input() preview: boolean = false;

    isChecked : boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private _agreementService : AgreementService
    ) {}

    ngOnInit() {}

    acceptAgreement() {
        this._agreementService.v2_acceptAgreement(this.data.id).subscribe(
            success => {
                this.activeModal.close()
            },
            err => {
                console.log("Error accepting agreement:",err)
            }
        )
    }

    close() {
        this.activeModal.close()
    }

}