import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { APIObjectDetailed } from '../../services/company-object.service';
import * as _ from 'lodash'
import { SniService, SNIBase, SNI } from '../../services/sni.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BenchmarkService, Benchmark } from '../../services/benchmark.service';
import { NetworkService } from '../../services/network.service';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-benchmark-filter',
  templateUrl: './benchmark-filter.component.html',
  styleUrls: ['./benchmark-filter.component.scss']
})
export class BenchmarkFilterComponent implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Input() coordinatorMode: boolean = false;
  @Input() context: string

  faAngleUp = faAngleUp
  faAngleDown = faAngleDown

  filter: BenchmarkFilter = new BenchmarkFilter

  constructor(
    private _companyService: CompanyService,
    private _networkService: NetworkService,
    private _sniService: SniService,
    private _benchmarkService: BenchmarkService,
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.getSNISections(e.lang)
        this.availableBenchmarks(e.lang)
        if (!this.filter.division.disable) {
          this.getSNIDivision(this.filter.section.select, e.lang)
        }
      })
  }

  ngOnInit() {

    if(this.context == "company") {
      this.filter.allObjects = this._companyService.currentObjects()
    } else {
      this.filter.allObjects = this._networkService.currentObjects()
    }

    this.filter.selectedObjects = this.filter.allObjects

    if(this.coordinatorMode) {
      this.filter.ratio.disable = true
      this.getSNISections(sessionStorage.getItem("lang"))
    }
    this.availableBenchmarks(sessionStorage.getItem("lang"))
  }

  emit() {
    this.onSubmit.emit(
      new FilterChangeEvent(
        this.filter.selectedObjects,
        this.filter.ratio.select));
  }

  getSNISections(lang?: string) {
    this._sniService.v2_listSNI(lang).subscribe(
      data => {
        this.filter.section.data = [...this.getAvailableSelections(data, 'sni_data.section')]
      }
    )
  }

  getSNIDivision(sni: SNIBase, lang?: string) {
    this._sniService.v2_getSNI(sni.code, lang).subscribe(
      (data: SNI) => {
        this.filter.division.data = [...this.getAvailableSelections(data.childs, 'sni_data.division')]
      });
  }

  availableBenchmarks(lang?: string) {
    this._benchmarkService.getBenchmarks(lang).subscribe(
      (benchmarks: Benchmark[]) => { this.filter.ratio.data = benchmarks }
    );
  }

  getAvailableSelections(data: any[], field: string): any[] {
    let available = []
    data.forEach((e: any) => {
      if (_.find(this.filter.selectedObjects, [field, e.id])) {
        available.push(e)
      }
    });
    return available
  }

  sectionChange() {
    this.filter.division.select = undefined
    this.filter.filter()
    if (_.isEmpty(this.filter.section.select)) {
      this.filter.division.disable = true
      return
    }
    this.filter.division.disable = false
    this.getSNIDivision(this.filter.section.select, sessionStorage.getItem("lang"))
  }

  divisionChange() {
    this.filter.filter()
    this.filter.ratio.disable = false
  }

}

class BenchmarkFilter {
  allObjects: APIObjectDetailed[] = []
  selectedObjects: APIObjectDetailed[] = []
  section: FilterEntry
  division: FilterEntry
  ratio: FilterEntry

  constructor() {
    this.section = new FilterEntry('sni_data.section', false)
    this.division = new FilterEntry('sni_data.division')
    this.ratio = new FilterEntry('', false)
  }

  filter() {
    this.selectedObjects = this.section.filter(this.allObjects)
    this.selectedObjects = this.division.filter(this.selectedObjects)
  }

}

class FilterEntry {
  select: any
  data: any = []
  disable: boolean
  filterString: string
  constructor(filterString: string, disabled: boolean = true) {
    this.disable = disabled
    this.filterString = filterString
  }

  filter(data) {
    if (!this.select) return data
    return _.filter(data, [this.filterString, this.select.id])
  }

}

export class FilterChangeEvent {
  objects: APIObjectDetailed[]
  ratio: Benchmark

  constructor(objects: APIObjectDetailed[] = [], ratio: Benchmark = undefined) {
    this.objects = objects
    this.ratio = ratio
  }

}