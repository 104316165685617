<div class="container-fluid">
    <div class="card search-component border-primary">
        <h4>{{ 'view.database.page-desc' | translate }} <app-tooltip type="info" placement="bottom"
                title="{{ 'view.database.tooltips.search-header' | translate}}"
                text="{{ 'view.database.tooltips.search-body' | translate}}"></app-tooltip>
        </h4>
        <!-- CATEGORY ROW -->
        <div class="row" id="category-container">
            <div class="col-md-6">
                <label>
                    {{ 'view.database.category' | translate }}
                    <app-tooltip type="info" text="{{ 'view.database.tooltips.category' | translate}}"></app-tooltip>
                </label>
                <ng-select [items]="v2_categories" [(ngModel)]="searchForm.category" bindValue="id"
                    placeholder="{{ 'view.database.select-category' | translate }}" (change)="categoryChange($event)"
                    (clear)="clearCategory()">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <div>{{ item.name }}</div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6">
                <label>
                    {{ 'view.database.subcategory' | translate }}
                    <app-tooltip type="info" text="{{ 'view.database.tooltips.subcategory' | translate}}"></app-tooltip>
                </label>
                <ng-select [disabled]="!searchForm.category" [items]="v2_subcategories"
                    [(ngModel)]="searchForm.subcategory" bindValue="id"
                    placeholder="{{ 'view.database.select-subcategory' | translate }}">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <div>{{ item.name }}</div>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <!-- SNI ROW -->
        <div class="row" id="sni-container">
            <div class="col-md-6">
                <label>
                    {{ 'view.database.main-code' | translate }}
                    <app-tooltip type="info" text="{{ 'view.database.tooltips.section' | translate}}"></app-tooltip>
                </label>
                <ng-select [items]="v2_sniSection" [(ngModel)]="searchForm.sni" bindValue="code"
                    placeholder="{{ 'view.database.select-main-code' | translate }}" (change)="sniChange($event)"
                    (clear)="clearSNI()">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <div>{{ item.code }}: {{ item.desc }}</div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6">
                <label>
                    {{ 'view.database.sub-code' | translate }}
                    <app-tooltip type="info" text="{{ 'view.database.tooltips.division' | translate}}"></app-tooltip>
                </label>
                <ng-select [disabled]="!searchForm.sni" [items]="v2_sniDivision.childs" [(ngModel)]="searchForm.subsni"
                    bindValue="code" placeholder="{{ 'view.database.select-sub-code' | translate }}">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <div>{{item.code}}: {{ item.desc }}</div>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <!-- BUTTON ROW -->
        <div class="row" id="search-button-container">
            <div class="col-md-2">
                <button class="btn btn-primary" (click)="onSearch()"
                    [disabled]="!searchForm.sni && !searchForm.category">
                    {{ 'view.database.search' | translate }}
                </button>
            </div>
        </div>
    </div>


    <!-- INFO PANEL -->
    <div class="card search-menu mt-3 border-primary">
        <div class="card-header bg-primary text-white">
            <span>{{ 'view.database.category-desc-title' | translate }}</span>
        </div>
        <div class="card-body">
            {{ (searchForm.subcategory? subcategoryDesc() : 'view.database.no-category') | translate }}
        </div>
    </div>

    <!-- BAT PANEL -->
    <div class="search-menu mt-3">
        <app-table [columns]="batColumns" [data]="searchResult.bats" [settings]="batTableSettings" [noData]="batNoData"
            [loading]="tablesLoading">
            <ng-template #field let-item="item">
                <a href="https://eippcb.jrc.ec.europa.eu/sites/default/files/2019-11/ENE_Adopted_02-2009.pdf#page={{item.reference_page}}"
                    target="_blank">{{ 'view.database.more' | translate }}</a>
            </ng-template>
        </app-table>
    </div>

    <div class="search-menu mt-3">
        <app-table [columns]="resultColumns" [data]="searchResult.savings" [settings]="resultTableSettings"
            [noData]="resultNoData" [loading]="tablesLoading" [selectors]="resultTableselectors"
            (selectorChange)="onSelectorChange($event)">
        </app-table>
    </div>

</div>