<div *ngIf="loading">
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
</div>

<div id="wrapper" *ngIf="!loading">
    <div class="row">
        <div class="col-md-12" id="top-nav">
            <nav class="navbar navbar-light bg-light">
                <img id="logo" class="ml-3" src="assets/nea-logo.svg">
            </nav>
        </div>
    </div>
    <div class="background">
        <div class="container">
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="card panel-login">
                        <div class="card-body">
                            <div class="row">

                                <div class="col-lg-12" *ngIf="requireLogin">
                                    <!-- LOGIN FORM -->
                                    <form id="login-form" role="form" (submit)="login()">
                                        <div class="form-group in-panel-body">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="password-addon">
                                                    <fa-icon [icon]="faLock"></fa-icon>
                                                </span>
                                                <input type="password" name="password" id="password" tabindex="2"
                                                    class="form-control"
                                                    placeholder="{{ 'view.login.password' | translate }}"
                                                    [(ngModel)]="loginForm.password">
                                            </div>
                                        </div>
                                        <!-- ERROR ROW -->
                                        <div class="in-panel-body" *ngIf="errors">
                                            <ngb-alert [type]="errors.type" [dismissible]="false">{{errors.msg}}
                                            </ngb-alert>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-6 offset-sm-3">
                                                    <button type="submit" class="btn btn-primary btn-login"
                                                        [disabled]="!loginForm.password" tabindex="4">
                                                        {{'view.login.public-button' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-lg-12" *ngIf="notFound">
                                    <p class="text-center">{{'view.login.public-not-found' | translate }}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>