import { Component, OnInit, Input } from '@angular/core';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  faQuestion = faQuestionCircle
  faInfo = faInfoCircle

  @Input() placement: string = "top";
  @Input() text: string = "";
  @Input() title: string = "";
  @Input() type: string = "question"
  @Input() keepFontColor: boolean = false;



  icon = this.faQuestion
  colorClass = ""

  constructor() { }

  ngOnInit() {
    this.type == "info" ? this.icon = this.faInfo : this.icon = this.faQuestion
    this.keepFontColor? this.colorClass = "" : this.colorClass = "nea-blue"
  }

}
