import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APINetworkLicense, LicenseService } from '../../../../../../shared/services/license.service';
import { ToastService } from '../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-network-form',
  templateUrl: './network-form.component.html',
  styleUrls: ['./network-form.component.scss']
})
export class NetworkFormComponent implements OnInit {
  mode: string
  license: APINetworkLicense = new APINetworkLicense
  readonly: boolean = true

  availableViews = [
    {key: "overview"},
    {
      key: "follow_up",
      children: [
        {key: "follow_up_supplied"},
        {key: "follow_up_used"},
      ]
    },
    {key: "benchmark"},
    {key: "measure"},
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private _licenseService: LicenseService,
    private _router: Router,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(
      data => {
        if(data["id"] == undefined) {
          return
        }
        this._licenseService.networkDetails(data["id"]).subscribe(
          (license: APINetworkLicense) => {
            this.license = license
          }
        )
      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        if(this.mode != "view") {
          this.readonly = false
        }
      }
    )

  }

  save() {
    if(!this.license.views["follow_up"]) {
      this.license.views["follow_up_supplied"] = false
      this.license.views["follow_up_used"] = false
    }
    if(this.mode == "new") {
      this.create(this.license)
    } else if (this.mode == "edit") {
      this.update(this.license.id, this.license)
    }
  }

  create(license: APINetworkLicense) {
    this._licenseService.networkCreate(license).subscribe(
      data => {
        this._router.navigateByUrl(`/admin/licenses/network/${data.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

  update(id: number, license: APINetworkLicense) {
    this._licenseService.networkUpdate(id, license).subscribe(
      (data: APINetworkLicense) => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

}
