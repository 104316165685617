import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {

    constructor() { }

    getCookie() {
        return document.cookie.split(';')
    }

    getCsrfToken() {
        let cookie = this.getCookie();
        for (let t of cookie) {
            if (t.search(/csrftoken=/) != -1) {
                return t.split('=')[1];
            }
        }
        return '';
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.getCsrfToken();
        request = request.clone({
            setHeaders: {
                'X-CSRFToken': token
            }
        })
        return next.handle(request);
    }

}
