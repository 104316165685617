import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash'
import { APIObjectDetailed } from './company-object.service';
import { resourceIDFromURL } from './common/resourceIDFromURL';
import { ToolbarService } from './toolbar.service';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { APISavingModel } from './savings.service';
import { APIEndUseCategory, CO2APIModel, EnergyService, NewEUForm, SuppliedAPIModel, SuppliedEnergyForm, UsedAPIModel } from './energy.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  readonly baseURL: string = "/api/v2/companies"

  private selectedCompanyObjects: APIObjectDetailed[] = []
  private selectedCompany: APICompany

  constructor(
    private http: HttpClient,
    private _toolbarService: ToolbarService,
    private _energyService: EnergyService
  ) { }

  // Runs when the page is refreshed to preload the selected company from URL
  preloadSelectedCompany(): Observable<APIObjectDetailed[]> {
    let companyID = resourceIDFromURL("companies")
    if (companyID) {
      return this.selectCompany(Number(companyID))
    }
    return of([])
  }

  currentCompany(): APICompany {
    return this.selectedCompany
  }

  currentObjects(): APIObjectDetailed[] {
    return this.selectedCompanyObjects
  }

  selectCompany(companyID: number): Observable<APIObjectDetailed[]> {
    const loadCompany = this.get(companyID)
    const loadObjects = this.getObjects(companyID)
    return forkJoin([loadCompany, loadObjects]).pipe(
      map(
        (data: [APICompany, APIObjectDetailed[]]) => {
          this.selectedCompanyObjects = data[1]
          this.selectedCompany = data[0]
          this._toolbarService.setTitle({ title: data[0].name, icon: faBuilding })
          return data[1]
        }
      )
    );
  }

  getUserCompanies(userID: number): Observable<APICompany[]> {
    return this.http.get(`/api/v2/companies`).pipe(
      map((res: APICompany[]) => { return res })
    );
  }

  getObjects(companyID: number): Observable<APIObjectDetailed[]> {
    return this.http.get(`/api/v2/companies/${companyID}/objects`).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    );
  }

  addObjects(companyID: number, data: number[]): Observable<APIObjectDetailed[]> {
    return this.http.post(`/api/v2/companies/${companyID}/objects`, {objects: data}).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    );
  }

  removeObjects(companyID: number, data: number[]): Observable<APIObjectDetailed[]> {
    const options = { headers: { 'Content-Type': 'application/json' }, body: { objects: data } }
    return this.http.request("delete", `/api/v2/companies/${companyID}/objects`, options).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    )
  }

  getMembers(companyID: number): Observable<APIMember[]> {
    return this.http.get(`/api/v2/companies/${companyID}/members`).pipe(
      map((res: APIMember[]) => { return res })
    )
  }

  addMembers(companyID: number, data: number[]): Observable<APIMember[]> {
    return this.http.post(`/api/v2/companies/${companyID}/members`, {members: data}).pipe(
      map((res: APIMember[]) => { return res })
    );
  }

  removeMembers(companyID: number, data: number[]): Observable<APIMember[]> {
    const options = { headers: { 'Content-Type': 'application/json' }, body: { members: data } }
    return this.http.request("delete", `/api/v2/companies/${companyID}/members`, options).pipe(
      map((res: APIMember[]) => { return res })
    )
  }

  listCompanies(): Observable<APICompany[]> {
    return this.http.get("/api/v2/companies").pipe(
      map((res: APICompany[]) => { return res })
    )
  }

  adminList(): Observable<APICompany[]> {
    return this.http.get("/api/v2/admin/companies").pipe(
      map((res: APICompany[]) => { return res })
    )
  }

  get(id: number): Observable<APICompany> {
    return this.http.get(`/api/v2/companies/${id}`).pipe(
      map((res: APICompany) => { return new APICompany(res) })
    )
  }

  create(form: CompanyForm): Observable<APICompany> {
    return this.http.post("/api/v2/companies/", form).pipe(
      map((res: APICompany) => { return res })
    )
  }

  update(id: number, form: CompanyForm): Observable<APICompany> {
    return this.http.put(`/api/v2/companies/${id}`, form).pipe(
      map((res: APICompany) => { return res })
    )
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/companies/${id}`)
  }

  savings(id: number, objects: number[], lang?: string, currency?: string): Observable<CompanySavings> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    if (!!currency) {
      params = params.append('currency', currency)
    }
    if (!!lang) {
      params = params.append('lang', lang)
    }
    return this.http.get(`${this.baseURL}/${id}/energy/savings`, { params: params }).pipe(
      map((res: CompanySavings) => {
        return res
      })
    )
  }

  supplied(id: number, objects: number[], years: number[]): Observable<SuppliedEnergyForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('year', years.toString())
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: {[id: number]: SuppliedAPIModel[]} = data[0]
        let co2:  {[id: number]: CO2APIModel[]} = data[1]
        let res: SuppliedEnergyForm[][] = []
        Object.keys(co2).forEach(objectID => {
          let objectForms = this._energyService.toSuppliedForms(Number(objectID), supplied[objectID], co2[objectID])
          res.push(objectForms)
        })
        return res
      })
    )
  }

  suppliedLatest(id: number, objects: number[], latest: number = 1): Observable<SuppliedEnergyForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('latest', String(latest))
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: {[id: number]: SuppliedAPIModel[]} = data[0]
        let co2:  {[id: number]: CO2APIModel[]} = data[1]
        let res: SuppliedEnergyForm[][] = []
        Object.keys(supplied).forEach(objectID => {
          let objectForms = this._energyService.toSuppliedForms(Number(objectID), supplied[objectID], co2[objectID])
          res.push(objectForms)
        })
        return res
      }
    ))
  }

  used(id: number, objects: number[], years: number[]): Observable<NewEUForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('year', years.toString())
    let energy = this.fetchUsed(id, params)
    let co2 = this.fetchC02(id, params)
    let categories = this._energyService.v2_getEndUseCategories(sessionStorage.getItem("lang"))
    return forkJoin([energy, co2, categories]).pipe(
      map(data => {
        let used: { [id: number]: UsedAPIModel[][] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let categories: APIEndUseCategory[] = data[2]
        let res: NewEUForm[][] = []
        Object.keys(used).forEach(objectID => {
          let objectForms = this._energyService.toUsedForms(Number(objectID), used[objectID], co2[objectID], categories)
          res.push(objectForms)
        })
        return res
      })
    )
  }

  usedLatest(id: number, objects: number[], latest: number = 1): Observable<NewEUForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('latest', String(latest))
    let energy = this.fetchUsed(id, params)
    let co2 = this.fetchC02(id, params)
    let categories = this._energyService.v2_getEndUseCategories(sessionStorage.getItem("lang"))
    return forkJoin([energy, co2, categories]).pipe(
      map(data => {
        let used: { [id: number]: UsedAPIModel[][] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let categories: APIEndUseCategory[] = data[2]
        let res: NewEUForm[][] = []
        Object.keys(used).forEach(objectID => {
          let objectForms = this._energyService.toUsedForms(Number(objectID), used[objectID], co2[objectID], categories)
          res.push(objectForms)
        })
        return res
      })
    )
  }

  benchmark(id: number, objects: number[], benchmarkID: number): Observable<BenchmarkResult> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    return this.http.get(`${this.baseURL}/${id}/benchmark/${benchmarkID}`, { params: params }).pipe(
      map((data: any) => {
        let res: BenchmarkResult = new BenchmarkResult
        res.others = data.data

        let total: number[] =  [...data.data]
        Object.values(data.values).forEach((v: number) => {
          if(v != 0) total.push(v)
        })

        Object.entries(data.values).forEach(([objectID, value]) => {
          if (value == 0) {
            res.missing.push(Number(objectID))
            return
          }

          let objectResult: ObjectResult = new ObjectResult
          objectResult.objectID = Number(objectID)
          objectResult.value = Number(value)

          // Calculate percentage
          let meanValues: number[] = [...total]
          let idx = total.findIndex(o => o == value)
          if (idx != -1) {
            meanValues.splice(idx, 1)
          }
          let percentage: number = 1
          if(meanValues.length != 0) {
            percentage = Number(value) / _.mean(meanValues)
          }
          objectResult.percentage = percentage

          res.objects.push(objectResult)
        });
        return res
      })
    )
  }

  private fetchSupplied(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/supplied`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

  private fetchUsed(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/used`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

  private fetchC02(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/co2`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

}

class ObjectResult {
  objectModel: APIObjectDetailed
  objectID: number
  value: number
  percentage: number
}

class BenchmarkResult {
  objects: ObjectResult[] = []
  others: number[] = []
  missing: number[] = []
}

export interface CompanySavings {
  [id: string]: APISavingModel[];
}

export class APIMember {
  id: number
  name: string
  email: string
}

export class CompanyForm {
  name: string
  org_nr: string
}

export class APICompany {
  id: number
  nr_member: number
  nr_objects: number
  name: string
  org_nr: string
  demo: boolean
  template: boolean

  constructor(obj?: any) {
    if (obj) {
      for (var key in obj) {
        this[key] = obj[key]
      }
    }
  }

}

export class APICompanyDetails {
  id: number;
  year: number;
  area: number;
  heated_area: number;
  prod_hours: number;
  unit: string;
  units: number;
  turnover: number;
  employees: number;
  company: number;
}

export class APICompanySimple {
  id: number
  name: string
  org_nr: string
  cfar_id: string
  sni: string
  location: number
  demo: boolean
  template: boolean
}

export class APICompanyDetailed {
  id: number
  name: string
  org_nr: string
  cfar_id: string
  demo: boolean
  template: boolean
  sni_data: {
    section: number
    division: number
    group: number
    class: number
    detail: number
    code: string
  }
  geo: {
    country: {
      id: number
      name: string
      code: string
    },
    state: {
      id: number
      name: string
    },
    municipality: {
      id: number
      name: string
    }
  }

  constructor(obj?: any) {
    if (obj) {
      _.forOwn(obj, (val, key) => {
        this[key] = val
      })
    }
  }

  toSimple(): APICompanySimple {
    let res = new APICompanySimple
    res.id = this.id
    res.name = this.name
    res.org_nr = this.org_nr
    res.cfar_id = this.cfar_id
    res.sni = this.sni_data.code
    res.location = this.geo.municipality.id
    res.demo = this.demo
    res.template = this.template
    return res
  }

}