import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserService, APIUser } from "../shared/services/user.service";

@Injectable()
export class StaffGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user: APIUser = this.userService.getUserInfo()
        if (user.is_staff) {
            return true;
        }
        this.router.navigateByUrl('/');
        return false;
    }
}
