import { Component, OnInit } from '@angular/core';
import { APIUser, UserService } from '../../../../../shared/services/user.service';
import * as _ from 'lodash';
import { ToastService } from '../../../../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { APICompany } from '../../../../../shared/services/company.service';
import { APINetwork } from '../../../../../shared/services/network.service';
import { APIObjectSimple } from '../../../../../shared/services/company-object.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  mode: string
  user: APIUser = new APIUser({ info: { views: {} } });

  readonly: boolean = true

  views: View[] = [
    new View("database", "view.admin.users.views.database"),
    new View("education", "view.admin.users.views.education"),
  ]

  loadingCompanies: boolean = true
  companyAccess: APICompany[] = []
  loadingObjects: boolean = true
  objectAccess: APIObjectSimple[] = []
  loadingNetworks: boolean = true
  networkAccess: APINetwork[] = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _router: Router,
    private _toastService: ToastService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      data => {
        if (data["id"] == undefined) {
          this.user = new APIUser();
          return
        }
        this._userService.get(data["id"]).subscribe(
          (data: APIUser) => {
            this.user = new APIUser(data);
          }
        )

        this._userService.companyAccess(data["id"]).subscribe(
          (data: APICompany[]) => {
            this.companyAccess = data
            this.loadingCompanies = false
          }
        )

        this._userService.companyObjectAccess(data["id"]).subscribe(
          (data: APIObjectSimple[]) => {
            this.objectAccess = data
            this.loadingObjects = false
          }
        )

        this._userService.networkAccess(data["id"]).subscribe(
          (data: APINetwork[]) => {
            this.networkAccess = data
            this.loadingNetworks = false
          }
        )

      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        if (this.mode != "view") {
          this.readonly = false
        }
      }
    )
  }

  save() {
    if (this.mode == "new") {
      this.create(this.user)
    } else if (this.mode == "edit") {
      this.update(this.user.id, this.user)
    }
  }

  create(u: APIUser) {
    this._userService.create(u).subscribe(
      (user: APIUser) => {
        this._router.navigateByUrl(`/admin/users/${user.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server();
      });
  }

  update(id: number, u: APIUser) {
    console.log(u)
    this._userService.update(id, u).subscribe(
      data => {
        this._toastService.saved();
      }, (_: any) => {
        this._toastService.server();
      });
  }

}

export class View {
  key: string;
  name: string;
  value: boolean;

  constructor(key: string, name: string, value = false) {
    this.key = key;
    this.name = name;
    this.value = value;
  }
}
