<div class="card border-primary investment-panel">
  <div class="card-header bg-primary text-white">
    <span>{{ 'investment.payback' | translate }}</span>
    <span class="invest-tooltip" triggers="mouseenter:mouseleave" placement="bottom" ngbPopover="{{ 'investment.payoff-desc' | translate }}">
      <fa-icon [icon]="faTooltip"></fa-icon>
    </span>
  </div>
  <div class="card-body">
    <div>
      <label for="investment"> {{ 'investment.investment' | translate }} (kr) </label>
      <input type="number" id="investment" [(ngModel)]="this.payoff.investment">
    </div>
    <div>
      <label for="in"> {{ 'investment.in' | translate }} (kr) </label>
      <input type="number" id="in" [(ngModel)]="this.payoff.in">
    </div>
    <div>
      <label for="out"> {{ 'investment.out' | translate }} (kr) </label>
      <input type="number" id="out" [(ngModel)]="this.payoff.out">
    </div>
    <div>
      <hr>
      <button class="btn btn-primary" (click)="calcPayoff()"> {{ 'investment.calc' | translate }} </button>
      <span class="result">{{this.payoff.ans | number}} {{ 'investment.year' | translate }}</span>
    </div>
  </div>
</div>