<app-benchmark-filter (onSubmit)="benchmark($event)"></app-benchmark-filter>

<!-- CHART CONTAINER -->
<div class="benchmark-graph-container mt-5" [hidden]="loadingGraph">
  <canvas #chartCanvas id="benchmark-chart">{{ chart }}</canvas>
</div>

<!-- RESULT CONTAINER -->
<div class="result-text-container" *ngIf="resultReady">
  {{ 'view.sites.benchmark.result.a' | translate }} <b>{{ 'view.sites.benchmark.result.b' | translate }}
    {{ selectedCompany.sni_data.code | slice:0:2 }}xxx
    <fa-icon class="sni-icon" [icon]="faQuestionCircle" [ngbPopover]="sniInfoDescTmpl" triggers="mouseenter:mouseleave"
      popoverTitle="{{ 'benchmark.nace' | translate }}"></fa-icon></b>,
  <b>{{ resultText.nr }}</b> {{ 'view.sites.benchmark.result.c' | translate }}
  {{ 'view.sites.benchmark.result.d' | translate }} <b>{{ resultText.percent }} %</b>
  {{ (resultText.text=="over"? 'view.sites.benchmark.result.e' : 'view.sites.benchmark.result.f') | translate }}
  <div class="chart-info-container">
    <div class="chart-info-row">
      <div class="good-color-info"></div><span>{{ 'benchmark.good' | translate }}</span>
    </div>
    <div class="chart-info-row">
      <div class="ok-color-info"></div><span>{{ 'benchmark.ok' | translate }}</span>
    </div>
    <div class="chart-info-row">
      <div class="bad-color-info"></div><span>{{ 'benchmark.bad' | translate }}</span>
    </div>
  </div>
</div>

<ng-template #sniInfoDescTmpl>
  <div>{{ currentSNI1 }}</div>
  <div class="ml-2">{{ currentSNI2 }}</div>
</ng-template>