<app-table [columns]="columns" [data]="networks" [settings]="settings" [actions]="actions" (action)="onAction($event)"
  (add)="onAdd($event)" [loading]="loading"></app-table>

<ng-template #confirm let-confirm>
  <div class="modal-header">
    <h4>
      <fa-icon [icon]="warning" style="color: darkorange;"></fa-icon>
      {{ 'buttons.confirm' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    {{ 'view.admin.confirm-desc' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger"
      (click)="confirm.close(true)">{{ 'buttons.delete' | translate }}</button>
    <button type="button" class="btn btn-outline-dark"
      (click)="confirm.close(false)">{{ 'buttons.cancel' | translate }}</button>
  </div>
</ng-template>