import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private _toastr: ToastrService,
    private _translate: TranslateService,
  ) { }

  success(message: string, title = "status.success") {
    return this._toastr.success(this._translate.instant(message), this._translate.instant(title))
  }

  error(message: string, title = "status.error") {
    return this._toastr.error(this._translate.instant(message), this._translate.instant(title))
  }

  saved() {
    return this.success("status.saved")
  }

  missing() {
    return this.error("status.missing")
  }

  server() {
    return this.error("status.server")
  }

  // Saving related messages
  savedMeasure() {
    return this.success("status.measure.saved")
  }

  createdMeasure() {
    return this.success("status.measure.created")
  }

  missingMeasure() {
    return this.error("status.measure.missing")
  }

  deletedMeasure() {
    return this.success("status.measure.deleted")
  }

  // Password related messages
  savedPassword() {
    return this.success("status.password.saved")
  }

  noMatchPassword() {
    return this.error("status.password.no-match")
  }

  invalidPassword() {
    return this.error("status.password.invalid")
  }

  // Benchmark related messages
  missingBenchmark() {
    return this.error("status.benchmark.missing")
  }

  errorBenchmark() {
    return this.error("status.benchmark.error")
  }

  missingBenchmarks() {
    return this.error("status.benchmarks.missing")
  }

  loginFailed() {
    return this.error("status.login.invalid")
  }

  loginPublicFailed() {
    return this.error("status.login.password")
  }

}
