import { Component, OnInit } from '@angular/core';
import { APIObjectDetailed, CompanyObjectService } from '../../../../shared/services/company-object.service';
import { APINetwork, NetworkService } from '../../../../shared/services/network.service';
import { APIUser, UserService } from '../../../../shared/services/user.service';
import * as _ from 'lodash';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  user: APIUser

  networks: APINetwork[] = []
  loadingNetworks: boolean = true

  objects: APIObjectDetailed[] = []
  loadingObjects: boolean = true

  networkColumns: TableColumn[] = [
    {
      name: "view.home.networks.name",
      key: "name",
      sort: true,
      search: true,
      template: true
    },
    {
      name: "view.home.networks.sites",
      key: "nr_objects",
      sort: true,
    },
  ];

  networkSettings: TableSettings = {
    title: "view.home.networks.title",
    add: false,
    paginate: false,
  }

  objectColumns: TableColumn[] = [
    {
      name: "view.home.sites.name",
      key: "name",
      sort: true,
      search: true,
      template: true
    },
    {
      name: "view.home.companies.name",
      key: "company_name",
      sort: true,
      search: true,
    },
  ];

  objectSettings: TableSettings = {
    title: "view.home.sites.title",
    add: false,
    paginate: false,
  }

  constructor(
    private _networkService: NetworkService,
    private _objectService: CompanyObjectService,
    private _userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.user = this._userService.getUserInfo()
    this._networkService.getUserNetworks(this.user.id).subscribe(data => {
      this.networks = data
      this.loadingNetworks = false
    })
    this._objectService.getUserObjects(this.user.id).subscribe(data => {
      this.objects = data
      this.loadingObjects = false
    })
  }

  navigateToSite(id: number) {
    this._objectService.selectObject(id).subscribe(
    data => {
      this.router.navigateByUrl(`/sites/${id}`)
    });
  }

  navigateToNetwork(id: number) {
    this._networkService.selectNetwork(id).subscribe(
    data => {
      this.router.navigateByUrl(`/networks/${id}`)
    });
  }

}
