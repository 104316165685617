import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
    selector: 'app-selected-modal',
    templateUrl: './selected-modal.component.html',
    styleUrls: ['./selected-modal.component.scss']
})
export class SelectedModalComponent implements OnInit {

    @Input() name: string;
    @Input() title: string;
    @Input() icon: any;
    @Input() buttonType: string;
    @Input() disabled: boolean;
    @Input() items: any;

    @ViewChild("modal", { static: true }) modal: ElementRef;

    measureTrans : string;
    savingTrans : string;
    investmentTrans : string;
    titleTrans : string;
    pdfNameTrans : string;

    constructor(
        private modalService: NgbModal,
        private translate: TranslateService
    ) {
        translate.stream('database.measure').subscribe(res => {
            this.measureTrans = res;
        });
        translate.stream('database.saving').subscribe(res => {
            this.savingTrans = res;
        });
        translate.stream('database.investment').subscribe(res => {
            this.investmentTrans = res;
        });
        translate.stream('database.selected-modal.title').subscribe(res => {
            this.titleTrans = res;
        });
        translate.stream('database.selected-modal.pdf').subscribe(res => {
            this.pdfNameTrans = res;
        });
    }

    ngOnInit() { }

    open() {
        this.modalService.open(this.modal, { centered: true, size: 'lg' });
    }

    downloadPdf() {

        var columns = [
            { title: this.measureTrans, dataKey: "name" },
            { title: this.savingTrans, dataKey: "saved" },
            { title: this.investmentTrans, dataKey: "cost" }];
        var rows = [];
        for (let item of this.items) {
            let saving = {
                "name": item.name,
                "saved": item.carriers.total,
                "cost": item.cost
            }
            rows.push(saving)
        }

        // Only pt supported (not mm or in)
        var doc = new jsPDF('p', 'pt');
        doc.autoTable(columns, rows, {
            theme: 'striped',
            styles: { overflow: 'linebreak' },
            margin: { top: 60 },
            addPageContent: () => {
                doc.text(this.titleTrans, 40, 30);
            }
        });
        doc.save(this.pdfNameTrans);
    }

}