<div class="d-flex flex-row justify-content-between align-items-center pb-2">
  <!-- <span>{{ memberType == 'user' ? 'membership.users' : 'membership.sites' | translate }}</span> -->
  <span *ngIf="memberType == 'user'">{{ 'membership.users' | translate }}</span>
  <span *ngIf="memberType != 'user'">{{ 'membership.sites' | translate }}</span>
  <div class="d-flex  w-75" *ngIf="!readonly">
    <ng-select class="w-100" [items]="members$ | async" [bindLabel]="memberTypeBind" [multiple]="true"
      [hideSelected]="true" [minTermLength]="3" [loading]="loadingMemberTypes" [typeahead]="memberInput$"
      [(ngModel)]="selectedMembers">
      <ng-template *ngIf="memberType == 'user'" ng-label-tmp ng-option-tmp let-item="item">
        {{item.first_name}} {{item.last_name}}
      </ng-template>
    </ng-select>
    <button type="button" class="btn btn-outline-primary btn-sm" (click)="add()">{{ 'buttons.add' | translate }}</button>
  </div>
</div>
<div class="wrapper overflow-auto form-control" [ngClass]="{'readonly': !!readonly}">
  <div *ngIf="loading" class="loader">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div *ngIf="!loading" class="d-flex flex-column">
    <div class="d-flex flex-row justify-content-between pr-3" *ngFor="let member of currentMembers">
      <span>{{ member.name }}</span>
      <fa-icon *ngIf="!readonly" class="clickable" [icon]="faTrash" (click)="delete(member)"></fa-icon>
    </div>
  </div>
</div>

<ng-template #confirm let-confirm>
  <div class="modal-body">
    <h4>
      <fa-icon [icon]="warning" style="color: darkorange;"></fa-icon>
      {{ 'buttons.confirm' | translate }}
    </h4>
    <p>
      {{ 'view.admin.confirm-desc' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="confirm.close(true)">{{ 'buttons.delete' |
      translate }}</button>
    <button type="button" class="btn btn-outline-dark" (click)="confirm.close(false)">{{ 'buttons.cancel' |
      translate }}</button>
  </div>
</ng-template>