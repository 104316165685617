import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

  constructor(
    private http: HttpClient
  ) { }

  getBenchmarks(lang?: string): Observable<Benchmark[]> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get("/api/v2/benchmark", {params: params}).pipe(
      map((benchmarks: Array<any>) => {
        let res: Benchmark[] = []
        benchmarks.forEach(b => {
          let benchmark: Benchmark = new Benchmark
          benchmark.id = b.id
          benchmark.name = b.name
          benchmark.class = b.numerator.name
          res.push(benchmark)
        });
        return res
      })
    )
  }

  runBenchmark(companyID: number, benchmarkID: number): Observable<BenchmarkResult> {
    return this.http.get(`/api/v2/objects/${companyID}/benchmark/${benchmarkID}`).pipe(
      map((res: BenchmarkResult) => {
        let percent: number
        if (_.isEmpty(res.data)) {
          percent = 1 // Own company is the same as mean (100%)
        } else {
          percent = res.value / _.mean(res.data)
        }
        res.percentage = percent
        return res
      })
    )
  }

}

export class BenchmarkResult {
  value: number
  data: number[]
  percentage: number
}

export class Benchmark {
  id: number
  name: string
  class: string
}
