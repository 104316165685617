import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Chart } from 'chart.js';
import * as _ from 'lodash'
import { CompanyObjectService, APIObjectDetailed } from '../../shared/services/company-object.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { SniService, SNI } from '../../shared/services/sni.service';
import { FilterChangeEvent } from '../../shared/components/benchmark-filter/benchmark-filter.component';
import { BenchmarkService, BenchmarkResult } from '../../shared/services/benchmark.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.scss']
})
export class BenchmarkComponent implements OnInit {
  //Icons
  faQuestionCircle = faQuestionCircle;

  sniInfoHeading: string = ""

  currentSNI1: string;
  currentSNI2: string;

  constructor(
    private http: HttpClient,
    private _objectService: CompanyObjectService,
    private translate: TranslateService,
    private _sniService: SniService,
    private _toastService: ToastService,
    private _benchmarkService: BenchmarkService
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.setSniText(e.lang)
      })
  }

  chart: any;

  resultReady: boolean;
  loadingGraph: boolean;

  private resultText: any = {}

  chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false
    },
    hover: { mode: null },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          return (Math.round(tooltipItem.yLabel * 100) / 100) + " MWh"
        }
      }
    },

    scales: {
      xAxes: [
        {
          offset: true,
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [{
        offset: true,
        scaleLabel: {
          display: true,
        }
      }]
    }
  }

  bubbleData = [];
  bubbleColors = []
  defaultBubbleColor = '#66B2FF'
  badBubbleColor = '#FF6f69'
  okBubbleColor = '#FFFF84'
  goodBubbleColor = '#7CEB98'

  selectedCompany: APIObjectDetailed = new APIObjectDetailed;

  ngOnInit() {
    this.resultReady = false;
    this.loadingGraph = true
    this.selectedCompany = this._objectService.currentObject()
  }

  resetChartData() {
    this.bubbleData = []
    this.bubbleColors = []
    this.resultText = {}
    this.resultReady = false
    this.loadingGraph = true
  }

  getBubbleColor(percent) {
    if (percent <= 1.10) {
      return this.goodBubbleColor;
    } else if (1.10 < percent && percent <= 1.20) {
      return this.okBubbleColor;
    } else {
      return this.badBubbleColor;
    }
  }

  generateChart(data, colors) {
    if (this.chart) this.chart.destroy()
    this.chart = new Chart('benchmark-chart', {
      type: 'bubble',
      data: {
        datasets: [
          {
            label: 'Benchmark',
            data: data,
            backgroundColor: colors
          },
        ]
      },
      options: this.chartOptions
    });
    this.loadingGraph = false
  }

  benchmark(e: FilterChangeEvent) {
    this.resetChartData()
    this._benchmarkService.runBenchmark(this.selectedCompany.id, e.ratio.id).subscribe(
      (res: BenchmarkResult) => {
        let formatedData = []
        formatedData.push({ value: res.value, color: this.getBubbleColor(res.percentage), size: 10 })
        res.data.forEach(d => { formatedData.push({ value: d, color: this.defaultBubbleColor, size: 5 }) })
        formatedData = _.orderBy(formatedData, ['value'], ['asc'])
        formatedData.forEach((item, index) => {
          this.bubbleData.push({
            x: index + 1,
            y: item.value,
            r: item.size
          })
          this.bubbleColors.push(item.color)
        });
        this.generateChart(this.bubbleData, this.bubbleColors)
        this.setResultText(res.percentage, res.data.length)
        this.resultReady = true
      },
      (error: HttpErrorResponse) => {
        console.log(error)
        if(error.status == 400){
          this._toastService.missingBenchmark()
        } else {
          this._toastService.errorBenchmark()
        }
      }
    )
  }

  setResultText(percent: number, nr: number) {
    this.resultText.nr = nr
    let formatedPercent = Math.round(percent * 100);
    if (formatedPercent > 100) {
      this.resultText.percent = formatedPercent - 100;
      this.resultText.text = "over";
    } else {
      this.resultText.percent = 100 - formatedPercent;
      this.resultText.text = "under";
    }
    this.setSniText(sessionStorage.getItem("lang"))
  }

  setSniText(lang?: any) {
    let sniDivision: string = this.selectedCompany.sni_data.code.slice(0, 2)
    this._sniService.v2_getSNI(sniDivision, lang).subscribe(
      (division: SNI) => {
        this.currentSNI2 = division.desc
        this._sniService.v2_getSNI(division.parent, lang).subscribe(
          (section: SNI) => { this.currentSNI1 = section.desc }
        )
      }
    )
  }

}