import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { NgbDate, NgbCalendar, NgbDropdown, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {
  faCalendarAlt = faCalendarAlt
  faTimes = faTimes
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  @Output('change')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('clear')
  clear: EventEmitter<any> = new EventEmitter();

  @Input('allowRange')
  allowRange: boolean = false;

  @Input('onlyYear')
  onlyYear: boolean = false;

  @Input('default')
  default: DateSpan = new DateSpan;

  years: number[];
  selectedYears: any; //Is either a []number or number depending on allowRange

  constructor(
    public calendar: NgbCalendar,
    private ngbDateFormatter: NgbDateParserFormatter) { }

  ngOnInit() {
    //If a datespan exists for the session, use it as init value.
    if (!this.onlyYear) {
      if (this.default.from == null) return
      this.fromDate = new NgbDate(
        this.default.from.getFullYear(),
        this.default.from.getMonth() + 1,
        this.default.from.getDate())
      if (this.allowRange) {
        if (this.default.to == null) return
        this.toDate = new NgbDate(
          this.default.to.getFullYear(),
          this.default.to.getMonth() + 1,
          this.default.to.getDate())
      }
    }
  }

  onDateSelection(date: NgbDate, rangeDropDown: NgbDropdown) {
    if (this.allowRange) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
        this.toDate = date;
        this.emitChange()
        rangeDropDown.close()
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    }
    else {
      this.fromDate = date;
      this.toDate = null;
      this.emitChange()
      rangeDropDown.close()
    }
  }

  formatDate(d) {
    return this.ngbDateFormatter.format(d)
  }

  isHovered(date: NgbDate) {
    if (this.allowRange) {
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }
    return false;
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  getYears(startYear: number): number[] {
    let currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

  onYearChange() {
    if (this.allowRange) {
      this.change.emit(this.selectedYears.sort())
    } else {
      this.change.emit(this.selectedYears)
    }
  }

  clearDate() {
    console.log("clear")
    this.fromDate = null
    this.toDate = null
    this.clear.emit()
  }

  emitChange() {
    let span = new DateSpan
    if (this.fromDate != null) {
      span.from = new Date(this.ngbDateFormatter.format(this.fromDate))
    }
    if (this.toDate != null) {
      span.to = new Date(this.ngbDateFormatter.format(this.toDate))
    }
    this.change.emit(span)
  }

}

export class DateSpan {
  from: Date
  to: Date
  constructor(obj?: any) {
    if (obj) {
      this.from = new Date(obj.from)
      this.to = new Date(obj.to)
    } else {
      this.from = null
      this.to = null
    }
  }

  nrOfDays(): number {
    let fromDate = new Date(this.from)
    let nrOfDays = 0
    while (fromDate <= this.to) {
      nrOfDays++
      fromDate.setDate(fromDate.getDate() + 1)
    }
    return nrOfDays
  }

}
