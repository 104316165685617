<div class="container-fluid">
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.admin.sites.info' | translate }}</h5>
        </div>
        <fieldset [disabled]="readonly">
            <div class="form-group">
                <label for="name"> {{ 'view.admin.sites.name' | translate }} </label>
                <input type="text" class="form-control" id="name" [(ngModel)]="object.name">
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="cin"> {{ 'view.admin.sites.org' | translate }} </label>
                    <input type="text" class="form-control" id="cin" [(ngModel)]="object.org_nr">
                </div>
                <div class="form-group col-md-4">
                    <label for="sic"> {{ 'view.admin.sites.nace' | translate }} </label>
                    <input type="text" class="form-control" id="sic" [(ngModel)]="object.sni_data.code">
                </div>
                <div class="form-group col-md-4">
                    <label for="cfar"> {{ 'view.admin.sites.cfar' | translate }} </label>
                    <input type="text" class="form-control" id="cfar" [(ngModel)]="object.cfar_id">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label> {{ 'view.admin.sites.country' | translate }} </label>
                    <ng-select placeholder="{{ 'view.admin.sites.select.country' | translate }}" [items]="countries"
                        [searchable]="true" bindLabel="name" [disabled]="readonly" bindValue="id" [closeOnSelect]="true"
                        [clearable]="false" [(ngModel)]="object.geo.country.id" (change)="countryChange($event)">
                    </ng-select>
                </div>
                <div class="form-group col-md-4">
                    <label> {{ 'view.admin.sites.region' | translate }} </label>
                    <ng-select placeholder="{{ 'view.admin.sites.select.region' | translate }}" [items]="states"
                        [searchable]="true" bindLabel="name" [disabled]="!(object.geo.country.id && states) || readonly"
                        bindValue="id" [closeOnSelect]="true" [clearable]="false" [(ngModel)]="object.geo.state.id"
                        (change)="stateChange($event)">
                    </ng-select>
                </div>
                <div class="form-group col-md-4">
                    <label> {{ 'view.admin.sites.municipality' | translate }} </label>
                    <ng-select placeholder="{{ 'view.admin.sites.select.municipality' | translate }}"
                        [items]="municipalities" [searchable]="true" bindLabel="name"
                        [disabled]="!(object.geo.state.id && municipalities) || readonly" bindValue="id"
                        [closeOnSelect]="true" [clearable]="false" [(ngModel)]="object.geo.municipality.id">
                    </ng-select>
                </div>
            </div>
            <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
                (click)="save()">{{ 'buttons.save' | translate }}</button>
        </fieldset>
    </div>

    <div class="section" *ngIf="mode != 'new'">
        <div class="section-header">
            <h5>{{ 'view.admin.sites.license' | translate }}</h5>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <div class="form-group">
                    <label for="license"> {{ 'view.admin.sites.license' | translate }} </label>
                    <ng-select [disabled]="readonly" [items]="licenses$ | async" bindValue="id" bindLabel="name"
                        [(ngModel)]="object.config.license">
                        <ng-template ng-option-tmp let-item="item">
                            <div class="font-weight-bold">
                                {{item.name}}
                            </div>
                            <div class="font-weight-light">
                                <span class="mr-2">{{ 'view.admin.sites.license_price' | translate }}: {{item.price}} kr</span> |
                                <span class="ml-2">{{ 'view.admin.sites.license_users' | translate }}: {{ item.nr_users }}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
                    (click)="updateLicense()">{{
                    'buttons.save' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="section" *ngIf="mode != 'new'">
        <div class="section-header">
            <h5>{{ 'view.admin.sites.memberships' | translate }}</h5>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <div class="form-group">
                    <app-membership-manager [resource]="object" memberType="user" [readonly]="readonly">
                    </app-membership-manager>
                </div>
            </div>
        </div>
    </div>
</div>