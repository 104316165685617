<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <ng-select placeholder="{{ 'view.sites.advanced.sensors.choose' | translate }}" [items]="sensors" [(ngModel)]="selected" [clearable]="false">
        <ng-template ng-label-tmp let-item="item">
          {{item.description? item.description : ('view.sites.advanced.sensors.unnamed' | translate) }}
        </ng-template>
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div><span>{{item.description? item.description : ('view.sites.advanced.sensors.unnamed' | translate) }}</span></div>
          <small><b>Id:</b> {{item.id}}</small>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row mt-3" *ngIf="selected">
    <div class="col-md-3">
      <label for="sensor-id">{{ 'view.sites.advanced.sensors.id' | translate }}</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="sensor-id" disabled value="{{ selected.id }}">
      </div>
      <label for="sensor-desc">{{ 'view.sites.advanced.sensors.desc' | translate }}</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="sensor-desc" [ngModel]="selected.description"
          (ngModelChange)="descChange($event)">
      </div>
    </div>
  </div>
</div>
