<div class="card border-primary investment-panel">
  <div class="card-header bg-primary text-white">
    <span>{{ 'investment.lcc' | translate }}</span>
    <fa-icon class="invest-tooltip" triggers="mouseenter:mouseleave" placement="bottom" ngbPopover="{{ 'investment.lcc-desc' | translate }}"
      [icon]="faTooltip"></fa-icon>
  </div>
  <div class="card-body">
    <div>
      <label for="investment"> {{ 'investment.investment' | translate }} (kr) </label>
      <input type="number" id="investment" [(ngModel)]="this.lcc.investment">
    </div>
    <div>
      <label for="year-cost"> {{ 'investment.energy' | translate }} (kr) </label>
      <input type="number" id="year-cost" [(ngModel)]="this.lcc.yearlyCost">
    </div>
    <div>
      <label for="maintenance"> {{ 'investment.maintenance' | translate }} (kr) </label>
      <input type="number" id="maintenance" [(ngModel)]="this.lcc.yearlyMaintenance">
    </div>
    <div>
      <label for="other"> {{ 'investment.other' | translate }} (kr) </label>
      <input type="number" id="other" [(ngModel)]="this.lcc.otherCosts">
    </div>
    <div>
      <label for="rest"> {{ 'investment.rest-value' | translate }} (kr) </label>
      <input type="number" id="rest" [(ngModel)]="this.lcc.restValue">
    </div>
    <div>
      <label for="years"> {{ 'investment.years' | translate }} </label>
      <input type="number" id="years" [(ngModel)]="this.lcc.years">
    </div>
    <div>
      <label for="interest"> {{ 'investment.interest' | translate }} (%) </label>
      <input type="number" id="interest" [(ngModel)]="this.lcc.interest">
    </div>
    <div>
      <hr>
      <button class="btn btn-primary" (click)="calcLCC()"> {{ 'investment.calc' | translate }} </button>
      <span class="result">{{this.lcc.ans | number}} kr</span>
    </div>
  </div>
</div>