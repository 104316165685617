import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faIndustry, faGlobeEurope, faSitemap, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { FilterChangeEvent } from '../../../../shared/components/company-filter/company-filter.component';
import { APICompany } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-company-energy',
  templateUrl: './company-energy.component.html',
  styleUrls: ['./company-energy.component.scss']
})
export class CompanyEnergyComponent implements OnInit {

  options: GraphOption[] = []
  filterData: FilterChangeEvent = new FilterChangeEvent
  selectMode: boolean = true
  company: APICompany

  //Variables for graph component
  graphGroupBy: string

  type: string = "supplied"

  constructor(
    public activatedRoute: ActivatedRoute
  ) { }

  companyID$ = this.activatedRoute.params.pipe(
    map(data => { return data["id"]} )
  )

  ngOnInit() {

    this.activatedRoute.data.subscribe(
      data => {
        this.type = data.type
      }
    )

    this.options = [
      new GraphOption("site", "name", faIndustry),
      new GraphOption("country", "geo.country.name", faGlobeEurope),
      new GraphOption("region", "geo.state.name", faGlobeEurope),
      new GraphOption("municipality", "geo.municipality.name", faGlobeEurope),
      new GraphOption("section", "sni_data.section", faSitemap),
      new GraphOption("division", "sni_data.division", faSitemap),
    ]

  }

  bundle(filter: string) {
    this.graphGroupBy = filter
    this.selectMode = false
  }

  filterChange(e: FilterChangeEvent) { this.filterData = e }

  back() { this.selectMode = true }

}

class GraphOption {
  name: string
  filterString: string
  icon: IconDefinition

  constructor(name: string, filter: string, icon: IconDefinition) {
    this.name = name
    this.filterString = filter
    this.icon = icon
  }
}