import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { APIObjectDetailed, CompanyObjectService } from '../../services/company-object.service';
import { EnergyService, SuppliedEnergyForm } from '../../services/energy.service';
import * as _ from 'lodash'
import { GraphData } from '../energy-graph/energy-graph.component';
import { NetworkService } from '../../services/network.service';
import { CompanyService } from '../../services/company.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-supplied-energy-graph',
  templateUrl: './supplied-energy-graph.component.html',
  styleUrls: ['./supplied-energy-graph.component.scss']
})
export class SuppliedEnergyGraphComponent implements OnInit {

  supportedContext: string[] = ["network", "company", "object"]

  @Input() companies: APIObjectDetailed[] = []
  @Input() years: number[] = []
  @Input() type: string = "vertical"
  @Input() groupBy: string
  @Input() co2: boolean = false

  @Input() context: string
  @Input() resourceID: number

  graphData: GraphData = new GraphData

  ngOnChanges(changes: SimpleChanges) {
    this.getSupplied()
  }

  constructor(
    private _networkService: NetworkService,
    private _companyService: CompanyService,
    private _objectService: CompanyObjectService
  ) { }

  ngOnInit() {
    if (!this.supportedContext.includes(this.context)) {
      throw Error(`Given context not supported. Allowed contexts: ${this.supportedContext}, got: ${this.context}`)
    }

    if(!this.resourceID) {
      throw Error(`Resource ID missing. Component require id for the resource to get data from`)
    }

  }

  getSupplied() {
    if (this.context == "network") {
      this.getNetworkSupplied()
    } else if (this.context == "company") {
      this.getCompanySupplied()
    } else if (this.context == "object") {
      this.getObjectSupplied()
    }

  }

  getNetworkSupplied() {
    let request: Observable<SuppliedEnergyForm[][]>
    if (this.years.length == 0) {
      request = this._networkService.suppliedLatest(this.resourceID, this.companies.map(e => e.id))
    } else {
      request = this._networkService.supplied(this.resourceID, this.companies.map(e => e.id), this.years)
    }
    request.subscribe(
      data => this.parseData(data)
    )
  }

  getCompanySupplied() {
    let request: Observable<SuppliedEnergyForm[][]>
    if (this.years.length == 0) {
      request = this._companyService.suppliedLatest(this.resourceID, this.companies.map(e => e.id))
    } else {
      request = this._companyService.supplied(this.resourceID, this.companies.map(e => e.id), this.years)
    }
    request.subscribe(
      data => this.parseData(data)
    )
  }

  getObjectSupplied() {
    let request: Observable<SuppliedEnergyForm[]>
    if (this.years.length == 0) {
      request = this._objectService.suppliedLatest(this.resourceID)
    } else {
      request = this._objectService.supplied(this.resourceID, this.years)
    }
    request.subscribe(
      data => this.parseData([data])
    )
  }

  private parseData(forms: SuppliedEnergyForm[][]) {
    let compData: CompanyData[] = []
    forms.forEach((e, i) => {
      let c = new CompanyData
      c.company = this.companies[i]
      c.supplied = e
      compData.push(c)
    })
    let bundled = _.groupBy(compData, 'company.' + this.groupBy)
    if (this.groupBy.includes("sni_data")) {
      bundled = this.changeSNILabels(bundled, this.groupBy.split('.').slice(-1)[0])
    }
    let graph: GraphData = new GraphData
    graph.type = this.type
    graph.group = "carrier"
    for (const [label, compData] of Object.entries(bundled)) {
      if (this.years.length == 0) {
        let nodeLabel: string = label
        let suppliedData = []
        compData.forEach(c => {
          suppliedData = suppliedData.concat(c.supplied)
        })
        graph.data[nodeLabel] = suppliedData
      } else {
        this.years.forEach(y => {
          let nodeLabel: string = label + " - " + y
          let suppliedData = []
          compData.forEach(c => {
            suppliedData.push(_.find(c.supplied, ['year', y]))
          });
          graph.data[nodeLabel] = suppliedData
        });
      }
    }
    this.graphData = graph
  }

  changeSNILabels(bundle: _.Dictionary<CompanyData[]>, part: string): _.Dictionary<CompanyData[]> {
    let stop: number = (part == 'section' ? 2 : 3)
    return _.keyBy(bundle, o => {
      return o[0].company.sni_data.code.slice(0, stop) + new Array(5 - stop).fill("0").join("")
    })
  }

}

class CompanyData {
  company: APIObjectDetailed
  supplied: SuppliedEnergyForm[] = []
}