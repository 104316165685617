<ngb-accordion #acc="ngbAccordion" activeIds="filter-panel" type="primary">
  <ngb-panel id="filter-panel">
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="d-flex align-items-center justify-content-center white-font">
        <h5 class="mb-0 mr-2">{{ 'filter.title' | translate }}</h5>
        <button ngbPanelToggle class="btn p-0 white-font">
          <fa-icon class="clickable" [icon]="opened? faAngleUp : faAngleDown"></fa-icon>
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent class="border">
      <div class="row">
        <div class="col">
          <div class="select-column">
            <label class="section-label">{{ 'filter.location.title' | translate }}</label>
            <ng-select appendTo="body" *ngIf="locationLevelNumber >= 1" [items]="filter.country.data" bindLabel="name" [multiple]="true"
              [closeOnSelect]="false" placeholder="{{ 'filter.location.countries' | translate }}"
              [(ngModel)]="filter.country.select" [disabled]="filter.country.disable" (change)="countryChange()">
            </ng-select>
            <ng-select appendTo="body" *ngIf="locationLevelNumber >= 2" [items]="filter.state.data" bindLabel="name" [multiple]="true"
              [closeOnSelect]="false" [(ngModel)]="filter.state.select" [disabled]="filter.state.disable"
              (change)="stateChange()" placeholder="{{ 'filter.location.regions' | translate }}">
            </ng-select>
            <ng-select appendTo="body" *ngIf="locationLevelNumber >= 3" [items]="filter.municipality.data" bindLabel="name" [multiple]="true"
              [closeOnSelect]="false" [(ngModel)]="filter.municipality.select" (change)="municipalityChange()"
              [disabled]="filter.municipality.disable"
              placeholder="{{ 'filter.location.municipalites' | translate }}">
            </ng-select>
          </div>
        </div>
        <div class="col">
          <div class="select-column">
            <label class="section-label">{{ 'filter.nace.title' | translate }}</label>
            <ng-select appendTo="body" *ngIf="sniLevelNumber >= 1" [items]="filter.section.data" [multiple]="true" [closeOnSelect]="false"
              [(ngModel)]="filter.section.select" [disabled]="filter.section.disable" (change)="sectionChange()"
              placeholder="{{ 'filter.nace.sections' | translate }}">
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{ item.code }}: {{ item.desc }}
              </ng-template>
            </ng-select>
            <ng-select appendTo="body" *ngIf="sniLevelNumber >= 2" [items]="filter.division.data" [multiple]="true" [closeOnSelect]="false"
              [(ngModel)]="filter.division.select" [disabled]="filter.division.disable" (change)="divisionChange()"
              placeholder="{{ 'filter.nace.divisions' | translate }}">
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{ item.code }}: {{ item.desc }}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div *ngIf="enableSite" class="col">
          <div class="select-column">
            <label class="section-label">{{ 'filter.sites.title' | translate }}</label>
            <ng-select appendTo="body" [items]="filter.company.data" bindLabel="name" [multiple]="true"
              [closeOnSelect]="false" [(ngModel)]="filter.company.select" [disabled]="filter.company.disable"
              (change)="companyChange()" placeholder="{{ 'filter.sites.sites' | translate }}">
            </ng-select>
          </div>
        </div>
        <div *ngIf="enablePeriod" class="col">
          <label class="section-label">{{ 'filter.time.title' | translate }}</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="periodRadios" id="latestRadio" value="true"
              [(ngModel)]="filter.latest" checked (change)="periodChange()">
            <label class="form-check-label" for="latestRadio">
              {{ 'filter.time.latest' | translate }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="periodRadios" id="specificRadio" value="false"
              [(ngModel)]="filter.latest" (change)="periodChange()">
            <label class="form-check-label" for="specificRadio">
              {{ 'filter.time.specific' | translate }}
            </label>
            <div class="calendar-wrapper">
              <ng-select appendTo="body" [items]="years" [multiple]="true" [closeOnSelect]="false"
                [(ngModel)]="filter.period" [disabled]="filter.latest == 'true'" (change)="periodChange()"
                placeholder="{{ 'filter.time.select' | translate }}">
              </ng-select>
            </div>
          </div>
        </div>
        <div *ngIf="enableCategory" class="col">
          <div class="select-column">
            <label class="section-label">{{ 'filter.category.title' | translate }}</label>
            <ng-select appendTo="body" [items]="categories" bindLabel="name" [multiple]="true" [closeOnSelect]="false"
              [(ngModel)]="filter.categories" (change)="categoriesChange()"
              placeholder="{{ 'filter.category.categories' | translate }}">
            </ng-select>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>