import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserService } from '../../../../shared/services/user.service';
import { CompanyObjectService } from '../../../services/company-object.service';
import { NetworkService } from '../../../services/network.service';


@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss']
})

export class SidenavItemComponent implements OnInit {

  @Input('item')
  item: SidenavItem;
  subItems: SidenavItem[] = [];

  faAngleDown = faAngleDown
  faAngleUp = faAngleUp
  active: boolean = false;
  showSubItems: boolean = false;

  validatorFunc: (viewKey: string) => boolean

  constructor(
    private _userService: UserService,
    private _objectService: CompanyObjectService,
    private _networkService: NetworkService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.validatorFunc = this.selectValidator()
    if (!this.renderItem()) {
      this.elementRef.nativeElement.remove()
    }
  }

  selectValidator() {
    let route: string = this.item.route
    if (route.includes("/sites")) {
      return this.objectViewAccess
    } else if(route.includes("/network")) {
      return this.networkViewAccess
    } else {
      return this.userViewAccess
    }
  }

  renderItem(): boolean {
    if (this.item.isRestricted()) {
      let showItem: boolean = this.validatorFunc(this.item.restricted.requiredView)
      if (showItem) {
        this.item.subItems.forEach(e => {
          if(!e.restricted.restricted || this.validatorFunc(e.restricted.requiredView)) {
            this.subItems.push(e)
          }
        });
      }
      return showItem
    }
    this.subItems = this.item.subItems
    return true
  }

  objectViewAccess(viewKey: string): boolean {
    return this._objectService.currentObject().views[viewKey]
  }

  networkViewAccess(viewKey: string): boolean {
    return this._networkService.currentViews()[viewKey]
  }

  userViewAccess(viewKey: string): boolean {
    return this._userService.getUserInfo().info.views[viewKey]
  }

  open() {
    this.showSubItems = !this.showSubItems;
  }

}

export class SidenavItem {
  name: string;
  icon: IconDefinition;
  route: string;
  restricted: RestrictRoute;
  parent: SidenavItem;
  subItems: SidenavItem[];
  skip_exact_match: boolean;

  constructor(model: any = null) {
    if (model) {
      this.name = model.name;
      this.icon = model.icon;
      this.route = model.route;
      this.restricted = model.restricted ? model.restricted : new RestrictRoute
      this.parent = model.parent;
      this.subItems = [];
      this.skip_exact_match = model.skip_exact_match
    }
  }

  hasSubItems() {
    if (this.subItems) {
      return this.subItems.length > 0;
    }
    return false;
  }

  hasParent() {
    return !!this.parent;
  }

  isRestricted() {
    return this.restricted.restricted;
  }

  mapSubItems(list: SidenavItem[]) {
    if (list) {
      list.forEach((item, index) => {
        list[index] = new SidenavItem(item);
      });

      return list;
    }
  }
}

export class RestrictRoute {
  restricted: boolean
  requiredView: string

  constructor(restrict: boolean = false, view: string = "") {
    this.restricted = restrict
    this.requiredView = view
  }
}
