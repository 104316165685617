import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { SankeyService, ProcessEntry, LinkEquation } from '../sankey/sankey.service';
import * as _ from 'lodash';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { KeyvalueService } from '../../shared/services/keyvalue.service';
import { DateSpan } from '../../shared/components/date-span/date-span.component';
import { FormulaService } from '../../shared/services/formula.service';
import { Formula } from '../../shared/components/formula/formula.component';

@Component({
  selector: 'app-energy-follow-up',
  templateUrl: './energy-follow-up.component.html',
  styleUrls: ['./energy-follow-up.component.scss']
})
export class EnergyFollowUpComponent implements OnInit {

  faCalendarAlt = faCalendarAlt
  selectedDateSpan: DateSpan;

  sankeyProcesses: { [process: string]: ProcessEntry };

  // Dropdowns
  processDropDown: any[] = [{ 'name': 'Total', 'parent': [] }];
  selectedProcess: Process = new Process('Total', undefined, []);

  // Chart
  @ViewChild('energyChart', { static: true }) energyChartRef: ElementRef;
  energyChart: Chart;
  dates: Date[] = [];
  energyChartData: any[] = [];
  linkDirection: string = "target";
  lineFill: boolean = true;
  keyValue: boolean = false;
  carrierField: string = "value"
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  eqTabs: any[] = []

  constructor(
    private sankeyService: SankeyService,
    private keyValueService: KeyvalueService,
    private formaulaService: FormulaService) { }

  ngOnInit() {
    this.sankeyProcesses = this.sankeyService.getAllProcessesViews();
    this.initDropdown(this.sankeyProcesses, [])
    this.onProcessChange(this.selectedProcess)
  }

  initDropdown(data, parent: string[]) {
    Object.keys(data).forEach(k => {
      let e = { 'name': k, 'parent': parent }
      if (!_.isEmpty(parent)) {
        e['ps'] = parent.join('/')
      }
      this.processDropDown.push(e)
      if (!_.isEmpty(data[k]['labels'])) {
        this.initDropdown(data[k]['labels'], [...parent, k])
      }
    })
  }

  initChart() {
    if (this.energyChart) this.energyChart.destroy();
    this.energyChartData = [];

    let data = {}
    let labels = []
    if (this.dates.length > 31) {
      let res = this.dayToMonth()
      labels = res.dates.map((d) => { return this.months[(new Date(d).getMonth())] + "-" + new Date(d).getFullYear().toString() })
      data = res.data
    } else {
      labels = this.dates.map((d) => { return new Date(d).toDateString() })
      data = this.selectedProcess.data
    }

    labels = ['', ...labels, '']

    Object.keys(this.selectedProcess.data).forEach(carrier => {
      let values = data[carrier]
      values = [undefined, ...values, undefined]

      let color = '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6)
      this.energyChartData.push({
        label: carrier,
        data: values,
        hidden: values.every(v => { return v == undefined || v == 0 }),
        backgroundColor: color,
        borderColor: color,
        fill: this.lineFill,
        spanGaps: false,
      })
    });
    this.energyChart = new Chart(
      this.energyChartRef.nativeElement,
      {
        type: 'line',
        data: {
          labels: labels,
          datasets: this.energyChartData,
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              stacked: this.lineFill,
              ticks: {
                beginAtZero: this.lineFill,
              }
            }]
          },
          tooltips: {
            mode: 'index'
          },
          animation: {
            duration: 750,
          },
          elements: {
            line: {
              tension: 0 // disables bezier curves
            }
          },
        }
      }
    );

    this.energyChart.update()
  }

  // Convert data on daily format to monthly format
  // First and last month may only contain partial data
  // unless the first and last days of the months were selected
  dayToMonth() {
    let dates: Date[] = []
    let month: number
    let prev = 0
    let data = {}

    let f = this.keyValue || this.carrierField != "value" && this.carrierField != "exergy" ? this.getPartialAvg : this.getPartialSum
    this.dates.forEach((dt, i) => {
      if (dates.length == 0) {
        dates.push(dt)
        month = dt.getMonth()
      } else {
        if (dt.getMonth() != month) {
          dates.push(dt)

          Object.entries(this.selectedProcess.data).forEach(([k, v]) => {
            if (!data[k]) {
              data[k] = []
            }
            let res = f(v, prev, i)
            data[k].push(res)
          })
          prev = i
          month = dt.getMonth()
        }
      }
    })
    Object.entries(this.selectedProcess.data).forEach(([k, v]) => {
      if (!data[k]) {
        data[k] = []
      }
      let res = f(v, prev)
      data[k].push(res)
    })
    return { "dates": dates, "data": data }
  }

  // Get the sum of a given span
  getPartialSum(data: any[], start?: number, end?: number): number {
    let slice = data.slice(start, end)
    if (!slice.every(e => { return e == undefined })) {
      let sum = slice.reduce((a, b) => { return (+a || 0) + (+b || 0); })
      return sum
    } else {
      return undefined
    }
  }

  // Get the average of a given span
  getPartialAvg(data: any[], start?: number, end?: number): number {
    let slice = data.slice(start, end)
    if (!slice.every(e => { return e == undefined })) {
      let num = slice.filter(x => x != undefined).length
      let sum = slice.reduce((a, b) => { return (+a || 0) + (+b || 0); })
      let avg = sum / num
      return isFinite(avg) ? avg : undefined
    } else {
      return undefined
    }
  }

  onTabChange(event) {
    let e = this.selectedProcess
    this.keyValue = event["nextId"] == "kv" ? true : false
    if (event["nextId"] == "input") {
      this.linkDirection = "target";
      this.lineFill = this.carrierField == "value" || this.carrierField == "exergy" ? true : false;
      this.onProcessChange(e);
    } else if (event["nextId"] == "output") {
      this.linkDirection = "source";
      this.lineFill = this.carrierField == "value" || this.carrierField == "exergy" ? true : false;
      this.onProcessChange(e);
    } else if (event["nextId"] == "kv") {
      this.linkDirection = ""
      this.lineFill = false;
      this.onKeyValue(e)
    }
  }

  onSubTabChange(event) {
    this.carrierField = event["nextId"]
    this.lineFill = this.carrierField == "value" || this.carrierField == "exergy" ? true : false
    this.onProcessChange(this.selectedProcess)
  }

  onKeyValue(event) {

    this.selectedProcess.name = event.name
    this.selectedProcess.parent = event.parent
    this.selectedProcess.data = {}

    if (event.name == 'Total') {
      Object.entries(this.sankeyProcesses).forEach(([name, process]) => {
        let data = this.getKeyValues(process, name)
        Object.keys(data).forEach(k => {
          // Check if the type is set and either add to it or set it
          if (this.selectedProcess.data[k]) {
            // Add elements in data[k] to the corresponding element in selectedProcess
            // if the result is 0 return undefined
            this.selectedProcess.data[k] = this.selectedProcess.data[k].map((v, i) => {
              let res = (+v || 0) + (+data[k][i] || 0)
              return res == 0 ? undefined : res
            })
          } else {
            this.selectedProcess.data[k] = data[k]
          }
        });
      })
    } else {
      let p = this.sankeyService.getProcess(event.name, event.parent)
      this.selectedProcess.data = this.getKeyValues(p)
    }
    this.initChart()
  }

  getKeyValues(process: ProcessEntry, name?: string): { [name: string]: number[] } {
    let kvs: { [name: string]: number[] } = {}

    process.keyvalues.forEach(kv => {
      let key = name ? name + "/" + kv.name : kv.name
      if (!kvs[key]) {
        kvs[key] = new Array<number>(this.dates.length)
      }

      this.dates.forEach((dt, index) => {
        let span = new DateSpan
        span.from = dt
        span.to = dt

        let val = this.keyValueService.getValue(kv, span)
        if (isFinite(val)) {
          kvs[key][index] = +val.toFixed(3)
        }
      })
    })

    return kvs
  }

  onProcessChange(event) {
    this.selectedProcess.name = event.name
    this.selectedProcess.parent = event.parent
    this.selectedProcess.data = {}

    if (!this.keyValue) {
      this.eqTabs = []
      if (event.name == 'Total') {
        Object.values(this.sankeyProcesses).forEach(process => {
          let dir = this.linkDirection == "target" ? "source" : "target"
          let node = this.linkDirection == "target" ? "input" : "output"

          let data = this.getCarrierValues(node, process, dir)

          Object.keys(data).forEach(k => {
            // Check if the type is set and either add to it or set it
            if (this.selectedProcess.data[k]) {
              // Add elements in data[k] to the corresponding element in selectedProcess
              // if the result is 0 return undefined
              this.selectedProcess.data[k] = this.selectedProcess.data[k].map((v, i) => {
                let res = (+v || 0) + (+data[k][i] || 0)
                return res == 0 ? undefined : res
              })
            } else {
              this.selectedProcess.data[k] = data[k]
            }
          });
        });
      } else {
        let p = this.sankeyService.getProcess(event.name, event.parent)
        this.selectedProcess.data = this.getCarrierValues(event.name, p, this.linkDirection)
      }
      this.initChart()
    } else {
      this.onKeyValue(event)
    }
  }

  // Get sum of each type's values for each date in the given date span.
  // If no value exists for a date, undefined is used in its place.
  getCarrierValues(name: string, process: ProcessEntry, dir: string): { [type: string]: number[] } {
    let carrierValues: { [type: string]: number[] } = {}

    process.links.forEach(uuid => {
      let link = this.sankeyService.findLinkByUUID(uuid)

      // Input: check if process is the target
      // Output: check if process is the source
      if (link[dir] == name) {

        if (!carrierValues[link.type]) {
          carrierValues[link.type] = new Array<number>(this.dates.length)
        }

        // Get all current tabs
        link.equations.extras.forEach((e: LinkEquation) => {
          if (!this.eqTabs.some((t: LinkEquation) => { 
            return t.name.data == e.name.data && t.name.show == e.name.show
          })) {
            this.eqTabs.push(e)
          }
        })

        // Add to or set the value in the correct position for each type
        // if no value exists leave it undefined to handle gaps
        this.dates.forEach((dt, index) => {
          let formula: Formula
          if (this.carrierField == "value") {
            formula = link.equations.energy
          } else if (this.carrierField == "exergy") {
            formula = link.equations.exergy
          } else {
            link.equations.extras.forEach(e => {
              if (e.name.data == this.carrierField) {
                formula = e
              }
            })
          }

          if (formula) {
            let v = this.formaulaService.evaluate(formula, dt)
            v = v != undefined ? +v.toFixed(3) : v
            if (carrierValues[link.type][index] == undefined) {
              carrierValues[link.type][index] = v
            } else {
              carrierValues[link.type][index] += v
            }
          }

        })
      }

    })
    return carrierValues
  }

  getDates(span: DateSpan): Date[] {
    let arr: Date[] = []
    let dt = new Date(span.from)
    while (dt <= span.to) {
      arr.push(new Date(dt))
      dt.setDate(dt.getDate() + 1)
    }
    return arr
  }

  getEpoch(d: Date): number {
    return Math.round(d.getTime() / 1000)
  }

  changeDateSpan(e) {
    this.selectedDateSpan = e
    this.dates = this.getDates(this.selectedDateSpan)
    let event = { "name": this.selectedProcess.name, "parent": this.selectedProcess.parent }
    this.onProcessChange(event)
  }

}

export class Process {
  constructor(name: string, data: any, path: string[]) {
    this.name = name;
    this.data = data;
    this.parent = path;
  }
  name: string;
  data: { [type: string]: number[] };
  parent: string[];
}
