import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent implements OnInit {

  @Input() loading: boolean = false
  @Input() items: any[] = []
  @Input() heading: string = ""
  @Input() displayKey: string = "name"
  @Input() resourceURL: string = ""

  constructor() { }

  ngOnInit(): void {
  }

}
