<div class="container-fluid">
    <div class="row top-panel mb-3">
        <div class="col-md-3">
            <ng-select placeholder="{{ 'view.sites.follow-up.views.select' | translate }}" [items]="availableViews"
                [clearable]="false" [(ngModel)]="selectedView" bindLabel="viewName" bindValue="modelName">
            </ng-select>
        </div>
        <div class="calendar-wrapper">
            <app-date-span (change)="yearChange($event)" onlyYear="true" allowRange="true"></app-date-span>
        </div>
        <button class="btn btn-primary ml-2" (click)="load()">
            {{ 'buttons.show' | translate}}
        </button>
    </div>

    <div>

        <app-supplied-energy-graph [companies]="graphCompanies" [years]="graphYears" groupBy="name"
            *ngIf="viewSettings.view == 'input'" context="object" [resourceID]="currentCompany.id">
        </app-supplied-energy-graph>

        <div *ngIf="viewSettings.view != 'input'">
            <ngb-tabset (tabChange)="onTabChange($event)">
                <ngb-tab title="{{ 'view.sites.follow-up.energy-tab' | translate }}" id="energy"></ngb-tab>
                <ngb-tab title="{{ 'view.sites.follow-up.co2-tab' | translate }}" id="co2"></ngb-tab>
            </ngb-tabset>

            <div *ngIf="viewSettings.view == 'enduse'">
                <div class="row mt-3">
                    <div class="col-md-3">
                        <ng-select placeholder="{{ 'view.sites.follow-up.enduse-select' | translate }}"
                            [items]="availableEndUse" [(ngModel)]="selectedEndUse" bindValue="id" multiple="true"
                            (change)="changeShowEnduses()">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                <div>{{ item.name }}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <canvas #chart class="mt-3" id="energyChart"></canvas>
            </div>

            <div #sankey id="sankey" *ngIf="viewSettings.view == 'sankey'">
                <svg id="sankey-svg" width="100%" height="50vh"></svg>
            </div>

        </div>
    </div>
</div>
