import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { SensorDB, SensorService } from '../../services/sensor.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @ViewChild("fileModal", { static: true }) fileModal: ElementRef;
  form: FormGroup;
  fileList: FileList;

  constructor(
    private formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _xmlService: NgxXml2jsonService,
    private _sensorService: SensorService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      file: ['']
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.fileList = event.target.files
      for (let i = 0; i < this.fileList.length; i++) {
        this.parseFile(this.fileList[i])
      }
    }
  }

  parseFile(file: File) {
    switch (file.type) {
      case 'text/csv':
        console.log("parse csv file")
        this.parseCSV(file)
        break;
      case 'text/xml':
        console.log("parse xml file")
        this.parseXML(file)
        break;
      default:
        console.log("File can not be parsed. File type not supported!")
    }
    this._modalService.dismissAll()
  }

  parseXML(file: File) {
    console.log(file)
    let reader = new FileReader()
    let content: any
    reader.onload = () => {
      let xmlString = reader.result.toString()
      let xml = new DOMParser().parseFromString(xmlString, 'text/xml')
      content = this._xmlService.xmlToJson(xml)

      let name = Object.keys(content)[0]

      let sf = new SodraFile(content[name])
      console.log(sf)

      sf.sensors.forEach(s => {
        this._sensorService.addEntry(s)
      })
      console.log("Importing", name, "done!")
    }
    reader.readAsText(file);
  }

  parseCSV(file: File) {
    let reader = new FileReader()
    reader.onload = () => {
      let rows: string[] = reader.result.toString().split(/\r?\n/)
      let header: string = rows[0]

      // Get sensor ID:s from header
      let sensorIDs: string[] = header.split(";").splice(1)

      let sensorReadings: DataItem[] = []

      rows.slice(1).forEach(r => {
        if (r == "") return
        let data: string[] = r.split(";")
        let ts = new Date(data[0].split(" ")[0]).getTime() / 1000
        data.splice(1).forEach((e, i) => {
          let d: DataItem = new DataItem
          d.id = sensorIDs[i]
          d.time = ts
          d.value = Number(e)
          sensorReadings.push(d)
        });
      });
      sensorReadings.forEach(s => {
        this._sensorService.addEntry(s)
      })
      console.log("Importing", file.name, "done!")
    }
    reader.readAsText(file);
  }

  open() {
    this._modalService.open(this.fileModal, { centered: true, size: 'lg' })
  }
}

class SodraFile {
  timeStamp: string
  id: string
  sender: Sender
  sensors: DataItem[]

  constructor(obj?) {
    if (obj) {
      this.timeStamp = obj.messageTimeStamp
      this.id = obj.messageID
      this.sender = new Sender(obj.sender)

      this.sensors = []
      obj.dataItems.dataItem.forEach(i => {
        this.sensors.push(new DataItem(i))
      })
    }
  }
}

class Sender {
  name: string
  shortName: string
  postalAddress: string
  zipCode: string
  postalPlaceName: string

  constructor(obj) {
    this.name = obj.millName
    this.shortName = obj.millShortName
    this.postalAddress = obj.postalAddress
    this.zipCode = obj.zipCode
    this.postalPlaceName = obj.postalPlaceName
  }
}

class DataItem {
  id: string
  description: string
  time: number
  value: number

  constructor(obj?) {
    if (obj) {
      this.id = obj.object
      this.description = obj.description
      this.time = new Date(obj.timeStamp).getTime() / 1000
      this.value = +obj.value
    }
  }
}

