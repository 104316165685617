import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircle, faEquals, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { APIObjectDetailed, CompanyObjectService } from '../../../../shared/services/company-object.service';
import { EnergyService } from '../../../../shared/services/energy.service';
import { APIUser, UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'app-company-object-home',
  templateUrl: './company-object-home.component.html',
  styleUrls: ['./company-object-home.component.scss']
})
export class CompanyObjectHomeComponent implements OnInit {

  //Icons
  faCircle = faCircle;

  faArrow = faLongArrowAltRight;
  faEquals = faEquals;

  companies: Company[] = []
  coordinator: boolean = false;

  user: APIUser

  columns: TableColumn[] = [
    { name: "view.sites.home.name", key: "name", sort: true, search: true, template: true},
    { name: "view.sites.home.nace", key: "sni_data.code", sort: true, search: true},
    { name: "view.sites.home.country", key: "geo.country.name", sort: true, search: true},
    { name: "view.sites.home.region", key: "geo.state.name", sort: true, search: true},
    { name: "view.sites.home.municipality", key: "geo.municipality.name", sort: true, search: true},
    { name: "view.sites.home.supplied", key: "latest_supplied", sort: true },
    { name: "view.sites.home.used", key: "latest_enduse", sort: true },
    { name: "view.sites.home.mapped", key: "latest_mapped", sort: true },
  ]
  settings: TableSettings = {
    title: "view.sites.home.title",
    add: false,
  }

  loading: boolean = true;

  constructor(
    private _energyService: EnergyService,
    private _objectService: CompanyObjectService,
    private _userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.companies = []
    this.user = this._userService.getUserInfo()
    this._objectService.getUserObjects(this.user.id).subscribe(
      data => {
        data.forEach(c => {
          this.companies.push(new Company(c))
        });
        this.initCoordinator()
      }
    )
  }

  initCoordinator() {
    this.loading = true;
    let count = 0;
    this.companies.forEach((c, i) => {
      forkJoin([
        this._objectService.suppliedLatest(c.id, 1),
        this._energyService.v2_latestUsed(c.id, 1)
      ]).subscribe(
        res => {
          let current = this.companies[i]
          let supplied = res[0]
          let enduse = res[1]
          current.latest_supplied = supplied.length != 0 ? supplied[0].year : undefined
          current.latest_enduse = enduse.length != 0 ? enduse[0].year : undefined
          if (current.latest_enduse == undefined) {
            current.mapped = null
            count += 1
            this.loading = !(count == this.companies.length)
          } else if (current.latest_supplied && current.latest_supplied == current.latest_enduse) {
            current.mapped = this.calculateBalance(supplied[0].getTotal(), enduse[0].getTotal())
            current.latest_mapped = current.latest_enduse
            count += 1
            this.loading = !(count == this.companies.length)
          } else {
            this._objectService.supplied(c.id, [current.latest_enduse]).subscribe(
              data => {
                let supplied = data[0]
                if (supplied.getTotal() == 0) {
                  current.mapped = null
                } else {
                  current.mapped = this.calculateBalance(supplied.getTotal(), enduse[0].getTotal())
                  current.latest_mapped = current.latest_enduse
                }
                count += 1
                this.loading = !(count == this.companies.length)
              }
            )
          }
        }
      )
    })
  }

  calculateBalance(supplied, enduse): number {
    return (supplied > 0 && enduse > 0) ? 100 * (enduse / supplied) : 0
  }

  navigateToSite(id: number) {
    this._objectService.selectObject(id).subscribe(
    data => {
      this.router.navigateByUrl(`/sites/${id}`)
    });
  }

}

class Company extends APIObjectDetailed {
  latest_supplied: number
  latest_enduse: number
  latest_mapped: number
  mapped: number

  constructor(obj?: any) {
    super(obj)
  }

}