import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CompanyObjectService, APIObjectDetailed } from '../../../shared/services/company-object.service';
import { EnergyService, SuppliedEnergyForm } from '../../../shared/services/energy.service';
import * as _ from 'lodash';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supplied-energy',
  templateUrl: './supplied-energy.component.html',
  styleUrls: ['./supplied-energy.component.scss']
})
export class SuppliedEnergyComponent implements OnInit {
  selectedCompany: APIObjectDetailed;
  selectedYear: number;

  carrierTrans: any;

  energyForm: SuppliedEnergyForm = this.energyService.emptySuppliedForm();

  // Energy Carrier Pie chart
  @ViewChild('energyCarrierChart', { static: true }) fuelChartRef: ElementRef;
  fuelChart: Chart;
  fuelChartData: number[] = []

  @ViewChild('energyCarbonChart', { static: true }) co2ChartRef: ElementRef;
  co2Chart: any;
  co2ChartData: number[] = []

  chartLabels: string[] = []
  chartColors: string[] = []

  constructor(
    private _objectService: CompanyObjectService,
    private energyService: EnergyService,
    private translate: TranslateService
  ) {
    translate.stream(['energycarriers']).subscribe(res => {
      this.carrierTrans = res['energycarriers']
      this.updateChartData()
    });
  }

  ngOnInit() {
    this.initCharts()
    this.selectedCompany = this._objectService.currentObject()
    if (this.selectedYear) {
      this.initSuppliedEnergy();
    }
  }

  yearChange(year) {
    this.selectedYear = year
    this.initSuppliedEnergy()
  }

  initSuppliedEnergy() {
    if (this.selectedYear && !_.isEmpty(this.selectedCompany)) {
      this.loadForm();
    }
  }

  initCharts() {
    this.initCarrierPieChart()
    this.initCo2Chart()
  }

  loadForm() {
    this._objectService.supplied(this.selectedCompany.id, [this.selectedYear]).subscribe(
      data => {
        this.energyForm = data[0];
        this.updateChartData()
      }
    )
  }

  updateView() {
    this.energyForm.updateTotals()
    this.updateChartData()
  }

  updateChartData() {
    this.chartLabels.length = 0 //Clears the array, keeps the reference
    this.fuelChartData.length = 0 //Clears the array, keeps the reference
    this.co2ChartData.length = 0 //Clears the array, keeps the reference
    this.chartColors.length = 0 //Clears the array, keeps the reference

    this.energyForm.fuels.forEach(f => {
      if (f.amount > 0) {
        this.chartLabels.push(this.carrierTrans[f.model])
        this.chartColors.push(f.graph_color)
        this.fuelChartData.push(f.amount)
        this.co2ChartData.push(f.amount * f.co2)
      }
    });
    if (this.fuelChart) this.fuelChart.update()
    if (this.co2Chart) this.co2Chart.update()
  }

  // Create the Chart object for the energy pie chart
  initCarrierPieChart() {
    if (this.fuelChart) return;
    this.fuelChart = new Chart(this.fuelChartRef.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels,
        datasets: [{
          data: this.fuelChartData,
          backgroundColor: this.chartColors,
        }]
      },
      options: {
        tooltips: {
          enabled: true,
          mode: 'point',
          callbacks: {
            label: function (tooltipItem, data) {
              return `${data.labels[tooltipItem.index]}: ${Math.round(Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]))}`
            }
          }
        },
        circumference: Math.PI,
        rotation: Math.PI,
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 30,
          }
        }
      }
    });
  }

  // Create the Chart object for the energy pie chart
  initCo2Chart() {
    if (this.co2Chart) return;
    this.co2Chart = new Chart(this.co2ChartRef.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels,
        datasets: [{
          data: this.co2ChartData,
          backgroundColor: this.chartColors,
        }]
      },
      options: {
        tooltips: {
          enabled: true,
          mode: 'point',
          callbacks: {
            label: function (tooltipItem, data) {
              return `${data.labels[tooltipItem.index]}: ${Math.round(Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]))}`
            }
          }
        },
        circumference: Math.PI,
        rotation: Math.PI,
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 30,
          }
        }
      }
    });
  }
}
