import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})

export class ModalsComponent implements OnInit {

  @Input() content: string;
  @Input() name: string;
  @Input() icon: any;
  @Input() buttonType: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit() { 
  }

  open() {
    this.modalService.open(this.content, { centered: true });
  }

}