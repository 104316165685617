import { Routes } from "@angular/router";
import { UsersComponent } from "../components/new/admin-page/users/users.component";
import { CompaniesComponent } from "../components/new/admin-page/companies/companies.component";
import { DemoComponent } from "../components/new/admin-page/demo/demo.component";
import { AgreementComponent } from "../components/new/admin-page/agreement/agreement.component";
import { CompanyObjectsComponent } from "../components/new/admin-page/company-objects/company-objects.component";
import { NetworksComponent } from "../components/new/admin-page/networks/networks.component";
import { NetworksFormComponent } from "../components/new/admin-page/networks/networks-form/networks-form.component";
import { CompaniesFormComponent } from "../components/new/admin-page/companies/companies-form/companies-form.component";
import { UserFormComponent } from "../components/new/admin-page/users/user-form/user-form.component";
import { ObjectFormComponent } from "../components/new/admin-page/company-objects/object-form/object-form.component";
import { ObjectComponent as ObjectLicense } from "../components/new/admin-page/licenses/object/object.component";
import {ObjectFormComponent as ObjectLicenseForm} from "../components/new/admin-page/licenses/object/object-form/object-form.component";
import { NetworkComponent as NetworkLicense } from "../components/new/admin-page/licenses/network/network.component";
import { NetworkFormComponent as NetworkLicenseForm} from "../components/new/admin-page/licenses/network/network-form/network-form.component";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
    },
    {
        path: 'users',
        children: [
            {
                path: '',
                component: UsersComponent,
            },
            {
                path: 'new',
                component: UserFormComponent,
                data: { mode: "new" }
            },
            {
                path: ":id",
                children: [
                    {
                        path: '',
                        component: UserFormComponent,
                        data: { mode: "view" },
                    },
                    {
                        path: 'edit',
                        component: UserFormComponent,
                        data: { mode: "edit" },
                    }
                ]
            }
        ]
    },
    {
        path: 'companies',
        children: [
            {
                path: '',
                component: CompaniesComponent
            },
            {
                path: 'new',
                component: CompaniesFormComponent,
                data: { mode: "new" }
            },
            {
                path: ":id",
                children: [
                    {
                        path: '',
                        component: CompaniesFormComponent,
                        data: { mode: "view" },
                    },
                    {
                        path: 'edit',
                        component: CompaniesFormComponent,
                        data: { mode: "edit" },
                    }
                ]
            }
        ]
    },
    {
        path: 'sites',
        children: [
            {
                path: '',
                component: CompanyObjectsComponent,
            },
            {
                path: 'new',
                component: ObjectFormComponent,
                data: { mode: "new" }
            },
            {
                path: ":id",
                children: [
                    {
                        path: '',
                        component: ObjectFormComponent,
                        data: { mode: "view" },
                    },
                    {
                        path: 'edit',
                        component: ObjectFormComponent,
                        data: { mode: "edit" },
                    }
                ]
            }
        ]
    },
    {
        path: 'networks',
        children: [
            {
                path: '',
                component: NetworksComponent
            },
            {
                path: 'new',
                component: NetworksFormComponent,
                data: { mode: "new" }
            },
            {
                path: ":id",
                children: [
                    {
                        path: '',
                        component: NetworksFormComponent,
                        data: { mode: "view" },
                    },
                    {
                        path: 'edit',
                        component: NetworksFormComponent,
                        data: { mode: "edit" },
                    }
                ]
            }
        ]
    },
    {
        path: 'licenses',
        children: [
            {
                path: '',
                redirectTo: 'site',
                pathMatch: 'full',
            },
            {
                path: 'site',
                children: [
                    {
                        path: '',
                        component: ObjectLicense
                    },
                    {
                        path: 'new',
                        component: ObjectLicenseForm,
                        data: { mode: "new" }
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                path: '',
                                component: ObjectLicenseForm,
                                data: { mode: "view" },
                            },
                            {
                                path: 'edit',
                                component: ObjectLicenseForm,
                                data: { mode: "edit" },
                            }
                        ]
                    }
                ]
            },
            {
                path: 'network',
                children: [
                    {
                        path: '',
                        component: NetworkLicense
                    },
                    {
                        path: 'new',
                        component: NetworkLicenseForm,
                        data: { mode: "new" }
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                path: '',
                                component: NetworkLicenseForm,
                                data: { mode: "view" },
                            },
                            {
                                path: 'edit',
                                component: NetworkLicenseForm,
                                data: { mode: "edit" },
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: 'demo',
        component: DemoComponent
    },
    {
        path: 'agreement',
        component: AgreementComponent
    }
]