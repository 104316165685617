import { Component, OnInit } from '@angular/core';
import { EnergyService, SuppliedEnergyForm, NewEUForm } from '../../../shared/services/energy.service';
import { CompanyObjectService, APIObjectDetailed } from '../../../shared/services/company-object.service';
import * as _ from 'lodash';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-energy-balance',
    templateUrl: './energy-balance.component.html',
    styleUrls: ['./energy-balance.component.scss']
})
export class EnergyBalanceComponent implements OnInit {

    init: boolean = false;

    selectedCompany: APIObjectDetailed = new APIObjectDetailed;
    selectedYear: number

    suppliedForm: SuppliedEnergyForm = new SuppliedEnergyForm;
    enduseForm: NewEUForm = new NewEUForm;

    faPlug = faPlug

    balanceResult: BalanceResult = new BalanceResult;

    constructor(
        private energyService: EnergyService,
        private _objectService: CompanyObjectService,
        private translate: TranslateService,
    ) {
        translate.onLangChange.subscribe(
            (e: LangChangeEvent) => {
                if (!this.init) return
                //Translate enduse form
                this.energyService.translateEndUseForm(this.enduseForm, e.lang).subscribe(
                    data => { this.enduseForm = data })
            }
        );
    }

    ngOnInit() {
        this.selectedCompany = this._objectService.currentObject()
        this.loadData()
    }

    yearChange(year) {
        this.selectedYear = year
        this.loadData()
    }

    loadData() {
        if (this.selectedYear && !_.isEmpty(this.selectedCompany)) {
            forkJoin([
                this._objectService.supplied(this.selectedCompany.id, [this.selectedYear]),
                this.energyService.v2_getNewUsed(this.selectedCompany.id, this.selectedYear)
            ]).subscribe(
                data => {
                    this.balanceResult = new BalanceResult;
                    this.suppliedForm = data[0][0]
                    this.suppliedForm.removeUnreported()
                    this.enduseForm = data[1]
                    let tot: number = 0
                    this.enduseForm.enduse.forEach(e => {
                        tot += e.carriers.total
                    });
                    this.enduseForm.removeUnreported()
                    this.balanceResult.totalEndUse = tot;
                    this.balanceResult.totalInput = _.find(this.suppliedForm.general, ['model', 'total']).amount
                    this.balanceResult.setPercent()
                    this.init = true
                }
            );
        }
    }
}

export class BalanceResult {
    totalInput: number;
    totalEndUse: number;
    percent: number;

    constructor() {
        this.totalInput = 0;
        this.totalEndUse = 0;
        this.percent = 0;
    }

    setPercent() {
        this.percent = (this.totalInput > 0 && this.totalEndUse > 0) ? Math.round(100 * (this.totalEndUse / this.totalInput)) : 0
    }
}
