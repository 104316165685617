import { Component, OnInit, ViewChild } from '@angular/core';
import { SankeyService, ProcessEntry } from '../sankey.service';
import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import * as _ from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sankey-definitions',
  templateUrl: './sankey-definitions.component.html',
  styleUrls: ['./sankey-definitions.component.scss']
})
export class SankeyDefinitionsComponent implements OnInit {

  @ViewChild(TreeComponent, { static: true })
  private tree: TreeComponent;

  data: any
  types: string[] = []
  flows: string[] = []

  newType: string
  newFlow: string

  nodeId: number = 0

  nodes: Node[] = [];

  options = {};
  faTimes = faTimes

  constructor(private _sankeyService: SankeyService) { }

  ngOnInit() {
    this.data = this._sankeyService.getAllProcessesViews()
    this.types = this._sankeyService.getTypes()
    this.flows = this._sankeyService.getFlows()

    this.load(this.data)
  }

  load(data: ProcessEntry[], parent?: Node) {
    if (_.isEmpty(data)) return

    Object.keys(data).forEach(k => {
      let node = new Node(this.nodeId++, k)
      if (parent) {
        parent.addChild(node)
      } else {
        this.nodes.push(node)
      }
      this.load(data[k].labels, node)
    })
  }

  // Get a list of a node's path expressed in parents
  getParentPath(node?: TreeNode): string[] {
    let n = []
    let nn = node
    while (nn.parent) {
      nn = nn.parent;
      if (!nn.data.virtual)
        n.unshift(nn.data.name);
    }
    return n
  }

  // Add a process to the data structure
  addProcess(name: string, node?: TreeNode) {
    console.log(name, node);

    if (!name) {
      return
    }
    if (node) {
      //Check if the name already exist on the level.
      if(node.data.hasChild(name)) {
        console.log("Node with the name already exist. Raise error")
        return
      }
      let n = [...this.getParentPath(node), node.data.name]
      this._sankeyService.addProcess(name, n)
      node.data.children.push(new Node(this.nodeId++, name))
    } else {
      for(let n of this.nodes) {
        if(n.name == name) {
          console.log("Node with the name already exist. Raise error")
          return
        }
      }
      this._sankeyService.addProcess(name, [])
      this.nodes.push(new Node(this.nodeId++, name))
    }
    this.reloadData()
  }

  // Delete a node and all its children then reload the tree
  deleteProcess(node: TreeNode) {
    let n = this.getParentPath(node)
    this._sankeyService.deleteProcess(node.data["name"], n)
    this.reloadData()
  }

  reloadData() {
    this.data = this._sankeyService.getAllProcessesViews()
    this.nodes = []
    this.load(this.data)
    this.tree.treeModel.update()
  }

  // Add a type to the list of available types
  addType() {
    if (!this.exists(this.newType, this.types)) {
      this._sankeyService.addType(this.newType)
    }
    this.newType = undefined
  }

  deleteType(type: string) {
    this._sankeyService.deleteType(type)
  }

  // Add a flow to the list of available flows
  addFlow() {
    if (!this.exists(this.newFlow, this.flows)) {
      this._sankeyService.addFlow(this.newFlow)
    }
    this.newFlow = undefined
  }

  deleteFlow(flow: string) {
    this._sankeyService.deleteFlow(flow)
  }

  // Check if X ezists in XS
  exists(x: string, xs: string[]): boolean {
    for (let s of xs) {
      if (x == s) {
        return true
      }
    }
    return false
  }

}

// Node class to use for intermediary tree nodes
class Node {
  constructor(id: number, name: string) {
    this.id = id
    this.name = name
    this.children = []
  }

  id: number
  name: string
  children: Node[]

  addChild(c: any) {
    this.children.push(c)
  }

  hasChild(name : string) : Boolean {
    for(let c of this.children) {
      if(c.name == name) {
        return true
      }
    }
    return false
  }

}