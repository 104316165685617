<div class="container-fluid">
  <div class="card border-primary investment-panel">
    <div class="card-header bg-primary text-white">
      <span>{{ 'investment.present-value' | translate }}</span>
      <span class="invest-tooltip" triggers="mouseenter:mouseleave" placement="bottom" ngbPopover="{{ 'investment.present-value-desc' | translate }}">
        <fa-icon [icon]="faTooltip"></fa-icon>
      </span>
    </div>
    <div class="card-body">
      <div>
        <label for="years"> {{ 'investment.years' | translate }} </label>
        <input type="number" id="years" [(ngModel)]="this.presentValue.years" (ngModelChange)="setRange()">
      </div>
      <div>
        <label for="interest"> {{ 'investment.interest' | translate }} (%) </label>
        <input type="number" id="interest" [(ngModel)]="this.presentValue.interest">
      </div>
      <div>
        <label for="rest"> {{ 'investment.rest-value' | translate }} (kr) </label>
        <input type="number" id="rest" [(ngModel)]="this.presentValue.restValue">
      </div>
      <hr>
      <div class="row">
        <div class="col-lg">
          <label> {{ 'investment.in' | translate }} (kr) </label>
          <div *ngFor="let x of range; let i = index">
            <label for="{{in + i}}"> {{ 'investment.io-year' | translate | titlecase }} {{i+1}} </label>
            <input id="{{in + i}}" type="number" [(ngModel)]="this.presentValue.in[i+1]">
          </div>
        </div>
        <div class="col-lg">
          <label> {{ 'investment.out' | translate }} (kr) </label>
          <div *ngFor="let x of range; let i = index">
            <label for="{{out + i}}"> {{ 'investment.io-year' | translate | titlecase }} {{i+1}} </label>
            <input id="{{out + i}}" type="number" [(ngModel)]="this.presentValue.out[i+1]">
          </div>
        </div>
      </div>
      <div>
        <hr>
        <button class="btn btn-primary" (click)="calcPresentValue()"> {{ 'investment.calc' | translate }}</button>
        <span class="result">{{this.getAnswer() | number}} kr</span>
        <!-- <span class="result">{{this.presentValue.ans}} kr</span> -->
      </div>
    </div>
  </div>
</div>