import { Component, OnInit } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-payoff',
  templateUrl: './payoff.component.html',
  styleUrls: ['./payoff.component.scss']
})
export class PayoffComponent implements OnInit {

  payoff: PayoffVars = new PayoffVars;
  faTooltip = faQuestionCircle;
  
  constructor() { }

  ngOnInit() {
  }

  public calcPayoff() {
    this.payoff.ans = this.payoff.investment / (this.payoff.in - this.payoff.out);
  }
  
}

export class PayoffVars {
  investment: number;
  in: number;
  out: number;
  ans: number;
}