import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APIUser } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: boolean = false
  private admin: boolean = false

  constructor(
    private http: HttpClient,
  ) { }

  setLogin(): void {
    this.loggedIn = true
  }

  setClientSession(user: APIUser): void {
    let expire = new Date(new Date().getTime() + (60 * 60 * 8 * 1000)) // 8 hours in milliseconds
    sessionStorage.setItem("user", JSON.stringify({
      'id': user.id,
      'expire': expire.toISOString()
    }))
  }

  isLoggedIn(): boolean {
    return this.loggedIn
  }

  logoutSession() {
    sessionStorage.clear()
    location.assign('/login')
    location.reload();
  }

  v2_login(form): Observable<APIUser> {
    return this.http.post("/api/v2/login", form).pipe(
      map((res: APIUser) => {
        let user: APIUser = new APIUser(res)
        return user
      })
    )
  }

  v2_logout(): Observable<any> {
    return this.http.post("/api/v2/logout", null).pipe(
      map(
        resp => {
          this.logoutSession()
        },
        err => {
          console.log(err)
        }
      )
    )
  }

}
