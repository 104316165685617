<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="{{ 'view.sites.advanced.definitions.process' | translate }}" #rootName>
        <div class="input-group-append">
          <button class="btn btn-outline-primary btn-sm" (click)="addProcess(rootName.value); rootName.value = ''">
            {{ 'view.sites.advanced.definitions.add' | translate }}
          </button>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <tree-root #tree [nodes]="nodes">
            <ng-template #treeNodeTemplate let-node let-index="index">
              <div class="input-group mb-1 input-group-sm">
                <span>{{ node.data.name }}</span>
                <input type="text" class="form-control ml-2"
                  placeholder="{{ 'view.sites.advanced.definitions.sub-process' | translate }}" #name>
                <div class="input-group-append">
                  <button class="btn btn-outline-primary btn-sm"
                    (click)="addProcess(name.value, node); name.value = '';">+</button>
                  <button class="btn btn-outline-danger btn-sm" (click)="deleteProcess(node)">-</button>
                </div>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="{{ 'view.sites.advanced.definitions.type' | translate }}"
          [(ngModel)]="newType">
        <div class="input-group-append">
          <button class="btn btn-outline-primary btn-sm" (click)="addType()">
            {{ 'view.sites.advanced.definitions.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="list-entry" *ngFor="let t of types">
            <span>{{t}}</span>
            <span class="remove-icon">
              <fa-icon [icon]="faTimes" (click)="deleteType(t)"></fa-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="{{ 'view.sites.advanced.definitions.flow' | translate }}"
          [(ngModel)]="newFlow">
        <div class="input-group-append">
          <button class="btn btn-outline-primary btn-sm" (click)="addFlow()">
            {{ 'view.sites.advanced.definitions.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="list-entry" *ngFor="let f of flows">
            <span>{{f}}</span>
            <span class="remove-icon">
              <fa-icon [icon]="faTimes" (click)="deleteFlow(f)"></fa-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
