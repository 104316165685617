import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private _userService: UserService,
        private router: Router
    ) { }

    canActivate(): Observable<boolean> {
        return new Observable(observer => {
            if (!this.authService.isLoggedIn()) {
                if (!sessionStorage.getItem("user")) {
                    this.router.navigateByUrl('/login')
                    observer.next(false)
                    return observer.complete()
                }

                let userSession = JSON.parse(sessionStorage.getItem("user"))
                if (new Date(userSession.expire) < new Date()) {
                    sessionStorage.removeItem("user")
                    this.router.navigateByUrl('/login')
                    observer.next(false)
                    return observer.complete()
                }
            }
            this.authService.setLogin()
            observer.next(true)
            observer.complete()
        });
    }
}