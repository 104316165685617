<div class="d-flex flex-row justify-content-between align-items-center pb-2">
    <span>{{ heading | translate }}</span>
</div>
<div class="wrapper readonly overflow-auto form-control">
    <div *ngIf="loading" class="loader">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div *ngIf="!loading" class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between pr-3" *ngFor="let item of items">
            <a *ngIf="resourceURL" [routerLink]="[resourceURL, item.id]"> {{item.name}} </a>
            <span *ngIf="!resourceURL">{{ item.name }}</span>
        </div>
    </div>
</div>