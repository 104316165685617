import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";
import { Observable } from "rxjs";

@Injectable()
export class PublicAuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private _userService: UserService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable(observer => {
            let loginUrl = `/public/${route.params["key"]}`
            if (!this.authService.isLoggedIn()) {
                if (!sessionStorage.getItem("user")) {
                    this.router.navigateByUrl(loginUrl)
                    observer.next(false)
                    return observer.complete()
                }

                let userSession = JSON.parse(sessionStorage.getItem("user"))
                if (new Date(userSession.expire) < new Date()) {
                    sessionStorage.removeItem("user")
                    this.router.navigateByUrl(loginUrl)
                    observer.next(false)
                    return observer.complete()
                }
            }
            this.authService.setLogin()
            observer.next(true)
            observer.complete()
        });
    }
}