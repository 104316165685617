import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APIObjectDetailed, APIObjectDetails, CompanyObjectService, ObjectDetailsForm } from '../../../../shared/services/company-object.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  units: any[] = [];

  object: APIObjectDetailed
  selectedYear: number;

  detailsForm: ObjectDetailsForm = new ObjectDetailsForm;

  constructor(
    private _objectService: CompanyObjectService,
    private translate: TranslateService,
    private _toastService: ToastService,
  ) {
    translate.stream('view.sites.details.production.units').subscribe(res => {
      this.units = res;
    });
  }

  ngOnInit(): void {
    this.object = this._objectService.currentObject()
    this.loadObjectDetails()
  }

  yearChange(year) {
    this.selectedYear = year
    this.loadObjectDetails()
  }

  loadObjectDetails() {
    this._objectService.getDetails(this.object.id, this.selectedYear).subscribe(
      (details: APIObjectDetails) => {
        if (!details) {
          details = new APIObjectDetails
          details.year = this.selectedYear
        }
        this.detailsForm = details;
      }
    )
  }

  saveCompanyDetails() {
    this._objectService.postDetails(this.object.id, this.detailsForm).subscribe(
      (resp: APIObjectDetails) => {
        this._toastService.saved()
      }, (error: any) => {
        console.log(error)
        this._toastService.server()
      }
    )
  }

}