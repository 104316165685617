import { Routes } from "@angular/router";
import { NetworkBenchmarkComponent } from "../components/new/network/network-benchmark/network-benchmark.component";
import { NetworkEnergyComponent } from "../components/new/network/network-energy/network-energy.component";
import { NetworkMeasureComponent } from "../components/new/network/network-measure/network-measure.component";
import { NetworkOverviewComponent } from "../components/new/network/network-overview/network-overview.component";
import { NetworksViewsGuard } from "../guards/views.guard";

export const routes: Routes = [
    {
        path: '',
        component: NetworkOverviewComponent,
        canActivate: [NetworksViewsGuard],
        data: {requiredView: "overview"}
    },
    {
        path: 'energy-follow-up',
        canActivate: [NetworksViewsGuard],
        data: {requiredView: "follow_up"},
        children: [
            {
                path: '',
                redirectTo: 'supplied',
                pathMatch: 'full',
            },
            {
                path: 'supplied',
                component: NetworkEnergyComponent,
                canActivate: [NetworksViewsGuard],
                data: {requiredView: "follow_up_supplied", type: "supplied" }
            },
            {
                path: 'used',
                component: NetworkEnergyComponent,
                canActivate: [NetworksViewsGuard],
                data: {requiredView: "follow_up_used", type: "used" }
            }
        ]
    },
    {
        path: 'benchmark',
        component: NetworkBenchmarkComponent,
        canActivate: [NetworksViewsGuard],
        data: {requiredView: "benchmark"}
    },
    {
        path: 'measures',
        component: NetworkMeasureComponent,
        canActivate: [NetworksViewsGuard],
        data: {requiredView: "measure"}
    }
]