import { Injectable } from '@angular/core';
import { SankeyService } from '../../components/sankey/sankey.service';
import { KeyValue, RootCauseEntry } from '../models/keyvalue.model';
import { DateSpan } from '../../shared/components/date-span/date-span.component';
import { FormulaService } from './formula.service';
import { Formula } from '../components/formula/formula.component';

@Injectable({
  providedIn: 'root'
})
export class KeyvalueService {

  constructor(
    private _formulaService: FormulaService) {}

  getValue(k: KeyValue, span: DateSpan) : number {
    return this._formulaService.avg(k, span)
  }

  getRootcauseValue(r: RootCauseEntry, span: DateSpan) : number {
    
    if (!r.linkID && !r.equationName) { return -1}

    let f = new Formula
    f.addLink()
    f.formula[0].linkID = r.linkID
    f.formula[0].equationName = r.equationName
    return this._formulaService.avg(f, span)
  }
}
