import { Component, OnInit } from '@angular/core';
import { SensorService } from '../../../shared/services/sensor.service';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit {

  sensors = []
  selected: any

  constructor(
    private _sensorService: SensorService
  ) { }

  ngOnInit() {
    this.sensors = this._sensorService.availableSensors()
  }

  descChange(e) {
    this.selected.description = e
    this._sensorService.setSensorDescription(this.selected.id, e)
  }

}
