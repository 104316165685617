<div class="container-fluid">
  <div class="panel-container" [hidden]="this.hidden">
    <div class="card border-primary investment-panel">
      <div class="card-header bg-primary text-white">
        <span>{{ 'investment.payback' | translate }}</span>
      </div>
      <div class="card-body">
        <table class="table">
          <tr>
            <td>{{ 'investment.req-1' | translate }}</td>
            <td>
              <fa-icon [icon]="faTimes"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-2' | translate }}</td>
            <td>
              <fa-icon [icon]="faTimes"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-3' | translate }}</td>
            <td>
              <fa-icon [icon]="faTimes"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-4' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-5' | translate }}</td>
            <td>
              <fa-icon [icon]="faTimes"></fa-icon>
            </td>
          </tr>
        </table>
      </div>
      <button class="btn btn-primary" (click)="select(1)">{{ 'investment.select' | translate }}</button>
    </div>
    <div class="card border-primary investment-panel">
      <div class="card-header bg-primary text-white">
        <span>{{ 'investment.present-value' | translate }}</span>
      </div>
      <div class="card-body">
        <table class="table">
          <tr>
            <td>{{ 'investment.req-1' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-2' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-3' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-4' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-5' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
        </table>
      </div>
      <button class="btn btn-primary" (click)="select(2)">{{ 'investment.select' | translate }}</button>
    </div>
    <div class="card border-primary investment-panel">
      <div class="card-header bg-primary text-white">
        <span>{{ 'investment.lcc' | translate }}</span>
      </div>
      <div class="card-body">
        <table class="table">
          <tr>
            <td>{{ 'investment.req-1' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-2' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-3' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-4' | translate }}</td>
            <td>
              <fa-icon [icon]="faCheck"></fa-icon>
            </td>
          </tr>
          <tr>
            <td>{{ 'investment.req-5' | translate }}</td>
            <td>
              <fa-icon [icon]="faTimes"></fa-icon>
            </td>
          </tr>
        </table>
      </div>
      <button class="btn btn-primary" (click)="select(3)">{{ 'investment.select' | translate }}</button>
    </div>
  </div>
  <button class="btn btn-primary" (click)="select(0)" [hidden]="!this.hidden"><fa-icon [icon]="faArrowLeft"></fa-icon></button> <div class="panel-container">
      <router-outlet></router-outlet>
</div>
</div>