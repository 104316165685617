import { Routes } from "@angular/router";
import { BenchmarkComponent } from "../components/benchmark/benchmark.component";
import { EnergyFollowUpComponent } from "../components/energy-follow-up/energy-follow-up.component";
import { EnergyBalanceComponent } from "../components/energydata/energy-balance/energy-balance.component";
import { FollowUpComponent } from "../components/energydata/follow-up/follow-up.component";
import { SuppliedEnergyComponent } from "../components/energydata/supplied-energy/supplied-energy.component";
import { UsedEnergyComponent } from "../components/energydata/used-energy/used-energy.component";
import { MeasureComponent } from "../components/measure/measure.component";
import { DetailsComponent } from "../components/new/company-object/details/details.component";
import { CompanyObjectOverviewComponent } from "../components/new/company-object/overview/overview.component";
import { SankeyDefinitionsComponent } from "../components/sankey/sankey-definitions/sankey-definitions.component";
import { SankeyComponent } from "../components/sankey/sankey.component";
import { SensorsComponent } from "../components/sankey/sensors/sensors.component";
import { SettingsComponent } from "../components/sankey/settings/settings.component";
import { ObjectsViewsGuard } from "../guards/views.guard";

export const routes: Routes = [
    {
        path: '',
        component: CompanyObjectOverviewComponent,
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "overview"}
    },
    {
        path: "details",
        component: DetailsComponent,
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "details"}
    },
    {
        path: 'energydata',
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "energy"},
        children: [
            {
                path: '',
                redirectTo: 'balance',
                pathMatch: 'full',
            },
            {
                path: 'balance',
                component: EnergyBalanceComponent,
                canActivate: [ObjectsViewsGuard],
                data: {requiredView: "energy_balance"},
            },
            {
                path: 'supplied',
                component: SuppliedEnergyComponent,
                canActivate: [ObjectsViewsGuard],
                data: {requiredView: "energy_supplied"},
            },
            {
                path: 'used',
                component: UsedEnergyComponent,
                canActivate: [ObjectsViewsGuard],
                data: {requiredView: "energy_used"},
            },
        ]
    },
    {
        path: 'energy-follow-up',
        component: FollowUpComponent,
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "follow_up"}
    },
    {
        path: 'benchmark',
        component: BenchmarkComponent,
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "benchmark"}
    },
    {
        path: 'measures',
        component: MeasureComponent,
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "measure"}
    },
    {
        path: 'analysis',
        canActivate: [ObjectsViewsGuard],
        data: {requiredView: "advanced"},
        children: [
            {
                path: '',
                redirectTo: 'sankey',
                pathMatch: 'full',
            },
            {
                path: 'sankey',
                children: [
                    {
                        path: '',
                        component: SankeyComponent
                    },
                    {
                        path: 'settings',
                        component: SettingsComponent
                    },
                    {
                        path: 'definitions',
                        component: SankeyDefinitionsComponent
                    },
                    {
                        path: 'sensors',
                        component: SensorsComponent,
                    },
                ]
            },
            {
                path: 'energy-follow-up',
                component: EnergyFollowUpComponent
            },
        ]
    },
]
