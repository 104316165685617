<div class="container-fluid">
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.admin.licenses.object.info' | translate }}</h5>
        </div>
        <fieldset [disabled]="readonly">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="form-group">
                        <label for="name"> {{ 'view.admin.licenses.object.name' | translate }} </label>
                        <input type="text" class="form-control" id="name" [(ngModel)]="license.name">
                    </div>
                    <div class="form-group">
                        <label for="price" class="form-label">{{ 'view.admin.licenses.object.price' | translate
                            }}</label>
                        <input type="number" class="form-control" id="price" [(ngModel)]="license.price">
                    </div>
                    <div class="form-group">
                        <label for="users"> {{ 'view.admin.licenses.object.users' | translate }} </label>
                        <input type="number" class="form-control" id="users" [(ngModel)]="license.nr_users">
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="section-header">
            <h5>{{ 'view.admin.licenses.object.views' | translate }}</h5>
        </div>
        <div class="form-group col-md-6">
            <fieldset [disabled]="readonly">
                <div class="custom-control custom-switch mb-3" *ngFor="let view of availableViews">
                    <input class="custom-control-input" type="checkbox" id="view-{{view.key}}"
                        [(ngModel)]="license.views[view.key]">
                    <label class="custom-control-label" for="view-{{view.key}}">
                        {{ 'menues.object.' + view.key | translate }}
                    </label>

                    <div *ngIf="view.children && license.views[view.key]" class="mt-3">
                        <div class="custom-control custom-switch mb-3"
                            *ngFor="let childView of view.children">
                            <input class="custom-control-input" type="checkbox" id="view-{{childView.key}}"
                                [(ngModel)]="license.views[childView.key]">
                            <label class="custom-control-label" for="view-{{childView.key}}">
                                {{ 'menues.object.' + childView.key | translate }}
                            </label>
                        </div>
                    </div>

                </div>
            </fieldset>
            <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
                (click)="save()">{{ 'buttons.save' | translate }}</button>
        </div>
    </div>
</div>