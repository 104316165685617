<button class="dropdown-item" (click)="open()">
  {{ 'view.sites.advanced.sankey.sensor_data' | translate }}
</button>

<ng-template #fileModal let-modal>
  <div class="modal-body">
    <span> Select file containing sensor data (.xml .csv) </span>
    <form [formGroup]="form">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">File</span>
        </div>
        <div class="custom-file">
          <input type="file" accept=".xml, .csv" class="custom-file-input" (change)="onFileChange($event)">
          <label class="custom-file-label">Choose file</label>
        </div>
      </div>
    </form>
  </div>
</ng-template>
