<div class="container-fluid">
    <h6 class="overview-header">{{ 'view.sites.details.general.title' | translate }}
        <app-tooltip placement="right" text="{{ 'view.sites.details.general.tooltip' | translate }}"></app-tooltip>
    </h6>
    <div>
        <div class="row comp-info-row">
            <div class="col-md-2">
                <label>{{ 'view.sites.details.general.name' | translate }}</label>
                <input type="text" class="form-control" disabled value="{{object?.name}}">
            </div>
            <div class="col-md-2">
                <label>{{ 'view.sites.details.general.nace' | translate }}</label>
                <input type="text" class="form-control" disabled value="{{object?.sni_data.code}}">
            </div>
        </div>
        <div class="row comp-info-row">
            <div class="col-md-3">
                <label>{{ 'view.sites.details.general.region' | translate }}</label>
                <input type="text" class="form-control" disabled value="{{object?.geo.state.name}}">
            </div>
            <div class="col-md-3">
                <label>{{ 'view.sites.details.general.municipality' | translate }}</label>
                <input type="text" class="form-control" disabled value="{{object?.geo.municipality.name}}">
            </div>
        </div>
    </div>

    <h6 class="overview-header">
        {{ 'view.sites.details.details' | translate}}
    </h6>
    <app-date-span class="" (change)="yearChange($event)" onlyYear="true"></app-date-span>

    <h6 class="overview-header">
        {{ 'view.sites.details.area.title' | translate }}
    </h6>
    <div>
        <form name="building_form" novalidate>
            <div class="row comp-info-row">
                <div class="col-md-4">
                    <label>{{ 'view.sites.details.area.total' | translate }}</label>
                    <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.area" name="area">
                </div>
                <div class="col-md-4">
                    <label>{{ 'view.sites.details.area.heated' | translate }}</label>
                    <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.heated_area"
                        name="heatedArea">
                </div>
            </div>
        </form>
    </div>

    <h6 class="overview-header">
        {{ 'view.sites.details.production.title' | translate }}
        <app-tooltip type="info" text="{{ 'view.sites.details.production.tooltip' | translate }}"></app-tooltip>
    </h6>
    <div>
        <form name="production_form" novalidate>
            <div class="row comp-info-row">
                <div class="col-md-4">
                    <label>
                        {{ 'view.sites.details.production.hours' | translate }}</label>
                    <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.prod_hours"
                        name="yearlyProductionHours">
                </div>

                <div class="col-md-6">
                    <label>
                        {{ 'view.sites.details.production.unit' | translate }}
                        <app-tooltip text="{{ 'view.sites.details.production.unit-tooltip' | translate}}"></app-tooltip>
                    </label>
                    <div class="row">
                        <div class="col-md-4">
                            <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.units"
                                name="yearlyUnits">
                        </div>
                        <div class="col-md-2">
                            <select class="form-control" [(ngModel)]="detailsForm.unit" title="Välj enhet"
                                name="yearlyUnit">
                                <option *ngFor="let u of units">{{u}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <h6 class="overview-header">
        {{ 'view.sites.details.revenue.title' | translate }}
        <app-tooltip type="info" text="{{ 'view.sites.details.revenue.tooltip' | translate }}"></app-tooltip>
    </h6>
    <div class="row">
        <div class="col-md-2">
            <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.turnover" name="turnover">
        </div>
    </div>

    <h6 class="overview-header">
        {{ 'view.sites.details.employees.title' | translate }}
        <app-tooltip type="info" text="{{ 'view.sites.details.employees.tooltip' | translate }}"></app-tooltip>
    </h6>
    <div class="row">
        <div class="col-md-2">
            <input type="number" step="0.001" class="form-control" [(ngModel)]="detailsForm.employees" name="employees">
        </div>
    </div>

    <button class="mt-3 btn btn-primary" (click)="saveCompanyDetails()">
        {{ 'buttons.save' | translate}}
    </button>
</div>