import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ToastrModule } from "ngx-toastr"

import { CompanyObjectService } from './shared/services/company-object.service';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

// Guards
import { AuthGuard } from './guards/auth.guard';

import { EnergydataComponent } from './components/energydata/energydata.component';
import { BenchmarkComponent } from './components/benchmark/benchmark.component';
import { MeasureComponent } from './components/measure/measure.component';
import { AccountComponent } from './components/new/home/account/account.component';
import { AlertsComponent } from './shared/components/alerts/alerts.component';
import { ModalsComponent } from './shared/components/modals/modals.component';
import { UsedEnergyComponent } from './components/energydata/used-energy/used-energy.component';
import { SuppliedEnergyComponent } from './components/energydata/supplied-energy/supplied-energy.component';
import { EnergyBalanceComponent } from './components/energydata/energy-balance/energy-balance.component';
import { EmailComponent } from './components/account/email/email.component';
import { PasswordComponent } from './components/account/password/password.component';
import { DatabaseComponent } from './components/new/home/database/database.component';
import { CompanyObjectOverviewComponent } from './components/new/company-object/overview/overview.component';
import { VideosComponent } from './components/new/home/videos/videos.component';
import { AuthService } from './shared/services/auth.service';

import { EnergyService } from './shared/services/energy.service';
import { CsrfInterceptor } from './interceptors/csrf.interceptor';
import { SelectedModalComponent } from './components/new/home/database/selected-modal/selected-modal.component';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { SidenavItemComponent } from './shared/components/sidenav/sidenav-item/sidenav-item.component';
import { AgreementModalComponent } from './components/home/agreement-modal/agreement-modal.component';
// Language
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './components/home/home.component';
import { RedirectComponent } from './components/redirect/redirect/redirect.component';
import { PublicComponent } from './components/new/public/public.component';
import { InvestmentComponent } from './components/investment/investment.component';
import { PayoffComponent } from './components/investment/payoff/payoff.component';
import { LccComponent } from './components/investment/lcc/lcc.component';
import { PresentValueComponent } from './components/investment/present-value/present-value.component';
import { AddedValueComponent } from './components/investment/added-value/added-value.component';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { SankeyComponent } from './components/sankey/sankey.component';
import { UsersComponent } from './components/new/admin-page/users/users.component';
import { SettingsComponent } from './components/sankey/settings/settings.component';
import { SankeyDefinitionsComponent } from './components/sankey/sankey-definitions/sankey-definitions.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { EnergyFollowUpComponent } from './components/energy-follow-up/energy-follow-up.component';
import { CompaniesComponent } from './components/new/admin-page/companies/companies.component';
import { DateSpanComponent } from './shared/components/date-span/date-span.component';
import { SankeyService } from './components/sankey/sankey.service';
import { KeyvalentryComponent } from './components/sankey/settings/keyvalentry/keyvalentry.component';
import { FormulaComponent } from './shared/components/formula/formula.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { KatexModule } from 'ng-katex';
import { CarrierComponent } from './components/energydata/supplied-energy/carrier/carrier.component';
import { FollowUpComponent } from './components/energydata/follow-up/follow-up.component';
import { DateInputComponent } from './shared/components/date-input/date-input.component';
import { DetailsComponent } from './components/measure/details/details.component';
import { TooltipComponent } from './shared/components/tooltip/tooltip.component';
import { UserService } from './shared/services/user.service';
import { Observable, forkJoin, from } from 'rxjs';
import { CompanyFilterComponent } from './shared/components/company-filter/company-filter.component';
import { TableComponent, SortableHeader } from './shared/components/table/table.component';
import { TableRowComponent } from './shared/components/table/table-row/table-row.component';
import { AgreementComponent } from './components/new/admin-page/agreement/agreement.component';
import { QuillModule } from 'ngx-quill';
import { ColumnValuePipe } from './shared/components/table/table-row/table-row.component';
import { EnergyGraphComponent } from './shared/components/energy-graph/energy-graph.component';
import { SuppliedEnergyGraphComponent } from './shared/components/supplied-energy-graph/supplied-energy-graph.component';
import { UserMenuComponent } from './shared/components/user-menu/user-menu.component';
import { UserFormComponent } from './components/new/admin-page/users/user-form/user-form.component';
import { DemoComponent } from './components/new/admin-page/demo/demo.component';
import { DemoFormComponent } from './components/new/admin-page/demo/demo-form/demo-form.component';
import { UsedEnergyGraphComponent } from './shared/components/used-energy-graph/used-energy-graph.component';
import { MeasureFormComponent } from './components/measure/measure-form/measure-form.component';
import { BenchmarkFilterComponent } from './shared/components/benchmark-filter/benchmark-filter.component';
import { CurrencyInputComponent } from './shared/components/currency-input/currency-input/currency-input.component';
import { EnergyInputComponent } from './shared/components/energy-input/energy-input.component';
import { YearInputComponent } from './shared/components/year-input/year-input.component';
import { CurrencyService } from './shared/services/currency.service';
import { HomepageComponent } from './components/new/home/homepage/homepage.component';
import { NetworkHomeComponent } from './components/new/network/network-home/network-home.component';
import { CompanyObjectHomeComponent } from './components/new/company-object/company-object-home/company-object-home.component';
import { CompanyHomeComponent } from './components/new/company/company-home/company-home.component';
import { NetworkOverviewComponent } from './components/new/network/network-overview/network-overview.component';
import { NetworkMeasureComponent } from './components/new/network/network-measure/network-measure.component';
import { NetworkBenchmarkComponent } from './components/new/network/network-benchmark/network-benchmark.component';
import { CompanyBenchmarkComponent } from './components/new/company/company-benchmark/company-benchmark.component';
import { NetworkService } from './shared/services/network.service';
import { CompanyService } from './shared/services/company.service';
import { NetworkEnergyComponent } from './components/new/network/network-energy/network-energy.component';
import { CompanyEnergyComponent } from './components/new/company/company-energy/company-energy.component';
import { CompanyOverviewComponent } from './components/new/company/company-overview/company-overview.component';
import { CompanyMeasureComponent } from './components/new/company/company-measure/company-measure.component';
import { NetworksComponent } from './components/new/admin-page/networks/networks.component';
import { CompanyObjectsComponent } from './components/new/admin-page/company-objects/company-objects.component';
import { NetworksFormComponent } from './components/new/admin-page/networks/networks-form/networks-form.component';
import { FormListerComponent } from './shared/components/form-lister/form-lister.component';
import { MembershipManagerComponent } from './shared/components/membership-manager/membership-manager.component';
import { CompaniesFormComponent } from './components/new/admin-page/companies/companies-form/companies-form.component';
import { ObjectFormComponent } from './components/new/admin-page/company-objects/object-form/object-form.component';
import { DetailsComponent as ObjectDetailsComponent } from './components/new/company-object/details/details.component';
import { PublicUrlComponent } from './components/new/admin-page/networks/networks-form/public-url/public-url.component';
import { PublicUrlService } from './shared/services/public-url.service';
import { NetworkComponent } from './components/new/admin-page/licenses/network/network.component';
import { ObjectComponent } from './components/new/admin-page/licenses/object/object.component';
import { NetworkFormComponent } from './components/new/admin-page/licenses/network/network-form/network-form.component';
import { ObjectFormComponent as ObjectLicenseForm } from './components/new/admin-page/licenses/object/object-form/object-form.component';
import { MembershipsComponent } from './components/new/admin-page/users/user-form/memberships/memberships.component';
import { SensorsComponent } from './components/sankey/sensors/sensors.component';
import i18nHash from '../../i18n-hash';

export class HashedHttpTranslateLoader extends TranslateHttpLoader {
  httpClient
  prefix: string;
  suffix: string;
  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix)
    this.httpClient = http
  }
  getTranslation(lang: string): Observable<any> {
    const path = `/assets/i18n/${lang}.json?hash=${i18nHash[lang]}`
    return this.httpClient.get(path)
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new HashedHttpTranslateLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EnergydataComponent,
    DatabaseComponent,
    CompanyObjectOverviewComponent,
    VideosComponent,
    BenchmarkComponent,
    MeasureComponent,
    AccountComponent,
    AlertsComponent,
    ModalsComponent,
    UsedEnergyComponent,
    SuppliedEnergyComponent,
    EnergyBalanceComponent,
    EmailComponent,
    PasswordComponent,
    SelectedModalComponent,
    AgreementModalComponent,
    SidenavComponent,
    SidenavItemComponent,
    HomeComponent,
    RedirectComponent,
    PublicComponent,
    SankeyComponent,
    InvestmentComponent,
    PayoffComponent,
    LccComponent,
    PresentValueComponent,
    AddedValueComponent,
    UsersComponent,
    SettingsComponent,
    SankeyDefinitionsComponent,
    EnergyFollowUpComponent,
    CompaniesComponent,
    DateSpanComponent,
    KeyvalentryComponent,
    FormulaComponent,
    FileUploadComponent,
    CarrierComponent,
    FollowUpComponent,
    DateInputComponent,
    DetailsComponent,
    TooltipComponent,
    CompanyFilterComponent,
    TableComponent,
    TableRowComponent,
    SortableHeader,
    AgreementComponent,
    ColumnValuePipe,
    EnergyGraphComponent,
    SuppliedEnergyGraphComponent,
    UserMenuComponent,
    UserFormComponent,
    DemoComponent,
    DemoFormComponent,
    UsedEnergyGraphComponent,
    MeasureFormComponent,
    BenchmarkFilterComponent,
    CurrencyInputComponent,
    EnergyInputComponent,
    YearInputComponent,
    HomepageComponent,
    NetworkHomeComponent,
    CompanyObjectHomeComponent,
    CompanyHomeComponent,
    NetworkOverviewComponent,
    NetworkMeasureComponent,
    NetworkBenchmarkComponent,
    CompanyBenchmarkComponent,
    NetworkEnergyComponent,
    CompanyEnergyComponent,
    CompanyOverviewComponent,
    CompanyMeasureComponent,
    NetworksComponent,
    CompanyObjectsComponent,
    NetworksFormComponent,
    FormListerComponent,
    MembershipManagerComponent,
    CompaniesFormComponent,
    ObjectFormComponent,
    ObjectDetailsComponent,
    PublicUrlComponent,
    NetworkComponent,
    ObjectComponent,
    NetworkFormComponent,
    ObjectLicenseForm,
    MembershipsComponent,
    SensorsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    RoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-right',
    }),
    FilterPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TreeModule,
    KatexModule,
    QuillModule.forRoot()
  ],
  providers: [
    CompanyObjectService,
    AuthService,
    EnergyService,
    AuthGuard,
    SankeyService,
    UserService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadUserInfo,
      multi: true,
      deps: [
        UserService,
        EnergyService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadCurrencies,
      multi: true,
      deps: [CurrencyService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadResource,
      multi: true,
      deps: [
        NetworkService,
        CompanyService,
        CompanyObjectService,
        PublicUrlService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function loadCurrencies(currencyService: CurrencyService) {
  return () => {
    if (!sessionStorage.getItem("user")) return
    const loadSupportedCurrencies = currencyService.loadSupportedCurrencies()
    return forkJoin([loadSupportedCurrencies])
      .toPromise()
      .then((resp) => { });
  };
}

export function loadUserInfo(userService: UserService, energyService: EnergyService,) {
  return () => {
    if (!sessionStorage.getItem("user")) return
    let userSession = JSON.parse(sessionStorage.getItem("user"))
    const loadUserInfo = userService.loadUserInfo(userSession.id)
    const loadEnergyCarriers = energyService.loadEnergyCarriers()
    return forkJoin([loadUserInfo, loadEnergyCarriers])
      .toPromise()
      .then((resp) => {
        userService.setUserInfo(resp[0])
      },
        error => {
          if (error.status == 403) {
            sessionStorage.removeItem("user")
            window.location.href = `${window.location.origin}/login`
          }
        });
  };
}

export function preloadResource(networkService: NetworkService, companyService: CompanyService, objectService: CompanyObjectService, publicUrlService: PublicUrlService) {
  return () => {

    const preloadNetwork = networkService.preloadSelectedNetwork()
    const preloadCompany = companyService.preloadSelectedCompany()
    const preloadObject = objectService.preloadSelectedObject()
    const preloadPublic = publicUrlService.preloadUrlSettings()
    return forkJoin([preloadNetwork, preloadCompany, preloadObject, preloadPublic]).toPromise().then(
      resp => { },
      error => { }
    )
  }
}
