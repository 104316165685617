<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"> {{ 'view.admin.demo.form-title' | translate }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <ng-container *ngIf="edit; else view;">
      <div class="form-group">
        <label for="name"> {{ 'view.admin.demo.name' | translate }} </label>
        <input type="text" class="form-control" id="name" [(ngModel)]="company.name">
      </div>
      <div class="form-group">
        <label> {{ 'view.admin.demo.template' | translate }} </label>
        <ng-select placeholder="{{ 'view.admin.demo.select-template' | translate }}" [items]="templates" [disabled]="!edit"
          bindLabel="name" [(ngModel)]="template">
        </ng-select>
      </div>
      <div class="form-group">
        <button class="btn btn-primary" (click)="save()"> {{ 'buttons.save' | translate }} </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #view>
  <fieldset [disabled]="!edit">
    <div class="form-group">
      <label for="name"> {{ 'view.admin.demo.name' | translate }} </label>
      <input type="text" class="form-control" id="name" [(ngModel)]="company.name">
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="cin"> {{ 'view.admin.demo.org' | translate }} </label>
        <input type="text" class="form-control" id="cin" [(ngModel)]="company.org_nr">
      </div>
      <div class="form-group col-md-4">
        <label for="sic"> {{ 'view.admin.demo.nace' | translate }} </label>
        <input type="text" class="form-control" id="sic" [(ngModel)]="company.sni_data.code">
      </div>
      <div class="form-group col-md-4">
        <label for="cfar"> {{ 'view.admin.demo.cfar' | translate }} </label>
        <input type="text" class="form-control" id="cfar" [(ngModel)]="company.cfar_id">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label> {{ 'view.admin.demo.country' | translate }} </label>
        <input type="text" class="form-control" id="country" [(ngModel)]="company.geo.country.name">
      </div>
      <div class="form-group col-md-4">
        <label> {{ 'view.admin.demo.region' | translate }} </label>
        <input type="text" class="form-control" id="state" [(ngModel)]="company.geo.state.name">
      </div>
      <div class="form-group col-md-4">
        <label> {{ 'view.admin.demo.municipality' | translate }} </label>
        <input type="text" class="form-control" id="municipality" [(ngModel)]="company.geo.municipality.name">
      </div>
    </div>
  </fieldset>
</ng-template>