import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { APINetwork } from './network.service';
import { APICompany } from './company.service';
import { APIObjectSimple } from './company-object.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfo: APIUser;
  private coordinator: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router) { }

  getUserInfo(): APIUser {
    return this.userInfo
  }

  getCoordinatorMode(): Observable<boolean> {
    return this.coordinator.asObservable()
  }

  loadUserInfo(userID: number): Observable<APIUser> {
    return this.http.get(`/api/v2/users/${userID}`).pipe(
      map((res: APIUser) => {
        let user: APIUser = new APIUser(res)
        this.setUserInfo(user)
        return user
      }))
  }

  setUserInfo(user: APIUser) {
    this.userInfo = user
  }

  adminList(): Observable<APIUser[]> {
    return this.http.get("/api/v2/admin/users").pipe(
      map((res: APIUser[]) => {
        let users: APIUser[] = []
        res.forEach(u => {
          users.push(new APIUser(u))
        });
        return users
      })
    )
  }

  get(id: number): Observable<APIUser> {
    return this.http.get(`/api/v2/users/${id}`).pipe(
      map((res: APIUser) => {
        return new APIUser(res)
      })
    )
  }

  create(data: APIUser): Observable<APIUser> {
    return this.http.post("/api/v2/admin/users", data).pipe(
      map((u: APIUser) => { return new APIUser(u) })
    )
  }

  update(id: number, data: APIUser): Observable<APIUser> {
    return this.http.put(`/api/v2/users/${id}`, data).pipe(
      map((u: APIUser) => { return new APIUser(u); })
    )
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/users/${id}`)
  }

  companyAccess(id: number): Observable<APICompany[]> {
    return this.http.get(`/api/v2/admin/users/${id}/companies`).pipe(
      map((c: APICompany[]) => { return c })
    )
  }

  companyObjectAccess(id: number): Observable<APIObjectSimple[]> {
    return this.http.get(`/api/v2/admin/users/${id}/objects`).pipe(
      map((o: APIObjectSimple[]) => { return o })
    )
  }

  networkAccess(id: number): Observable<APINetwork[]> {
    return this.http.get(`/api/v2/admin/users/${id}/networks`).pipe(
      map((n: APINetwork[]) => { return n })
    )
  }

}

export class APIUser {
  id: number
  last_login: string
  is_superuser: boolean
  is_coordinator: boolean
  first_name: string
  last_name: string
  is_staff: boolean
  is_active: boolean
  date_joined: string
  info: APIUserInfo = new APIUserInfo
  constructor(obj?: any) {
    if (obj) {
      _.forOwn(obj, (val, key) => {
        this[key] = val
      })
    }
  }
}

export class APIUserInfo {
  email: string
    phone: string
    organization: string
    first_login: string
    deactivate_in: boolean
    demo: boolean
    agreement: number
    is_public: boolean
    views: { [view_key: string]: boolean } = {}
}