import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { APIUser, UserService } from '../../../../shared/services/user.service';
import { APIAgreementModel, AgreementService } from '../../../../shared/services/agreement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementModalComponent } from '../../../home/agreement-modal/agreement-modal.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  user: APIUser
  passwordForm: PasswordForm = new PasswordForm

  constructor(
    private _userService: UserService,
    private http: HttpClient,
    private _toastService: ToastService,
    private _agreementService: AgreementService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.user = this._userService.getUserInfo()
  }

  update_password() {
    if (this.passwordForm.password != this.passwordForm.confirm_password) {
      this._toastService.noMatchPassword()
      return
    }
    this.http.post("/api/v2/password", this.passwordForm).subscribe(
      (res: any) => {
        this._toastService.savedPassword()
        this.passwordForm = new PasswordForm
      },
      (error: any) => {
        if (error.status == 401) {
          this._toastService.invalidPassword()
          return
        }
        this._toastService.server()
      });
  }

  showAgreement() {
    this._agreementService.v2_getAgreement().subscribe(
      (resp: APIAgreementModel) => {
        const modalRef = this._modalService.open(AgreementModalComponent, {
          size: 'lg',
          centered: true,
          keyboard: false,
          backdrop: "static",
        });
        modalRef.componentInstance.data = resp
        modalRef.componentInstance.preview = true
      }
    )
  }

}

export class PasswordForm {
  current_password: string;
  password: string;
  confirm_password: string;
}
