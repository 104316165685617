import { Component, OnInit } from '@angular/core';
import { CompanyObjectService, APIObjectDetailed } from '../../shared/services/company-object.service';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { UserService, APIUser } from '../../shared/services/user.service';
import { AgreementService, APIAgreementModel } from '../../shared/services/agreement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToolbarService, ToolbarTitle } from '../../shared/services/toolbar.service';
import { NetworkService } from '../../shared/services/network.service';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title: ToolbarTitle

  faSignOutAlt = faSignOutAlt;
  userMenu = faUserCircle

  userInfo: APIUser;
  sidenavMode: string = "";
  resourceID: number;

  constructor(
    private _userService: UserService,
    private _agreementService: AgreementService,
    private _toolbarService: ToolbarService,
    private modalService: NgbModal,
    private translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private networkService: NetworkService
  ) { }

  ngOnInit() {
    this.userInfo = this._userService.getUserInfo()

    // Skip agreements for public url users
    // @TODO: Specific agreement for public url users?
    if (!this.userInfo.info.is_public) {
      this._agreementService.v2_getAgreement().subscribe(
        (resp: APIAgreementModel) => {
          if (this.userInfo.info.agreement != resp.id) {
            this.openAgreement(resp)
          }
        }
      )
    }

    this._toolbarService.getTitle().subscribe(
      (title: ToolbarTitle) => { this.title = title }
    )

    this.setSidenavMode(this.router.url)

    //Handles side nav update after router changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = String(event.url)
        this.setSidenavMode(url)
        this.setTitle(url)
      }
    });
  }

  setSidenavMode(url: string) {
    if (url == "/home" || url == "/networks" || url == "/companies" || url == "/sites") {
      this.sidenavMode = "home"
    } else if (url.startsWith("/networks")) {
      this.resourceID = Number(url.split("/")[2])
      this.sidenavMode = "network"
    } else if (url.startsWith("/companies")) {
      this.resourceID = Number(url.split("/")[2])
      this.sidenavMode = "company"
    } else if (url.startsWith("/sites")) {
      this.resourceID = Number(url.split("/")[2])
      this.sidenavMode = "company-object"
    } else if (url.startsWith("/admin")) {
      this.sidenavMode = "admin"
    } else if (url.startsWith("/public")) {
      this.sidenavMode = "public"
    } else {
      this.sidenavMode = "home"
    }
  }

  setTitle(url: string) {
    if (url == "/home") {
      this.title = { title: "", icon: undefined }
    }
  }

  openAgreement(data: any) {
    const modalRef = this.modalService.open(AgreementModalComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.data = data
  }

}
