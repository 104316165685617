<div class="container-fluid">
  <div class="d-flex justify-content-center mb-3">
    <app-date-span class="" (change)="yearChange($event)" onlyYear="true"></app-date-span>
  </div>

  <!--INPUT ENERGY PANEL-->
  <div class="panel-container">
    <div class="card border-primary mr-3">
      <div class="card-header bg-primary text-white" routerLink="../supplied">
        <span>{{ 'view.sites.balance.supplied.title' | translate }}</span>
        <app-tooltip keepFontColor="true" text="{{ 'view.sites.balance.supplied.tooltip' | translate }}"></app-tooltip>
      </div>
      <div class="card-body">

        <!-- NO DATA VIEW -->
        <div class="d-flex flex-column align-items-center" *ngIf="suppliedForm.fuels.length == 0">
          <span>{{ 'view.sites.balance.supplied.no-data' | translate }}</span>
          <span  class="link" routerLink="../supplied">
            {{ 'view.sites.balance.supplied.start' | translate }}
          </span>
        </div>

        <!-- HAS DATA VIEW -->
        <div *ngIf="suppliedForm.fuels.length > 0">
          <table class="table table-borderless mb-0">
            <thead>
              <tr>
                <th scope="col">{{ 'view.sites.balance.supplied.carrier' | translate }}</th>
                <th scope="col">MWh</th>
              </tr>
            </thead>
            <tr *ngFor="let f of suppliedForm.fuels">
              <td>
                {{ 'energycarriers.'+f.model | translate }}
              </td>
              <td>{{f.amount | number : '1.2-2'}}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>

    <!-- USED ENERGY PANEL -->
    <div class="card border-primary ml-3">
      <div class="card-header bg-primary text-white" routerLink="../used">
        <span>{{ 'view.sites.balance.used.title' | translate }}</span>
        <app-tooltip keepFontColor="true" text="{{ 'view.sites.balance.used.tooltip' | translate }}"></app-tooltip>
      </div>
      <div class="card-body">

        <!-- NO DATA VIEW -->
        <div class="d-flex flex-column align-items-center" *ngIf="enduseForm.enduse.length == 0">
          <span>{{ 'view.sites.balance.used.no-data' | translate }}</span>
          <span class="link" routerLink="../used">
            {{ 'view.sites.balance.used.start' | translate }}
          </span>
        </div>

        <!-- HAS DATA VIEW -->
        <div *ngIf="enduseForm.enduse.length > 0">
          <table class="table table-borderless mb-0">
            <thead>
              <tr>
                <th scope="col">{{ 'view.sites.balance.used.user' | translate }}</th>
                <th scope="col">MWh</th>
              </tr>
            </thead>
            <tr *ngFor="let eu of enduseForm.enduse">
              <td>
                {{ eu.name }}
              </td>
              <td>{{eu.carriers.total | number : '1.2-2'}}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>

  <!-- RESULT PANEL -->
  <div class="panel-container result-container">
    <div>
      <div class="card border-secondary">
        <div class="card-body flex">
          <div class="flex-col">
            <span class="result-header">{{ 'view.sites.balance.need.title' | translate }}
              <app-tooltip text="{{ 'view.sites.balance.need.tooltip' | translate }}"></app-tooltip>
            </span>
            <span> {{ balanceResult.totalEndUse }} MWh</span>
          </div>
          <div class="flex-col">
            <span class="result-header">{{ 'view.sites.balance.mapped.title' | translate }}
              <app-tooltip text="{{ 'view.sites.balance.mapped.tooltip' | translate }}"></app-tooltip>
            </span>
            <span> {{ balanceResult.percent }} % </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
