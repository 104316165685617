<fieldset [disabled]="readonly">
  <h5 *ngIf="urlForm.key">
    <a href="{{urlForm.url}}">{{urlForm.url}}</a>
  </h5>
  <h5 *ngIf="!urlForm.key">
    {{ 'view.admin.networks.urls.new' | translate }}
  </h5>


  <div class="mb-3">
    <label for="name-{{index}}"> {{ 'view.admin.networks.urls.name' | translate }} </label>
    <input type="text" class="form-control" id="name-{{index}}" [(ngModel)]="urlForm.name">
  </div>

  <div class="custom-control custom-switch mb-3">
    <input class="custom-control-input" type="checkbox" id="pw-enable-{{index}}" [(ngModel)]="urlForm.password_enabled">
    <label class="custom-control-label" for="pw-enable-{{index}}">
      {{ 'view.admin.networks.urls.pw-enable' | translate }}
    </label>
  </div>
  <input *ngIf="urlForm.password_enabled" class="form-control mb-3" type="password" id="pw-{{index}}"
    [(ngModel)]="urlForm.password">

  <div class="custom-control custom-switch mb-3">
    <input class="custom-control-input" type="checkbox" id="expire-{{index}}" [(ngModel)]="urlForm.expire_enabled">
    <label class="custom-control-label" for="expire-{{index}}">
      {{ 'view.admin.networks.urls.expire' | translate }}
    </label>
  </div>

  <input *ngIf="urlForm.expire_enabled" type="date" class="form-control mb-3" id="expire-date-{{index}}"
    [(ngModel)]="urlForm.expire_date">

  <div class="section-header">
    <h6>{{ 'view.admin.networks.urls.views' | translate }}</h6>
  </div>
  <div class="form-group">
    <fieldset [disabled]="readonly">
      <div class="custom-control custom-switch mb-3" *ngFor="let view of availableViews">
        <input class="custom-control-input" type="checkbox" id="view-{{view.key}}-{{index}}"
          [(ngModel)]="urlForm.views[view.key]">
        <label class="custom-control-label" for="view-{{view.key}}-{{index}}">
          {{ 'menues.network.' + view.key | translate }}
        </label>

        <div *ngIf="view.children && urlForm.views[view.key]" class="mt-3">
          <div class="custom-control custom-switch mb-3" *ngFor="let childView of view.children">
            <input class="custom-control-input" type="checkbox" id="view-{{childView.key}}-{{index}}"
              [(ngModel)]="urlForm.views[childView.key]">
            <label class="custom-control-label" for="view-{{childView.key}}-{{index}}">
              {{ 'menues.network.' + childView.key | translate }}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="section-header">
    <h6>{{ 'view.admin.networks.data_access' | translate }}</h6>
  </div>
  <div class="form-group">
    <fieldset [disabled]="readonly">
      <div class="form-group">
        <div class="sub-section">{{ 'view.admin.networks.time_period.title' | translate }}</div>

        <div class="custom-control custom-switch mb-3">
          <input class="custom-control-input" type="checkbox" id="specific-years-{{index}}" [(ngModel)]="specificYears">
          <label class="custom-control-label" for="specific-years-{{index}}">
            {{ 'view.admin.networks.time_period.specific' | translate }}
          </label>

          <ng-select *ngIf="specificYears" [disabled]="readonly" [items]="years"
            placeholder="{{ 'date-span.select-year' | translate }}" [(ngModel)]="selectedYears" [multiple]="true"
            [closeOnSelect]="false">
          </ng-select>
        </div>
      </div>

      <div class="form-group">
        <div class="sub-section">{{ 'view.admin.networks.integrity_level.title' | translate }}</div>
        <div class="custom-control custom-switch mb-3">
          <input class="custom-control-input" type="checkbox" id="site-anonymize-{{index}}"
            [(ngModel)]="urlForm.config.anonymize_objects">
          <label class="custom-control-label" for="site-anonymize-{{index}}">
            {{ 'view.admin.networks.integrity_level.object_anonymize' | translate }}
          </label>
        </div>
        <div class="custom-control custom-switch mb-3">
          <input class="custom-control-input" type="checkbox" id="site-recognition-{{index}}"
            [(ngModel)]="urlForm.config.object_recognition">
          <label class="custom-control-label" for="site-recognition-{{index}}">
            {{ 'view.admin.networks.integrity_level.object_recognition' | translate }}
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="sub-section">{{ 'view.admin.networks.visualisations.title' | translate }}</div>
        <div class="visual-btn">
          <span *ngFor="let item of locationVisuals" (click)="selectLocation(item.value)"
            [ngClass]="{'active': item.active, 'disabled': readonly}">
            {{ item.display | translate }}
          </span>
        </div>
        <div class="visual-btn mt-3">
          <span *ngFor="let item of sniVisuals" (click)="selectSNI(item.value)"
            [ngClass]="{'active': item.active, 'disabled': readonly}">
            {{ item.display | translate }}
          </span>
        </div>
      </div>

    </fieldset>
  </div>

  <div *ngIf="!readonly">
    <button type="button" class="btn btn-outline-danger btn-sm" (click)="remove()">{{ 'buttons.delete' | translate
      }}</button>
    <button type="button" class="btn btn-outline-primary btn-sm float-right" (click)="save()">{{ 'buttons.save' |
      translate }}</button>
  </div>
</fieldset>
<hr>

<ng-template #confirm let-confirm>
  <div class="modal-body">
    <h4>
      <fa-icon [icon]="warning" style="color: darkorange;"></fa-icon>
      {{ 'buttons.confirm' | translate }}
    </h4>
    <p>
      {{ 'view.admin.confirm-desc' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="confirm.close(true)">{{ 'buttons.delete' | translate
      }}</button>
    <button type="button" class="btn btn-outline-dark" (click)="confirm.close(false)">{{ 'buttons.cancel' | translate
      }}</button>
  </div>
</ng-template>