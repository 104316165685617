<div class="d-flex flex-row justify-content-between pb-2">
    <span>{{ title }}</span>
    <button type="button" class="btn btn-outline-primary btn-sm">{{ 'buttons.edit' | translate }}</button>
</div>
<div class="wrapper overflow-auto">
    <div class="d-flex flex-column">
        <span *ngFor="let item of items">
            {{ item }}
        </span>
    </div>
</div>