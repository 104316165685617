import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { RowEvent } from '../../../../shared/components/table/table-row/table-row.component';
import { TableColumn, TableSettings, AddEvent } from '../../../../shared/components/table/table.component';
import { APIObjectDetailed, CompanyObjectService } from '../../../../shared/services/company-object.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-company-objects',
  templateUrl: './company-objects.component.html',
  styleUrls: ['./company-objects.component.scss']
})
export class CompanyObjectsComponent implements OnInit {
  objects: APIObjectDetailed[] = [];

  baseURL: string = "/admin/sites"

  columns: TableColumn[] = [
    new TableColumn("view.admin.sites.site", "name"),
    new TableColumn("view.admin.sites.company", "company_name"),
    new TableColumn("view.admin.sites.org", "org_nr"),
    new TableColumn("view.admin.sites.nace", "sni_data.code"),
    new TableColumn("view.admin.sites.municipality", "geo.municipality.name", false, false),
    new TableColumn("view.admin.sites.region", "geo.state.name", false, false),
    new TableColumn("view.admin.sites.template", "template", true, false)
  ];
  settings: TableSettings = new TableSettings("view.admin.sites.title");
  actions: string[];
  actionMap: { [id: string]: (c: APIObjectDetailed) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }
  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _objectService: CompanyObjectService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.actions = Object.keys(this.actionMap)
    this._objectService.adminListDetailed().subscribe((companies: APIObjectDetailed[]) => {
      this.objects = companies.filter((c: APIObjectDetailed) => { return !c.demo });
      this.loading = false;
    });
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onAdd(_: AddEvent) {
    this._router.navigateByUrl(`${this.baseURL}/new`)
  }

  view(r: APIObjectDetailed) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}`)
  }

  edit(r: APIObjectDetailed) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}/edit`)
  }

  delete(c: APIObjectDetailed) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) return
      this._objectService.delete(c.id).subscribe(
        () => {
          this.objects = this.objects.filter((e: APIObjectDetailed) => { return e.id !== c.id; })
          this._toastService.saved()
        }, (error: any) => {
          this._toastService.server()
        });
    });
  }

}