import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CompanyObjectService } from "../shared/services/company-object.service";
import { NetworkService } from "../shared/services/network.service";
import { UserService, APIUser } from "../shared/services/user.service";

@Injectable()
export class UserViewsGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let viewKey = route.data.requiredView
        let hasAccess: boolean = this.userService.getUserInfo().info.views[viewKey]
        if (hasAccess) {
            return true
        }
        this.router.navigateByUrl('/');
        return false
    }
}

@Injectable()
export class ObjectsViewsGuard implements CanActivate {

    constructor(
        private _objectService: CompanyObjectService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let viewKey = route.data.requiredView
        let hasAccess: boolean = this._objectService.currentObject().views[viewKey]
        if (hasAccess) {
            return true
        }
        this.router.navigateByUrl('/')
        return false
    }
}

@Injectable()
export class NetworksViewsGuard implements CanActivate {

    constructor(
        private _networkService: NetworkService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let viewKey = route.data.requiredView
        let hasAccess: boolean = this._networkService.currentViews()[viewKey]
        if (hasAccess) {
            return true
        }
        this.router.navigateByUrl('/')
        return false
    }
}