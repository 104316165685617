import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIObjectLicense, LicenseService } from '../../../../../../shared/services/license.service';
import { ToastService } from '../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.scss']
})
export class ObjectFormComponent implements OnInit {

  mode: string
  license: APIObjectLicense = new APIObjectLicense
  readonly: boolean = true

  availableViews = [
    {key: "overview"},
    {key: "details"},
    {
      key: "energy",
      children: [
        {key: "energy_balance"},
        {key: "energy_supplied"},
        {key: "energy_used"},
      ]
    },
    {key: "follow_up"},
    {key: "benchmark"},
    {key: "measure"},
    {key: "advanced"},
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private _licenseService: LicenseService,
    private _router: Router,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(
      data => {
        if(data["id"] == undefined) {
          console.log(this.license)
          return
        }
        this._licenseService.objectDetails(data["id"]).subscribe(
          (license: APIObjectLicense) => {
            this.license = license
          }
        )
      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        if(this.mode != "view") {
          this.readonly = false
        }
      }
    )

  }

  save() {
    if(!this.license.views["energy"]) {
      this.license.views["energy_balance"] = false
      this.license.views["energy_supplied"] = false
      this.license.views["energy_used"] = false
    }
    if(this.mode == "new") {
      this.create(this.license)
    } else if (this.mode == "edit") {
      this.update(this.license.id, this.license)
    }
  }

  create(license: APIObjectLicense) {
    this._licenseService.objectCreate(license).subscribe(
      data => {
        this._router.navigateByUrl(`/admin/licenses/site/${data.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

  update(id: number, license: APIObjectLicense) {
    this._licenseService.objectUpdate(id, license).subscribe(
      (data: APIObjectLicense) => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

}
