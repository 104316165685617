<div class="container-fluid">
  <div class="row top-panel">
    <div class="col-md-3">
      <ng-select placeholder="Process" [items]="processDropDown" [clearable]="false" bindLabel="name" groupBy="ps"
        [selectableGroup]="false" [(ngModel)]="selectedProcess.name" (change)="onProcessChange($event)">
        <ng-template ng-optgroup-tmp let-item="item">
          {{item.ps || ''}}
        </ng-template>
      </ng-select>
    </div>
    <div class="calendar-wrapper">
      <app-date-span (change)="changeDateSpan($event)" allowRange="true"></app-date-span>
    </div>
  </div>
</div>
<div class="container-fluid">
  <ngb-tabset (tabChange)="onTabChange($event)">
    <ngb-tab title="{{ 'followup.in' | translate }}" id="input"> </ngb-tab>
    <ngb-tab title="{{ 'followup.out' | translate }}" id="output"> </ngb-tab>
    <ngb-tab title="{{ 'followup.key-figures' | translate }}" id="kv"> </ngb-tab>
  </ngb-tabset>
  <ngb-tabset (tabChange)="onSubTabChange($event)" [hidden]="keyValue">
    <ngb-tab title="{{ 'general.equations.energy' | translate }}" id="value"> </ngb-tab>
    <ngb-tab title="{{ 'general.equations.exergy' | translate }}" id="exergy"> </ngb-tab>
    <ngb-tab *ngFor="let t of eqTabs" title="{{t.name.show}}" id="{{t.name.data}}"></ngb-tab>
  </ngb-tabset>
  <canvas #energyChart class="mt-3" id="energyChart">{{energyChart}}</canvas>
</div>