import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyObjectService, APIObjectDetailed } from '../../shared/services/company-object.service';
import { faShareSquare, faPencilAlt, faTrashAlt, faCheck, faTimes, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Saving, SavingsService } from '../../shared/services/savings.service';
import { UserService } from '../../shared/services/user.service';
import { TableColumn, TableSettings, AddEvent, TableSelector, SelectorEvent } from '../../shared/components/table/table.component';
import { RowEvent } from '../../shared/components/table/table-row/table-row.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MeasureFormComponent } from './measure-form/measure-form.component';
import { ToastService } from '../../shared/services/toast.service';
import { Currency, CurrencyService } from '../../shared/services/currency.service';


@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class MeasureComponent implements OnInit {
  // Icons
  faShareSquare = faShareSquare
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faAngleDown = faAngleDown
  faAngleUp = faAngleUp
  faCheck = faCheck
  faTimes = faTimes

  savings: Saving[] = [];
  selectedCompany: APIObjectDetailed;

  openDetails: boolean[] = []
  coordinator: boolean = false;

  columns: TableColumn[] = []
  settings: TableSettings = new TableSettings("view.sites.savings.title", true, true, true, true, true);
  selectors: TableSelector[] = []

  currency: Currency

  actions: string[];
  actionMap: { [id: string]: (c: Saving) => void } = {
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }

  loading: boolean = true;

  constructor(
    private http: HttpClient,
    private _objectService: CompanyObjectService,
    private translate: TranslateService,
    private _savingService: SavingsService,
    private _userService: UserService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _currencyService: CurrencyService,
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.getSavings(this.selectedCompany.id, e.lang)
      });
  }

  ngOnInit() {
    this.setTableSelectors()
    this.setTableColumns()
    this.actions = Object.keys(this.actionMap);
    this.selectedCompany = this._objectService.currentObject()
    this.getSavings(this.selectedCompany.id, sessionStorage.getItem("lang"))
    this._userService.getCoordinatorMode().subscribe((coordinator: boolean) => {
      this.coordinator = coordinator;
    });
  }

  setTableSelectors() {
    let currencySelector = new TableSelector("currency", this._currencyService.supported.map(c => c.code))
    this.selectors = [currencySelector]
    this.currency = this._currencyService.getCurrency(currencySelector.current)
  }

  setTableColumns() {
    this.columns = [
      new TableColumn("view.sites.savings.name", "name"),
      new TableColumn("view.sites.savings.category", "category_name"),
      new TableColumn("view.sites.savings.savings", "carriers.total", true, false, "", "units.energy-year"),
      new TableColumn("view.sites.savings.cost", "cost", true, false, "", this.currency.code),
      new TableColumn("view.sites.savings.proposed", "date_proposed", true, false),
      new TableColumn("view.sites.savings.planned", "date_planned", true, false),
      new TableColumn("view.sites.savings.implemented", "date_implemented", true, false),
      new TableColumn("view.sites.savings.climate", "climate_adapted", false, false),
    ];
  }

  getSavings(companyID: number, lang?: string, currency?: string) {
    this.loading = true
    this._savingService.v2_getSavings(companyID, lang, currency).subscribe(
      (res: Observable<Saving[]>) => {
        res.subscribe(
          (res: Saving[]) => {
            this.savings = res
            this.loading = false
          }
        )
      }
    )
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onSelectorChange(event: SelectorEvent) {
    console.log(event)
    if(event.selectorID == "currency") {
      this.getSavings(this.selectedCompany.id, sessionStorage.getItem("lang"), event.new)
      this.currency = this._currencyService.getCurrency(event.new)
      this.setTableColumns()
    }
  }

  onAdd(_: AddEvent) {
    this.open().result.then((s: Saving) => {
      this.savings = [...this.savings, s];
    }, e => { });
  }

  edit(s: Saving) {
    this.open(s, false).result.then((saving: Saving) => {
      this.savings = _.map(this.savings, (s: Saving) => { return s.id == saving.id ? saving : s; })
    }, e => { });
  }

  delete(s: Saving) {
    this._savingService.v2_deleteSaving(this.selectedCompany.id, s.id).subscribe((res: any) => {
      this.savings = this.savings.filter((e: Saving) => { return e.id !== s.id })
      this._toastService.deletedMeasure()
    }, (error: any) => {
      this._toastService.server()
    })
  }

  open(saving?: Saving, add: boolean = true) {
    let modalRef = this._modalService.open(MeasureFormComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static'
    })
    modalRef.componentInstance.add = add
    modalRef.componentInstance.company = this.selectedCompany
    modalRef.componentInstance.currency = this.currency
    if (!add) {
      modalRef.componentInstance.savingRef = saving
    }
    return modalRef
  }

}


