import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthService } from '../../../shared/services/auth.service';
import { CurrencyService } from '../../../shared/services/currency.service';
import { EnergyService } from '../../../shared/services/energy.service';
import { PublicUrlService, UrlSettings } from '../../../shared/services/public-url.service';
import { APIUser, UserService } from '../../../shared/services/user.service';
import { NetworkService } from '../../../shared/services/network.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  faLock = faLock

  loading: boolean = true
  requireLogin: boolean = false
  notFound: boolean = false

  urlKey: string
  urlSettings: UrlSettings


  loginForm: LoginForm = new LoginForm

  constructor(
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private _authService: AuthService,
    private _userService: UserService,
    private _energyService: EnergyService,
    private _currencyService: CurrencyService,
    private _publicUrlService: PublicUrlService,
    private _networkService: NetworkService,
    private router: Router,
    private _toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(
      data => {
        this.urlKey = data["key"]
        this._publicUrlService.getKeySettings(this.urlKey).subscribe(
          data => {
            this.urlSettings = data
            this._publicUrlService.setUrlSettings(data)
            this.loginForm.username = data.email
            if (data.password_enabled) {
              this.requireLogin = true
              this.loading = false
              return
            }
            this.loginForm.password = data.username
            this.login()
          },
          error => {
            if (error.status == 404) {
              this.loading = false
              this.notFound = true
            }
          }
        )
      }
    )
  }

  login() {
    this.doLogin(this.loginForm).subscribe(
      (user: APIUser) => {
        this._authService.setLogin()
        this._authService.setClientSession(user)
        this._userService.setUserInfo(user)
        this._energyService.loadEnergyCarriers().subscribe(
          res => {}
        )
        this._currencyService.loadSupportedCurrencies().subscribe(
          res => {}
        )

        this._networkService.selectNetwork(this.urlSettings.network).subscribe(
          res => {
            this.router.navigateByUrl(`/public/${this.urlKey}/networks/${this.urlSettings.network}`)
          }
        )
      },
      (err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 404) {
          this._toastService.loginPublicFailed()
        } else {
          this._toastService.server()
        }
      }
    )
  }

  doLogin(form: LoginForm): Observable<APIUser> {
    return this.http.post("/api/v2/public/login", form).pipe(
      map((res: APIUser) => {
        let user: APIUser = new APIUser(res)
        return user
      })
    )
  }

}

class LoginForm {
  username: string;
  password: string;
}
