<div class="container">
    <div class="row">
        <div class="col">
            <label for="heading-input">{{ 'view.admin.agreements.heading-label' | translate }}</label>
            <div class="input-group mb-3">
                <input type="text" id="heading-input" class="form-control" [(ngModel)]="agreement.heading">
            </div>
        </div>
        <div class="col">
            <div class="float-right">
                <button class="btn btn-primary mr-3" (click)="preview()" role="button">
                    {{ 'view.admin.agreements.preview' | translate }}
                </button>
                <button class="btn btn-primary" (click)="publish()" role="button">
                    {{ 'view.admin.agreements.publish' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="mb-2">
                {{ 'view.admin.agreements.content-info' | translate }}
            </div>
            <quill-editor [styles]="{maxHeight: '70vh'}" [(ngModel)]="agreement.body"></quill-editor>
        </div>
    </div>
</div>