import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { APIObjectDetailed } from '../../../../shared/services/company-object.service';
import { NetworkService } from '../../../../shared/services/network.service';

@Component({
  selector: 'app-network-overview',
  templateUrl: './network-overview.component.html',
  styleUrls: ['./network-overview.component.scss']
})
export class NetworkOverviewComponent implements OnInit {

  //Icons
  faCircle = faCircle;

  companyObjects: APIObjectDetailed[] = []

  columns: TableColumn[] = [
    { name: "view.networks.overview.site", key: "name", sort: true, },
    { name: "view.networks.overview.nace", key: "sni_data.code", sort: true },
    { name: "view.networks.overview.country", key: "geo.country.name", sort: true },
    { name: "view.networks.overview.region", key: "geo.state.name", sort: true },
    { name: "view.networks.overview.municipality", key: "geo.municipality.name", sort: true },
    { name: "view.networks.overview.supplied", key: "latest_supplied", sort: true },
    { name: "view.networks.overview.used", key: "latest_used", sort: true },
    { name: "view.networks.overview.mapped", key: "mapped", template: true },
  ]
  settings: TableSettings = new TableSettings("view.networks.overview.title", false, true, true, false, false, false);
  loadingObjects: boolean = true;

  constructor(
    private _networkService: NetworkService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this._networkService.selectNetwork(params["id"]).subscribe(
        (data: APIObjectDetailed[]) => {
          data.forEach(o => {
            if(!o.geo.state) {
              o.geo.state = {id: 0, name: ""}
            }
            if(!o.geo.municipality) {
              o.geo.municipality = {id: 0, name: ""}
            }
            this.companyObjects.push(o)
          })


          this.companyObjects = data
          this.loadingObjects = false
        }
      )
    });
  }

}
