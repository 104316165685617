import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Category, Subcategory } from '../models/category.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient) {}

  v2_getCategories(lang?: string): Observable<APICategory[]> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get("/api/v2/categories", {params: params}).pipe(
      map((res: APICategory[]) => { return _.sortBy(res, ['view_order']) }));
  }

  v2_getCategoryDetails(id: number, lang?: string): Observable<APICategoryDetails> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get(`/api/v2/categories/${id}`, {params: params}).pipe(
      map((res: APICategoryDetails) => { return res }));
  }

  v2_getSubcategory(id: number, lang?: string): Observable<APISubcategory> {
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get(`/api/v2/categories/subcategories/${id}`, {params: params}).pipe(
      map((res: APISubcategory) => { return res }));
  }

}

export class APICategory {
  id: number;
  name: string;
  desc: string;
  view_order: number;
}

export class APICategoryDetails extends APICategory {
  subcategories: APISubcategory[] = [];
}

export class APISubcategory {
  id: number;
  category: number;
  name: string;
  desc: string;
  bats: APIBat[] = []
}

export class APIBat {
  id: number;
  name: string;
  desc: string;
  reference_page: number
}