<div class="card border-primary">
  <div class="card-header bg-primary text-white">
    <div class="row">
      <div class="col-md-3">
        <span class="align-middle" [ngClass]="{'table-link': !!settings.link}"
          [routerLink]="!!settings.link ? settings.link : null">
          {{ settings?.title | translate }}
        </span>
        <app-tooltip *ngIf="!!settings.tooltip" keepFontColor="true" placement="bottom"
          text="{{ settings.tooltip | translate }}">
        </app-tooltip>
      </div>
      <div class="col-md-6 input-group-sm">
        <input #searchBar *ngIf="settings.search" type="search" class="search-bar form-control input-sm"
          placeholder="{{ 'table.search' | translate }}" (keydown.enter)="onSearch(searchBar.value)">
      </div>
      <div class="col-md-3">
        <div *ngIf="settings.selectors" class="d-inline-block table-selectors">
          <div ngbDropdown class="d-inline-block" *ngFor="let s of selectors">
            <button id="options-toggle" class="btn btn-sm btn-primary" ngbDropdownToggle>{{ s.current | translate }}</button>
            <div ngbDropdownMenu id="options-menu" aria-labelledby="options-toggle">
              <button ngbDropdownItem *ngFor="let o of s.options" (click)="onSelectorChange(s, o)">
                {{ o | translate }}
              </button>
            </div>
          </div>
        </div>
        <button *ngIf="settings.add" class="btn btn-sm btn-primary float-right" (click)="onAdd()" [disabled]="loading">
          <fa-icon *ngIf="!loading" [icon]="addIcon"></fa-icon>
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-borderless" [ngClass]="{'table-sm': settings.small}">
        <tr>
          <th *ngIf="rowTemplate"></th>
          <ng-container *ngTemplateOutlet="!settings.sort ? header : sortable"></ng-container>
          <th *ngIf="actions && actions.length"></th>
        </tr>
        <ng-container *ngIf="!loading && data.length > 0">
          <app-table-row *ngFor="let p of page" [item]="p" [columns]="columns" [actions]="actions"
            (rowAction)="handleAction($event)" [template]="rowTemplate" [fieldTemplate]="fieldTemplate"
            class="table-row">
          </app-table-row>
        </ng-container>
      </table>
    </div>

    <ng-container *ngIf="!loading && data.length == 0">
      <ng-container *ngTemplateOutlet="emptyData"></ng-container>
    </ng-container>

    <ng-container *ngIf="loading">
      <ng-container *ngTemplateOutlet="spinner"></ng-container>
    </ng-container>
    <div *ngIf="settings.paginate" class="d-flex justify-content-between p-2" [ngClass]="{'invisible': !!loading || data.length == 0}">
      <ngb-pagination [collectionSize]="collectionSize" size="sm" [(page)]="currentPage" [pageSize]="pageSize"
        [maxSize]="10" [rotate]="true">
      </ngb-pagination>
      <ng-select class="w-auto" [items]="pageSizes" [clearable]="false" [(ngModel)]="pageSize"></ng-select>
    </div>
  </div>
</div>

<ng-template #header>
  <th *ngFor="let c of columns" class="text-nowrap" [ngClass]="{'invisible': !!loading || data.length == 0}">

    <div>
      <div>
        {{ c.name | translate }}
        <app-tooltip class="table-tooltip" *ngIf="!!c.tooltip" text="{{ c.tooltip | translate }}"></app-tooltip>
      </div>
      <span class="table-header-unit" *ngIf="!!c.unit">[{{ c.unit | translate }}]</span>
    </div>

  </th>
</ng-template>

<ng-template #sortable>
  <th *ngFor="let c of columns" scope="col" [ngClass]="{'sortable': c.sort ? true : false, 'invisible': !!loading || data.length == 0}"
    class="text-nowrap" [sortable]="c.sort ? c.key : null" (sort)=" c.sort ? onSort($event) : null">
    <div class="d-flex">
      <ng-container *ngIf="c.sort">
        <div class="mr-2">
          <fa-icon *ngIf="!sortDirection || c.key != sortColumn" [icon]="sortDefault" size="sm"></fa-icon>
          <fa-icon *ngIf="sortDirection == 'asc' && c.key == sortColumn" [icon]="sortIcon"></fa-icon>
          <fa-icon *ngIf="sortDirection == 'desc' && c.key == sortColumn" [icon]="sortIcon" flip="vertical"></fa-icon>
        </div>
      </ng-container>
      <div>
        <div>
          {{ c.name | translate }}
          <app-tooltip class="table-tooltip" *ngIf="!!c.tooltip" text="{{ c.tooltip | translate }}"></app-tooltip>
        </div>
        <span class="table-header-unit" *ngIf="!!c.unit">[{{ c.unit | translate }}]</span>
      </div>
    </div>
  </th>
</ng-template>

<ng-template #spinner>
  <div class="text-center my-10">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
</ng-template>

<ng-template #emptyData>
  <div>
    {{ noData | translate }}
  </div>
</ng-template>