import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            if (err.url.includes("/api/v2/login") || err.url.includes("/api/v2/public/login")) {
                                return
                            }
                            if (err.url.includes("/api/v2/password")) {
                                return
                            }
                            this._authService.logoutSession()
                        }
                    }
                }
            )
        )
    }
}