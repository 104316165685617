<ngb-accordion #acc="ngbAccordion" activeIds="filter-panel" type="primary">
    <ngb-panel id="filter-panel">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-center white-font">
                <h5 class="mb-0 mr-2">{{ 'filter.title' | translate }}</h5>
                <button ngbPanelToggle class="btn p-0 white-font">
                    <fa-icon class="clickable" [icon]="opened? faAngleUp : faAngleDown"></fa-icon>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent class="border">
            <div class="row">
                <div *ngIf="coordinatorMode" class="col">
                    <div class="select-column">
                        <label class="section-label">{{ 'filter.nace.title' | translate }}</label>
                        <ng-select appendTo="body" [items]="filter.section.data" [(ngModel)]="filter.section.select"
                            [disabled]="filter.section.disable" (change)="sectionChange()"
                            placeholder="{{ 'filter.nace.section' | translate }}">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                {{ item.code }}: {{ item.desc }}
                            </ng-template>
                        </ng-select>
                        <ng-select class="last" appendTo="body" [items]="filter.division.data"
                            [(ngModel)]="filter.division.select" [disabled]="filter.division.disable"
                            (change)="divisionChange()"
                            placeholder="{{ 'filter.nace.division' | translate }}">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                {{ item.code }}: {{ item.desc }}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col">
                    <div class="select-column">
                        <label class="section-label">{{ 'filter.ratio.title' | translate }}</label>
                        <ng-select appendTo="body" [items]="filter.ratio.data" [(ngModel)]="filter.ratio.select"
                            groupBy="class" [disabled]="filter.ratio.disable"
                            placeholder="{{ 'filter.ratio.select' | translate }}">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                {{ item.name }}
                            </ng-template>
                        </ng-select>
                        <button *ngIf="coordinatorMode" class="btn btn-primary float-right last" (click)="emit()"
                            [disabled]="!filter.division.select || !filter.ratio.select">{{ 'filter.button.benchmark' | translate }}</button>
                        <button *ngIf="!coordinatorMode" class="btn btn-primary float-right last" (click)="emit()"
                            [disabled]="!filter.ratio.select">{{ 'filter.button.benchmark' | translate }}</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>