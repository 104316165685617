<div class="container">

  <div class="d-flex justify-content-center mb-3">
    <app-date-span class="" (change)="yearChange($event)" onlyYear="true"></app-date-span>
  </div>

  <h5>{{ 'view.sites.used.title' | translate }}</h5>
  <hr>

  <div class="row row-cols-sm-2 row-cols-md-2 row-cols-xl-3">
    <div class="col mb-3" *ngFor="let e of endUseForm.enduse">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            {{ e.name }}
            <app-tooltip class="align-self-center" [hidden]="!e.desc" type="info" text="{{e.desc}}" placement="right"></app-tooltip>
            <button type="button" class="btn btn-primary btn-sm float-right" (click)="open(modal, e)">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </h6>
          <p class="card-text pt-2">
            <small class="text-muted">
              {{ 'view.sites.used.usage' | translate }}: {{ e.carriers.total }} MWh
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ modalEntry.name }}
    </h5>
  </div>

  <div class="modal-body">
    <div class="row row-cols-sm-2 row-cols-md-2 row-cols-xl-3">
      <div class="col pl-2 pr-2 mb-3" *ngFor="let c of energyCarriers">
        <div class="card">
          <div class="card-body p-3">
            <h6 class="card-title">
              {{ 'energycarriers.'+c.model | translate }}
            </h6>
            <div class="input-group">
              <input type="number" class="form-control" min="0"
              [(ngModel)]="modalEntry.carriers[c.model]">
              <div class="input-group-append">
                <span class="input-group-text">MWh</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss()">{{ 'buttons.close' | translate }}</button>
    <button type="button" class="btn btn-outline-primary" (click)="save(modal)">{{ 'buttons.save' | translate }}</button>
  </div>
</ng-template>