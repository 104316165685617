import { Injectable } from '@angular/core';
import { APIBat, APICategory, APISubcategory, CategoryService } from './category.service';
import { APICarriers } from './energy.service';
import * as _ from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SavingsService {

  constructor(
    private http: HttpClient,
    private _categoryService: CategoryService,
  ) { }

  v2_getSavings(companyID: number, lang?: string, currency?: string): Observable<Observable<Saving[]>> {
    let params = new HttpParams();
    if (!!currency) {
      params = params.append('currency', currency)
    }
    return this.http.get(`/api/v2/objects/${companyID}/energy/savings`, { params: params }).pipe(
      map(
        (res: APISavingModel[]) => {
          const requests = []
          requests.push(this._categoryService.v2_getCategories(lang))
          res.forEach(s => {
            requests.push(this.decorateSaving(s, lang))
          })
          return forkJoin(requests).pipe(
            map(
              (res: any) => {
                let categories: APICategory[] = res[0]
                let savings: Saving[] = res.slice(1)
                savings.forEach(s => {
                  s.categoryModel = _.find(categories, ['id', s.subcategoryModel.category])
                })
                return savings
              }
            )
          )
        }))
  }

  v2_editSaving(companyID: number, saving: Saving, currency?: string): Observable<Observable<Saving>> {
    let params = new HttpParams();
    if (!!currency) {
      params = params.append('currency', currency)
    }
    return this.http.put(`api/v2/objects/${companyID}/energy/savings/${saving.id}`, saving, { params: params }).pipe(
      map((res: APISavingModel) => {
        return this.decorateSaving(res, sessionStorage.getItem("lang")).pipe(map((s: Saving) => {
          return s
        }))
      })
    )
  }

  v2_addSaving(companyID: number, saving: Saving, currency?: string): Observable<Observable<Saving>> {
    let params = new HttpParams();
    if (!!currency) {
      params = params.append('currency', currency)
    }
    return this.http.post(`api/v2/objects/${companyID}/energy/savings`, saving, { params: params }).pipe(
      map((res: APISavingModel) => {
        return this.decorateSaving(res, sessionStorage.getItem("lang")).pipe(map((s: Saving) => {
          return s
        }))
      })
    )
  }

  v2_deleteSaving(companyID: number, savingID: number): Observable<any> {
    return this.http.delete(`/api/v2/objects/${companyID}/energy/savings/${savingID}`).pipe(
      map(res => { return res; })
    )
  }

  decorateSaving(saving: APISavingModel, lang?: string): Observable<Saving> {
    return this._categoryService.v2_getSubcategory(saving.subcategory, lang).pipe(
      map((subcategory: APISubcategory) => {
        let s: Saving = new Saving(saving)
        s.subcategoryModel = subcategory
        return s
      })
    );
  }

  search(form: SearchForm, currency?: string): Observable<SearchResult> {
    let params: HttpParams = form.toParams()
    if (!!currency) {
      params = params.append('currency', currency)
    }
    return this.http.get("api/v2/energy/savings", { params: params }).pipe(
      map((res: APISavingModel[]) => {
        let searchRes = new SearchResult

        res.forEach(s => {
          searchRes.savings.push(new APISavingModel(s))
        });

        return searchRes
      })
    );
  }
}

export class APISavingModel {
  id: number;
  name: string;
  carriers: APICarriers = new APICarriers;
  cost: number;
  payoff: number;
  lcc: number;
  net_present: number;
  comment_existing_system: string;
  comment_new_system: string;
  comment_extent: string;
  climate_adapted: boolean;
  date_proposed: string;
  date_planned: string;
  date_implemented: string;
  approved: boolean;
  reported: number;
  category: number
  category_name: string
  subcategory: number;
  subcategory_name: string

  constructor(obj?: any) {
    if (obj) {
      _.forOwn(obj, (val, key) => {
        if (key == 'carriers') {
          this.carriers = new APICarriers(val)
        } else {
          this[key] = val
        }
      });
    }
  }
}

export class Saving extends APISavingModel {
  categoryModel: APICategory;
  subcategoryModel: APISubcategory;
  constructor(obj?: any) {
    super(obj)
  }
}

export class SearchForm {
  category: number;
  subcategory: number;
  sni: string;
  subsni: string;

  toParams(): HttpParams {
    let params: HttpParams = new HttpParams
    if (this.category) params = params.append("category", String(this.category))
    if (this.subcategory) params = params.append("subcategory", String(this.subcategory))
    if (this.sni) params = params.append("sni", this.sni)
    if (this.subsni) params = params.append("sni", this.subsni)
    return params
  }

}

export class SearchResult {
  savings: APISavingModel[];
  bats: APIBat[];

  constructor() {
    this.savings = []
    this.bats = []
  }

}