import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(
    private http: HttpClient,
  ) { }

  v2_getAgreement(): Observable<APIAgreementModel> {
    return this.http.get('/api/v2/agreement').pipe(
      map((res: APIAgreementModel) => { return res })
    );
  }

  v2_acceptAgreement(id: number): Observable<any> {
    return this.http.post(`/api/v2/agreement/${id}`, null)
  }

  v2_postAgreement(data: APIAgreementModel): Observable<APIAgreementModel> {
    return this.http.post('/api/v2/agreement', data).pipe(
      map((resp: APIAgreementModel) => {return resp})
    )
  }

}

export class APIAgreementModel {
  id: number;
  heading: string;
  body: string;
  active: boolean;
}