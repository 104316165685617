<div class="modal-header">
  <h3 class="modal-title" ngbAutofocus>{{data.heading}}</h3>
</div>

<div class="modal-body">

  <quill-view [content]="data.body" format="html" theme="snow"></quill-view>

</div>

<div class="modal-footer" *ngIf="!preview">
  <input type="checkbox" [(ngModel)]="isChecked">
  <span>{{ 'view.home.agreement.accept' | translate }}</span>
  <button class="btn btn-primary" (click)="acceptAgreement()" role="button" [disabled]="!isChecked">
    {{ 'view.home.agreement.continue' | translate }}
  </button>
</div>

<div class="modal-footer" *ngIf="preview">
  <button class="btn btn-primary" (click)="close()" role="button">
    {{ 'view.home.agreement.close' | translate }}
  </button>
</div>