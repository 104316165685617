import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Chart, ChartData, ChartDataSets } from 'chart.js';
import { EnergyService, SuppliedEnergyForm, APIEndUseCategory, NewEUForm } from '../../../shared/services/energy.service';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as d3Sankey from 'd3-sankey-diagram';
import * as d3 from 'd3';
import * as SvgPanZoom from 'svg-pan-zoom';
import { ToastService } from '../../../shared/services/toast.service';
import { APIObjectDetailed, CompanyObjectService } from '../../../shared/services/company-object.service';


@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {
  currentCompany: APIObjectDetailed
  selectedView: string;
  availableViews: View[] = [
    new View("", "sankey"),
    new View("", "input"),
    new View("", "enduse")
  ];
  selectedYears: number[] = [];

  viewSettings: ViewSettings = new ViewSettings()

  endUseCategories: APIEndUseCategory[] = []
  availableEndUse: APIEndUseCategory[] = []
  selectedEndUse: number[] = [];
  @ViewChild('chart') chartRef: ElementRef;

  axesLabel: string = "MWh"
  endUseChart: Chart;
  endUseChartData: ChartData = {}
  loadedData: LoadedData = new LoadedData

  @ViewChild('sankey') sankeyRef: ElementRef;
  svgPanZoom: SvgPanZoom.Instance

  //Translation objects
  carrierTrans: any;
  availableViewsTrans: any;
  suppliedString: string;
  errorsTrans: any;

  //Graph control variables
  graphCompanies: APIObjectDetailed[] = []
  graphYears: number[] = []

  constructor(
    private _energyService: EnergyService,
    private _toastService: ToastService,
    private translate: TranslateService,
    private _objectService: CompanyObjectService) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.getEndUseCategories(e.lang)
      });
    translate.stream(
      ['energycarriers',
        'view.sites.follow-up.views', 'view.sites.follow-up.supplied', "view.sites.follow-up.errors.no-data"]).subscribe(res => {
          this.carrierTrans = res['energycarriers']
          this.availableViewsTrans = res['view.sites.follow-up.views']
          this.errorsTrans = res["view.sites.follow-up.errors.no-data"]
          this.suppliedString = res['view.sites.follow-up.supplied']

          //Update translation for the view dropdown
          let newViews: View[] = []
          this.availableViews.forEach(v => {
            newViews.push(new View(this.availableViewsTrans[v.modelName], v.modelName))
          })
          this.availableViews = newViews

          if (this.viewSettings.view == "sankey") {
            this.updateSankey()
          } else {
            if (this.endUseChart) this.updateEndUseChartData()
          }
        })
  }

  ngOnInit() {
    this.viewSettings.type = "energy"
    this.currentCompany = this._objectService.currentObject()
    this.getEndUseCategories(sessionStorage.getItem("lang"))
  }

  getEndUseCategories(lang?: string) {
    this._energyService.v2_getEndUseCategories(lang).subscribe(
      (res: APIEndUseCategory[]) => { this.endUseCategories = res })
  }

  yearChange(years) {
    this.selectedYears = years
  }

  onTabChange(e) {
    this.viewSettings.type = e.nextId
    if (this.viewSettings.type == "energy") {
      this.axesLabel = "MWh"
    } else {
      this.axesLabel = "kg CO\u2082"
    }
    if (this.viewSettings.view == "enduse") {
      this.updateEndUseChartData()
    } else {
      this.updateSankey()
    }
  }

  load() {
    this.viewSettings.view = this.selectedView
    this.destroyCharts()

    if (this.viewSettings.view == "input") {
      this.graphCompanies = [this.currentCompany]
      this.graphYears = this.selectedYears
      return
    }

    const enduseRequest = this._energyService.v2_getMultipleUsed(this.currentCompany.id, this.selectedYears)
    const supplyRequest = this._objectService.supplied(this.currentCompany.id, this.selectedYears)
    forkJoin([enduseRequest, supplyRequest]).pipe(
      map((res: any) => { return res })).subscribe(res => {
        this.loadedData.enduse = res[0]
        this.loadedData.supplied = res[1]
        if (this.viewSettings.view == "enduse") {
          this.loadedData.enduse.forEach(f => { f.removeUnreported() })
          this.initAvailableEndUse()
          this.initEnduseChart()
          this.updateEndUseChartData()
        } else {
          this.updateSankey()
        }
      });
  }

  initAvailableEndUse() {
    let available: APIEndUseCategory[] = []
    this.loadedData.enduse.forEach(f => {
      f.enduse.forEach(e => {
        const endUseCategory = _.find(this.endUseCategories, { 'id': e.category })
        available.push(endUseCategory)
      })
    })
    this.availableEndUse = [..._.uniqBy(available, 'id')]
  }

  updateEndUseChartData() {
    let datasets: ChartDataSets[] = []
    let labels: string[] = []
    let carriers = this._energyService.v2_getEnergyCarriers()

    //Create all needed labels on form {enduse_name}-{year}
    this.loadedData.enduse.forEach(form => {
      form.enduse.forEach(e => {
        if (_.isEmpty(this.selectedEndUse) || this.selectedEndUse.includes(e.category)) {
          labels.push(e.name + " " + form.year)
        }
      });
    });

    //Create empty datasets for each reported carrier within the result.
    //Next part of the code push the end use's energy into the correct carrier arrays.
    let usedCarriers: string[] = []
    this.loadedData.enduse.forEach(f => {
      f.enduse.forEach(e => {
        usedCarriers.push(...e.carriers.reported())
      })
    })
    usedCarriers = _.uniq(usedCarriers)

    usedCarriers.forEach(c => {
      let dataset: ChartDataSets = {}
      let carrier = _.find(carriers, {"model": c})
      dataset.data = []
      dataset.label = this.carrierTrans[carrier.model]
      dataset.backgroundColor = carrier.graph_color
      dataset.maxBarThickness = 50
      datasets.push(dataset)
    })

    this.loadedData.enduse.forEach((d, formIndex) => {
      let supplied = this.loadedData.supplied[formIndex]
      d.enduse.forEach(e => {
        if (_.isEmpty(this.selectedEndUse) || this.selectedEndUse.includes(e.category)) {
          Object.keys(e.carriers).forEach(c => {
            if (c == "total") {
              return
            }
            let val: number
            if (this.viewSettings.type == "energy") {
              val = e.carriers[c]
            } else {
              let co2 = _.find(supplied.fuels, ["model", c]).co2
              val = e.carriers[c] * co2
            }
            let dataset = _.find(datasets, ["label", this.carrierTrans[c]])
            if (dataset) {
              dataset.data.push(val)
            }
          });
        }
      });
    });

    this.endUseChartData.labels = labels
    this.endUseChartData.datasets = datasets;
    this.endUseChart.options.scales.xAxes[0].scaleLabel.labelString = this.axesLabel
    this.endUseChart.update()
  }

  updateSankey() {
    let sankeyData = new Sankey
    this.loadedData.supplied.forEach(form => {
      form.fuels.forEach(f => {
        sankeyData.addNode(new SankeyNode(this.suppliedString + " " + this.carrierTrans[f.model]))
        let val: number
        if (this.viewSettings.type == "energy") {
          val = f.amount
        } else {
          val = f.co2 * f.amount
        }
        sankeyData.addLink(
          new SankeyLink("input", this.suppliedString + " " + this.carrierTrans[f.model], val, this.carrierTrans[f.model], f.graph_color), true)
      });
    });

    this.loadedData.enduse.forEach((form, formIndex) => {
      let supplied = this.loadedData.supplied[formIndex]
      form.enduse.forEach(process => {
        sankeyData.addNode(new SankeyNode(process.name))
        Object.keys(process.carriers).forEach(carrier => {
          if (carrier == "total") return
          let color = _.find(supplied.fuels, ["model", carrier]).graph_color
          let val: number
          if (this.viewSettings.type == "energy") {
            val = process.carriers[carrier]
          } else {
            let co2 = _.find(supplied.fuels, ["model", carrier]).co2
            val = process.carriers[carrier] * co2
          }
          sankeyData.addLink(new SankeyLink(
            this.suppliedString + " " + this.carrierTrans[carrier], process.name, val, this.carrierTrans[carrier], color), true)
        });
      });
    });

    if (sankeyData.links.length == 0) {
      this._toastService.error(this.errorsTrans)
      return
    }

    console.log("Generating sankey from:", sankeyData)

    let width = this.sankeyRef.nativeElement.offsetWidth
    let height = this.sankeyRef.nativeElement.offsetHeight
    var layout = d3Sankey.sankey().extent([[50, 30], [width - 100, height - 30]]).nodeWidth(5)
    var graph = layout(sankeyData)
    var diagram = d3Sankey.sankeyDiagram()
      .linkColor(function (d) { return d.color })
      .linkTitle((d) => {
        const parts = []
        const sourceTitle = d.source.id
        const targetTitle = d.target.id
        const matTitle = d.type

        parts.push(`${sourceTitle} → ${targetTitle}`)
        if (matTitle) parts.push(matTitle)
        parts.push(`${d3.format('.3s')((d.value))} ${this.axesLabel}`)
        return parts.join('\n')
      })
    d3.select('#sankey svg').datum(graph).call(diagram);
    //Add zoom to the diagram
    this.svgPanZoom = SvgPanZoom('#sankey svg', {
      controlIconsEnabled: true,
      mouseWheelZoomEnabled: false,
      dblClickZoomEnabled: false,
    });
    this.svgPanZoom.updateBBox() // Update viewport bounding box
    this.svgPanZoom.resize(); // Recalculate size of svg
    this.svgPanZoom.fit(); // Fit within svg
    this.svgPanZoom.center(); // Center inside of svg
  }

  destroyCharts() {
    if (this.endUseChart) this.endUseChart.destroy()
  }

  initEnduseChart() {
    if (this.endUseChart) this.endUseChart.destroy()
    this.endUseChart = new Chart(this.chartRef.nativeElement, {
      type: 'horizontalBar',
      data: this.endUseChartData,
      options: {
        tooltips: {
          mode: 'point'
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.axesLabel,
            },
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    })
  }

  changeShowEnduses() {
    this.updateEndUseChartData()
  }

  clearShowEnduses() {
    this.selectedEndUse = []
  }

}

export class LoadedData {
  supplied: SuppliedEnergyForm[]
  enduse: NewEUForm[]
  constructor() {
    this.supplied = []
    this.enduse = []
  }
}

export class ViewSettings {
  view: string
  type: string
}

export class View {
  viewName: string
  modelName: string
  constructor(viewName, modelName: string) {
    this.viewName = viewName
    this.modelName = modelName
  }
}

export class Sankey {
  nodes: SankeyNode[]
  links: SankeyLink[]
  alignLinkTypes: boolean
  constructor() {
    this.links = []
    this.nodes = [
      { id: 'input' }
    ]
  }

  addNode(node: SankeyNode) {
    if (_.find(this.nodes, ["id", node.id])) return
    this.nodes.push(node)
  }

  addLink(link: SankeyLink, addToDuplicate?: boolean) {
    if (link.value == 0) return
    let existing = _.find(this.links, (l: SankeyLink) => {
      return l.source == link.source && l.target == link.target && l.type == link.type
    });
    if (existing) {
      if (addToDuplicate) {
        existing.value += link.value
      }
      return
    }
    this.links.push(link)
  }

}

export class SankeyNode {
  id: string
  constructor(id?: string) {
    if (id) {
      this.id = id
    }
  }
}

export class SankeyLink {
  source: string
  target: string
  value: number
  type: string
  color: string

  constructor(source: string, target: string, value: number, type: string, color?: string) {
    this.source = source
    this.target = target
    this.value = value
    this.type = type
    if (color) this.color = color
  }
}
