import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, Directive, ContentChild, TemplateRef, ContentChildren } from '@angular/core';
import { IconDefinition, faSortUp, faSort, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RowEvent, ColumnValuePipe } from './table-row/table-row.component';
import * as _ from 'lodash';

export type SortColumn = string;
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };
const compare = (v1: any, v2: any) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

export class AddEvent { }

export class SelectorEvent {
  selectorID: string
  old: string
  new: string
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class SortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  items: any[];
  private _data: any[] = [];
  @Input("data") set data(val: any[]) { this._data = val; this.items = val; }
  get data(): any[] { return this._data; }

  private _settings: TableSettings = new TableSettings;
  @Input("settings") set settings(val: TableSettings) { Object.keys(val).forEach(k => { this._settings[k] = val[k]; }) }
  get settings(): TableSettings { return this._settings; }

  @Input("columns") columns: TableColumn[] = [];
  @Input("actions") actions: string[] = [];
  @Output("action") action = new EventEmitter<RowEvent>();
  @Output("add") add = new EventEmitter<AddEvent>();
  @ContentChild("row", { static: false }) rowTemplate: TemplateRef<any>;
  @ContentChild("field", { static: false }) fieldTemplate: TemplateRef<any>;
  @Input("loading") loading: boolean = false;

  @Input("selectors") selectors: TableSelector[] = [];
  @Output("selectorChange") selectorChange = new EventEmitter<SelectorEvent>();

  @Input("noData") noData: string = "table.no-data"

  addIcon: IconDefinition = faPlus;
  sortIcon: IconDefinition = faSortUp;
  sortDefault: IconDefinition = faSort;
  sortDirection: SortDirection = "";
  sortColumn: SortColumn = "";

  currentPage = 1;
  pageSizes: number[] = [10, 15, 20];
  pageSize: number = this.pageSizes[0];
  collectionSize: number = 0;

  constructor() { }

  ngOnInit() {}

  // TODO: Change from getter to something else
  get page(): any[] {
    this.collectionSize = this.items.length;
    if (!this.settings.paginate) {
      return this.items;
    }
    return this.items
      .slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
  }

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  onSort({ column, direction }: SortEvent) {
    this.sortDirection = direction;
    this.sortColumn = column;

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.items = this.data;
    } else {
      let pipe = new ColumnValuePipe;
      this.items = [...this.data].sort((a, b) => {
        const res = compare(pipe.transform(a, column), pipe.transform(b, column));
        return direction === 'asc' ? res : -res;
      });
    }
  }

  onSearch(searchTerm: string) {
    if (!searchTerm) {
      this.items = this.data;
    } else {
      this.items = this.data.filter((item: any) => {
        const term = searchTerm.toLowerCase();
        let pipe = new ColumnValuePipe;
        for (let c of this.columns.filter(c => { return c.search })) {
          if (pipe.transform(item, c.key).toLowerCase().includes(term)) {
            return true;
          }
        }
        return false;
      });
    }
  }

  handleAction(event: RowEvent) {
    this.action.emit(event);
  }

  onAdd() {
    this.add.emit(new AddEvent);
  }

  onSelectorChange(selector: TableSelector, option: string) {
    let e: SelectorEvent = new SelectorEvent
    e.old = selector.current
    e.new = option
    e.selectorID = selector.id
    selector.current = option
    this.selectorChange.emit(e)
  }

}

export class TableSettings {
  title?: string = "";
  search?: boolean = true;
  sort?: boolean = true;
  paginate?: boolean = true;
  add?: boolean = true;
  selectors?: boolean = false;
  small?: boolean = false;
  link?: string = "";
  tooltip?: string = "";

  constructor(
    title: string = "",
    search: boolean = true,
    sort: boolean = true,
    paginate: boolean = true,
    add: boolean = true,
    selectors: boolean = false,
    small: boolean = false,
    link: string = "",
    tooltip: string = ""
  ) {
    this.title = title
    this.search = search
    this.sort = sort
    this.paginate = paginate
    this.add = add
    this.selectors = selectors
    this.small = small
    this.link = link
    this.tooltip = tooltip
  }
}

export class TableColumn {
  name: string;
  key: string;
  sort?: boolean = true;
  search?: boolean = true;
  tooltip?: string = "";
  unit?: string = "";
  template?: boolean = false;

  constructor(
    name: string,
    key: string,
    sort: boolean = true,
    search: boolean = true,
    tooltip: string = "",
    unit: string = "",
    template: boolean = false
  ) {
    this.name = name
    this.key = key
    this.sort = sort
    this.search = search
    this.tooltip = tooltip
    this.unit = unit
    this.template = template
  }

}

export class TableSelector {
  id: string
  current: string
  options: string[]

  constructor(id: string, options: string[]) {
    this.id = id
    this.current = options[0]
    this.options = options
  }

}
