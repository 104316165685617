import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EnergyService {

  private _energyCarrier: EnergyCarrier[]

  private _endUseCategories: { [lang: string]: APIEndUseCategory[] } = {}

  supportedUnits: EnergyUnit[] = [
    new EnergyUnit("units.energy.mwh", 1),
    new EnergyUnit("units.energy.gwh", Math.pow(10, -3)),
    new EnergyUnit("units.energy.twh", Math.pow(10, -6))
  ]

  constructor(
    private http: HttpClient,
  ) { }

  loadEnergyCarriers(): Observable<any> {
    return this.http.get("/api/v2/energy/carriers").pipe(
      map((res: EnergyCarrier[]) => { this._energyCarrier = res })
    )
  }

  v2_getEnergyCarriers(): EnergyCarrier[] {
    return this._energyCarrier
  }

  v2_getEndUseCategories(lang?: string): Observable<APIEndUseCategory[]> {
    let selectedLang: string = lang ? lang : "en"
    if (selectedLang in this._endUseCategories) {
      const cachedRes = Observable.create((observer) => {
        observer.next(this._endUseCategories[selectedLang]);
        observer.complete();
      });
      return cachedRes
    }
    let params = new HttpParams();
    params = params.append('lang', lang ? lang : "en")
    return this.http.get("/api/v2/energy/categories", { params: params }).pipe(
      map((data: APIEndUseCategory[]) => {
        let res = _.sortBy(data, ['view_order'])
        if (!(selectedLang in this._endUseCategories)) {
          this._endUseCategories[selectedLang] = res
        }
        return res
      })
    )
  }

  private createSuppliedForm(companyID: number, supplied: SuppliedAPIModel, co2: CO2APIModel): SuppliedEnergyForm {
    let form: SuppliedEnergyForm = this.emptySuppliedForm()
    form.compID = companyID
    form.year = supplied.year
    _.find(form.general, ["model", "water"]).amount = supplied.water
    _.find(form.general, ["model", "effect"]).amount = supplied.effect
    form.fuels.forEach(f => {
      f.amount = supplied.carriers[f.model]
      f.co2 = co2.carriers[f.model]
    })
    form.updateTotals()
    return form
  }

  private createUsedForm(companyID: number, data: UsedAPIModel[], categories: APIEndUseCategory[]) {
    let form: NewEUForm = new NewEUForm
    form.year = data[0].year
    form.compID = companyID
    categories.forEach(c => {
      let entry: EndUseEntry = new EndUseEntry
      entry.year = form.year
      entry.name = c.name
      entry.desc = c.desc
      entry.category = c.id
      entry.graphColor = c.graph_color
      let used: UsedAPIModel = _.find(data, ["category", c.id])
      if (used) {
        entry.carriers = new APICarriers(used.carriers)
      }
      form.enduse.push(entry)
    });
    return form
  }

  v2_getMultipleUsed(companyID: number, year?: number[] | string[]): Observable<NewEUForm[]> {
    const requests = []
    year.forEach(y => {
      requests.push(this.v2_getNewUsed(companyID, y))
    });
    return forkJoin(requests).pipe(
      map(
        (res: any) => {
          return res
        }
      )
    )
  }

  v2_getNewUsed(companyID: number, year?: number | string): Observable<NewEUForm> {
    const used = this.getUsed(companyID, year)
    const categories = this.v2_getEndUseCategories(sessionStorage.getItem("lang"))
    return forkJoin([used, categories]).pipe(
      map((res: [UsedAPIModel[], APIEndUseCategory[]]) => {
        let used: UsedAPIModel[] = res[0]
        let categories: APIEndUseCategory[] = res[1]
        let form: NewEUForm = new NewEUForm
        form.year = Number(year)
        form.compID = companyID
        categories.forEach(c => {
          let entry: EndUseEntry = new EndUseEntry
          entry.year = Number(year)
          entry.name = c.name
          entry.desc = c.desc
          entry.category = c.id
          let stored: UsedAPIModel = _.find(used, ["category", c.id])
          if (stored) {
            entry.carriers = new APICarriers(stored.carriers)
          }
          form.enduse.push(entry)
        });
        return form
      })
    )
  }

  v2_latestUsed(companyID: number, nr: number): Observable<NewEUForm[]> {
    return this.getUsed(companyID, null, nr).pipe(
      map((data: { string: UsedAPIModel[] }) => {
        let res: NewEUForm[] = []
        for (let [key, value] of Object.entries(data)) {
          let form: NewEUForm = new NewEUForm
          form.year = Number(key)
          form.compID = companyID
          value.forEach(u => {
            let entry: EndUseEntry = new EndUseEntry
            entry.carriers = new APICarriers(u.carriers)
            form.enduse.push(entry)
          })
          res.push(form)
        }
        return res
      })
    )
  }

  getUsed(companyID: number, year?: number | string, latest?: number) {
    let params = new HttpParams();
    params = year ? params.append('year', String(year)) : params
    params = latest ? params.append('latest', String(latest)) : params
    return this.http.get(`/api/v2/objects/${companyID}/energy/used`, { params: params }).pipe(
      map((res: any) => {
        return year ? res[year] : res
      })
    );
  }

  v2_postUsed(companyID: number, data: EndUseEntry[]): Observable<any> {
    return this.http.post(`/api/v2/objects/${companyID}/energy/used`, data).pipe(
      map(res => {
        console.log(res)
      })
    )
  }

  v2_postSupplied(data: SuppliedEnergyForm): Observable<any> {
    const supplied = this.postSupplied(data.compID, data.toSupplied())
    const co2 = this.postCo2(data.compID, data.toCo2())
    return forkJoin([supplied, co2]).pipe(
      map(res => { return res })
    )
  }

  postSupplied(companyID: number, data: SuppliedAPIModel): Observable<any> {
    return this.http.post(`/api/v2/objects/${companyID}/energy/supplied`, data).pipe(
      map(res => {
        console.log("supplied energy saved!")
      })
    )
  }

  postCo2(companyID: number, data: CO2APIModel): Observable<any> {
    return this.http.post(`/api/v2/objects/${companyID}/energy/co2`, data).pipe(
      map(res => {
        console.log("co2 saved!")
      })
    )
  }

  emptySuppliedForm(): SuppliedEnergyForm {
    let f: SuppliedEnergyForm = new SuppliedEnergyForm
    this._energyCarrier.forEach(c => {
      let entry: FormCarrier = new FormCarrier
      entry.co2 = c.co2
      entry.graph_color = c.graph_color
      entry.model = c.model
      entry.amount = 0
      f.fuels.push(entry)
    })
    return f
  }

  defaultCo2(year: number): CO2APIModel {
    let co2 = new CO2APIModel
    co2.year = year
    this._energyCarrier.forEach(c => {
      co2.carriers[c.model] = c.co2
    })
    return co2
  }

  translateEndUseForm(form: NewEUForm, lang?: string): Observable<NewEUForm> {
    return this.v2_getEndUseCategories(lang).pipe(
      map((categories: APIEndUseCategory[]) => {
        form.enduse.forEach(e => {
          let category = _.find(categories, ["id", e.category])
          e.name = category.name
          e.desc = category.desc
        })
        return form
      })
    )
  }

  private padSuppliedCO2(supplied: SuppliedAPIModel[], co2: CO2APIModel[]): CO2APIModel[] {
    let res: CO2APIModel[] = []
    supplied.forEach((s, i) => {
      let c = _.find(co2, ['year', s.year])
      if (!c) {
        c = this.defaultCo2(s.year)
      }
      res.push(c)
    })
    return res
  }

  private padSupplied(supplied: SuppliedAPIModel[], co2: CO2APIModel[]): [SuppliedAPIModel[], CO2APIModel[]] {
    let res: [SuppliedAPIModel[], CO2APIModel[]] = [[], []]
    co2.forEach((c, i) => {
      let s = _.find(supplied, ['year', c.year])
      if (!s) {
        s = new SuppliedAPIModel(c.year)
      }
      res[0].push(s)
      res[1].push(c)
    })
    return res
  }

  private padUsedCO2(used: UsedAPIModel[][], co2: CO2APIModel[]): CO2APIModel[] {
    let res: CO2APIModel[] = []
    used[0].forEach((u, i) => {
      let c = _.find(co2, ['year', u.year])
      if (!c) {
        c = this.defaultCo2(u.year)
      }
      res.push(c)
    })
    return res
  }

  private padUsed(used: UsedAPIModel[][], co2: CO2APIModel[], categories: APIEndUseCategory[]): UsedAPIModel[][] {
    let res: UsedAPIModel[][] = []
    co2.forEach(e => {
      let u = _.find(used, u => { return u[0].year == e.year })
      if (!u) {
        u = []
        categories.forEach((c: APIEndUseCategory) => {
          let apiModel: UsedAPIModel = new UsedAPIModel
          apiModel.year = e.year
          apiModel.category = c.id
          u.push(apiModel)
        });
      }
      res.push(u)
    })
    return res
  }

  toSuppliedForms(objectID: number, supplied: SuppliedAPIModel[], co2: CO2APIModel[]): SuppliedEnergyForm[] {
    if (supplied.length > co2.length) {
      co2 = this.padSuppliedCO2(supplied, co2)
    } else if (supplied.length < co2.length) {
      [supplied, co2] = this.padSupplied(supplied, co2)
    }
    let forms: SuppliedEnergyForm[] = []
    supplied.forEach((s, i) => {
      let c = _.find(co2, ['year', s.year])
      if (!c) {
        c = this.defaultCo2(s.year)
      }
      forms.push(this.createSuppliedForm(objectID, s, c))
    })
    return forms
  }

  toUsedForms(objectID: number, used: UsedAPIModel[][], co2: CO2APIModel[], categories: APIEndUseCategory[]): NewEUForm[] {
    if (used.length > co2.length) {
      co2 = this.padUsedCO2(used, co2)
    } else if (used.length < co2.length) {
      used = this.padUsed(used, co2, categories)
    }
    let res: NewEUForm[] = []
    used.forEach((u, i) => {
      let form = this.createUsedForm(objectID, u, categories)
      form.co2 = co2[i]
      res.push(form)
    });
    return res
  }

}


export class EnergyUnit {
  translateKey: string
  multiplier: number

  constructor(translateKey: string, multiplier: number) {
    this.translateKey = translateKey
    this.multiplier = multiplier
  }

}

export class EnergyCarrier {
  model: string;
  co2: number;
  graph_color: string
}

export class SuppliedAPIModel {
  year: number
  water: number = 0
  effect: number = 0
  carriers: APICarriers
  constructor(year) {
    this.year = year
    this.carriers = new APICarriers()
  }
}

export class APIEndUseCategory {
  id: number
  name: string
  desc: string
  graph_color: string
}

export class APICarriers {
  elec: number = 0
  district_heating: number = 0
  oil: number = 0
  diesel: number = 0
  gasol: number = 0
  n_gas: number = 0
  pellets: number = 0
  wood: number = 0
  gasolin: number = 0
  coal: number = 0
  biofuel: number = 0
  other: number = 0
  total: number = 0

  constructor(obj?: any) {
    if (obj) {
      _.forOwn(obj, (val, key) => {
        this[key] = val
      })
    }
    this.sum()
  }

  sum() {
    let tot: number = 0;
    _.forOwn(this, (val, key) => {
      if (key != "total") {
        tot += Number(val);
      }
    });
    this.total = tot
  }

  reported(): string[] {
    let res: string[] = []
    _.forOwn(this, (val, key) => {
      if (key != "total" && Number(val) != 0) {
        res.push(key)
      }
    })
    return res
  }

}

export class CO2APIModel {
  year: number
  carriers: APICarriers
  constructor() {
    this.carriers = new APICarriers
  }
}

export class UsedAPIModel {
  year: number
  category: number
  carriers: APICarriers
  constructor(obj?: any) {
    this.carriers = new APICarriers(obj)
  }
}

export class FormCarrier extends EnergyCarrier {
  amount: number
}

export class SuppliedEnergyForm {
  compID: number;
  year: number;
  general: any
  fuels: FormCarrier[];
  constructor() {
    this.general = [
      new GeneralEntry({ model: "total", unit: 'MWh', amount: 0 }),
      new GeneralEntry({ model: "supplied", unit: 'MWh', amount: 0 }),
      new GeneralEntry({ model: "effect", unit: 'kW', amount: 0 }),
      new GeneralEntry({ model: "water", unit: 'm3', amount: 0 })
    ]
    this.fuels = []
  }

  updateTotals() {
    let total = _.sumBy(this.fuels, 'amount')
    _.find(this.general, ['model', 'total']).amount = total
    _.find(this.general, ['model', 'supplied']).amount = total - _.find(this.fuels, ["model", "elec"]).amount
  }

  toSupplied(): SuppliedAPIModel {
    let res: SuppliedAPIModel = new SuppliedAPIModel(this.year)
    res.water = _.find(this.general, ["model", "water"]).amount
    res.effect = _.find(this.general, ["model", "effect"]).amount
    this.fuels.forEach(f => {
      res.carriers[f.model] = f.amount
    })
    return res
  }

  toCo2(): CO2APIModel {
    let res: CO2APIModel = new CO2APIModel
    res.year = this.year
    this.fuels.forEach(f => {
      res.carriers[f.model] = f.co2
    })
    return res
  }

  getTotal(): number {
    return _.find(this.general, ['model', 'total']).amount
  }

  removeUnreported() {
    _.remove(this.fuels, f => { return f.amount == 0 })
  }

}

export class GeneralEntry {
  model: string
  amount: number
  unit: string
  constructor(obj?: any) {
    if (obj) {
      this.model = obj.model
      this.amount = obj.amount
      this.unit = obj.unit
    }
  }
}

export class EndUseEntry extends UsedAPIModel {
  name: string
  graphColor: string
  desc: string
  constructor(obj?: any) {
    super(obj);
  }
}

export class NewEUForm {
  compID: number;
  year: number;
  enduse: EndUseEntry[] = []
  co2: CO2APIModel

  getTotal(): number {
    let tot: number = 0
    this.enduse.forEach(e => {
      tot += e.carriers.total
    });
    return tot
  }

  getCategoryCO2(i: number): number {
    let tot: number = 0
    _.forOwn(this.enduse[i].carriers, (val, key) => {
      if (key != "total") {
        tot += this.co2.carriers[key] * Number(val)
      }
    });
    return tot
  }

  getCategoryTotal(i: number): number {
    return this.enduse[i].carriers.total
  }

  removeUnreported() {
    _.remove(this.enduse, e => { return e.carriers.total == 0 })
  }
}
