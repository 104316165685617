<div class="container-fluid">
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.admin.companies.info' | translate }}</h5>
        </div>
        <fieldset [disabled]="readonly">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="form-group">
                        <label for="name"> {{ 'view.admin.companies.name' | translate }} </label>
                        <input type="text" class="form-control" id="name" [(ngModel)]="company.name">
                    </div>
                    <div class="form-group">
                        <label for="orgNr" class="form-label">{{ 'view.admin.companies.org' | translate }}</label>
                        <input type="text" class="form-control" id="orgNr" [(ngModel)]="company.org_nr">
                    </div>
                    <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
                        (click)="save()"> {{ 'buttons.save' | translate }} </button>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="section" *ngIf="mode != 'new'">
        <div class="section-header">
            <h5>{{ 'view.admin.companies.memberships' | translate }}</h5>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <div class="form-group">
                    <app-membership-manager [resource]="company" memberType="object" [readonly]="readonly">
                    </app-membership-manager>
                </div>
                <div class="form-group">
                    <app-membership-manager [resource]="company" memberType="user" [readonly]="readonly">
                    </app-membership-manager>
                </div>
            </div>
        </div>
    </div>
</div>