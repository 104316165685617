<div class="container-fluid">
  <fieldset [disabled]="readonly">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="first-name"> {{ 'view.admin.users.firstname' | translate }} </label>
        <input type="text" class="form-control" id="first-name" [(ngModel)]="user.first_name">
      </div>
      <div class="form-group col-md-6">
        <label for="last-name"> {{ 'view.admin.users.lastname' | translate }} </label>
        <input type="text" class="form-control" id="last-name" [(ngModel)]="user.last_name">
      </div>
    </div>
    <div class="form-group">
      <label for="email"> {{ 'view.admin.users.email' | translate }} </label>
      <input type="email" class="form-control" id="email" [(ngModel)]="user.info.email">
    </div>
    <div class="form-row">
      <div class="form-group col-md-9">
        <label for="org"> {{ 'view.admin.users.org' | translate }} </label>
        <input type="text" class="form-control" id="org" [(ngModel)]="user.info.organization">
      </div>
      <div class="form-group col-md-3">
        <label for="deactivate"> {{ 'view.admin.users.deactivate' | translate }} </label>
        <div class="input-group">
          <input type="number" class="form-control" id="deactivate" min="0" [(ngModel)]="user.info.deactivate_in">
          <div class="input-group-append">
            <div class="input-group-text"> {{ 'view.admin.users.days' | translate }} </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label> {{ 'view.admin.users.views.title' | translate }} </label>
      <div class="custom-control custom-switch" *ngFor="let v of views">
        <input type="checkbox" class="custom-control-input" id="{{v.key}}" [(ngModel)]="user.info.views[v.key]">
        <label class="custom-control-label" for="{{v.key}}">{{ v.name | translate }}</label>
      </div>
    </div>
    <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right" (click)="save()">{{
      'buttons.save' | translate }}</button>
  </fieldset>

  <div class="section mt-3" *ngIf="mode != 'new'">
    <div class="section-header">
      <h5>{{ 'view.admin.users.membership' | translate }}</h5>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="form-group">
          <app-user-memberships heading="view.admin.users.memberships.companies" [loading]="loadingCompanies" [items]="companyAccess" resourceURL="/admin/companies">
          </app-user-memberships>
        </div>
        <div class="form-group">
          <app-user-memberships heading="view.admin.users.memberships.objects" [loading]="loadingObjects" [items]="objectAccess" resourceURL="/admin/sites"></app-user-memberships>
        </div>
        <div class="form-group">
          <app-user-memberships heading="view.admin.users.memberships.networks"  [loading]="loadingNetworks" [items]="networkAccess" resourceURL="/admin/networks">
          </app-user-memberships>
        </div>
      </div>
    </div>
  </div>

</div>