import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  emailForm: EmailForm = new EmailForm;

  constructor() { }

  ngOnInit() {
  }

  changeEmail() {
    console.log(this.emailForm);
  }

}

export class EmailForm {
  email: string;
  confirm_email: string;
}