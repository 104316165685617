import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { APIUser, UserService } from '../../shared/services/user.service';
import { CompanyObjectService } from '../../shared/services/company-object.service';
import { EnergyService } from '../../shared/services/energy.service';
import { CurrencyService } from '../../shared/services/currency.service';
import { ToastService } from '../../shared/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  supportedLanguages = ['en', 'sv', 'ru'];
  selectedLanguage: string;

  faLock = faLock
  faUser = faUser

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    private _objectService: CompanyObjectService,
    private energyService: EnergyService,
    private currencyService: CurrencyService,
    private router: Router,
    private _toastService: ToastService,
  ) { }

  switchLanguage(lang: string) {
    sessionStorage.setItem("lang", lang);
    this.translate.use(lang);
    this.selectedLanguage = lang;
  }


  errors = null
  loginForm = new LoginForm;
  ngOnInit() {
    this.selectedLanguage = this.translate.currentLang;
  }

  loginUser() {
    this.errors = null
    this.authService.v2_login(this.loginForm).subscribe(
      (user: APIUser) => {
        this.authService.setLogin()
        this.authService.setClientSession(user)
        this.userService.setUserInfo(user)
        this.energyService.loadEnergyCarriers().subscribe(
          res => { }
        )
        this.currencyService.loadSupportedCurrencies().subscribe(
          res => { }
        )
        this.router.navigateByUrl('/home')
        //TODO: Error if login fails
        // if(resp.status != 'OK') {
        //   this.errors = {}
        //   let errorObj : any = {}
        //   errorObj.msg = resp.message
        //   if(resp.code == 1) {
        //     errorObj.type = 'danger'
        //   } else {
        //     errorObj.type = 'warning'
        //   }
        //   this.errors = errorObj
        // } else{
      },
      (err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 404) {
          this._toastService.loginFailed()
        } else {
          this._toastService.server()
        }
      }
    )
  }
}

export class LoginForm {
  username: string;
  password: string;
}
