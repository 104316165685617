<div class="container-fluid" *ngIf="!coordinator">
  <app-table [columns]="columns" [data]="savings" [settings]="settings" [loading]="loading" [selectors]="selectors" [actions]="actions"
    (action)="onAction($event)" (add)="onAdd($event)" (selectorChange)="onSelectorChange($event)">
    <ng-template #row let-item="item">
      <measure-details [measure]="item" [currency]="currency"></measure-details>
      <hr>
    </ng-template>
  </app-table>
</div>

<div class="container-fluid" *ngIf="coordinator">
  <app-measure-coordinator></app-measure-coordinator>
</div>