import { Component, OnInit, Input } from '@angular/core';
import { faTimes, faBackspace } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SensorService } from '../../services/sensor.service';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {
  faTimes = faTimes;
  faBackspace = faBackspace
  allowLinks: boolean
  sensorList: any


  operators = [
    { show: '+', data: '+' },
    { show: '-', data: '-' },
    { show: '×', data: '*' },
    { show: '÷', data: '/' },
    { show: '(', data: '(' },
    { show: ')', data: ')' },
    { show: 'ln', data: 'ln('}
  ]

  @Input('formula')
  formula: Formula;

  @Input('linkList')
  linkList: any

  @Input('allowSensors')
  allowSensors: boolean

  equationNames: any

  equationTrans: any = {}

  updateEquationTranslates() {
    this.equationNames = [
      { show: this.equationTrans.energy, data: "energy" },
      { show: this.equationTrans.exergy, data: "exergy" },
      { show: this.equationTrans.power, data: "power" },
      { show: this.equationTrans.pressure, data: "pressure" },
      { show: this.equationTrans.temperature, data: "temperature" },
      { show: this.equationTrans.massflow, data: "massflow" },
      { show: this.equationTrans.volumeflow, data: "volumeflow" },
      { show: this.equationTrans.tonne, data: "tonne" },
      { show: this.equationTrans.dry_solids, data: "dry_solids"},
      { show: this.equationTrans.moisture, data: "moisture"},
      { show: this.equationTrans.cubic_meter, data: "cubic_meter"}
    ]
  }

  constructor(
    private translate: TranslateService,
    private _sensorService : SensorService
  ) {
    translate.stream(['equations']).subscribe(res => {
      this.equationTrans = res['equations']
      this.updateEquationTranslates()
    });
  }
  ngOnInit() {
    this.allowLinks = (this.linkList != undefined)
    this.sensorList = this.allowSensors? this._sensorService.availableSensors() : []
  }

}

export class Formula {
  balance: number
  formula: FormulaEntry[]

  constructor() {
    this.formula = []
    this.balance = 0
  }

  validNewEntry(type : string) {
    let lastEntry = this.formula[this.formula.length-1];
    switch (type) {
      case 'equation': case 'sensor': case 'constant': case 'ln(':
        return this.formula.length == 0 || 
          lastEntry.isOperator && lastEntry.operator != ')'
      case '+': case '-': case '*': case '/':
        return this.formula.length > 0 &&
          (!lastEntry.isOperator || lastEntry.operator == ')')
      case '(':
        return this.formula.length == 0 ||
          lastEntry.isOperator && lastEntry.operator != ')'
      case ')':
        return this.formula.length > 0 &&
          (!lastEntry.isOperator || lastEntry.operator == ')') &&
          this.balance > 0
      default:
        return true
    }
  }

  addConstant() {
    if(!this.validNewEntry('constant')) return;
    let f = new FormulaEntry
    f.constant = true
    this.formula.push(f)
  }

  addLink() {
    if(!this.validNewEntry('equation')) return;
    let f = new FormulaEntry
    f.equation = true
    this.formula.push(f)
  }

  addSensor() {
    if(!this.validNewEntry('sensor')) return;
    let f = new FormulaEntry
    f.sensor = true
    this.formula.push(f)
  }

  addOperator(o: string) {
    if(!this.validNewEntry(o)) return;

    if(o == '(' || o == "ln(") this.balance++
    if(o == ')') this.balance--

    let f = new FormulaEntry
    f.isOperator = true
    f.operator = o
    this.formula.push(f)
  }

  getFormula(): FormulaEntry[] {
    return this.formula
  }

  removeLast() {
    let last = this.formula.pop()
    if(last.isOperator) {
      if(last.operator == '(' || last.operator == "ln(") this.balance--
      if(last.operator == ')') this.balance++
    }
  }

  clear() {
    this.formula = []
    this.balance = 0
  }

  toString(): string {
    let linkIndex = 1
    let sensorIndex = 1
    let expression = ""
    //let entries = formula.formula

    this.formula.forEach((e, i) => {
      if (e.constant) {
        if(e.constantValue) {
          expression += e.constantValue
        }
      } else if (e.sensor) {
        expression += "S" + sensorIndex
        sensorIndex++
      } else if (e.equation) {
        expression += "L" + linkIndex
        linkIndex++
      } else if (e.isOperator) {
        expression += e.operator
      }
    });
    return expression
  }


}

export class FormulaEntry {

  operator: string
  isOperator: true

  //Properties used for equations
  equation: boolean
  linkID: string
  equationName: string

  // Properties used for constants
  constant: boolean
  constantValue: number

  // Properties used for sensors
  sensor: boolean
  sensorID: string
}

export class FormulaConstantEntry {
  value: string
}

export class FormulaLinkEntry {
  id: string
  equation: string
}

export class FormulaSensorEntry {
  id: string
}

export class FormulaOperatorEntry {
  value: string
}
