<div id="breadcrumb">
  <div *ngFor="let h of history; let last = last">
    <span class="link" (click)="back(h)">{{h}}</span>
    <span *ngIf="!last" class="separator">/</span>
  </div>
</div>

<div class="container-fluid">
  <div class="row top-panel">
    <div class="col-md-3">
      <ng-select placeholder="{{ 'view.sites.advanced.settings.process' | translate }}" [items]="processDropDown"
        [(ngModel)]="selectedComponent" (change)="onProcessChange($event)"></ng-select>
    </div>

    <div class="col-md-3">
      <button (click)="save()" class="btn btn-outline-primary btn-sm">{{ 'view.sites.advanced.settings.save' | translate
        }}</button>
    </div>

  </div>

  <div class="row" *ngIf="selectedComponent">
    <div class="col-md-12 max-height">
      <ngb-tabset>
        <ngb-tab title="{{ 'view.sites.advanced.settings.in' | translate }}">
          <ng-template ngbTabContent>
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th scope="col">{{ 'view.sites.advanced.settings.type' | translate }}</th>
                  <th scope="col">{{ 'view.sites.advanced.settings.process' | translate }}</th>
                  <th scope="col">{{ 'view.sites.advanced.settings.flow' | translate }}</th>
                  <th scope="col">{{ 'view.sites.advanced.settings.equations' | translate }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let e of currentViewLinkData | filterBy: {'target' : currentViewName}">
                  <td class="table-process-dropdown">
                    <ng-select [items]="typesDropDown" [(ngModel)]="e.type"></ng-select>
                  </td>
                  <td class="table-process-dropdown">
                    <ng-select [items]="linkProcessDropDown" [(ngModel)]="e.source"></ng-select>
                  </td>
                  <td class="table-process-dropdown">
                    <ng-select [items]="flowsDropDown" [closeOnSelect]="false" [multiple]="true" [searchable]="true"
                      [(ngModel)]="e.flow"></ng-select>
                  </td>
                  <td>
                    <button class="btn btn-outline-primary btn-sm" (click)="editSensors(formulaModal, e)">
                      {{ 'view.sites.advanced.settings.edit' | translate }}
                    </button>
                  </td>
                  <td>
                    <span class="remove-icon">
                      <fa-icon [icon]="faTimes" (click)="deleteLink(deleteModal, e.uuid)"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button (click)="addLink('target')" class="btn btn-outline-primary btn-sm">{{
              'view.sites.advanced.settings.add-link' | translate }}</button>

          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{ 'view.sites.advanced.settings.out' | translate }}">
          <ng-template ngbTabContent>
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th scope="col">{{ 'view.sites.advanced.settings.type' | translate }}</th>
                  <th scope="col">{{ 'view.sites.advanced.settings.process' | translate }}</th>
                  <th scope="col">{{ 'view.sites.advanced.settings.flow' | translate }}</th>
                  <th scope="col">Equations</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let e of currentViewLinkData | filterBy: {'source' : currentViewName}">
                  <td class="table-process-dropdown">
                    <ng-select [items]="typesDropDown" [(ngModel)]="e.type"></ng-select>
                  </td>
                  <td class="table-process-dropdown">
                    <ng-select [items]="linkProcessDropDown" [(ngModel)]="e.target"></ng-select>
                  </td>
                  <td class="table-process-dropdown">
                    <ng-select [items]="flowsDropDown" [closeOnSelect]="false" [multiple]="true" [searchable]="true"
                      [(ngModel)]="e.flow"></ng-select>
                  </td>
                  <td>
                    <button class="btn btn-outline-primary btn-sm" (click)="editSensors(formulaModal, e)">
                      {{ 'view.sites.advanced.settings.edit' | translate }}
                    </button>
                  </td>
                  <td>
                    <span class="remove-icon">
                      <fa-icon [icon]="faTimes" (click)="deleteLink(deleteModal, e.uuid)"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button (click)="addLink('source')" class="btn btn-outline-primary btn-sm">{{
              'view.sites.advanced.settings.add-link' | translate }}</button>
          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{ 'view.sites.advanced.settings.kpi_tab' | translate }}">
          <ng-template ngbTabContent>
            <div class="tab-content">
              <app-keyvalentry [currentView]="currentView" [linkList]="keyFigureLinks"></app-keyvalentry>
            </div>
          </ng-template>
        </ngb-tab>

        <ngb-tab title="{{ 'view.sites.advanced.settings.sub-processes' | translate }}">
          <ng-template ngbTabContent>
            <div *ngIf="!hasSubprocesses()">
              <span>{{ 'view.sites.advanced.settings.no-sub-processes' | translate }}</span>
            </div>
            <div id="subprocess-list" *ngIf="hasSubprocesses()">
              <span class="link" *ngFor="let s of currentView.labels | keyvalue"
                (click)="toSubprocess(s.key)">{{s.key}}</span>
            </div>
          </ng-template>
        </ngb-tab>

      </ngb-tabset>
    </div>
  </div>
</div>


<!-- UNSAVED MODAL TEMPLATE -->
<ng-template #unsavedModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Unsaved changed</h4>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm delete</h4>
  </div>
  <div class="modal-body">
    <p class="font-weight-bold">Deleting a link will remove all the data points associated with the link.</p>
    <p>Are you sure you want to delete the link?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('delete')">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('cancel')">Cancel</button>
  </div>
</ng-template>

<ng-template #formulaModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Equations</h4>
  </div>
  <div class="modal-body">
    <ngb-tabset (tabChange)="beforeTabChange($event)">
      <ngb-tab id="energy-tab" title="{{ 'general.equations.energy' | translate }}">
        <ng-template ngbTabContent>
          <div class="container mt-3">
            <div class="row ml-auto">
              <app-formula [formula]="editLink.equations.energy" allowSensors="true" class="w-100"></app-formula>
            </div>
          </div>
        </ng-template>
      </ngb-tab>

      <ngb-tab id="exergy-tab" title="{{ 'general.equations.exergy' | translate }}">
        <ng-template ngbTabContent>
          <div class="container mt-3">
            <div class="row ml-auto">
              <app-formula [formula]="editLink.equations.exergy" allowSensors="true" class="w-100"></app-formula>
            </div>
          </div>
        </ng-template>
      </ngb-tab>

      <ngb-tab id="extra-tab-{{i}}" *ngFor="let e of editLink.equations.extras; let i = index;" title="{{e.name.show}}">
        <ng-template ngbTabContent>
          <div class="container mt-3">
            <div class="row justify-content-between mb-3">
              <div class="form-group col-6">
                <label>Equation name</label>
                <ng-select [items]="equationNames" [(ngModel)]="e.name" bindLabel="show"></ng-select>
              </div>
              <button class="btn btn-outline-danger btn-sm fixed-button-height"
                (click)="removeEquation(i)">Remove</button>
            </div>
            <div class="row ml-auto">
              <app-formula [formula]="e" allowSensors="true" class="w-100"></app-formula>
            </div>
          </div>
        </ng-template>
      </ngb-tab>

      <ngb-tab id="formula-modal-add-tab" title="+">
        <ng-template ngbTabContent>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close('save')">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('cancel')">Cancel</button>
  </div>
</ng-template>
