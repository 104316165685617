<div class="container-fluid">
  <div class="row">
    <div class="col-md-2 top-nav bg-light">
      <nav class="navbar">
        <div routerLink="/home" class="clickable w-100 d-none d-xl-block">
          <img id="logo" src="assets/nead-logo.svg">
        </div>
        <div routerLink="/home" class="clickable w-100 d-xl-none d-flex justify-content-center">
          <img id="logo" src="assets/nea-symbol.svg">
        </div>
      </nav>
    </div>
    <div class="col-md-10 top-nav">
      <nav class="navbar bg-light">
        <div></div> <!-- Forces title to be centered -->
        <h4>
          <fa-icon class="mr-3" *ngIf="title.icon" [icon]="title.icon"></fa-icon>
          {{ title.title | translate }}
        </h4>
        <div>
          <app-user-menu *ngIf="!userInfo?.info.is_public" [icon]="userMenu"></app-user-menu>
        </div>
      </nav>
    </div>
  </div>

  <div class="row">
    <!-- SIDENAV -->
    <div class="col-md-2" id="sidenav">
      <app-sidenav [menuMode]="sidenavMode" [resourceID]="resourceID"></app-sidenav>
    </div>

    <!-- VIEW -->
    <div class="col-md-10" id="viewer">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
