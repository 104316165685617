<!-- TEMPLATE FOR A DATE IN THE CALENDAR VIEW -->
<ng-template #t let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
<div class="calendar-wrapper" [class.allow-span]="allowRange">
  <div ngbDropdown #drop="ngbDropdown" [autoClose]="false">
    <div ngbDropdownMenu class="p-0 border-0">
      <ngb-datepicker (select)="onDateSelection($event, drop)" [displayMonths]="2" [dayTemplate]="t"
        outsideDays="hidden">
      </ngb-datepicker>
    </div>
    <div class="input-group" *ngIf="!onlyYear">
      <div class="input-group-prepend">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <fa-icon [icon]="faCalendarAlt"></fa-icon>
        </button>
      </div>
      <input type="text" class="form-control" *ngIf="allowRange"
        value="{{ formatDate(fromDate) }} - {{ formatDate(toDate) }}" [disabled]="true">
      <input type="text" class="form-control" *ngIf="!allowRange" value="{{ formatDate(fromDate) }}" [disabled]="true">
    </div>
  </div>

  <ng-select *ngIf="onlyYear" appendTo="body" [items]="years" placeholder="{{ 'date-span.select-year' | translate }}"
    (change)="onYearChange($event)" [(ngModel)]="selectedYears" [multiple]="allowRange" [closeOnSelect]="!allowRange">
  </ng-select>
</div>