<div class="container-fluid pl-0 pr-0">
  <div class="d-flex justify-content-center">
    <div class="card border-primary mr-3">
      <div class="card-header bg-primary text-white" routerLink="energydata/supplied">
        <span>{{ 'view.sites.overview.supplied.title' | translate }}</span>
        <app-tooltip keepFontColor="true" placement="right"
          text="{{ 'view.sites.overview.supplied.tooltip' | translate }}"></app-tooltip>
      </div>
      <div class="card-body">
        <div class="d-flex flex-column" *ngIf="suppliedData.length == 0">
          <span>{{ 'view.sites.overview.supplied.no-supplied' | translate }}</span>
          <span class="link align-self-center" routerLink="energydata/supplied">
            {{ 'view.sites.overview.supplied.start' | translate }}
          </span>
        </div>
        <div *ngIf="suppliedData.length > 0">
          <div class="overflow-auto">
            <table class="table table-borderless mb-0">
              <thead>
                <tr>
                  <th scope="col" class="align-top">
                    <div>{{ 'view.sites.overview.supplied.carriers' | translate }}</div>
                  </th>
                  <th scope="col" *ngFor="let y of suppliedYears">
                    <div>{{ y }}</div>
                    <div class="table-header-unit">[MWh]</div>
                  </th>
                  <th scope="col">
                    <div>{{ 'view.sites.overview.supplied.change' | translate }}</div>
                    <div class="table-header-unit">[%]</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of suppliedData">
                  <td>{{ 'energycarriers.'+s.model | translate }}</td>
                  <td *ngFor="let d of s.data">{{ d }}</td>
                  <td>
                    <div class="text-nowrap">
                      <fa-icon *ngIf="s.change != 0" class="mr-2" [icon]="faArrow"
                        [ngClass]="{ 'increase': s.change > 0, 'decrease': s.change < 0}"></fa-icon>
                      <span class="font-italic font-weight-light" *ngIf="s.change != 0 && s.change != 'Infinity'">{{
                        s.change | number : '1.0-0' }}</span>
                      <span *ngIf="s.change == 0 || s.change == 'Infinity'">-</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="p-0">
                    <hr>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'view.sites.overview.supplied.mapped' | translate }}</td>
                  <td *ngFor="let b of balanceData">
                    <div class="text-nowrap">
                      <fa-icon [icon]="faCircle" class="mr-2" size="xs"
                        [ngClass]="{'good': b > 95, 'ok': b >= 90 && b <= 95, 'bad': b < 90}"></fa-icon>
                      <span class="font-italic font-weight-light">{{ b | number : '1.0-0' }} %</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- MEASURE PANEL -->
    <app-table [columns]="measureColumns" [data]="savings" [settings]="measureTableSettings" [loading]="measureLoading">
    </app-table>

  </div>
</div>
