import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnergyUnit } from '../../services/energy.service';

@Component({
  selector: 'app-energy-input',
  templateUrl: './energy-input.component.html',
  styleUrls: ['./energy-input.component.scss']
})
export class EnergyInputComponent implements OnInit {

  @Input('value')
  value: number;

  @Output('valueChange')
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input('disabled')
  disabled: boolean;

  @Input('unit')
  unit: EnergyUnit

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange() {
    this.valueChange.emit(this.value)
  }

}
