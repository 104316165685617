import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Chart, ChartData, ChartDataSets } from 'chart.js';
import { EnergyService, EnergyCarrier } from '../../../shared/services/energy.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Saving } from '../../../shared/services/savings.service';
import { Currency } from '../../../shared/services/currency.service';


@Component({
  selector: 'measure-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  @Input() measure: Saving;

  @Input() currency: Currency;

  carrierTrans: any;

  // Energy Carrier Pie chart
  @ViewChild('chart', { static: true }) chartRef: ElementRef;
  chart: Chart;
  chartData: ChartData = {}
  carriers: EnergyCarrier[] = [];
  axesLabel: string = "MWh"

  constructor(
    private _energyService: EnergyService,
    private translate: TranslateService) {
    translate.stream('energycarriers').subscribe(res => {
      this.carrierTrans = res
      let labels: string[] = []
      this.carriers.forEach((f: EnergyCarrier) => {
        labels.push(this.carrierTrans[f.model])
      });
      this.chartData.labels = labels
      if (this.chart) this.chart.update();
    });
  }

  ngOnInit() {
    this.carriers = this._energyService.v2_getEnergyCarriers()
    this.initChart()
    this.loadEnergyData()
  }

  initChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: this.chartData,
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.axesLabel,
            }
          }]
        }
      }
    });
  }

  loadCo2Data() {
    let dataset: ChartDataSets = {}
    dataset.data = []
    dataset.backgroundColor = []
    let labels: string[] = []
    let colors: string[] = []

    this.carriers.forEach((f: EnergyCarrier) => {
      labels.push(this.carrierTrans[f.model])
      dataset.data.push(f.co2 * this.measure.carriers[f.model])
      colors.push(f.graph_color)
    });

    dataset.backgroundColor = colors
    this.chartData.datasets = [dataset]
    this.chartData.labels = labels
    this.chart.options.scales.yAxes[0].scaleLabel.labelString = this.axesLabel
    this.chart.update()
  }

  loadEnergyData() {
    let dataset: ChartDataSets = {}
    dataset.data = []
    dataset.backgroundColor = []
    let labels: string[] = []
    let colors: string[] = []

    this.carriers.forEach((f: EnergyCarrier) => {
      labels.push(this.carrierTrans[f.model])
      dataset.data.push(this.measure.carriers[f.model])
      colors.push(f.graph_color)
    });

    dataset.backgroundColor = colors
    this.chartData.datasets = [dataset]
    this.chartData.labels = labels
    this.chart.options.scales.yAxes[0].scaleLabel.labelString = this.axesLabel
    this.chart.update()
  }

  onTabChange(event: NgbTabChangeEvent) {
    if (event.nextId == "co2") {
      this.axesLabel = "kg CO\u2082"
      this.loadCo2Data()
    } else {
      this.axesLabel = "MWh"
      this.loadEnergyData()
    }
  }

}
