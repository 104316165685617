<app-benchmark-filter (onSubmit)="benchmark($event)" coordinatorMode="true" context="network"></app-benchmark-filter>

<!-- CHART CONTAINER -->
<div class="benchmark-graph-container mt-5" [hidden]="loadingGraph">
    <canvas #chartCanvas id="benchmark-chart">{{ chart }}</canvas>
</div>

<!-- RESULT CONTAINER -->
<div class="result-text-container" *ngIf="resultReady">
    <ngb-alert *ngIf="failedCompanies.length" [dismissible]="false">
        <fa-icon [icon]="warningIcon"></fa-icon>
        {{ 'view.networks.benchmark.missing-data.msg' | translate }}
        <span class="float-right text-primary clickable" [ngbPopover]="popContent"
            [popoverTitle]="popTitle" placement="right">{{ 'view.networks.benchmark.missing-data.more' | translate }}</span>
    </ngb-alert>

    {{ 'view.networks.benchmark.result.a' | translate }} <b>{{ 'view.networks.benchmark.result.b' | translate }}
        {{ this.filterSelection.objects[0].sni_data.code | slice:0:2 }}xxx
        <fa-icon class="sni-icon" [icon]="faQuestionCircle" [ngbPopover]="sniInfoDescTmpl"
            triggers="mouseenter:mouseleave" popoverTitle="{{ 'benchmark.nace' | translate }}"></fa-icon>
    </b>,
    <b>{{ resultText.nr }}</b> {{ 'view.networks.benchmark.result.c' | translate }}
    <div class="chart-info-container">
        <div class="chart-info-row">
            <div class="outside-color-info"></div><span>{{ 'benchmark.outside' | translate }} {{ 'view.networks.benchmark.result.type' | translate }}</span>
        </div>
        <div class="chart-info-row">
            <div class="good-color-info"></div><span>{{ 'benchmark.good' | translate }}</span>
        </div>
        <div class="chart-info-row">
            <div class="ok-color-info"></div><span>{{ 'benchmark.ok' | translate }}</span>
        </div>
        <div class="chart-info-row">
            <div class="bad-color-info"></div><span>{{ 'benchmark.bad' | translate }}</span>
        </div>
    </div>
</div>



<ng-template #sniInfoDescTmpl>
    <div>{{ currentSNI1 }}</div>
    <div class="ml-2">{{ currentSNI2 }}</div>
</ng-template>

<ng-template #popTitle><span class="text-dark">{{ 'view.networks.benchmark.missing-data.excluded' | translate }}</span></ng-template>
<ng-template #popContent>
    <ul class="list-unstyled">
        <li *ngFor="let f of failedCompanies">{{ f }}</li>
    </ul>
</ng-template>