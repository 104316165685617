import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash'
import { APIMember } from './network.service';
import { resourceIDFromURL } from './common/resourceIDFromURL';
import { ToolbarService } from './toolbar.service';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import { CO2APIModel, EnergyService, SuppliedAPIModel, SuppliedEnergyForm } from './energy.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyObjectService {

  baseURL: string = "/api/v2/objects"

  private selectedObject: APIObjectDetailed

  constructor(
    private http: HttpClient,
    private _toolbarService: ToolbarService,
    private _energyService: EnergyService
  ) { }

  // Runs when the page is refreshed to preload the selected object from URL
  preloadSelectedObject(): Observable<APIObjectDetailed> {
    let objectID = resourceIDFromURL("sites")
    if (objectID) {
      return this.selectObject(Number(objectID))
    }
    return of(new APIObjectDetailed)
  }

  currentObject(): APIObjectDetailed {
    return this.selectedObject
  }

  selectObject(objectID: number): Observable<APIObjectDetailed> {
    return this.get(objectID).pipe(
      map((data: APIObjectDetailed) => {
        this.selectedObject = data
        this._toolbarService.setTitle({ title: data.name, icon: faIndustry })
        return data
      })
    )
  }

  getUserObjects(userID: number): Observable<APIObjectDetailed[]> {
    return this.http.get(`/api/v2/objects/detailed`).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    )
  }

  getDetails(id: number, year?: number): Observable<APIObjectDetails> {
    let params = new HttpParams();
    params = year ? params.append('year', String(year)) : params
    return this.http.get(`/api/v2/objects/${id}/details`, { params: params }).pipe(
      map((res: any) => {
        return res[year]
      })
    );
  }

  postDetails(id: number, data: ObjectDetailsForm): Observable<APIObjectDetails> {
    return this.http.post(`/api/v2/objects/${id}/details`, data).pipe(
      map((res: APIObjectDetails) => {
        console.log("company details saved")
        return res
      })
    )
  }

  list(): Observable<APIObjectSimple[]> {
    return this.http.get("/api/v2/objects").pipe(
      map((res: APIObjectSimple[]) => { return res })
    )
  }

  adminList(): Observable<APIObjectSimple[]> {
    return this.http.get("/api/v2/admin/objects").pipe(
      map((res: APIObjectSimple[]) => { return res })
    )
  }

  adminListDetailed(): Observable<APIObjectDetailed[]> {
    return this.http.get("/api/v2/admin/objects/detailed").pipe(
      map((res: APIObjectDetailed[]) => { return res })
    )
  }

  v2_listCompaniesDetailed(): Observable<APIObjectDetailed[]> {
    return this.http.get("/api/v2/objects/detailed").pipe(
      map((res: APIObjectDetailed[]) => { return res })
    )
  }

  get(id: number): Observable<APIObjectDetailed> {
    return this.http.get(`/api/v2/objects/${id}`).pipe(
      map((res: APIObjectDetailed) => { return res })
    )
  }

  create(data: APIObjectSimple | APIObjectDetailed): Observable<APIObjectDetailed> {
    if (data instanceof APIObjectDetailed) data = data.toSimple()
    return this.http.post("/api/v2/objects/", data).pipe(
      map((res: APIObjectDetailed) => { return res })
    )
  }

  update(id: number, data: APIObjectSimple | APIObjectDetailed): Observable<APIObjectDetailed> {
    if (data instanceof APIObjectDetailed) data = data.toSimple()
    return this.http.put(`/api/v2/objects/${id}`, data).pipe(
      map((res: APIObjectDetailed) => { return res; })
    )
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/objects/${id}`)
  }

  setLicense(id: number, licenseID: number): Observable<any> {
    return this.http.put(`/api/v2/admin/objects/${id}/license/${licenseID}`, null)
  }

  v2_createDemoCompany(template: number, name: string): Observable<APIObjectDetailed> {
    return this.http.post("/api/v2/objects/demo", { "template": template, "name": name }).pipe(
      map((res: APIObjectDetailed) => { return res; })
    )
  }

  getMembers(id: number): Observable<APIMember[]> {
    return this.http.get(`${this.baseURL}/${id}/members`).pipe(
      map((res: APIMember[]) => { return res })
    );
  }

  addMembers(id: number, data: number[]): Observable<APIMember[]> {
    return this.http.post(`${this.baseURL}/${id}/members`, { members: data }).pipe(
      map((res: APIMember[]) => { return res })
    );
  }

  removeMembers(id: number, data: number[]): Observable<APIMember[]> {
    const options = { headers: { 'Content-Type': 'application/json' }, body: { members: data } }
    return this.http.request("delete", `${this.baseURL}/${id}/members`, options).pipe(
      map((res: APIMember[]) => { return res })
    )
  }

  //TODO: Just added to fit interface for membership manager component
  getObjects(id: number): Observable<APIObjectDetailed[]> {
    return of([])
  }

  //TODO: Just added to fit interface for membership manager component
  addObjects(id: number, data: number[]): Observable<APIObjectDetailed[]> {
    return of([])
  }

  //TODO: Just added to fit interface for membership manager component
  removeObjects(id: number, data: number[]): Observable<APIObjectDetailed[]> {
    return of([])
  }

  private fetchSupplied(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/supplied`, { params: params }).pipe(
      map(data => {
        return Object.values(data)
      })
    )
  }

  private fetchC02(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/co2`, { params: params }).pipe(
      map(data => {
        return Object.values(data)
      })
    )
  }

  supplied(id: number, years: number[]): Observable<SuppliedEnergyForm[]> {
    let params = new HttpParams();
    params = params.append('year', years.toString())
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: SuppliedAPIModel[] = data[0]
        let co2: CO2APIModel[] = data[1]
        return this._energyService.toSuppliedForms(id, supplied, co2)
      })
    )
  }

  suppliedLatest(id: number, latest: number = 1): Observable<SuppliedEnergyForm[]> {
    let params = new HttpParams();
    params = params.append('latest', String(latest))
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: SuppliedAPIModel[] = data[0]
        let co2: CO2APIModel[] = data[1]
        return this._energyService.toSuppliedForms(id, supplied, co2)
      })
    )
  }


}



export class APIObjectDetails {
  id: number;
  year: number;
  area: number;
  heated_area: number;
  prod_hours: number;
  unit: string;
  units: number;
  turnover: number;
  employees: number;
  company: number;
}

export class ObjectDetailsForm extends APIObjectDetails { }

export class APIObjectSimple {
  id: number
  name: string
  org_nr: string
  cfar_id: string
  sni: string
  location: number
  demo: boolean
  template: boolean
}

export class APIObjectDetailed {
  id: number
  name: string
  org_nr: string
  cfar_id: string
  demo: boolean
  template: boolean
  sni_data: {
    section: number
    division: number
    group: number
    class: number
    detail: number
    code: string
  }
  geo: {
    country: {
      id: number
      name: string
      code: string
    },
    state: {
      id: number
      name: string
    },
    municipality: {
      id: number
      name: string
    }
  }
  latest_supplied: number
  latest_used: number
  mapped: number
  company: number
  company_name: string
  config: {
    id: number
    license: number
  }
  views: {} = {}

  constructor(obj?: any) {
    if (obj) {
      _.forOwn(obj, (val, key) => {
        this[key] = val
      })
    }
  }

  toSimple(): APIObjectSimple {
    let res = new APIObjectSimple
    res.id = this.id
    res.name = this.name
    res.org_nr = this.org_nr
    res.cfar_id = this.cfar_id
    res.sni = this.sni_data.code
    res.location = this.geo.municipality.id
    res.demo = this.demo
    res.template = this.template
    return res
  }

}
