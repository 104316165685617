import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-present-value',
  templateUrl: './present-value.component.html',
  styleUrls: ['./present-value.component.scss']
})
export class PresentValueComponent implements OnInit {

  presentValue: PresentValueVars = new PresentValueVars;
  range: number[];
  faTooltip = faQuestionCircle

  constructor() { }

  ngOnInit() {
    this.presentValue.in = [];
    this.presentValue.out = [];
    this.presentValue.ans = [];
  }

  calcPresentValue() {
    let denom = 1;
    if (this.presentValue.interest) {
      denom = 1 + (this.presentValue.interest / 100);
    }

    if (this.presentValue.restValue == undefined) {
      this.presentValue.ans[0] = 0;
    } else if (this.presentValue.years == undefined) {
      this.presentValue.ans[0] = this.presentValue.restValue / Math.pow(denom, 0);
    } else {
      this.presentValue.ans[0] = this.presentValue.restValue / Math.pow(denom, this.presentValue.years);
    }

    for (let i = 1; i <= this.presentValue.years; i++) {
      let inValue = this.presentValue.in[i] ? this.presentValue.in[i] : 0;
      let outValue = this.presentValue.out[i] ? this.presentValue.out[i] : 0;
      this.presentValue.ans[i] = (inValue - outValue) / Math.pow(denom, i);
    }

  }

  getAnswer() {
    return _.sum(this.presentValue.ans)
  }

  setRange() {
    let tmp = this.presentValue.years;
    if (tmp == undefined) {
      tmp = 0;
    }
    this.range = new Array(tmp);
    this.presentValue.in = new Array(tmp);
    this.presentValue.out = new Array(tmp);
  }
}

export class PresentValueVars {
  in: number[];
  out: number[];
  restValue: number;
  years: number;
  interest: number;
  ans: number[];
}
