<div class="card m-2">
  <div class="card-body">
    <h6 class="card-title">
      {{ modelString+data.model | translate }}
      <app-tooltip *ngIf="!skipTooltip" type="info" text="{{ modelString+'tooltips.'+data.model | translate }}"></app-tooltip>
    </h6>

    <div class="input-group">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="icon"></fa-icon>
        </span>
      </div> -->
      <input type="number" class="form-control" min="0" [(ngModel)]="data.amount" disabled>
      <div class="input-group-append">
        <span class="input-group-text">{{ unit }}</span>
        <button type="button" class="btn btn-primary" *ngIf="!inputDisable" (click)="open(modal)">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
        </button>
      </div>
    </div>

    <p class="card-text pt-2" *ngIf="hasCo2"><small class="text-muted">
        <fa-icon [icon]="faLeaf"></fa-icon>
        {{ data.co2 }} kg CO<sub>2</sub>/MWh
      </small>
    </p>
  </div>
</div>

<!-- MODAL -->
<ng-template #modal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ modelString+data.model | translate }}
    </h5>
  </div>
  <div class="modal-body">
    <div class="input-group mb-3">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="icon"></fa-icon>
        </span>
      </div> -->
      <input type="number" class="form-control" min="0" [(ngModel)]="modalData.amount">
      <div class="input-group-append">
        <span class="input-group-text">{{ unit }}</span>
      </div>
    </div>
    <div class="input-group" *ngIf="hasCo2">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="faLeaf"></fa-icon>
        </span>
      </div>
      <input type="number" class="form-control" min="0" [(ngModel)]="modalData.co2">
      <div class="input-group-append">
        <span class="input-group-text">kg CO<sub>2</sub>/MWh</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss()">{{ 'buttons.close' | translate }}</button>
    <button type="button" class="btn btn-outline-primary" (click)="save(modal)">{{ 'buttons.save' | translate }}</button>
  </div>
</ng-template>