import { Component, OnInit } from '@angular/core';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { FilterChangeEvent } from '../../../../shared/components/company-filter/company-filter.component';
import { TableColumn, TableSettings, TableSelector, SelectorEvent } from '../../../../shared/components/table/table.component';
import { APICategory } from '../../../../shared/services/category.service';
import { APIObjectDetailed} from '../../../../shared/services/company-object.service';
import { Currency, CurrencyService } from '../../../../shared/services/currency.service';
import { APINetwork, NetworkConfig, NetworkSavings, NetworkService } from '../../../../shared/services/network.service';
import { APISavingModel } from '../../../../shared/services/savings.service';

@Component({
  selector: 'app-network-measure',
  templateUrl: './network-measure.component.html',
  styleUrls: ['./network-measure.component.scss']
})
export class NetworkMeasureComponent implements OnInit {

  network: APINetwork
  objects: APIObjectDetailed[] = [];
  selectedSavings: ObjectSaving[] = [];
  allSavings: ObjectSaving[] = [];

  categories: APICategory[] = [];

  config: NetworkConfig

  faAngleDown = faAngleUp;
  faAngleUp = faAngleDown;

  columns: TableColumn[] = [
    new TableColumn("view.networks.savings.site", "company_object.name"),
    new TableColumn("view.networks.savings.name", "name"),
    new TableColumn("view.networks.savings.category", "category_name"),
    new TableColumn("view.networks.savings.savings", "carriers.total", true, false, "", "units.energy-year"),
    new TableColumn("view.networks.savings.cost", "cost", true, false, "", "units.cost"),
    new TableColumn("view.networks.savings.proposed", "date_proposed", true, false),
    new TableColumn("view.networks.savings.planned", "date_planned", true, false),
    new TableColumn("view.networks.savings.implemented", "date_implemented", true, false),
    new TableColumn("view.networks.savings.climate", "climate_adapted", false, false),
  ];
  settings: TableSettings = new TableSettings("view.networks.savings.title", true, true, true, false, true);
  selectors: TableSelector[] = []

  loading: boolean = true;

  currency: Currency

  constructor(
    private _currencyService: CurrencyService,
    private _networkService: NetworkService,
  ) {}

  ngOnInit() {
    this.setTableSelectors()
    this.setTableColumns()
    this.objects = this._networkService.currentObjects()
    this.network = this._networkService.currentNetwork()
    this.config = this._networkService.currentConfig()
    this.getSavings(sessionStorage.getItem("lang"));
  }

  onSelectorChange(event: SelectorEvent) {
    if (event.selectorID == "currency") {
      this.getSavings(sessionStorage.getItem("lang"), event.new)
      this.currency = this._currencyService.getCurrency(event.new)
      this.setTableColumns()
    }
  }

  setTableSelectors() {
    let currencySelector = new TableSelector("currency", this._currencyService.supported.map(c => c.code))
    this.selectors = [currencySelector]
    this.currency = this._currencyService.getCurrency(currencySelector.current)
  }

  setTableColumns() {
    this.columns = [
      new TableColumn("view.networks.savings.site", "company_object.name"),
      new TableColumn("view.networks.savings.name", "name"),
      new TableColumn("view.networks.savings.category", "category_name"),
      new TableColumn("view.networks.savings.savings", "carriers.total", true, false, "", "units.energy-year"),
      new TableColumn("view.networks.savings.cost", "cost", true, false, "", this.currency.code),
      new TableColumn("view.networks.savings.proposed", "date_proposed", true, false),
      new TableColumn("view.networks.savings.planned", "date_planned", true, false),
      new TableColumn("view.networks.savings.implemented", "date_implemented", true, false),
      new TableColumn("view.networks.savings.climate", "climate_adapted", false, false),
    ];
  }

  getSavings(lang?: string, currency?: string) {

    this.allSavings = [];
    this.selectedSavings = [];
    console.log(this.objects)

    this._networkService.savings(this.network.id, this.objects.map(e => e.id), lang, currency).subscribe(
      (data: NetworkSavings) => {
        for (const [key, value] of Object.entries(data)) {
          value.forEach(s => {
            let objectSaving = new ObjectSaving(s)
            objectSaving.company_object = _.find(this.objects, ['id', Number(key)])
            this.allSavings.push(objectSaving);
          })
        }
        this.selectedSavings = this.allSavings;
        this.loading = false;
      }
    )
  }

  filterChange(event: FilterChangeEvent) {
    if (this.objects != event.objects || this.categories != event.categories) {
      this.selectedSavings = this.allSavings;
      this.objects = event.objects;
      this.selectedSavings = this.companiesFilter(this.objects, this.selectedSavings);
      this.categories = event.categories;
      this.selectedSavings = this.categoriesFilter(this.categories, this.selectedSavings);
    }
  }

  companiesFilter(companies: APIObjectDetailed[], savings: ObjectSaving[]): ObjectSaving[] {
    if (_.isEmpty(companies)) { return savings; }
    return savings.filter(saving => { return _.some(companies, ["id", saving.company_object.id]); });
  }

  categoriesFilter(categories: APICategory[], savings: ObjectSaving[]): ObjectSaving[] {
    if (_.isEmpty(categories)) { return savings; }
    return savings.filter(saving => { return _.some(categories, ["id", saving.category]); });
  }

}

export class ObjectSaving extends APISavingModel {
  company_object: APIObjectDetailed

  constructor(obj?: any) {
    super(obj)
  }
}
