import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIObjectDetailed, CompanyObjectService } from '../../../../../shared/services/company-object.service';
import { APIObjectLicense, LicenseService } from '../../../../../shared/services/license.service';
import { Country, LocationService, Municipality, State } from '../../../../../shared/services/location.service';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
  selector: 'app-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.scss']
})
export class ObjectFormComponent implements OnInit {

  mode: string
  object: APIObjectDetailed = new APIObjectDetailed

  readonly: boolean = true

  countries: Country[];
  states: State[];
  municipalities: Municipality[];

  licenses$: Observable<APIObjectLicense[]>

  constructor(
    private activatedRoute: ActivatedRoute,
    private _locationService: LocationService,
    private _objectService: CompanyObjectService,
    private _licenseService: LicenseService,
    private _toastService: ToastService,
    private _router: Router,
  ) { }

  ngOnInit(): void {

    this.licenses$ = this._licenseService.objectList()

    this.object.sni_data = {
      section: undefined,
      division: undefined,
      group: undefined,
      class: undefined,
      detail: undefined,
      code: undefined
    }
    this.object.geo = {
      country: { id: undefined, name: undefined, code: undefined },
      state: { id: undefined, name: undefined },
      municipality: { id: undefined, name: undefined }
    }

    this._locationService.getCountries().subscribe((countries: Country[]) => {
      this.countries = countries;
    });

    this.activatedRoute.params.subscribe(
      data => {
        if (data["id"] == undefined) {
          return
        }
        this._objectService.get(data["id"]).subscribe(
          (data: APIObjectDetailed) => {
            this.object = new APIObjectDetailed(data)
            this._locationService.getStates(this.object.geo.country.id).subscribe((states: State[]) => {
              this.states = states;
            })
            this._locationService.getMunicipalities(this.object.geo.country.id, this.object.geo.state.id).subscribe((municipalities: Municipality[]) => {
              this.municipalities = municipalities;
            })
          }
        )
      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        console.log(this.mode)
        if (this.mode != "view") {
          this.readonly = false
        }
      }
    )
  }

  clearState() {
    this.states = [];
    this.object.geo.state.id = undefined;
  }

  clearMunicipality() {
    this.municipalities = [];
    this.object.geo.municipality.id = undefined;
  }

  countryChange(country: Country) {
    this.clearState();
    this.clearMunicipality();
    this._locationService.getStates(country.id).subscribe((states: State[]) => {
      this.states = states;
    });
  }

  stateChange(state: State) {
    this.clearMunicipality();
    this._locationService.getMunicipalities(this.object.geo.country.id, state.id).subscribe((municipalities: Municipality[]) => {
      this.municipalities = municipalities;
    });
  }

  save() {
    if (this.mode == "new") {
      this.create(this.object)
    } else if (this.mode == "edit") {
      this.update(this.object.id, this.object)
    }
  }

  create(o: APIObjectDetailed) {
    this._objectService.create(o).subscribe(
      data => {
        this._router.navigateByUrl(`/admin/sites/${data.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

  update(id: number, o: APIObjectDetailed) {
    this._objectService.update(id, o).subscribe(
      (data: APIObjectDetailed) => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

  updateLicense() {
    this._objectService.setLicense(this.object.id, this.object.config.license).subscribe(
      data => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      }
    );
  }

}
