<div id="wrapper">
  <div class="row">
    <div class="col-md-12" id="top-nav">
      <nav class="navbar navbar-light bg-light">
        <img id="logo" class="ml-3" src="assets/nead-logo.svg">
        <div>
          <div class="nav-settings">
            <ng-select [items]="supportedLanguages" (change)="switchLanguage($event)" [clearable]="false" placeholder="{{ 'view.login.language' | translate }}"
              [(ngModel)]="selectedLanguage"></ng-select>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="background">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card panel-login">
            
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <!-- LOGIN FORM -->
                  <form id="login-form" role="form" (submit)="loginUser()">
                    <div class="form-group in-panel-body">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="user-addon">
                          <fa-icon [icon]="faUser"></fa-icon>
                        </span>
                        <input type="text" name="username" id="username" tabindex="1" class="form-control" placeholder="{{ 'view.login.email' | translate }}"
                          value="" aria-describedby="user-addon" [(ngModel)]="loginForm.username">
                      </div>
                    </div>

                    <div class="form-group in-panel-body">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="password-addon">
                          <fa-icon [icon]="faLock"></fa-icon>
                        </span>
                        <input type="password" name="password" id="password" tabindex="2" class="form-control"
                          placeholder="{{ 'view.login.password' | translate }}" aria-describedby="password-addon"
                          [(ngModel)]="loginForm.password">
                      </div>
                    </div>
                    <!-- ERROR ROW -->
                    <div class="in-panel-body" *ngIf="errors">
                      <ngb-alert [type]="errors.type" [dismissible]="false">{{errors.msg}}</ngb-alert>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6 offset-sm-3">
                          <button type="submit" class="btn btn-primary btn-login" tabindex="4">{{
                            'view.login.button' | translate }}</button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="text-center">
                            <a href="" tabindex="5" class="forgot-password">{{ 'view.login.forgot' | translate }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
