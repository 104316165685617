import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash'
import { UserService, APIUser } from '../../../../shared/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn, TableSettings, AddEvent } from '../../../../shared/components/table/table.component';
import { RowEvent } from '../../../../shared/components/table/table-row/table-row.component';
import { APIObjectSimple } from '../../../../shared/services/company-object.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { ToastService } from '../../../../shared/services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: APIUser[] = [];

  columns: TableColumn[] = [
    new TableColumn("view.admin.users.firstname", "first_name", true, true),
    new TableColumn("view.admin.users.lastname", "last_name", true, true),
    new TableColumn("view.admin.users.email", "info.email", true, true),
    new TableColumn("view.admin.users.staff", "is_staff", true, false),
    new TableColumn("view.admin.users.active", "is_active", true, false),
    new TableColumn("view.admin.users.deactivate", "info.deactivate_in", true, false),
  ];
  settings: TableSettings = new TableSettings("view.admin.users.title");
  actions: string[];
  actionMap: { [id: string]: (c: APIUser) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }
  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.actions = Object.keys(this.actionMap);
    this._userService.adminList().subscribe(
      data => {
        this.users = data
        this.loading = false
      }
    )
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onAdd(_: AddEvent) {
    this._router.navigateByUrl('/admin/users/new')
  }
  view(u: APIUser) {
    this._router.navigateByUrl(`/admin/users/${u.id}`)
  }
  edit(u: APIUser) {
    this._router.navigateByUrl(`/admin/users/${u.id}/edit`)
  }

  delete(u: APIUser) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) return
      this._userService.delete(u.id).subscribe(
        () => {
          this.users = this.users.filter((e: APIUser) => { return e.id !== u.id; })
          this._toastService.saved()
        }, (error: any) => {
          this._toastService.server()
        });
    });
  }
}

