import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { APICompany, CompanyService } from '../../../../shared/services/company.service';
import { APINetwork, NetworkService } from '../../../../shared/services/network.service';
import { APIUser, UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss']
})
export class CompanyHomeComponent implements OnInit {

  user: APIUser

  companies: APICompany[] = []
  loadingCompanies = true

  columns: TableColumn[] = [
    {
      name: "view.companies.home.name",
      key: "name",
      sort: true,
      search: true,
      template: true
    },
    {
      name: "view.companies.home.org",
      key: "org_nr",
      sort: true,
      search: true
    },
    {
      name: "view.companies.home.sites",
      key: "nr_objects",
      sort: true,
      search: false
    }
  ];

  settings: TableSettings = {
    title: "view.companies.home.title",
    add: false,
  }

  constructor(
    private _companyService: CompanyService,
    private _userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this._userService.getUserInfo()
    this._companyService.getUserCompanies(this.user.id).subscribe(
      (data: APICompany[]) => {
        this.companies = data
        this.loadingCompanies = false
      }
    )
  }

}
