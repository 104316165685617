import { Component, OnInit } from '@angular/core';
import { EnergyService, EnergyCarrier, NewEUForm, EndUseEntry } from '../../../shared/services/energy.service';
import { CompanyObjectService, APIObjectDetailed } from '../../../shared/services/company-object.service';
import * as _ from 'lodash';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-used-energy',
  templateUrl: './used-energy.component.html',
  styleUrls: ['./used-energy.component.scss']
})
export class UsedEnergyComponent implements OnInit {
  init: boolean = false;

  faPencilAlt = faPencilAlt
  selectedCompany: APIObjectDetailed = new APIObjectDetailed;
  selectedYear: number

  energyCarriers: EnergyCarrier[]

  endUseForm: NewEUForm = new NewEUForm
  modalEntry: EndUseEntry;

  constructor(
    private energyService: EnergyService,
    private _objectService: CompanyObjectService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        if (!this.init) return
        //Translate enduse form
        this.energyService.translateEndUseForm(this.endUseForm, e.lang).subscribe(
          data => { this.endUseForm = data })
      }
    );
  }

  ngOnInit() {
    this.energyCarriers = this.energyService.v2_getEnergyCarriers()
    this.selectedCompany = this._objectService.currentObject()
    this.load()
  }

  yearChange(year) {
    this.selectedYear = year
    this.load()
  }

  load() {
    if (this.selectedYear && !_.isEmpty(this.selectedCompany)) {
      this.energyService.v2_getNewUsed(this.selectedCompany.id, this.selectedYear).subscribe(
        data => {
          this.endUseForm = data;
          this.init = true;
        });
    }
  }

  open(modal, e: EndUseEntry) {
    this.modalEntry = _.cloneDeep(e)
    this._modalService.open(modal, { centered: true, size: 'lg' }).result.then(res => { }, reason => { })
  }

  save(modal) {
    this.energyService.v2_postUsed(this.selectedCompany.id, [this.modalEntry]).subscribe(
      (data) => {
        this._toastService.saved()
        //Replace entry in form with the newly saved entry.
        this.modalEntry.carriers.sum()
        _.find(this.endUseForm.enduse, ["category", this.modalEntry.category]).carriers = this.modalEntry.carriers
        modal.close()
      },
      (error) => {
        this._toastService.server()
      });
  }

}