import { Component, OnInit, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService, APIUser } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input() icon: IconDefinition;

  userInfo: APIUser;
  supportedLanguages = ['en', 'sv'];
  selectedLanguage: string;
  sidenavMode: string;

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _translateService: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.selectedLanguage = this._translateService.currentLang;
    this.userInfo = this._userService.getUserInfo()
  }

  switchLanguage(lang: string) {
    sessionStorage.setItem("lang", lang);
    this._translateService.use(lang);
    this.selectedLanguage = lang;
  }

  logout() {
    if (this._authService.isLoggedIn()) {
      this._authService.v2_logout().subscribe(res => { });
    } else {
      console.error("Not logged in");
    }
  }

}
