import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APICompany, CompanyForm, CompanyService } from '../../../../../shared/services/company.service';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
  selector: 'app-companies-form',
  templateUrl: './companies-form.component.html',
  styleUrls: ['./companies-form.component.scss']
})
export class CompaniesFormComponent implements OnInit {

  mode: string
  company: APICompany = new APICompany

  readonly: boolean = true

  constructor(
    private activatedRoute: ActivatedRoute,
    private _companyService: CompanyService,
    private _router: Router,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      data => {
        if(data["id"] == undefined) {
          this.company = new APICompany
          return
        }
        this._companyService.get(data["id"]).subscribe(
          (data: APICompany) => {
            this.company = data
          }
        )
      }
    )

    this.activatedRoute.data.subscribe(
      data => {
        this.mode = data.mode
        if(this.mode != "view") {
          this.readonly = false
        }
      }
    )

  }

  save() {
    let form: CompanyForm = new CompanyForm
    form.name = this.company.name
    form.org_nr = this.company.org_nr
    if(this.mode == "new") {
      this.create(form)
    } else if (this.mode == "edit") {
      this.update(this.company.id, form)
    }
  }

  create(form: CompanyForm) {
    this._companyService.create(form).subscribe(
      data => {
        this._router.navigateByUrl(`/admin/companies/${data.id}/edit`)
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

  update(id: number, form: CompanyForm) {
    this._companyService.update(id, form).subscribe(
      data => {
        this._toastService.saved()
      }, (_: any) => {
        this._toastService.server()
      });
  }

}
