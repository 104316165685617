import { Component, OnInit, Input } from '@angular/core';
import { RestrictRoute, SidenavItem } from './sidenav-item/sidenav-item.component';
import { faChartBar, faFileAlt, faPlayCircle, IconDefinition, faUser, faCircle, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faChartLine, faHome, faPlug, faListUl, faAngleDown, faWrench, faArrowLeft, faDollarSign, faIndustry, faBars, faFlask, faBuilding, faProjectDiagram, faPodcast, faGraduationCap, faInfo, faBalanceScale, faBolt, faStar, faMagic, faReply, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { UserService, APIUser } from '../../services/user.service';
import { PublicUrlService } from '../../services/public-url.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  menu: Menu = new Menu
  userInfo: APIUser = new APIUser();
  faBars = faBars

  constructor(
    private _userService: UserService,
    private _publicUrlService: PublicUrlService,
  ) {}

  private _menuMode: string;
  @Input('menuMode') set menuMode(value: string) {
    this._menuMode = value
    this.setMenu(this._menuMode, this._resourceID)
  }

  private _resourceID: number;
  @Input('resourceID') set resourceID(value: number) {
    this._resourceID = value
    this.setMenu(this._menuMode, this._resourceID)
  }

  //Icons
  faHome = faHome;
  faPlug = faPlug;
  faChartBar = faChartBar;
  faFileAlt = faFileAlt;
  faListUI = faListUl;
  faPlayCircle = faPlayCircle;
  faAngleDown = faAngleDown
  faUser = faUser
  faWrench = faWrench
  faArrowLeft = faArrowLeft
  faCircle = faCircle
  faDollarSign = faDollarSign
  faIndustry = faIndustry
  faChartLine = faChartLine
  faHandshake = faHandshake
  faFlask = faFlask
  faBuilding = faBuilding
  faNetwork = faProjectDiagram
  faInfo = faInfo

  ngOnInit() {
    this.userInfo = this._userService.getUserInfo()
    this.menu = new Menu;
    this.setMenu(this._menuMode, this._resourceID)
  }

  setMenu(mode: string, resourceID: number) {
    if (mode == "home") {
      this.menu = this.homepageMenu()
    } else if (mode == "network") {
      this.menu = this.networkMenu(resourceID)
    } else if (mode == "company") {
      this.menu = this.companyMenu(resourceID)
    } else if (mode == "company-object") {
      this.menu = this.companyObjMenu(resourceID)
    } else if (mode == "admin") {
      this.menu = this.adminMenu()
    } else if (mode == "public") {
      this.menu = this.publicMenu()
    }
  }

  homepageMenu(): Menu {
    let menu = new Menu;
    menu.addItem("menues.home.home", faHome, "/home")
    menu.addItem("menues.home.companies", faBuilding, "/companies")
    menu.addItem("menues.home.sites", faIndustry, "/sites")
    menu.addItem("menues.home.networks", faProjectDiagram, "/networks")
    menu.addItem("menues.home.education", faGraduationCap, "/education", new RestrictRoute(true, "education"))
    menu.addItem("menues.home.database", faListUl, "/database", new RestrictRoute(true, "database"))
    menu.addItem("menues.home.account", faUser, "/account")
    if (this.userInfo.is_staff) {
      menu.addItem("menues.home.admin", faWrench, "/admin")
    }
    return menu
  }

  adminMenu(): Menu {
    let menu = new Menu;
    menu.addItem("menues.admin.users", faUser, "/admin/users", new RestrictRoute(), true)
    menu.addItem("menues.admin.companies", faBuilding, "/admin/companies", new RestrictRoute(), true)
    menu.addItem("menues.admin.sites", faIndustry, "/admin/sites", new RestrictRoute(), true)
    menu.addItem("menues.admin.networks", faProjectDiagram, "/admin/networks", new RestrictRoute(), true)
    let licenses = menu.addItem("menues.admin.licenses", faFileInvoiceDollar, "/admin/licenses", new RestrictRoute(), true)
    menu.addSubItem(licenses, "menues.admin.licenses-site", null, "/admin/licenses/site",  new RestrictRoute())
    menu.addSubItem(licenses, "menues.admin.licenses-network", null, "/admin/licenses/network",  new RestrictRoute())
    menu.addItem("menues.admin.demo", faFlask, "/admin/demo")
    menu.addItem("menues.admin.agreement", faHandshake, "/admin/agreement")
    menu.addItem("menues.admin.leave", faArrowLeft, "/home")
    return menu;
  }

  networkMenu(id: number): Menu {
    let menu = new Menu;

    let baseURL = `/networks/${id}`

    menu.addItem("menues.network.overview", faHome, baseURL, new RestrictRoute(true, "overview"))
    // TODO: Add back when view is defined
    //menu.addItem("Network information", faInfo, "/not-implemented")

    let followUp = menu.addItem("menues.network.follow_up", faChartLine, baseURL + "/energy-follow-up", new RestrictRoute(true, "follow_up"))
    // TODO: Add back when view is defined
    // menu.addSubItem(followUp, "Energy Balance", null, "/new")
    menu.addSubItem(followUp, "menues.network.follow_up_supplied", null, baseURL + "/energy-follow-up/supplied", new RestrictRoute(true, "follow_up_supplied"))
    menu.addSubItem(followUp, "menues.network.follow_up_used", null, baseURL + "/energy-follow-up/used", new RestrictRoute(true, "follow_up_used"))

    menu.addItem("menues.network.benchmark", faChartBar, baseURL + "/benchmark", new RestrictRoute(true, "benchmark"))
    menu.addItem("menues.network.measure", faFileAlt, baseURL + "/measures", new RestrictRoute(true, "measure"))

    //Don't render back option for public url users
    if (!this.userInfo.info.is_public) {
      menu.addItem("menues.network.home", faReply, "/home")
    }
    return menu
  }

  companyMenu(id: number): Menu {
    let menu = new Menu;

    let baseURL = `/companies/${id}`

    menu.addItem("menues.company.overview", faHome, baseURL)
    // TODO: Add back when view is defined
    // menu.addItem("Company information", faInfo, "/new")

    let followUp = menu.addItem("menues.company.follow-up", faChartLine, baseURL + "/energy-follow-up")
    // TODO: Add back when view is defined
    // menu.addSubItem(followUp, "Energy Balance", null, "/new")
    menu.addSubItem(followUp, "menues.company.supplied", null, baseURL + "/energy-follow-up/supplied")
    menu.addSubItem(followUp, "menues.company.used", null, baseURL + "/energy-follow-up/used")

    menu.addItem("menues.company.benchmark", faChartBar, baseURL + "/benchmark")
    menu.addItem("menues.company.measure", faFileAlt, baseURL + "/measures")
    menu.addItem("menues.company.home", faReply, "/home")
    return menu
  }

  companyObjMenu(id: number): Menu {
    let menu = new Menu;

    let baseURL = `/sites/${id}`

    menu.addItem("menues.object.overview", faHome, baseURL, new RestrictRoute(true, "overview"))
    menu.addItem("menues.object.details", faInfo, baseURL+"/details", new RestrictRoute(true, "details"))
    // TODO: Add back when view is defined
    //menu.addItem("Site information", faInfo, "/no-implemented")
    let energyDataItem = menu.addItem("menues.object.energy", faPlug, baseURL + "/energydata", new RestrictRoute(true, "energy"))
    menu.addSubItem(energyDataItem, "menues.object.energy_balance", faBalanceScale, baseURL + "/energydata/balance", new RestrictRoute(true, "energy_balance"))
    menu.addSubItem(energyDataItem, "menues.object.energy_supplied", faBolt, baseURL + "/energydata/supplied", new RestrictRoute(true, "energy_supplied"))
    menu.addSubItem(energyDataItem, "menues.object.energy_used", faStar, baseURL + "/energydata/used", new RestrictRoute(true, "energy_used"))

    menu.addItem("menues.object.follow_up", faChartLine, baseURL + "/energy-follow-up", new RestrictRoute(true, "follow_up"))

    menu.addItem("menues.object.benchmark", faChartBar, baseURL + "/benchmark", new RestrictRoute(true, "benchmark"))
    menu.addItem("menues.object.measure", faFileAlt, baseURL + "/measures", new RestrictRoute(true, "measure"))

    let advanced = menu.addItem("menues.object.advanced", faMagic, baseURL + "/analysis", new RestrictRoute(true, "advanced"))
    menu.addSubItem(advanced, "menues.object.sankey", null, baseURL + "/analysis/sankey")
    menu.addSubItem(advanced, "menues.object.sankey-definitions", null, baseURL + "/analysis/sankey/definitions")
    menu.addSubItem(advanced, "menues.object.sankey-sensors", null, baseURL + "/analysis/sankey/sensors")
    menu.addSubItem(advanced, "menues.object.sankey-settings", null, baseURL + "/analysis/sankey/settings")
    menu.addSubItem(advanced, "menues.object.sankey-follow-up", null, baseURL + "/analysis/energy-follow-up")
    // TODO: Activate when investment has been fixed
    // menu.addItem(this.navs['investment'], faDollarSign, "/company/investment", true)
    menu.addItem("menues.object.home", faReply, "/home")

    return menu;
  }

  publicMenu(): Menu {
    let menu = new Menu;

    let urlSettings = this._publicUrlService.currentSettings()

    let baseURL = `/public/${urlSettings.key}/networks/${urlSettings.network}`

    menu.addItem("menues.network.overview", faHome, baseURL, new RestrictRoute(true, "overview"))
    // TODO: Add back when view is defined
    //menu.addItem("Network information", faInfo, "/not-implemented")

    let followUp = menu.addItem("menues.network.follow_up", faChartLine, baseURL + "/energy-follow-up", new RestrictRoute(true, "follow_up"))
    // TODO: Add back when view is defined
    // menu.addSubItem(followUp, "Energy Balance", null, "/new")
    menu.addSubItem(followUp, "menues.network.follow_up_supplied", null, baseURL + "/energy-follow-up/supplied",  new RestrictRoute(true, "follow_up_supplied"))
    menu.addSubItem(followUp, "menues.network.follow_up_used", null, baseURL + "/energy-follow-up/used",  new RestrictRoute(true, "follow_up_used"))

    menu.addItem("menues.network.benchmark", faChartBar, baseURL + "/benchmark", new RestrictRoute(true, "benchmark"))
    menu.addItem("menues.network.measure", faFileAlt, baseURL + "/measures", new RestrictRoute(true, "measure"))

    return menu
  }

}

class Menu {
  items: SidenavItem[]
  constructor() {
    this.items = []
  }

  addItem(name: string, icon: IconDefinition, route: string, restricted?: RestrictRoute, skip_exact_match?: boolean): SidenavItem {
    let item = new SidenavItem({ name: name, icon: icon, route: route, restricted: restricted, skip_exact_match: skip_exact_match });
    this.items.push(item)
    return item
  }

  addSubItem(parent: SidenavItem, name: string, icon: IconDefinition, route: string, restricted?: RestrictRoute) {
    let item = new SidenavItem({ name: name, icon: icon, restricted: restricted, route: route });
    parent.subItems.push(item)
    return item
  }

}
