import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { SuppliedEnergyForm, NewEUForm, EnergyService } from '../../services/energy.service';
import { Chart, ChartData, ChartDataSets, ChartConfiguration } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash'


@Component({
  selector: 'app-energy-graph',
  templateUrl: './energy-graph.component.html',
  styleUrls: ['./energy-graph.component.scss']
})
export class EnergyGraphComponent implements OnInit {

  mode: string = "energy"

  // Will filter the datasets to only show groups (carriers, enduse categories) being not 0.
  onlyShowUsedGroups: boolean = true

  @Input() energy: GraphData
  @Input() co2: boolean = false

  @ViewChild('chart', { static: true }) chartRef: ElementRef;
  fuelChart: Chart;
  fuelChartData: ChartData = {}
  axesLabel: string = "[MWh]"

  //Translation objects
  carrierTrans: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.energy.currentValue.type) {
      this.initFuelChart()
      this.updateChart()
    }
  }

  constructor(
    private _energyService: EnergyService,
    private translate: TranslateService
  ) {
    translate.stream('energycarriers').subscribe(res => {
      this.carrierTrans = res
      if (this.fuelChart) this.updateChart()
    });
  }

  ngOnInit() { }

  onTabChange(e) {
    this.mode = e.nextId
    this.axesLabel = (this.mode == "energy" ? "[MWh]" : "[kg CO\u2082]")
    this.updateChart()
  }

  initFuelChart() {
    if (this.fuelChart) this.fuelChart.destroy()

    let conf: ChartConfiguration
    conf = {
      type: this.energy.type == "horizontal" ? 'horizontalBar' : "bar",
      data: this.fuelChartData,
      options: {
        tooltips: {
          enabled: true,
          mode: 'point',
          callbacks: {
            label: function (tooltipItem, data) {
              return `${data.datasets[tooltipItem.datasetIndex].label}: ${Math.round(Number(tooltipItem.value))}`
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 16,
            },
            stacked: true
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 16,
            },
            stacked: true
          }]
        }
      }
    }

    this.fuelChart = new Chart(this.chartRef.nativeElement, conf)
    this.setAxesLabel(this.axesLabel)
  }

  updateChart() {
    let e = Object.values(this.energy.data)[0]
    if (e instanceof Array && e[0] instanceof SuppliedEnergyForm) {
      this.updateEnergyChart()
    } else if (e instanceof Array && e[0] instanceof NewEUForm) {
      this.updateUsed()
    } else {
      console.log("model not supported:", e)
    }
  }

  updateEnergyChart() {
    let datasets: ChartDataSets[] = []
    this.fuelChartData.labels = Object.keys(this.energy.data)
    let carrierForm: SuppliedEnergyForm = this._energyService.emptySuppliedForm()
    carrierForm.fuels.forEach(f => {
      let dataset: ChartDataSets = {}
      dataset.data = []
      dataset.backgroundColor = f.graph_color
      dataset.maxBarThickness = 200
      dataset.label = this.carrierTrans[f.model]
      datasets.push(dataset)
    })

    for (const [label, forms] of Object.entries(this.energy.data)) {
      carrierForm.fuels.forEach((f, i) => {
        let tot: number = 0;
        (forms as SuppliedEnergyForm[]).forEach(form => {
          let val: number = form.fuels[i].amount
          if (this.mode == "co2") {
            val = val * form.fuels[i].co2
          }
          tot += val
        })
        datasets[i].data.push(tot)
      });
    }

    // Filter the datasets to only include energycarriers used
    if (this.onlyShowUsedGroups) {
      datasets = _.filter(datasets, d => {
        return _.some(d.data, e => e > 0)
      })
    }

    this.fuelChartData.datasets = datasets
    this.setAxesLabel(this.axesLabel)
    this.fuelChart.update()
  }

  updateUsed() {
    let datasets: ChartDataSets[] = []
    this.fuelChartData.labels = Object.keys(this.energy.data);
    (Object.values(this.energy.data) as NewEUForm[][])[0][0].enduse.forEach(u => {
      let dataset: ChartDataSets = {}
      dataset.data = []
      dataset.maxBarThickness = 200
      dataset.backgroundColor = u.graphColor
      dataset.label = u.name
      datasets.push(dataset)
    });

    for (const [label, forms] of Object.entries(this.energy.data)) {
      datasets.forEach((d, i) => {
        let tot: number = 0;
        (forms as NewEUForm[]).forEach(form => {
          if (this.mode == "co2") {
            tot += form.getCategoryCO2(i)
          } else {
            tot += form.getCategoryTotal(i)
          }
        });
        d.data.push(tot)
      })
    }

    // Filter the datasets to only include energycarriers used
    if (this.onlyShowUsedGroups) {
      datasets = _.filter(datasets, d => {
        return _.some(d.data, e => e > 0)
      })
    }

    this.fuelChartData.datasets = datasets
    this.setAxesLabel(this.axesLabel)
    this.fuelChart.update()
  }

  setAxesLabel(label: string) {
    if (this.energy.type == "horizontal") {
      this.fuelChart.options.scales.xAxes[0].scaleLabel.labelString = label
    } else {
      this.fuelChart.options.scales.yAxes[0].scaleLabel.labelString = label
    }
  }

}

export class GraphData {
  type: string
  data: { [label: string]: SuppliedEnergyForm[] | NewEUForm[] }
  group: string

  constructor() {
    this.data = {}
  }
}
