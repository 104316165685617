import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { CompanyObjectService, APIObjectDetailed, APIObjectSimple, APIObjectDetails } from '../../services/company-object.service';
import * as _ from 'lodash'
import { LocationService, Country, State, Municipality } from '../../services/location.service';
import { SniService, SNIBase, SNI } from '../../services/sni.service';
import { forkJoin } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { APICategory, CategoryService } from '../../services/category.service';
import { CompanyService } from '../../services/company.service';
import { NetworkConfig, NetworkService } from '../../services/network.service';

@Component({
  selector: 'app-company-filter',
  templateUrl: './company-filter.component.html',
  styleUrls: ['./company-filter.component.scss']
})
export class CompanyFilterComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() context: string;
  @Input() locationLevel: string = "municipality"
  @Input() sniLevel: string = "division"
  @Input() enableSite: boolean = true;
  @Input() enablePeriod: boolean = false;
  @Input() enableCategory: boolean = false;
  @Input() specificYears: string[] = [];

  faAngleUp = faAngleUp
  faAngleDown = faAngleDown

  filter: CompanyFilter = new CompanyFilter
  years: number[] = []
  categories: APICategory[] = [];

  locationLevelNumber: number
  sniLevelNumber: number

  constructor(
    private _companyService: CompanyService,
    private _networkService: NetworkService,
    private _locationService: LocationService,
    private _sniService: SniService,
    private _categoryService: CategoryService,
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.getSNISections(e.lang)
        if (!this.filter.division.disable) {
          this.getSNIDivision(this.filter.section.select, e.lang)
        }
        this.getCategories(e.lang)
      })
  }

  ngOnInit() {

    if(this.specificYears.length > 0) {
      this.years = [...this.specificYears].map(e => Number(e))
    } else {
      this.years = this.getYears(1970)
    }

    this.locationLevelNumber = this.getLocationLevel(this.locationLevel)
    this.sniLevelNumber = this.getSniLevel(this.sniLevel)

    console.log(this.locationLevelNumber)

    //TODO: Check if context is valid. Network or company
    if(this.context == "company") {
      this.filter.allObjects = this._companyService.currentObjects()
    } else {
      this.filter.allObjects = this._networkService.currentObjects()
    }

    this.filter.selectedObjects = this.filter.allObjects

    this._locationService.getCountries().subscribe(
      data => {
        this.filter.country.data = [...this.getAvailableSelections(data, 'geo.country.id')]
      });
    this.getSNISections(sessionStorage.getItem("lang"))
    this.getCategories(sessionStorage.getItem("lang"))
    this.emit()
  }

  getLocationLevel(v: string): number {
    switch (v) {
      case "country":
        return 1
      case "region":
        return 2
      case "municipality":
        return 3
    }
  }

  getSniLevel(v: string): number {
    switch (v) {
      case "section":
        return 1
      case "division":
        return 2
    }
  }

  emit() {
    let latest: boolean = this.filter.latest === 'true'
    let period: number[] = latest ? [] : this.filter.period.sort()
    let categories: APICategory[] = this.filter.categories
    this.onChange.emit(
      new FilterChangeEvent(
        this.filter.selectedObjects,
        latest,
        period,
        categories,
      ));
  }

  getStates(countries: Country[]) {
    const requests = []
    countries.forEach(c => requests.push(this._locationService.getStates(c.id)))
    forkJoin(requests).subscribe(
      (data: State[][]) => {
        this.filter.state.data = [...this.getAvailableSelections(_.flatten(data), 'geo.state.id')]
      });
  }

  getMunicipalities(states: State[]) {
    const requests = []
    states.forEach(s => requests.push(this._locationService.getMunicipalities(s.country, s.id)))
    forkJoin(requests).subscribe(
      (data: Municipality[][]) => {
        this.filter.municipality.data = [...this.getAvailableSelections(_.flatten(data), 'geo.municipality.id')]
      });
  }

  getSNISections(lang?: string) {
    this._sniService.v2_listSNI(lang).subscribe(
      data => {
        this.filter.section.data = [...this.getAvailableSelections(data, 'sni_data.section')]
      }
    )
  }

  getSNIDivision(snis: SNIBase[], lang?: string) {
    const requests = []
    snis.forEach(s => requests.push(this._sniService.v2_getSNI(s.code, lang)))
    forkJoin(requests).subscribe(
      (data: SNI[]) => {
        let res: SNIBase[] = []
        data.forEach(s => res.push(...s.childs))
        this.filter.division.data = [...this.getAvailableSelections(res, 'sni_data.division')]
      });
  }

  getCompanies() {
    this.filter.company.data = [...this.getAvailableSelections(this.filter.allObjects, 'id')]
  }

  getAvailableSelections(data: any[], field: string): any[] {
    let available = []
    data.forEach((e: any) => {
      if (_.find(this.filter.selectedObjects, [field, e.id])) {
        available.push(e)
      }
    });
    return available
  }

  getYears(startYear: number): number[] {
    let currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

  getCategories(lang?: string) {
    this._categoryService.v2_getCategories(lang).subscribe(categories => {
      this.categories = categories
    });
  }

  countryChange() {
    this.filter.state.select = []
    this.filter.municipality.select = []
    this.filter.filter()
    this.emit()
    this.getSNISections(sessionStorage.getItem("lang"))
    if (_.isEmpty(this.filter.country.select)) {
      this.filter.state.disable = true
      this.filter.municipality.disable = true
      this.filter.section.disable = true
      return
    }
    this.filter.state.disable = false
    this.filter.section.disable = false
    this.getStates(this.filter.country.select)
  }

  stateChange() {
    this.filter.municipality.select = []
    this.filter.filter()
    this.emit()
    this.getSNISections(sessionStorage.getItem("lang"))
    if (_.isEmpty(this.filter.state.select)) {
      this.filter.municipality.disable = true
      return
    }
    this.filter.municipality.disable = false
    this.getMunicipalities(this.filter.state.select)
  }

  municipalityChange() {
    this.filter.filter()
    this.emit()
    this.getSNISections(sessionStorage.getItem("lang"))
  }

  sectionChange() {
    this.filter.division.select = []
    this.filter.filter()
    this.emit()
    if (_.isEmpty(this.filter.section.select)) {
      this.filter.company.disable = true
      this.filter.division.disable = true
      this.filter.country.disable = false
      this.filter.state.disable = false
      if (!_.isEmpty(this.filter.state.select)) {
        this.filter.municipality.disable = false
      }
      return
    }
    this.filter.country.disable = true
    this.filter.state.disable = true
    this.filter.municipality.disable = true
    this.filter.division.disable = false
    this.filter.company.disable = false
    this.getSNIDivision(this.filter.section.select, sessionStorage.getItem("lang"))
    this.getCompanies()
  }

  divisionChange() {
    this.filter.filter()
    this.emit()
    this.getCompanies()
  }

  companyChange() {
    this.filter.filter()
    this.emit()
    if (_.isEmpty(this.filter.company.select)) {
      this.filter.section.disable = false
      this.filter.division.disable = false
      return
    }
    this.filter.section.disable = true
    this.filter.division.disable = true
  }

  periodChange() {
    this.emit()
  }

  categoriesChange() {
    this.emit()
  }

}

class CompanyFilter {
  country: FilterEntry
  state: FilterEntry
  municipality: FilterEntry
  section: FilterEntry
  division: FilterEntry
  company: FilterEntry
  selectedObjects: APIObjectDetailed[] = []
  allObjects: APIObjectDetailed[] = []
  latest: string = "true"
  period: number[] = []
  categories: APICategory[] = []

  constructor() {
    this.country = new FilterEntry('geo.country.id', false)
    this.state = new FilterEntry('geo.state.id')
    this.municipality = new FilterEntry('geo.municipality.id')
    this.section = new FilterEntry('sni_data.section')
    this.division = new FilterEntry('sni_data.division')
    this.company = new FilterEntry('id')
  }

  filter() {
    this.selectedObjects = this.country.filter(this.allObjects)
    this.selectedObjects = this.state.filter(this.selectedObjects)
    this.selectedObjects = this.municipality.filter(this.selectedObjects)
    this.selectedObjects = this.section.filter(this.selectedObjects)
    this.selectedObjects = this.division.filter(this.selectedObjects)
    this.selectedObjects = this.company.filter(this.selectedObjects)
  }
}

class FilterEntry {
  select: any = []
  data: any = []
  disable: boolean
  filterString: string
  constructor(filterString: string, disabled: boolean = true) {
    this.disable = disabled
    this.filterString = filterString
  }

  filter(data) {
    if (this.select.length == 0) return data
    let selectedIDs: number[] = []
    selectedIDs = _.map(this.select, 'id')
    return _.filter(data, e => selectedIDs.includes(_.get(e, this.filterString)))
  }

}

export class FilterChangeEvent {
  objects: APIObjectDetailed[]
  latest: boolean
  period: number[]
  categories: APICategory[]

  constructor(objects: APIObjectDetailed[] = [], latest: boolean = false, period: number[] = [], categories: APICategory[] = []) {
    this.objects = objects
    this.latest = latest
    this.period = period
    this.categories = categories
  }

}