<div class="d-flex">
    <app-table class="flex-grow-1 mr-2" [columns]="objectColumns" [data]="objects" [settings]="objectSettings" [loading]="loadingObjects">
        <ng-template #field let-item="item">
            <a href="/sites/{{item.id}}" (click)="$event.preventDefault(); navigateToSite(item.id)">{{ item.name }}</a>
        </ng-template>
    </app-table>
    <app-table class="flex-grow-1" [columns]="networkColumns" [data]="networks" [settings]="networkSettings" [loading]="loadingNetworks">
        <ng-template #field let-item="item">
            <a href="/networks/{{item.id}}" (click)="$event.preventDefault(); navigateToNetwork(item.id)">{{ item.name }}</a>
        </ng-template>
    </app-table>
</div>