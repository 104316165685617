<div class="container-fluid">
  <div class="section">
    <div class="section-header">
      <div></div>
      <h5>{{ 'view.admin.networks.info' | translate }}</h5>
    </div>
    <fieldset [disabled]="readonly">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="form-group">
            <label for="name"> {{ 'view.admin.networks.name' | translate }} </label>
            <input type="text" class="form-control" id="name" [(ngModel)]="network.name">
          </div>
          <div class="form-group">
            <label for="desc" class="form-label">{{ 'view.admin.networks.description' | translate }}</label>
            <textarea class="form-control" id="desc" [(ngModel)]="network.description" rows="4"></textarea>
          </div>
          <div class="form-group">
            <label for="owner"> {{ 'view.admin.networks.admin' | translate }} </label>
            <ng-select [disabled]="readonly" [items]="users$ | async" bindValue="id" [(ngModel)]="network.owner">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{item.first_name}} {{item.last_name}}
              </ng-template>
            </ng-select>
          </div>
          <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right" (click)="save()">{{
            'buttons.save' | translate }}</button>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="section" *ngIf="mode != 'new'">
    <div class="section-header">
      <h5>{{ 'view.admin.networks.license' | translate }}</h5>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="form-group">
          <label for="license"> {{ 'view.admin.networks.license' | translate }} </label>
          <ng-select [disabled]="readonly" [items]="licenses$ | async" bindValue="id" bindLabel="name"
            [(ngModel)]="network.config.license">
            <ng-template ng-option-tmp let-item="item">
              <div class="font-weight-bold">
                {{item.name}}
              </div>
              <div class="font-weight-light">
                <span class="mr-2">{{ 'view.admin.networks.license_price' | translate }}: {{item.price}} kr</span> |
                <span class="mr-2 ml-2">{{ 'view.admin.networks.license_sites' | translate }}: {{item.nr_sites}}</span>
                |
                <span class="ml-2">{{ 'view.admin.networks.license_users' | translate }}: {{ item.nr_users }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
          (click)="updateLicense()">{{
          'buttons.save' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="mode != 'new'">
    <div class="section-header">
      <h5>{{ 'view.admin.networks.data_access' | translate }}</h5>
    </div>
    <fieldset [disabled]="readonly">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="form-group">
            <div class="sub-section">{{ 'view.admin.networks.time_period.title' | translate }}</div>

            <div class="custom-control custom-switch mb-3">
              <input class="custom-control-input" type="checkbox" id="specific-years" [(ngModel)]="specificYears">
              <label class="custom-control-label" for="specific-years">
                {{ 'view.admin.networks.time_period.specific' | translate }}
              </label>

              <ng-select *ngIf="specificYears" [disabled]="readonly" [items]="years"
                placeholder="{{ 'date-span.select-year' | translate }}" [(ngModel)]="selectedYears" [multiple]="true"
                [closeOnSelect]="false">
              </ng-select>
            </div>
          </div>

          <div class="form-group">
            <div class="sub-section">{{ 'view.admin.networks.integrity_level.title' | translate }}</div>
            <div class="custom-control custom-switch mb-3">
              <input class="custom-control-input" type="checkbox" id="site-anonymize"
                [(ngModel)]="network.config.anonymize_objects">
              <label class="custom-control-label" for="site-anonymize">
                {{ 'view.admin.networks.integrity_level.object_anonymize' | translate }}
              </label>
            </div>
            <div class="custom-control custom-switch mb-3">
              <input class="custom-control-input" type="checkbox" id="site-recognition"
                [(ngModel)]="network.config.object_recognition">
              <label class="custom-control-label" for="site-recognition">
                {{ 'view.admin.networks.integrity_level.object_recognition' | translate }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="sub-section">{{ 'view.admin.networks.visualisations.title' | translate }}</div>
            <div class="visual-btn">
              <span *ngFor="let item of locationVisuals" (click)="selectLocation(item.value)"
                [ngClass]="{'active': item.active, 'disabled': readonly}">
                {{ item.display | translate }}
              </span>
            </div>
            <div class="visual-btn mt-3">
              <span *ngFor="let item of sniVisuals" (click)="selectSNI(item.value)"
                [ngClass]="{'active': item.active, 'disabled': readonly}">
                {{ item.display | translate }}
              </span>
            </div>
          </div>
          <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm float-right"
            (click)="updateConfig()">{{'buttons.save' | translate }}</button>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="section" *ngIf="mode != 'new'">
    <div class="section-header">
      <h5>{{ 'view.admin.networks.memberships' | translate }}</h5>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="form-group">
          <app-membership-manager [resource]="network" memberType="object" [readonly]="readonly">
          </app-membership-manager>
        </div>
        <div class="form-group">
          <app-membership-manager [resource]="network" memberType="user" [readonly]="readonly"></app-membership-manager>
        </div>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="mode != 'new'">
    <div class="section-header">
      <h5>{{ 'view.admin.networks.urls.title' | translate }}</h5>
    </div>

    <button *ngIf="!readonly" type="button" class="btn btn-outline-primary btn-sm mb-1" (click)="addURL()">{{
      'buttons.add' | translate }}</button>

    <div class="form-row">
      <div class="form-group col-md-6">
        <div *ngFor="let url of network.public_urls; let i = index">
          <app-public-url [readonly]="readonly" [url]="url" [index]="i" (deleted)="removeURL(i)"></app-public-url>
        </div>
      </div>
    </div>
  </div>
</div>