<div class="row">
  <div class="col-md-3">
    <div id="keyvalue-list">
      <div class="keyvalue-entry" *ngFor="let k of currentView.keyvalues; let i = index;">
        <span class="link" (click)="setCurrentKeyValue(i)">{{k.name}}</span>
        <span class="remove-icon">
          <fa-icon [icon]="faTimes" (click)="removeKeyValue(k)"></fa-icon>
        </span>
      </div>
      <button type="button" (click)="newKeyValue()" class="btn btn-outline-primary btn-sm btn-block mt-3">
        {{ 'view.sites.advanced.settings.kpi.add' | translate }}
      </button>
    </div>
  </div>
  <div class="col-md-9" *ngIf="currentKeyValueRef">
    <div class="row">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ 'view.sites.advanced.settings.kpi.name' | translate }}</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" name="keyValueName" [(ngModel)]="currentKeyValueRef.name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <app-formula [formula]="currentKeyValueRef" allowSensors="true" [linkList]="linkList"></app-formula>
      </div>
      
      <div class="col-md-6">
        <h5>{{ 'view.sites.advanced.settings.kpi.rootcauses' | translate }}</h5>
        <ngb-accordion [closeOthers]="true">
          <ngb-panel [title]="r.name" *ngFor="let r of currentKeyValueRef.rootcauses; let i = index;">
            <ng-template ngbPanelContent>
              <form name="rootCauseForm">
                <div class="form-group">
                  <label>{{ 'view.sites.advanced.settings.kpi.name' | translate }}</label>
                  <input type="text" name="rootCauseName" [(ngModel)]="r.name" class="form-control rootcause-name">
                </div>
                <div class="row">
                  <div class="rootcause-link">
                    <ng-select name="rootcauseLink" [(ngModel)]="r.linkID" placeholder="Link"
                      [items]="linkList" [clearable]="false" bindLabel="show" groupBy="process" bindValue="uuid" appendTo="body">
                    </ng-select>
                  </div>
                  <div class="rootcause-link-value">
                    <ng-select name="rootcauseLinkValue" [(ngModel)]="r.equationName" placeholder="Equation"
                      [items]="equationNames" [clearable]="false" bindLabel="show" bindValue="data" appendTo="body">
                    </ng-select>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ 'view.sites.advanced.settings.kpi.description' | translate }}</label>
                  <input type="text" name="rootcauseDesc" [(ngModel)]="r.desc" class="form-control rootcause-desc">
                </div>
                <div class="form-group">
                  <label>{{ 'view.sites.advanced.settings.kpi.impact' | translate }}: {{ r.impact }} %</label>
                  <input class="rootcause-impact custom-range" [(ngModel)]="r.impact" type="range" name="impact"
                    min="-100" max="100">
                </div>
                <button type="button" (click)="currentKeyValueRef.removeRootCause(i)"
                  class="btn btn-outline-danger btn-sm">{{ 'view.sites.advanced.settings.kpi.remove' | translate }}</button>
              </form>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <button type="button" (click)="currentKeyValueRef.addRootCause()"
          class="btn btn-outline-primary btn-sm btn-block mt-3">{{ 'view.sites.advanced.settings.kpi.add-rootcause' | translate }}</button>
      </div>
    </div>
  </div>
</div>
