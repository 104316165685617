import * as _ from 'lodash';
import { Formula, FormulaEntry } from '../components/formula/formula.component';

export class KeyValue extends Formula {
    name: string
    rootcauses: RootCauseEntry[]

    constructor(name: string, formula: FormulaEntry[], rootcauses: any) {
        super();
        this.name = name
        this.formula = formula
        this.rootcauses = rootcauses
    }

    addRootCause() {
        this.rootcauses.push(new RootCauseEntry("New rootcause"))
    }

    //Removes the rootcause at the given index.
    removeRootCause(index: number) {
        this.rootcauses.splice(index, 1)
    }
}

export class RootCauseEntry {
    name: string
    linkID: string
    equationName: string
    desc: string
    impact: number

    constructor(name: string) {
        this.name = name
        this.impact = 0
    }
}