import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { NetworksViewsGuard, ObjectsViewsGuard, UserViewsGuard } from './guards/views.guard';
import { NotPublicUserGuard } from './guards/public-user.guard';
import { HomeComponent } from './components/home/home.component';
import { routes as companyObjectRoutes } from './routes/company-object-routes';
import { routes as companyRoutes } from './routes/company-routes';
import { routes as networkRoutes } from './routes/network-routes';
import { routes as adminRoutes } from './routes/admin-routes';
import { StaffGuard } from './guards/staff.guard';
import { CompanyObjectHomeComponent } from './components/new/company-object/company-object-home/company-object-home.component';
import { CompanyHomeComponent } from './components/new/company/company-home/company-home.component';
import { NetworkHomeComponent } from './components/new/network/network-home/network-home.component';
import { HomepageComponent } from './components/new/home/homepage/homepage.component';
import { VideosComponent } from './components/new/home/videos/videos.component';
import { DatabaseComponent } from './components/new/home/database/database.component';
import { AccountComponent } from './components/new/home/account/account.component';
import { PublicComponent } from './components/new/public/public.component';
import { PublicAuthGuard } from './guards/public-auth.guard';

const routes: Routes = [
  {
    path: 'public',
    children: [
      {
        path: ":key",
        children: [
          {
            path: "",
            component: PublicComponent,
          },
          {
            path: "networks",
            component: HomeComponent,
            canActivate: [PublicAuthGuard],
            children: [
              {
                path: ':id',
                children: networkRoutes,
              },
            ]
          }
        ]
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard, NotPublicUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomepageComponent,
      },
      {
        path: 'networks',
        children: [
          {
            path: '',
            component: NetworkHomeComponent,
          },
          {
            path: ':id',
            children: networkRoutes,
          },
        ],
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            component: CompanyHomeComponent,
          },
          {
            path: ':id',
            children: companyRoutes
          },
        ],
      },
      {
        path: 'sites',
        children: [
          {
            path: '',
            component: CompanyObjectHomeComponent,
          },
          {
            path: ':id',
            children: companyObjectRoutes
          },
        ]
      },
      {
        path: "education",
        component: VideosComponent,
        canActivate: [UserViewsGuard],
        data: { requiredView: "education" }
      },
      {
        path: "database",
        component: DatabaseComponent,
        canActivate: [UserViewsGuard],
        data: { requiredView: "database" }
      },
      {
        path: "account",
        component: AccountComponent,
      },
      {
        path: 'admin',
        canActivate: [AuthGuard, StaffGuard],
        children: adminRoutes
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    UserViewsGuard,
    ObjectsViewsGuard,
    NetworksViewsGuard,
    StaffGuard,
    NotPublicUserGuard,
    PublicAuthGuard]
})
export class RoutingModule { }
