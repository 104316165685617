import { Component, OnInit, Input } from '@angular/core';
import { Saving, SavingsService } from '../../../shared/services/savings.service';
import { DateSpan } from '../../../shared/components/date-input/date-input.component';
import { APICategory, APISubcategory, CategoryService } from '../../../shared/services/category.service';
import { APIObjectDetailed } from '../../../shared/services/company-object.service';
import { EnergyCarrier, EnergyService } from '../../../shared/services/energy.service';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../shared/services/toast.service';
import { Currency } from '../../../shared/services/currency.service';

@Component({
  selector: 'app-measure-form',
  templateUrl: './measure-form.component.html',
  styleUrls: ['./measure-form.component.scss']
})
export class MeasureFormComponent implements OnInit {
  @Input() savingRef: Saving = new Saving
  @Input() add: boolean = true
  @Input() company: APIObjectDetailed
  @Input() currency: Currency

  saving: SavingForm
  //The dates used in the form. Not bound directly to the model
  dateProposed: DateSpan = new DateSpan
  datePlanned: DateSpan = new DateSpan
  dateImpl: DateSpan = new DateSpan

  categories: APICategory[] = []
  subcategories: APISubcategory[] = []

  carriers: EnergyCarrier[] = []

  saved: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _categoryService: CategoryService,
    private _energyService: EnergyService,
    private _toastService: ToastService,
    private _savingService: SavingsService,
  ) { }

  ngOnInit(): void {
    this.saving = new SavingForm(this.savingRef)
    this.saving.climate_adapted = !!this.saving.climate_adapted
    this._categoryService.v2_getCategories(sessionStorage.getItem("lang")).subscribe(categories => {
      this.categories = categories
    })
    if (this.saving.id) {
      this._categoryService.v2_getCategoryDetails(this.saving.categoryModel.id, sessionStorage.getItem("lang")).subscribe(category => {
        this.subcategories = category.subcategories
      })
    }
    if (this.saving.date_proposed) { this.dateChange(new DateSpan({ from: this.saving.date_proposed, to: null }), "proposed") }
    if (this.saving.date_planned) { this.dateChange(new DateSpan({ from: this.saving.date_planned, to: null }), "planned") }
    if (this.saving.date_implemented) { this.dateChange(new DateSpan({ from: this.saving.date_implemented, to: null }), "impl") }
    this.carriers = this._energyService.v2_getEnergyCarriers()
  }

  save() {
    if (!this.saving.validate()) {
      this._toastService.missingMeasure()
      return
    }

    this.saving.subcategory = this.saving.subcategoryModel.id
    if (this.add) {
      this.create()
    } else {
      this.update()
    }
  }

  categoryChange(category: APICategory) {
    this.saving.subcategoryModel = undefined
    if (!category) return
    this._categoryService.v2_getCategoryDetails(category.id, sessionStorage.getItem("lang")).subscribe(category => {
      this.subcategories = category.subcategories
    })
  }

  create() {
    this._savingService.v2_addSaving(this.company.id, this.saving, this.currency.code).subscribe(res => {
      res.subscribe(saving => {
        saving.categoryModel = _.find(this.categories, ['id', saving.subcategoryModel.category])
        this.saving = new SavingForm(saving)
        this.savingRef = this.saving
        this.activeModal.close(this.savingRef)
        this._toastService.createdMeasure()
      }, (error: any) => {
        this._toastService.server()
        console.log(error);
      })
    }, (error: any) => {
      this._toastService.server()
      console.log(error);
    })
  }

  update() {
    this._savingService.v2_editSaving(this.company.id, this.saving, this.currency.code).subscribe(res => {
      res.subscribe(saving => {
        saving.categoryModel = _.find(this.categories, ['id', saving.subcategoryModel.category])
        this.saving = new SavingForm(saving)
        this.savingRef = this.saving
        this.activeModal.close(this.savingRef)
        this._toastService.savedMeasure()
      }, (error: any) => {
        this._toastService.server()
        console.log(error);
      })
    }, (error: any) => {
      this._toastService.server()
      console.log(error);
    })
  }

  dateChange(e: DateSpan, model) {
    switch (model) {
      case "proposed":
        this.dateProposed = e
        this.saving.date_proposed = e.from.toISOString().split("T")[0]
        break;
      case "planned":
        this.datePlanned = e
        this.saving.date_planned = e.from.toISOString().split("T")[0]
        break;
      case "impl":
        this.dateImpl = e
        this.saving.date_implemented = e.from.toISOString().split("T")[0]
        break;
    }
  }

  dateClear(model: string) {
    switch (model) {
      case "proposed":
        this.saving.date_proposed = null
        break;
      case "planned":
        this.saving.date_planned = null
        break;
      case "impl":
        this.saving.date_implemented = null
        break;
    }
  }

}

class SavingForm extends Saving {

  validate(): boolean {
    if (!this.validateRequired(this, "form")) return false
    if (!this.validateRequired(this.name, "measure")) return false
    if (!this.validateRequired(this.subcategoryModel, "subcategoryModel")) return false
    console.log(this.subcategoryModel)
    if (!this.validateRequired(this.comment_existing_system, "comment_existing")) return false
    if (!this.validateRequired(this.comment_new_system, "comment_new")) return false
    if (!this.validateRequired(this.comment_extent, "comment_extent")) return false
    if (!this.validateDates()) return false
    return true
  }

  private validateRequired(field, name: string): boolean {
    if (!field) {
      console.log("Validation failed. Required field not set: ", name)
      return false
    }
    return true
  }

  private validateDates(): boolean {
    if (this.date_proposed || this.date_planned || this.date_implemented) return true
    console.log("Validation failed. Require atleast one date")
    return false
  }

  sanitize(): SavingForm {
    let f: SavingForm = _.cloneDeep(this);
    delete f.reported;
    return f
  }

}
