import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  supported: Currency[] = []

  constructor(
    private http: HttpClient,
  ) { }

  // Runs on app load
  loadSupportedCurrencies(): Observable<Currency[]> {
    return this.http.get(`/api/v2/currencies/`).pipe( //TODO: Requires `/` in end of route for some reason
        map((res: Currency[]) => {
          this.supported = res
          return res
        }));
  }

  getSymbol(code: string): string {
    for (let c of this.supported) {
      if (c.code == code) {
        return c.symbol
      }
    }
  }

  getCurrency(code: string): Currency {
    for (let c of this.supported) {
      if (c.code == code) {
        return c
      }
    }
  }

}

export class Currency {
  id: number
  code: string
  symbol: string

  constructor(id: number, code: string, symbol: string) {
    this.id = id
    this.code = code
    this.symbol = symbol
  }
}
