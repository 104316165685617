import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { RowEvent } from '../../../../shared/components/table/table-row/table-row.component';
import { TableColumn, TableSettings, AddEvent } from '../../../../shared/components/table/table.component';
import { APINetwork, NetworkService } from '../../../../shared/services/network.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-networks',
  templateUrl: './networks.component.html',
  styleUrls: ['./networks.component.scss']
})
export class NetworksComponent implements OnInit {
  networks: APINetwork[] = [];

  baseURL: string = "/admin/networks"

  columns: TableColumn[] = [
    new TableColumn("view.admin.networks.name", "name"),
    new TableColumn("view.admin.networks.admin", "owner_name"),
    new TableColumn("view.admin.networks.members", "nr_members"),
    new TableColumn("view.admin.networks.sites", "nr_objects", false, false),
  ];

  settings: TableSettings = new TableSettings("view.admin.networks.title");
  actions: string[];
  actionMap: { [id: string]: (c: APINetwork) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }
  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _networkService: NetworkService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.actions = Object.keys(this.actionMap)
    this._networkService.adminList().subscribe(
      (networks: APINetwork[]) => {
        this.networks = networks
        this.loading = false;
      }
    )
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onAdd(_: AddEvent) {
    this._router.navigateByUrl(`${this.baseURL}/new`)
  }

  view(r: APINetwork) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}`)
  }

  edit(r: APINetwork) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}/edit`)
  }

  delete(c: APINetwork) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) return
      this._networkService.delete(c.id).subscribe(
        () => {
          this.networks = this.networks.filter((e: APINetwork) => { return e.id !== c.id; })
          this._toastService.saved()
        }, (error: any) => {
          this._toastService.server()
        });
    });
  }
}
