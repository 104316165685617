import { Component, OnInit, Input, Output, EventEmitter, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { IconDefinition, faEllipsisV, faAngleUp, faAngleDown, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from '../table.component';

export type RowItem = any;
export type RowAction = string;
export interface RowEvent { action: RowAction; item: RowItem; };

@Pipe({
  name: 'columnValue'
})
export class ColumnValuePipe implements PipeTransform {
  transform(item: RowItem, key: string): any {
    return key.split('.').reduce((o, k) => { return o[k]; }, item);
  }
}

@Component({
  // selector: 'tr[app-table-row]',
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss']
})
export class TableRowComponent implements OnInit {
  pipe = new ColumnValuePipe;

  @Input("item") item: RowItem;
  @Input("columns") columns: TableColumn[];
  @Input("actions") actions: RowAction[];
  @Input("template") template: TemplateRef<any>;
  @Input("fieldTemplate") fieldTemplate: TemplateRef<any>;
  @Output("rowAction") rowAction = new EventEmitter<RowEvent>();
  values: RowItem[] = []
  optionsIcon: IconDefinition = faEllipsisV;
  boolIconTrue: IconDefinition = faCheck;
  boolIconFalse: IconDefinition = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  expanded: boolean = false;

  constructor() { }

  ngOnInit() {
    this.values = []
    this.columns.forEach((c: TableColumn) => {
      this.values.push(this.pipe.transform(this.item, c.key))
    });
  }

  handleAction(action: RowAction) {
    this.rowAction.emit({ action: action, item: this.item })
  }

}
