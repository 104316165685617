<div class="container-fluid">
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.account.info.title' | translate }}</h5>
        </div>
        <fieldset disabled>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="form-group">
                        <label for="first_name"> {{ 'view.account.info.firstname' | translate }} </label>
                        <input type="text" class="form-control" id="first_name" [(ngModel)]="user.first_name">
                    </div>
                    <div class="form-group">
                        <label for="last_name"> {{ 'view.account.info.lastname' | translate }} </label>
                        <input type="text" class="form-control" id="last_name" [(ngModel)]="user.last_name">
                    </div>
                    <div class="form-group">
                        <label for="email"> {{ 'view.account.info.email' | translate }} </label>
                        <input type="text" class="form-control" id="email" [(ngModel)]="user.info.email">
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.account.password.title' | translate }}</h5>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <div class="form-group">
                    <label for="current-password" class="form-label">{{ 'view.account.password.current' | translate
                        }}</label>
                    <input class="form-control" type="password" id="current-password"
                        [(ngModel)]="passwordForm.current_password">
                </div>
                <div class="form-group">
                    <label for="first-password" class="form-label">{{ 'view.account.password.new' | translate }}</label>
                    <input class="form-control" type="password" id="first-password" [(ngModel)]="passwordForm.password">
                </div>
                <div class="form-group">
                    <label for="second-password" class="form-label">{{ 'view.account.password.repeat' | translate
                        }}</label>
                    <input class="form-control" type="password" id="second-password"
                        [(ngModel)]="passwordForm.confirm_password">
                </div>
                <button
                    [disabled]="!passwordForm.current_password || !passwordForm.password || !passwordForm.confirm_password"
                    type="button" class="btn btn-outline-primary btn-sm float-right" (click)="update_password()">
                    {{ 'buttons.save' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="section-header">
            <h5>{{ 'view.account.agreement.title' | translate }}</h5>
        </div>
        <div class="mt-3">
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="showAgreement()">
                {{ 'view.account.agreement.show' | translate }}
            </button>
        </div>
    </div>
</div>
