import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ObjectSaving } from '../../components/new/company/company-measure/company-measure.component';
import { CategoryService } from './category.service';
import { resourceIDFromURL } from './common/resourceIDFromURL';
import { APIObjectDetailed } from './company-object.service';
import { APIEndUseCategory, CO2APIModel, EnergyService, NewEUForm, SuppliedAPIModel, SuppliedEnergyForm, UsedAPIModel } from './energy.service';
import { PublicUrlService } from './public-url.service';
import { Saving } from './savings.service';
import { ToolbarService } from './toolbar.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  baseURL: string = "/api/v2/networks"

  private selectedNetworkObjects: APIObjectDetailed[] = []
  private selectedNetwork: APINetwork

  constructor(
    private http: HttpClient,
    private _toolbarService: ToolbarService,
    private _categoryService: CategoryService,
    private _energyService: EnergyService,
    private _publicUrlService: PublicUrlService,
  ) { }

  // Runs when the page is refreshed to preload the selected network from URL
  preloadSelectedNetwork(): Observable<APIObjectDetailed[]> {
    let networkID = resourceIDFromURL("networks")
    if (networkID) {
      return this.selectNetwork(Number(networkID))
    }
    return of([])
  }

  currentObjects(): APIObjectDetailed[] {
    return this.selectedNetworkObjects
  }

  currentNetwork(): APINetwork {
    return this.selectedNetwork
  }

  currentViews(): { [view_key: string]: boolean } {
    let urlSettings = this._publicUrlService.currentSettings()
    if(urlSettings) {
      return urlSettings.views
    }
    return this.selectedNetwork.views
  }

  currentConfig(): NetworkConfig {
    let urlSettings = this._publicUrlService.currentSettings()
    if(urlSettings) {
      return urlSettings.config
    }
    return this.selectedNetwork.config
  }

  selectNetwork(networkID: number): Observable<APIObjectDetailed[]> {
    const loadNetwork = this.getNetwork(networkID)
    const loadObjects = this.getObjects(networkID)
    return forkJoin([loadNetwork, loadObjects]).pipe(
      map(
        (data: [APINetwork, APIObjectDetailed[]]) => {
          this.selectedNetworkObjects = data[1]
          this.selectedNetwork = data[0]
          this._toolbarService.setTitle({ title: data[0].name, icon: faProjectDiagram })
          return data[1]
        }
      )
    );
  }

  getUserNetworks(userID: number): Observable<APINetwork[]> {
    return this.http.get(`/api/v2/networks`).pipe(
      map((res: APINetwork[]) => { return res })
    );
  }

  getObjects(networkID: number): Observable<APIObjectDetailed[]> {
    return this.http.get(`/api/v2/networks/${networkID}/objects`).pipe(
      map((res: APIObjectDetailed[]) => {
        let objects: APIObjectDetailed[] = []
        res.forEach(o => {
          objects.push(new APIObjectDetailed(o))
        })
        return objects
      })
    );
  }

  addObjects(networkID: number, data: number[]): Observable<APIObjectDetailed[]> {
    return this.http.post(`/api/v2/networks/${networkID}/objects`, { objects: data }).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    );
  }

  removeObjects(companyID: number, data: number[]): Observable<APIObjectDetailed[]> {
    const options = { headers: { 'Content-Type': 'application/json' }, body: { objects: data } }
    return this.http.request("delete", `/api/v2/networks/${companyID}/objects`, options).pipe(
      map((res: APIObjectDetailed[]) => { return res })
    )
  }

  getMembers(networkID: number): Observable<APIMember[]> {
    return this.http.get(`/api/v2/networks/${networkID}/members`).pipe(
      map((res: APIMember[]) => { return res })
    );
  }

  addMembers(networkID: number, data: number[]): Observable<APIMember[]> {
    return this.http.post(`/api/v2/networks/${networkID}/members`, { members: data }).pipe(
      map((res: APIMember[]) => { return res })
    );
  }

  removeMembers(networkID: number, data: number[]): Observable<APIMember[]> {
    const options = { headers: { 'Content-Type': 'application/json' }, body: { members: data } }
    return this.http.request("delete", `/api/v2/networks/${networkID}/members`, options).pipe(
      map((res: APIMember[]) => { return res })
    )
  }

  getNetwork(networkID: number): Observable<APINetwork> {
    return this.http.get(`/api/v2/networks/${networkID}`).pipe(
      map((res: APINetwork) => { return new APINetwork(res) })
    )
  }

  listNetworks(): Observable<APINetwork[]> {
    return this.http.get("/api/v2/networks").pipe(
      map((res: APINetwork[]) => { return res })
    )
  }

  adminList(): Observable<APINetwork[]> {
    return this.http.get("/api/v2/admin/networks").pipe(
      map((res: APINetwork[]) => { return res })
    )
  }

  create(form: NetworkForm): Observable<APINetwork> {
    return this.http.post("/api/v2/networks/", form).pipe(
      map((res: APINetwork) => { return res })
    )
  }

  update(id: number, form: NetworkForm): Observable<APINetwork> {
    return this.http.put(`/api/v2/networks/${id}`, form).pipe(
      map((res: APINetwork) => { return res })
    )
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`/api/v2/networks/${id}`)
  }

  setLicense(id: number, licenseID: number): Observable<any> {
    return this.http.put(`/api/v2/admin/networks/${id}/license/${licenseID}`, null)
  }

  updateConfig(id: number, config: NetworkConfig): Observable<NetworkConfig> {
    return this.http.put(`api/v2/networks/${id}/config`, config).pipe(
      map((res: NetworkConfig) => { return res })
    )
  }

  savings(id: number, objects: number[], lang?: string, currency?: string): Observable<NetworkSavings> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    if (!!currency) {
      params = params.append('currency', currency)
    }
    if (!!lang) {
      params = params.append('lang', lang)
    }
    return this.http.get(`${this.baseURL}/${id}/energy/savings`, { params: params }).pipe(
      map((res: NetworkSavings) => {
        return res
      })
    )
  }

  supplied(id: number, objects: number[], years: number[]): Observable<SuppliedEnergyForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('year', years.toString())
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: { [id: number]: SuppliedAPIModel[] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let res: SuppliedEnergyForm[][] = []
        Object.keys(co2).forEach(objectID => {
          let objectForms = this._energyService.toSuppliedForms(Number(objectID), supplied[objectID], co2[objectID])
          res.push(objectForms)
        })
        return res
      })
    )
  }

  suppliedLatest(id: number, objects: number[], latest: number = 1): Observable<SuppliedEnergyForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('latest', String(latest))
    let energy = this.fetchSupplied(id, params)
    let co2 = this.fetchC02(id, params)
    return forkJoin([energy, co2]).pipe(
      map(data => {
        let supplied: { [id: number]: SuppliedAPIModel[] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let res: SuppliedEnergyForm[][] = []
        Object.keys(supplied).forEach(objectID => {
          let objectForms = this._energyService.toSuppliedForms(Number(objectID), supplied[objectID], co2[objectID])
          res.push(objectForms)
        })
        return res
      }
      ))
  }

  used(id: number, objects: number[], years: number[]): Observable<NewEUForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('year', years.toString())
    let energy = this.fetchUsed(id, params)
    let co2 = this.fetchC02(id, params)
    let categories = this._energyService.v2_getEndUseCategories(sessionStorage.getItem("lang"))
    return forkJoin([energy, co2, categories]).pipe(
      map(data => {
        let used: { [id: number]: UsedAPIModel[][] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let categories: APIEndUseCategory[] = data[2]
        let res: NewEUForm[][] = []
        Object.keys(used).forEach(objectID => {
          let objectForms = this._energyService.toUsedForms(Number(objectID), used[objectID], co2[objectID], categories)
          res.push(objectForms)
        })
        return res
      })
    )
  }

  usedLatest(id: number, objects: number[], latest: number = 1): Observable<NewEUForm[][]> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    params = params.append('latest', String(latest))
    let energy = this.fetchUsed(id, params)
    let co2 = this.fetchC02(id, params)
    let categories = this._energyService.v2_getEndUseCategories(sessionStorage.getItem("lang"))
    return forkJoin([energy, co2, categories]).pipe(
      map(data => {
        let used: { [id: number]: UsedAPIModel[][] } = data[0]
        let co2: { [id: number]: CO2APIModel[] } = data[1]
        let categories: APIEndUseCategory[] = data[2]
        let res: NewEUForm[][] = []
        Object.keys(used).forEach(objectID => {
          let objectForms = this._energyService.toUsedForms(Number(objectID), used[objectID], co2[objectID], categories)
          res.push(objectForms)
        })
        return res
      })
    )
  }

  benchmark(id: number, objects: number[], benchmarkID: number): Observable<BenchmarkResult> {
    let params = new HttpParams();
    params = params.append('objects', objects.toString())
    return this.http.get(`${this.baseURL}/${id}/benchmark/${benchmarkID}`, { params: params }).pipe(
      map((data: any) => {
        let res: BenchmarkResult = new BenchmarkResult
        res.others = data.data

        let total: number[] = [...data.data]
        Object.values(data.values).forEach((v: number) => {
          if (v != 0) total.push(v)
        })

        Object.entries(data.values).forEach(([objectID, value]) => {
          if (value == 0) {
            res.missing.push(Number(objectID))
            return
          }

          let objectResult: ObjectResult = new ObjectResult
          objectResult.objectID = Number(objectID)
          objectResult.value = Number(value)

          // Calculate percentage
          let meanValues: number[] = [...total]
          let idx = total.findIndex(o => o == value)
          if (idx != -1) {
            meanValues.splice(idx, 1)
          }
          let percentage: number = 1
          if (meanValues.length != 0) {
            percentage = Number(value) / _.mean(meanValues)
          }
          objectResult.percentage = percentage

          res.objects.push(objectResult)
        });
        return res
      })
    )
  }

  private fetchSupplied(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`/api/v2/networks/${id}/energy/supplied`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

  private fetchUsed(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}/energy/used`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

  private fetchC02(id: number, params: HttpParams): Observable<any> {
    return this.http.get(`/api/v2/networks/${id}/energy/co2`, { params: params }).pipe(
      map(data => {
        Object.entries(data).forEach(([key, val]) => {
          data[key] = Object.values(val)
        });
        return data
      })
    )
  }

}

export class APINetwork {
  id: number
  nr_members: number
  nr_objects: number
  owner_name: string
  name: string
  description: string
  owner: number
  public_urls: PublicURL[] = []
  config: NetworkConfig = new NetworkConfig
  views: {} = {}

  constructor(obj?: any) {
    if (obj) {
      for (var key in obj) {
        this[key] = obj[key]
      }
    }
  }
}

export class NetworkConfig {
  id: number
  time_years: string[] = []
  anonymize_objects: boolean
  object_recognition: boolean
  location_level: string
  sni_level: string
  license: number
}

export class PublicURL {
  name: string = ""
  key: string = ""
  expire_date: string = ""
  password_enabled: boolean = false
  user: number
  network: number
  views: { [view_key: string]: boolean } = {}
  config: NetworkConfig = new NetworkConfig

  constructor(obj?: any) {
    if (obj) {
      for (var key in obj) {
        this[key] = obj[key]
      }
    }
  }
}

export class NetworkForm {
  name: string
  description: string
  owner: number
}

export class APIMember {
  id: number
  name: string
  email: string
}

export interface NetworkSavings {
  [id: string]: Saving[];
}

class ObjectResult {
  objectModel: APIObjectDetailed
  objectID: number
  value: number
  percentage: number
}

class BenchmarkResult {
  objects: ObjectResult[] = []
  others: number[] = []
  missing: number[] = []
}