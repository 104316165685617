<div class="row pl-3 pr-3">
    <div class="col-md-6 pr-5">
        <div class="form-group">
            <label>{{ 'view.sites.savings.subcategory' | translate }}</label>
            <input type="text" disabled class="form-control"
                value="{{ measure.subcategory_name }}">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-row">
            <div class="form-group col-md-4">
                <label>
                    {{ 'view.sites.savings.payback' | translate }}
                </label>
                <app-year-input disabled="true" [value]="measure.payoff"></app-year-input>
            </div>
            <div class="form-group col-md-4">
                <label>
                    {{ 'view.sites.savings.present' | translate }}
                </label>
                <app-currency-input disabled="true" [currency]="currency" value="{{ measure.net_present }}"></app-currency-input>
            </div>
            <div class="form-group col-md-4">
                <label>
                    {{ 'view.sites.savings.lcc' | translate }}
                </label>
                <app-currency-input disabled="true" [currency]="currency" value="{{ measure.lcc }}"></app-currency-input>
            </div>
        </div>
    </div>
</div>
<div class="row pl-3 pr-3">
    <div class="col-md-6 pr-5">
        <div class="form-group">
            <label for="comment_existing">
                {{ 'view.sites.savings.existing' | translate }}
            </label>
            <textarea disabled rows="4" class="form-control" id="comment_existing">{{ measure.comment_existing_system }}</textarea>
        </div>

        <div class="form-group">
            <label for="comment_new_system">
                {{ 'view.sites.savings.new' | translate }}
            </label>
            <textarea disabled rows="4" class="form-control" id="comment_new_system">{{ measure.comment_new_system }}</textarea>
        </div>

        <div class="form-group">
            <label for="comment_extent">
                {{ 'view.sites.savings.extent' | translate }}
            </label>
            <textarea disabled rows="4" class="form-control" id="comment_extent">{{ measure.comment_extent }}</textarea>
        </div>
    </div>
    <div class="col-md-6">
        <ngb-tabset (tabChange)="onTabChange($event)">
            <ngb-tab title="{{ 'view.sites.follow-up.energy-tab' | translate }}" id="energy" (click)="loadEnergyData()"></ngb-tab>
            <ngb-tab title="{{ 'view.sites.follow-up.co2-tab' | translate }}" id="co2" (click)="loadCo2Data()"></ngb-tab>
        </ngb-tabset>
        <div>
            <canvas #chart class="mt-3" id="energyChart"></canvas>
        </div>
    </div>
</div>