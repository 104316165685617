<a class="nav-item" [routerLink]="item.route" routerLinkActive="active"
  [routerLinkActiveOptions]="item.subItems.length > 0 || item.skip_exact_match? '' : {exact: true}" #openTmpl="routerLinkActive">
  <div class="content-box">
    <span class="icon-box">
      <fa-icon [icon]="item.icon"></fa-icon>
    </span>
    <span>{{ item.name | translate }}</span>
  </div>
  <fa-icon *ngIf="item.hasSubItems()" [icon]="showSubItems? faAngleUp : faAngleDown"
    (click)="open(); $event.stopPropagation(); $event.preventDefault();"></fa-icon>
</a>

<div id="subitem-container" *ngIf="showSubItems || openTmpl.isActive">
  <a *ngFor="let subItem of subItems" class="nav-item nav-sub-item" [routerLink]="subItem.route"
    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
    <span>{{ subItem.name | translate }}</span>
  </a>
</div>