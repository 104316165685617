import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RowEvent } from '../../../../../shared/components/table/table-row/table-row.component';
import { AddEvent, TableColumn, TableSettings } from '../../../../../shared/components/table/table.component';
import { APIObjectLicense, LicenseService } from '../../../../../shared/services/license.service';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss']
})
export class ObjectComponent implements OnInit {

  licenses: APIObjectLicense[] = [];

  baseURL: string = "/admin/licenses/site"

  columns: TableColumn[] = [
    new TableColumn("view.admin.licenses.object.name", "name"),
    new TableColumn("view.admin.licenses.object.price", "price", true, true, "", "kr"),
    new TableColumn("view.admin.licenses.object.users", "nr_users"),
  ];

  settings: TableSettings = new TableSettings("view.admin.licenses.object.title");
  actions: string[];
  actionMap: { [id: string]: (c: APIObjectLicense) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.edit": this.edit.bind(this),
    "buttons.delete": this.delete.bind(this),
  }

  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _licenseService: LicenseService,
    private _router: Router,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.actions = Object.keys(this.actionMap)
    this._licenseService.objectList().subscribe(
      (licenses: APIObjectLicense[]) => {
        this.licenses = licenses
        this.loading = false;
      }
    )
  }

  onAction(event: RowEvent) { let f = this.actionMap[event.action]; f(event.item); }

  onAdd(_: AddEvent) {
    this._router.navigateByUrl(`${this.baseURL}/new`)
  }

  view(r: APIObjectLicense) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}`)
  }

  edit(r: APIObjectLicense) {
    this._router.navigateByUrl(`${this.baseURL}/${r.id}/edit`)
  }

  delete(r: APIObjectLicense) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) return
      this._licenseService.objectDelete(r.id).subscribe(
        () => {
          this.licenses = this.licenses.filter((e: APIObjectLicense) => { return e.id !== r.id; })
          this._toastService.saved()
        }, (error: any) => {
          this._toastService.server()
        });
    });
  }

}
