import { Component, OnInit } from '@angular/core';
import { AgreementService, APIAgreementModel } from '../../../../shared/services/agreement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementModalComponent } from '../../../home/agreement-modal/agreement-modal.component';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  agreement: APIAgreementModel = new APIAgreementModel;

  constructor(
    private _agreementService: AgreementService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
  ) { }

  ngOnInit() {
    this._agreementService.v2_getAgreement().subscribe(
      (resp: APIAgreementModel) => { this.agreement = resp })
  }

  preview() {
    const modalRef = this._modalService.open(AgreementModalComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.data = this.agreement
    modalRef.componentInstance.preview = true
  }

  publish() {
    this._agreementService.v2_postAgreement(this.agreement).subscribe(
      (resp => {
        this.agreement = resp
        this._toastService.saved()
      })
    )
  }

}
