<div class="container">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="card panel-login">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <form id="login-form" role="form">
                <div class="form-group in-panel-body">
                  <div class="input-group">
                    <span class="input-group-addon" id="email-addon"><i class="fa fa-lock fa-fw"></i></span>
                    <input type="email" name="email" id="firstEmail" tabindex="2" class="form-control"
                      placeholder="{{ 'account.email.new' | translate }}" aria-describedby="email-addon" [(ngModel)]="emailForm.email">
                  </div>
                </div>
                <div class="form-group in-panel-body">
                  <div class="input-group">
                    <span class="input-group-addon" id="email-addon"><i class="fa fa-lock fa-fw"></i></span>
                    <input type="email" name="email" id="secondEmail" tabindex="2" class="form-control"
                      placeholder="{{ 'account.email.repeat' | translate }}" aria-describedby="email-addon" [(ngModel)]="emailForm.confirm_email">
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                      <input type="submit" name="login-submit" id="login-submit" tabindex="4" class="form-control btn btn-primary btn-login"
                        value="{{ 'account.email.change' | translate }}" (click)="changeEmail();">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>