import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyObjectService, APIObjectDetailed } from '../../../../shared/services/company-object.service';
import { TableColumn, TableSettings, AddEvent } from '../../../../shared/components/table/table.component';
import { RowEvent } from '../../../../shared/components/table/table-row/table-row.component';
import { DemoFormComponent } from './demo-form/demo-form.component';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../../shared/services/toast.service';


@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  companies: APIObjectDetailed[] = [];
  templates: APIObjectDetailed[] = [];

  columns: TableColumn[] = [
    new TableColumn("view.admin.demo.name", "name"),
    new TableColumn("view.admin.demo.org", "org_nr", false, false),
    new TableColumn("view.admin.demo.nace", "sni_data.code"),
    new TableColumn("view.admin.demo.municipality", "geo.municipality.name", false, false),
    new TableColumn("view.admin.demo.region", "geo.state.name", false, false)
  ];
  settings: TableSettings = new TableSettings("view.admin.demo.title");
  actions: string[];
  actionMap: { [id: string]: (c: APIObjectDetailed) => void } = {
    "buttons.view": this.view.bind(this),
    "buttons.delete": this.delete.bind(this),
  }
  @ViewChild("confirm") modal: ElementRef;
  warning = faExclamationTriangle;
  loading: boolean = true;

  constructor(
    private _objectService: CompanyObjectService,
    private _toastService: ToastService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.actions = Object.keys(this.actionMap);
    this._objectService.adminListDetailed().subscribe((companies: APIObjectDetailed[]) => {
      this.companies = companies.filter((c: APIObjectDetailed) => { return c.demo; });
      this.templates = companies.filter((c: APIObjectDetailed) => { return c.template; });
      this.loading = false;
    });
  }

  onAction(event: RowEvent) { this.actionMap[event.action](event.item) }

  onAdd(_: AddEvent) { this.open().result.then((c: APIObjectDetailed) => { this.companies = [...this.companies, c]; }, e => { }) }

  view(c: APIObjectDetailed) { this.open(c, false) }

  delete(c: APIObjectDetailed) {
    this._modalService.open(this.modal, {
      centered: true,
      backdrop: "static",
    }).result.then((ok: boolean) => {
      if (!ok) { return }
      this._objectService.delete(c.id).subscribe((res: any) => {
        this.companies = this.companies.filter((e: APIObjectDetailed) => { return e.id !== c.id; })
        this._toastService.saved()
      }, (error: any) => {
        this._toastService.server()
      });
    });
  }

  open(company?: APIObjectDetailed, edit: boolean = true) {
    let modalRef = this._modalService.open(DemoFormComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.edit = edit;
    modalRef.componentInstance.templates = this.templates;
    if (company) {
      modalRef.componentInstance.companyRef = company;
    }
    return modalRef;
  }

}
