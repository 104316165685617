import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as d3Sankey from 'd3-sankey-diagram';
import * as d3 from 'd3';
import { SankeyService, ProcessEntry } from './sankey.service';
import * as _ from 'lodash'
import { KeyvalueService } from '../../shared/services/keyvalue.service';
import { KeyValue, RootCauseEntry } from '../../shared/models/keyvalue.model';
import { DateSpan } from '../../shared/components/date-span/date-span.component';
import { FormulaService } from '../../shared/services/formula.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SensorService } from '../../shared/services/sensor.service';
import { ToastrService } from 'ngx-toastr';
import * as SvgPanZoom from 'svg-pan-zoom';

@Component({
    selector: 'app-sankey',
    templateUrl: './sankey.component.html',
    styleUrls: ['./sankey.component.scss']
})
export class SankeyComponent implements OnInit {
    // colorGenerator = d3.scaleOrdinal(d3.schemeSet3);
    opacity = 0.6;
    colorGenerator = d3.scaleOrdinal([
        d3.rgb(218, 93, 181, this.opacity),
        d3.rgb(120, 67, 58, this.opacity),
        d3.rgb(128, 78, 175, this.opacity),
        d3.rgb(202, 17, 31, this.opacity),
        d3.rgb(39, 147, 33, this.opacity),
        d3.rgb(253, 105, 15, this.opacity),
        d3.rgb(27, 98, 165, this.opacity),
        d3.rgb(232, 192, 57, this.opacity),
        d3.rgb(252, 136, 152, this.opacity),
        d3.rgb(175, 179, 26, this.opacity),
        d3.rgb(108, 108, 108, this.opacity),
        d3.rgb(30, 178, 195, this.opacity)
    ]);
    svgPanZoom: SvgPanZoom.Instance
    sankeyRawData: any = {}
    selectedDateSpan: DateSpan
    history: string[] = ["overview"]
    currentView: ProcessEntry = new ProcessEntry
    currentViewName: string = ""

    invalidSankey: boolean = false

    graphView: any
    selectedGraphView: string

    currentKeyValues: KeyValueSummary
    selectedKeyValue: KeyValueSummaryEntry
    @ViewChild('sankey', { static: true }) sankeyRef: ElementRef;
    constructor(
        private _sankeyService: SankeyService,
        private _keyvalueService: KeyvalueService,
        private _formulaService: FormulaService,
        private _modalService: NgbModal,
        private _sensorService: SensorService,
        private _notifyService: ToastrService
    ) { }

    ngOnInit() {
        this.currentKeyValues = new KeyValueSummary
        this.graphView = [
            {show: "Energy", data: "energy"},
            {show: "Exergy", data: "exergy"},
        ]
        this.selectedGraphView = 'energy'
    }

    loadDemo() {
        this._sankeyService.loadDemo()
    }

    clearAllData() {
        this._sankeyService.clear()
        window.location.reload()
    }

    validNodeClick(node): boolean {
        return node && this.currentViewName != node.id && node.id != "input" && node.id != "output"
    }

    selectKeyValue(k: KeyValueSummaryEntry) {
        this.selectedKeyValue = k
    }

    changeDateSpan(e) {
        this.selectedDateSpan = e
        this.showView(this.selectedDateSpan)
    }

    changeGraphView() {
        this.showView(this.selectedDateSpan)
    }

    generate(s: Sankey) {
        this.invalidSankey = false
        let data = _.cloneDeep(s)
        data.nodes = _.uniqWith(data.nodes, _.isEqual)
        data.links = _.uniqWith(data.links, _.isEqual)

        //Validate the links
        let validLinks: SankeyLink[] = []
        data.links.forEach(l => {
            if (l.value) {
                validLinks.push(l)
            }
        })

        if (validLinks.length == 0) {
            console.log("No valid links. Not drawing shit.")
            //Clear the graph
            d3.select('#sankey svg').selectAll("*").remove()
            this.invalidSankey = true
            return
        }

        if (validLinks.length < data.links.length) {
            console.log("Dead link removed. Drawing rest");
        }

        data.links = validLinks

        console.log("Generating from:", data)
        let width = this.sankeyRef.nativeElement.offsetWidth
        let height = this.sankeyRef.nativeElement.offsetHeight
        var layout = d3Sankey.sankey().extent([[50, 30], [width - 100, height - 30]]).nodeWidth(5)
        var graph = layout(data)
        var genColor = this.colorGenerator
        var diagram = d3Sankey.sankeyDiagram()
            .linkColor(function (d) { return d.color || genColor(d.type) })
            .on('selectNode', (node) => {
                if (!this.validNodeClick(node)) return
                this.changeView(node.id)
            })
        d3.select('#sankey svg').datum(graph).call(diagram);
        this.svgPanZoom = SvgPanZoom('#sankey svg', {
            controlIconsEnabled: true, 
            mouseWheelZoomEnabled: false,
            dblClickZoomEnabled: false,
        });
        this.svgPanZoom.updateBBox() // Update viewport bounding box
        this.svgPanZoom.resize(); // Recalculate size of svg
        this.svgPanZoom.fit(); // Fit within svg
        this.svgPanZoom.center(); // Center inside of svg
    }

    showSingleComponent(span: DateSpan) {
        let s: Sankey = new Sankey
        let allowed = ['input', 'output', this.currentViewName]
        s.nodes.push({ id: this.currentViewName })

        this.currentView.links.forEach(uuid => {
            let l = this._sankeyService.findLinkByUUID(uuid)
            let linkValSum = this._formulaService.sum(l.equations[this.selectedGraphView], span)
            let newLink = new SankeyLink(
                allowed.includes(l.source) ? l.source : "input",
                allowed.includes(l.target) ? l.target : "output",
                linkValSum,
                l.type)

            let i = _.findIndex(s.links, { source: newLink.source, target: newLink.target, type: newLink.type })
            i == -1 ? s.links.push(newLink) : s.links[i].value += newLink.value
        });
        this.generate(s)
    }

    evalutateKeyValues(k: KeyValue[], process?: string) {
        let res = []
        k.forEach(kv => {
            let kvEntry = new KeyValueSummaryEntry()
            kvEntry.keyvalue = kv
            kvEntry.value = this._keyvalueService.getValue(kv, this.selectedDateSpan)
            if (process) { kvEntry.process = process };
            kv.rootcauses.forEach(rcv => {
                let rcEntry = new RootCauseSummaryEntry()
                rcEntry.rootcause = rcv
                rcEntry.value = this._keyvalueService.getRootcauseValue(rcv, this.selectedDateSpan)
                kvEntry.rootcauses.push(rcEntry)
            })
            res.push(kvEntry)
        });
        return res
    }

    loadOverview(span: DateSpan) {
        let s: Sankey = new Sankey
        let processes = this._sankeyService.getComponentNames()
        this.currentKeyValues.self = this.evalutateKeyValues(this.currentView.keyvalues)
        processes.forEach(p => {
            let process = this._sankeyService.getProcessEntry([...this.history.slice(1), p])
            if (!_.isEmpty(process.keyvalues)) {
                this.currentKeyValues.sub.push(...this.evalutateKeyValues(process.keyvalues, p))
            }

            process.links.forEach(uuid => {
                let l = this._sankeyService.findLinkByUUID(uuid)

                let linkValSum = this._formulaService.sum(l.equations[this.selectedGraphView], span)
                s.links.push(new SankeyLink(l.source, l.target, linkValSum, l.type))
            })
            s.nodes.push({ id: p })
        })
        this.generate(s)
    }

    showView(span: DateSpan) {
        let s: Sankey = new Sankey
        this.currentKeyValues = new KeyValueSummary
        this.selectedKeyValue = new KeyValueSummaryEntry
        if (this.currentViewName != "") {
            this.currentKeyValues.self = this.evalutateKeyValues(this.currentView.keyvalues)
            if (_.isEmpty(this.currentView.labels)) {
                return this.showSingleComponent(span)
            }
            for (let process in this.currentView.labels) {
                let child = this._sankeyService.getProcessEntry([...this.history.slice(1), process])
                if (!_.isEmpty(child.keyvalues)) {
                    this.currentKeyValues.sub.push(...this.evalutateKeyValues(child.keyvalues, process))
                }

                child.links.forEach(uuid => {
                    let l = this._sankeyService.findLinkByUUID(uuid)
                    let linkValSum = this._formulaService.sum(l.equations[this.selectedGraphView], span)
                    s.links.push(new SankeyLink(l.source, l.target, linkValSum, l.type))
                })
                s.nodes.push({ id: process })
            }
        } else {
            return this.loadOverview(span)
        }
        this.generate(s)
    }

    back(dest: string) {
        if (dest == this.history[this.history.length - 1]) return;
        let index = this.history.indexOf(dest)
        if (index == 0) {
            this.history = [this.history[0]]
            this.currentView = new ProcessEntry
            this.currentViewName = ""
        } else {
            this.history = this.history.slice(0, index + 1)
            this.currentViewName = this.history[this.history.length - 1]
            this.currentView = this._sankeyService.getProcessEntry(this.history.slice(1))
        }
        this.showView(this.selectedDateSpan)
    }

    changeView(next: string) {
        this.history.push(next)
        this.currentViewName = next
        this.currentView = this._sankeyService.getProcessEntry(this.history.slice(1))
        this.showView(this.selectedDateSpan)
    }

    exportData() {
        var hiddenElement = document.createElement('a');
        let data = localStorage.getItem("sensorDB")

        if (data) {
            hiddenElement.href = 'data:text;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'sensors.nead';
            hiddenElement.click();
        }

        data = localStorage.getItem("sankeyDef")
        if (data) {
            hiddenElement.href = 'data:text;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'definition.nead';
            hiddenElement.click();
        }

        data = localStorage.getItem("sankeyLinkData")
        if (data) {
            hiddenElement.href = 'data:text;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'links.nead';
            hiddenElement.click();
        }
    }

    files: FileList
    @ViewChild("fileImportModal", { static: true }) fileModal: ElementRef;
    importData() {
        this._modalService.open(this.fileModal, { centered: true, size: 'lg' })
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            let reader = new FileReader()
            let file: File = event.target.files[0]
            reader.onload = () => {
                let type = file.name
                let key = ""
                let importType = ""

                let content = reader.result.toString()
                if (type == "sensors.nead") {
                    key = "sensorDB"
                    importType = "Sensor data"
                } else if (type == "links.nead") {
                    key = "sankeyLinkData"
                    importType = "Sankey link data"
                } else if (type == "definition.nead") {
                    key = "sankeyDef"
                    importType = "Sankey definition"
                }
                if (key != "") {
                    localStorage.setItem(key, content)
                    this._sankeyService.localLoad()
                    this._sensorService.reloadFromStorage()
                    this._notifyService.success(
                        `${importType} has been imported.`,
                        "Import",
                        {
                            timeOut: 1500
                        });
                }
            }
            reader.readAsText(file)
        }
    }

}

export class KeyValueSummary {
    self: KeyValueSummaryEntry[]
    sub: KeyValueSummaryEntry[]
    constructor() {
        this.self = []
        this.sub = []
    }
}

export class KeyValueSummaryEntry {
    value: number
    keyvalue: KeyValue
    rootcauses: RootCauseSummaryEntry[]
    process: string
    constructor() {
        this.rootcauses = []
    }
}

export class RootCauseSummaryEntry {
    value: number
    rootcause: RootCauseEntry
}

export class Sankey {
    nodes: SankeyNode[]
    links: SankeyLink[]
    alignLinkTypes: boolean
    constructor() {
        this.links = []
        this.nodes = [
            { id: 'input' },
            { id: 'output' }
        ]
    }
}

export class SankeyNode {
    id: string
    constructor(id?: string) {
        if (id) {
            this.id = id
        }
    }
}

export class SankeyLink {
    source: string
    target: string
    value: number
    type: string

    constructor(source: string, target: string, value: number, type: string) {
        this.source = source
        this.target = target
        this.value = value
        this.type = type
    }
}
