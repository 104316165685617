export class DemoSankeyData {
    definition() {
        return {
            "definition": {
                "kokeri": {
                    "labels": {},
                    "links": [
                        "a7edcc56-4a3f-4af9-ba62-c7ead7e4ee1d",
                        "ad24d20c-a92c-40e9-b249-46e86cecd858",
                        "427420ff-0372-4b99-865a-b8f146268cd8",
                        "d53d9d18-7ef4-4274-a18e-9bc4b0b4210f",
                        "83d7a217-c494-44c6-aaf2-f7a88855f9e3",
                        "82a9dbfb-31db-4503-b176-7dad5861352a",
                        "e7b2b4a9-d0e9-48d8-b001-eb0224602279",
                        "489eef5e-495d-4044-a42b-9b8023b0e175",
                        "97cc80eb-3e71-4b9d-afe8-df3b81e038e5",
                        "05ca782e-56fd-4610-b309-71050496f864",
                        "d29bdc55-f943-4f8c-aeb9-2df576fc4176",
                        "e900085e-9bc9-456b-8151-150d13175b27",
                        "8d2c6ccd-8059-4518-91d9-fefdc42cb388"
                    ],
                    "keyvalues": [
                        {
                            "name": "KV1",
                            "formula": [
                                {
                                    "linkID": "a7edcc56-4a3f-4af9-ba62-c7ead7e4ee1d",
                                    "equationName": "energy"
                                },
                                {
                                    "isOperator": true,
                                    "operator": "/"
                                },
                                {
                                    "linkID": "d526ff36-7ab8-4c3b-a85e-b356b4b58ec1",
                                    "equationName": "energy"
                                }
                            ],
                            "rootcauses": [
                                {
                                    "name": "Massflöde ved in",
                                    "impact": 0,
                                    "linkID": "a7edcc56-4a3f-4af9-ba62-c7ead7e4ee1d",
                                    "equationName": "massflow",
                                    "desc": "Massflöde ved in"
                                },
                                {
                                    "name": "Temperatur ånga in",
                                    "impact": 0,
                                    "linkID": "ad24d20c-a92c-40e9-b249-46e86cecd858",
                                    "equationName": "temperature",
                                    "desc": "Temperatur ånga in"
                                }
                            ]
                        }
                    ]
                },
                "blekeri": {
                    "labels": {},
                    "links": [
                        "489eef5e-495d-4044-a42b-9b8023b0e175",
                        "d29bdc55-f943-4f8c-aeb9-2df576fc4176",
                        "f158975d-2bb9-4035-ab17-d933da9eb6f5",
                        "d886383a-2ec3-4f6a-9723-bb91af4ead9e",
                        "bc82d2a7-a708-4695-98fc-85cc2aa571bb",
                        "9f8d7cef-bdeb-434a-ad21-509736abb114",
                        "cc38a1bc-e7e7-4c3a-a502-bea5af168665"
                    ],
                    "keyvalues": []
                },
                "tm": {
                    "labels": {},
                    "links": [
                        "bc82d2a7-a708-4695-98fc-85cc2aa571bb",
                        "184292ee-9b5a-4b1a-959a-e1b59f470270",
                        "612c4bd2-ffbe-4c8d-992e-2fa2f80be1a3",
                        "d526ff36-7ab8-4c3b-a85e-b356b4b58ec1"
                    ],
                    "keyvalues": []
                },
                "indunstning": {
                    "labels": {
                        "effekt1": {
                            "labels": {},
                            "links": [
                                "ecb713df-0d61-4481-bd1e-f2ce4fbc125a",
                                "79e5eed7-1411-4eaf-9f29-e34853d64b9f",
                                "69ad79d8-ac5b-4ace-bd67-1eeeee1bc00a",
                                "e3f3cd9f-0c61-48fd-8b8c-53ca27d6e928",
                                "86c79dcb-2e0c-461c-ac20-6f674592f828"
                            ],
                            "keyvalues": []
                        },
                        "effekt2": {
                            "labels": {},
                            "links": [
                                "e3f3cd9f-0c61-48fd-8b8c-53ca27d6e928",
                                "86c79dcb-2e0c-461c-ac20-6f674592f828",
                                "2ead4925-30e4-4078-a912-e5f912f395f2",
                                "eba9a176-98c7-4573-b4a4-f59da4e0ce10",
                                "59d80b55-a0b9-4f36-9fb1-2c6f31459b85"
                            ],
                            "keyvalues": []
                        },
                        "effekt3": {
                            "labels": {},
                            "links": [
                                "59d80b55-a0b9-4f36-9fb1-2c6f31459b85",
                                "3b2bee6a-7afe-4d33-bff0-547080202658",
                                "7b9e8851-5aea-404b-80c4-554662f68b55",
                                "1470277c-f1ff-4007-a8e3-c34349f337aa",
                                "bd475583-6b8c-4375-98a5-db2f500c9720"
                            ],
                            "keyvalues": []
                        },
                        "effekt4": {
                            "labels": {},
                            "links": [
                                "1470277c-f1ff-4007-a8e3-c34349f337aa",
                                "bd475583-6b8c-4375-98a5-db2f500c9720",
                                "55fa2a49-a903-4290-9bb2-522eff883132",
                                "5096fcb8-19bd-40ae-bdd4-12e4d1fde0af",
                                "23049a4f-e442-459f-8e41-f2e63a73612f"
                            ],
                            "keyvalues": []
                        },
                        "effekt5": {
                            "labels": {},
                            "links": [
                                "5096fcb8-19bd-40ae-bdd4-12e4d1fde0af",
                                "23049a4f-e442-459f-8e41-f2e63a73612f",
                                "052c6d05-d0c5-47af-80e8-6772bf5637c0",
                                "0e6c79e4-7852-4e28-9811-0c7b5992a70d",
                                "ecb713df-0d61-4481-bd1e-f2ce4fbc125a",
                                "bd9eb004-7b0b-4fae-b850-41e2ec772b0d",
                                "b556965a-ba7d-466d-bae9-20ec7a719b70"
                            ],
                            "keyvalues": []
                        }
                    },
                    "links": [
                        "97cc80eb-3e71-4b9d-afe8-df3b81e038e5",
                        "e1b7a6ba-f15b-42fc-ac41-76a76a8b5ea5",
                        "34b72af5-2f64-4d9a-8927-462055e01244",
                        "4453a4ce-fe9a-470a-a896-f394edb30df1",
                        "83d7a217-c494-44c6-aaf2-f7a88855f9e3",
                        "82a9dbfb-31db-4503-b176-7dad5861352a",
                        "4cedc1c7-012b-4ba6-8dc4-3e799505ca6f"
                    ],
                    "keyvalues": [
                        {
                            "name": "KV1",
                            "formula": [
                                {
                                    "linkID": "97cc80eb-3e71-4b9d-afe8-df3b81e038e5",
                                    "equationName": "value"
                                },
                                {
                                    "isOperator": true,
                                    "operator": "/"
                                },
                                {
                                    "linkID": "d526ff36-7ab8-4c3b-a85e-b356b4b58ec1",
                                    "equationName": "value"
                                }
                            ],
                            "rootcauses": [
                                {
                                    "name": "Massflöde svaglut in",
                                    "impact": 23,
                                    "linkID": "97cc80eb-3e71-4b9d-afe8-df3b81e038e5",
                                    "equationName": "massflow",
                                    "desc": "Massflöde svaglut in"
                                },
                                {
                                    "name": "Temperatur ånga",
                                    "impact": 0,
                                    "desc": "Temperatur ånga",
                                    "linkID": "e1b7a6ba-f15b-42fc-ac41-76a76a8b5ea5",
                                    "equationName": "temperature"
                                },
                                {
                                    "name": "Effekt 2 har slam i botten",
                                    "impact": 15,
                                    "desc": "Effekt 2 har slam i botten"
                                }
                            ]
                        }
                    ]
                },
                "mu": {
                    "labels": {},
                    "links": [
                        "e900085e-9bc9-456b-8151-150d13175b27",
                        "fa33a0a8-0c41-4c53-88ce-dda4421aa309",
                        "d5f0a13b-d271-40a6-b4f8-02119f963ec3",
                        "62dc571a-8d3d-486a-9da8-ab4fa9b9bc8e",
                        "2d9c1bc0-5adb-49ef-95cd-1dedfce056ad",
                        "1bbba020-75d5-4405-92a9-25e9d2060017"
                    ],
                    "keyvalues": []
                },
                "kemikalieåtervinning": {
                    "labels": {},
                    "links": [
                        "4cedc1c7-012b-4ba6-8dc4-3e799505ca6f",
                        "2d9c1bc0-5adb-49ef-95cd-1dedfce056ad",
                        "84574d4a-15a9-4e2b-b2fe-0eafae2f50dd",
                        "d2266c3a-b7d6-4fff-870a-c7c1d4230197",
                        "c688f86d-82b0-4124-849f-e1fe5a897542",
                        "0fa7ed65-9c51-4f61-8406-87887ae30555",
                        "d53d9d18-7ef4-4274-a18e-9bc4b0b4210f",
                        "ed13d372-d0a0-4b54-a81b-6fabda4780e2",
                        "d19ae079-b819-4a4b-8882-fa9ee9a58c2d",
                    ],
                    "keyvalues": []
                },
                "barkpanna": {
                    "labels": {},
                    "links": [
                        "f923dc1e-a8ea-4112-9137-e64e4602a661",
                        "b5bcac06-4faa-4824-9002-fbedbd01cd69",
                        "d64f4ce5-09d7-4721-b587-60eb30b93bae",
                        "0ac10e13-94be-4208-bdfd-0e75a9175d7e",
                        "716ca0de-407f-48b1-8f2f-b460472ec86b"
                    ],
                    "keyvalues": []
                },
                "turbiner": {
                    "labels": {},
                    "links": [
                        "ed13d372-d0a0-4b54-a81b-6fabda4780e2",
                        "0ac10e13-94be-4208-bdfd-0e75a9175d7e",
                        "ad24d20c-a92c-40e9-b249-46e86cecd858",
                        "427420ff-0372-4b99-865a-b8f146268cd8",
                        "f158975d-2bb9-4035-ab17-d933da9eb6f5",
                        "d886383a-2ec3-4f6a-9723-bb91af4ead9e",
                        "184292ee-9b5a-4b1a-959a-e1b59f470270",
                        "612c4bd2-ffbe-4c8d-992e-2fa2f80be1a3",
                        "e1b7a6ba-f15b-42fc-ac41-76a76a8b5ea5",
                        "34b72af5-2f64-4d9a-8927-462055e01244",
                        "c688f86d-82b0-4124-849f-e1fe5a897542",
                        "0fa7ed65-9c51-4f61-8406-87887ae30555",
                        "b4b7a5cb-2944-4b17-a13d-1f9eb0a047d2",
                        "90bfe9f4-c2a9-40df-a343-201b974492dd",
                        "e2ea82fc-5849-47d9-804d-e58e58d2f089",
                        "a7a0329c-bfab-42a5-a792-e907e8ddc833",
                        "7e23482c-7ded-470e-bb10-247b6adcdce2",
                    ],
                    "keyvalues": []
                },
                "förlust": {
                    "labels": {},
                    "links": [
                        "1bbba020-75d5-4405-92a9-25e9d2060017",
                        "d19ae079-b819-4a4b-8882-fa9ee9a58c2d",
                        "716ca0de-407f-48b1-8f2f-b460472ec86b",
                        "90bfe9f4-c2a9-40df-a343-201b974492dd",
                        "a7a0329c-bfab-42a5-a792-e907e8ddc833",
                        "7e23482c-7ded-470e-bb10-247b6adcdce2"
                    ],
                    "keyvalues": []
                }
            },
            "flows": [
                "Fiberlinje",
                "Lutflödet"
            ],
            "types": [
                "Ved",
                "Ånga",
                "El",
                "Vitlut",
                "Tallolja",
                "Sekv. (fr. ind.) lutkondensat",
                "Sekv. (fr. ind.) kondensorer",
                "Svartlut cirk",
                "Massa",
                "Svaglut",
                "Terpentin",
                "Sekv. Lutkylare",
                "Sekv. (fr kok) Lutkylare",
                "Fjärrvärme",
                "Avlopp till LAS (varmvatten)",
                "Mellanlut",
                "Svartlut",
                "VKM",
                "Sekv. (VHM)",
                "Sekv. (VVM)",
                "Sekv. (VHM) lutkondensat",
                "Sekv. (VVM) kondensorer",
                "Bark",
                "Olja",
                "Bioolja",
                "Kalk",
                "Rökgasförlust",
                "Övrigt",
                "Ånga SP6",
                "Ånga ÅP5",
                "Friblåsning",
                "Kyltorn"
            ]
        }
    }

    linkData() {
        return {
            "a7edcc56-4a3f-4af9-ba62-c7ead7e4ee1d": {
                "source": "input",
                "target": "kokeri",
                "type": "Ved",
                "uuid": "a7edcc56-4a3f-4af9-ba62-c7ead7e4ee1d",
                "equations": {
                    "energy": {
                        "formula": [
                            {
                                "name": "energy",
                                "constant": true,
                                "constantValue": 100
                            }
                        ]
                    }
                },
            },
            "ad24d20c-a92c-40e9-b249-46e86cecd858": {
                "source": "turbiner",
                "target": "kokeri",
                "type": "\u00c5nga"
            },
            "427420ff-0372-4b99-865a-b8f146268cd8": {
                "source": "turbiner",
                "target": "kokeri",
                "type": "El"
            },
            "d53d9d18-7ef4-4274-a18e-9bc4b0b4210f": {
                "source": "kemikalie\u00e5tervinning",
                "target": "kokeri",
                "type": "Vitlut"
            },
            "83d7a217-c494-44c6-aaf2-f7a88855f9e3": {
                "source": "indunstning",
                "target": "kokeri",
                "type": "Sekv. (fr. ind.) lutkondensat"
            },
            "82a9dbfb-31db-4503-b176-7dad5861352a": {
                "source": "indunstning",
                "target": "kokeri",
                "type": "Sekv. (fr. ind.) kondensorer"
            },
            "e7b2b4a9-d0e9-48d8-b001-eb0224602279": {
                "source": "input",
                "target": "kokeri",
                "type": "Tallolja"
            },
            "489eef5e-495d-4044-a42b-9b8023b0e175": {
                "source": "kokeri",
                "target": "blekeri",
                "type": "Massa"
            },
            "97cc80eb-3e71-4b9d-afe8-df3b81e038e5": {
                "source": "kokeri",
                "target": "indunstning",
                "type": "Svaglut"
            },
            "05ca782e-56fd-4610-b309-71050496f864": {
                "source": "kokeri",
                "target": "output",
                "type": "Tallolja"
            },
            "d29bdc55-f943-4f8c-aeb9-2df576fc4176": {
                "source": "kokeri",
                "target": "blekeri",
                "type": "Sekv. Lutkylare"
            },
            "e900085e-9bc9-456b-8151-150d13175b27": {
                "source": "kokeri",
                "target": "mu",
                "type": "Tallolja"
            },
            "8d2c6ccd-8059-4518-91d9-fefdc42cb388": {
                "source": "kokeri",
                "target": "output",
                "type": "Terpentin"
            },
            "f158975d-2bb9-4035-ab17-d933da9eb6f5": {
                "source": "turbiner",
                "target": "blekeri",
                "type": "\u00c5nga"
            },
            "d886383a-2ec3-4f6a-9723-bb91af4ead9e": {
                "source": "turbiner",
                "target": "blekeri",
                "type": "El"
            },
            "bc82d2a7-a708-4695-98fc-85cc2aa571bb": {
                "source": "blekeri",
                "target": "tm",
                "type": "Massa"
            },
            "9f8d7cef-bdeb-434a-ad21-509736abb114": {
                "source": "blekeri",
                "target": "output",
                "type": "Fj\u00e4rrv\u00e4rme"
            },
            "cc38a1bc-e7e7-4c3a-a502-bea5af168665": {
                "source": "blekeri",
                "target": "output",
                "type": "Avlopp till LAS (varmvatten)"
            },
            "184292ee-9b5a-4b1a-959a-e1b59f470270": {
                "source": "turbiner",
                "target": "tm",
                "type": "\u00c5nga"
            },
            "612c4bd2-ffbe-4c8d-992e-2fa2f80be1a3": {
                "source": "turbiner",
                "target": "tm",
                "type": "El"
            },
            "d526ff36-7ab8-4c3b-a85e-b356b4b58ec1": {
                "source": "tm",
                "target": "output",
                "type": "Massa"
            },
            "ecb713df-0d61-4481-bd1e-f2ce4fbc125a": {
                "source": "effekt5",
                "target": "effekt1",
                "type": "Mellanlut"
            },
            "79e5eed7-1411-4eaf-9f29-e34853d64b9f": {
                "source": "input",
                "target": "effekt1",
                "type": "\u00c5nga"
            },
            "69ad79d8-ac5b-4ace-bd67-1eeeee1bc00a": {
                "source": "input",
                "target": "effekt1",
                "type": "El"
            },
            "e3f3cd9f-0c61-48fd-8b8c-53ca27d6e928": {
                "source": "effekt1",
                "target": "effekt2",
                "type": "Mellanlut"
            },
            "86c79dcb-2e0c-461c-ac20-6f674592f828": {
                "source": "effekt1",
                "target": "effekt2",
                "type": "\u00c5nga"
            },
            "2ead4925-30e4-4078-a912-e5f912f395f2": {
                "source": "input",
                "target": "effekt2",
                "type": "El"
            },
            "eba9a176-98c7-4573-b4a4-f59da4e0ce10": {
                "source": "effekt2",
                "target": "output",
                "type": "Svartlut"
            },
            "59d80b55-a0b9-4f36-9fb1-2c6f31459b85": {
                "source": "effekt2",
                "target": "effekt3",
                "type": "\u00c5nga"
            },
            "3b2bee6a-7afe-4d33-bff0-547080202658": {
                "source": "input",
                "target": "effekt3",
                "type": "Svaglut"
            },
            "7b9e8851-5aea-404b-80c4-554662f68b55": {
                "source": "input",
                "target": "effekt3",
                "type": "El"
            },
            "1470277c-f1ff-4007-a8e3-c34349f337aa": {
                "source": "effekt3",
                "target": "effekt4",
                "type": "Mellanlut"
            },
            "bd475583-6b8c-4375-98a5-db2f500c9720": {
                "source": "effekt3",
                "target": "effekt4",
                "type": "\u00c5nga"
            },
            "55fa2a49-a903-4290-9bb2-522eff883132": {
                "source": "input",
                "target": "effekt4",
                "type": "El"
            },
            "5096fcb8-19bd-40ae-bdd4-12e4d1fde0af": {
                "source": "effekt4",
                "target": "effekt5",
                "type": "Mellanlut"
            },
            "23049a4f-e442-459f-8e41-f2e63a73612f": {
                "source": "effekt4",
                "target": "effekt5",
                "type": "\u00c5nga"
            },
            "052c6d05-d0c5-47af-80e8-6772bf5637c0": {
                "source": "input",
                "target": "effekt5",
                "type": "El"
            },
            "0e6c79e4-7852-4e28-9811-0c7b5992a70d": {
                "source": "input",
                "target": "effekt5",
                "type": "VKM"
            },
            "bd9eb004-7b0b-4fae-b850-41e2ec772b0d": {
                "source": "effekt5",
                "target": "output",
                "type": "Sekv. (VHM)"
            },
            "b556965a-ba7d-466d-bae9-20ec7a719b70": {
                "source": "effekt5",
                "target": "output",
                "type": "Sekv. (VVM)"
            },
            "e1b7a6ba-f15b-42fc-ac41-76a76a8b5ea5": {
                "source": "turbiner",
                "target": "indunstning",
                "type": "\u00c5nga"
            },
            "34b72af5-2f64-4d9a-8927-462055e01244": {
                "source": "turbiner",
                "target": "indunstning",
                "type": "El"
            },
            "4453a4ce-fe9a-470a-a896-f394edb30df1": {
                "source": "input",
                "target": "indunstning",
                "type": "VKM"
            },
            "4cedc1c7-012b-4ba6-8dc4-3e799505ca6f": {
                "source": "indunstning",
                "target": "kemikalie\u00e5tervinning",
                "type": "Svartlut"
            },
            "fa33a0a8-0c41-4c53-88ce-dda4421aa309": {
                "source": "input",
                "target": "mu",
                "type": "Olja"
            },
            "d5f0a13b-d271-40a6-b4f8-02119f963ec3": {
                "source": "input",
                "target": "mu",
                "type": "Bioolja"
            },
            "62dc571a-8d3d-486a-9da8-ab4fa9b9bc8e": {
                "source": "input",
                "target": "mu",
                "type": "Bark"
            },
            "2d9c1bc0-5adb-49ef-95cd-1dedfce056ad": {
                "source": "mu",
                "target": "kemikalie\u00e5tervinning",
                "type": "Kalk"
            },
            "1bbba020-75d5-4405-92a9-25e9d2060017": {
                "source": "mu",
                "target": "f\u00f6rlust",
                "type": "R\u00f6kgasf\u00f6rlust"
            },
            "84574d4a-15a9-4e2b-b2fe-0eafae2f50dd": {
                "source": "input",
                "target": "kemikalie\u00e5tervinning",
                "type": "\u00d6vrigt"
            },
            "d2266c3a-b7d6-4fff-870a-c7c1d4230197": {
                "source": "input",
                "target": "kemikalie\u00e5tervinning",
                "type": "Olja"
            },
            "c688f86d-82b0-4124-849f-e1fe5a897542": {
                "source": "turbiner",
                "target": "kemikalie\u00e5tervinning",
                "type": "\u00c5nga"
            },
            "0fa7ed65-9c51-4f61-8406-87887ae30555": {
                "source": "turbiner",
                "target": "kemikalie\u00e5tervinning",
                "type": "El"
            },
            "ed13d372-d0a0-4b54-a81b-6fabda4780e2": {
                "source": "kemikalie\u00e5tervinning",
                "target": "turbiner",
                "type": "\u00c5nga"
            },
            "d19ae079-b819-4a4b-8882-fa9ee9a58c2d": {
                "source": "kemikalie\u00e5tervinning",
                "target": "f\u00f6rlust",
                "type": "R\u00f6kgasf\u00f6rlust"
            },
            "f923dc1e-a8ea-4112-9137-e64e4602a661": {
                "source": "input",
                "target": "barkpanna",
                "type": "Bark"
            },
            "b5bcac06-4faa-4824-9002-fbedbd01cd69": {
                "source": "input",
                "target": "barkpanna",
                "type": "Olja"
            },
            "d64f4ce5-09d7-4721-b587-60eb30b93bae": {
                "source": "input",
                "target": "barkpanna",
                "type": "\u00d6vrigt"
            },
            "0ac10e13-94be-4208-bdfd-0e75a9175d7e": {
                "source": "barkpanna",
                "target": "turbiner",
                "type": "\u00c5nga"
            },
            "716ca0de-407f-48b1-8f2f-b460472ec86b": {
                "source": "barkpanna",
                "target": "f\u00f6rlust",
                "type": "R\u00f6kgasf\u00f6rlust"
            },
            "b4b7a5cb-2944-4b17-a13d-1f9eb0a047d2": {
                "source": "turbiner",
                "target": "output",
                "type": "\u00c5nga"
            },
            "90bfe9f4-c2a9-40df-a343-201b974492dd": {
                "source": "turbiner",
                "target": "f\u00f6rlust",
                "type": "El"
            },
            "e2ea82fc-5849-47d9-804d-e58e58d2f089": {
                "source": "turbiner",
                "target": "output",
                "type": "El"
            },
            "a7a0329c-bfab-42a5-a792-e907e8ddc833": {
                "source": "turbiner",
                "target": "f\u00f6rlust",
                "type": "Fribl\u00e5sning"
            },
            "7e23482c-7ded-470e-bb10-247b6adcdce2": {
                "source": "turbiner",
                "target": "f\u00f6rlust",
                "type": "Kyltorn"
            }
        }
    }
}