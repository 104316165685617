import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn, TableSettings } from '../../../../shared/components/table/table.component';
import { APINetwork, NetworkService } from '../../../../shared/services/network.service';
import { APIUser, UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'app-network-home',
  templateUrl: './network-home.component.html',
  styleUrls: ['./network-home.component.scss']
})
export class NetworkHomeComponent implements OnInit {

  user: APIUser

  networks: APINetwork[] = []
  loadingNetworks = true

  columns: TableColumn[] = [
    {
      name: "view.networks.home.name",
      key: "name",
      sort: true,
      search: true,
      template: true
    },
    {
      name: "view.networks.home.admin",
      key: "owner_name",
      sort: true,
      search: true
    },
    {
      name: "view.networks.home.members",
      key: "nr_members",
      sort: true,
      search: false
    },
    {
      name: "view.networks.home.sites",
      key: "nr_objects",
      sort: true,
      search: false
    }
  ];

  settings: TableSettings = {
    title: "view.networks.home.title",
    add: false,
  }

  constructor(
    private _networkService: NetworkService,
    private _userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this._userService.getUserInfo()
    this._networkService.getUserNetworks(this.user.id).subscribe(
      (data: APINetwork[]) => {
        this.networks = data
        this.loadingNetworks = false
      }
    )
  }

  navigateToNetwork(id: number) {
    this._networkService.selectNetwork(id).subscribe(
    data => {
      this.router.navigateByUrl(`/networks/${id}`)
    });
  }

}
